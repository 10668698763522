import React, { useState, useMemo, FC } from 'react';
import { Formik, Form } from 'formik';
import Question from 'app/constants/Question';
import checkboxCheckedImage from 'images/CheckedBoxDefault.svg';
import checkboxUncheckedImage from 'images/UncheckedBoxDefault.svg';
import { getComponentForQuestion, getDefaultValue } from 'app/components/customer/questions/helpers';
import { getIntakeSchema } from 'app/helpers/validators';
import ProtocolHeader from 'app/components/common/Typography/ProtocolHeader';
import { AvailableProducts } from 'app/constants/Products';

const INITIAL_STEPS = ['current_hair', 'facial_hair'];

const getNextStep = (steps, currentIndex, values) => {
  const nextStep = steps[currentIndex + 1];

  if (!nextStep) {
    return null;
  }

  if (values.current_hair === 'never' && ['current_hair', 'facial_hair'].includes(nextStep)) {
    return getNextStep(steps, currentIndex + 1, values);
  }

  return nextStep;
};

const Step0 = ({ onSubmit }) => {
  const [currentStep, setCurrentStep] = useState(INITIAL_STEPS[0]);
  const questions = useMemo(
    () => INITIAL_STEPS.reduce((o, q) => o.set(q, new Question(q)), new Map()),
    [INITIAL_STEPS],
  );
  const [initialValues, setInitialValues] = useState(
    INITIAL_STEPS.reduce((o, q) => ({ ...o, [q]: getDefaultValue({ question: questions.get(q) }) }), {}),
  );

  const question = questions.get(currentStep);
  const IntakeSchema = getIntakeSchema(question);
  const Component = getComponentForQuestion({ question }) as FC<{
    question: any;
    name: string;
    checkedImage: typeof checkboxCheckedImage;
    uncheckedImage: typeof checkboxUncheckedImage;
    contained: boolean;
  }>;

  const submitNextStep = (values) => {
    const nextStep = getNextStep(INITIAL_STEPS, INITIAL_STEPS.indexOf(currentStep), values);
    if (!nextStep) {
      const clearValues = Object.entries(values).reduce((o, [k, v]) => {
        if (v !== '') {
          o[k] = v;
        }

        return o;
      }, {});
      onSubmit(clearValues);
    } else {
      setInitialValues(values);
      setCurrentStep(nextStep);
    }
  };

  return (
    <Formik initialValues={initialValues} onSubmit={submitNextStep} validationSchema={IntakeSchema} enableReinitialize>
      {() => (
        <Form className="registration-flow registration-flow_warrior">
          <ProtocolHeader protocol={AvailableProducts.Warrior} />
          <h2 className="title title_sm">{question.sentence}</h2>
          <div className="component">
            <Component
              question={question}
              name={currentStep}
              checkedImage={checkboxCheckedImage}
              uncheckedImage={checkboxUncheckedImage}
              contained={false}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default Step0;
