import React, { useState } from 'react';
import { connect } from 'react-redux';

import { push } from 'connected-react-router/immutable';
import Question from 'app/constants/Question';
import * as selectors from 'app/selectors/customer';
import { Formik } from 'formik';
import { getIntakeSchema } from 'app/helpers/validators';

import { getComponentForQuestion, getDefaultValue, getOnSubmit } from './helpers';
import ProgressBar from './ProgressBar';
import { BlackTitle } from './Title';

import BackNextFooter from '../steps/BackNextFooter';
import './css/Questions.scss';

import DiscountBanner from '../steps/Payment/DiscountBanner';

const mapStateToProps = (state, { step }) => {
  const currentProductName = selectors.selectCurrentIntakeProduct(state);
  const curIntake = selectors.selectCurrentIntake(state);

  const currentStrictlyIntakeSteps = selectors.selectStrictlyProductIntakeStepsByLastIntake(state, currentProductName);

  const stepObject = selectors.selectCurrentStepObject(state);
  const position = currentStrictlyIntakeSteps.findIndex((s) => s.get('name') === stepObject.get('name')) + 1;

  const isPrevStepRevisitable = selectors.isPrevStepRevisitable(state);
  const initialValues = selectors.selectInitialIntakeFormValues(state).toJS();
  const onSuccessActionCreator = (_, stateAfterSuccess) =>
    push(selectors.selectNextStepPath({ ...state, customer: stateAfterSuccess }));
  const previousStepAction = push(selectors.selectPreviousStepPath(state));
  const question = new Question(step, currentProductName);
  const initial = initialValues[step];

  return {
    initial,
    initialValues,
    intake: curIntake,
    onSuccessActionCreator,
    isPrevStepRevisitable,
    isFirstStep: position === 1,
    previousStepAction,
    key: step,
    question,
  };
};
const IntakeStep = ({
  question,
  handleSubmit,
  dispatch,
  isPrevStepRevisitable,
  position,
  previousStepAction,
  initial,
  intake,
  overrideSubmit = false,
  total,
  onSuccessActionCreator,
}) => {
  const { name } = question;
  const redirectPrevious = () => dispatch(previousStepAction);
  const props = {
    handleSubmit,
    question,
    position,
    name,
    redirectPrevious,
  };
  const Component = getComponentForQuestion({ question });
  const initialValues = {
    [name]: getDefaultValue({ initial, question }),
  };

  const IntakeSchema = getIntakeSchema(question);
  const [showSubmitButton, setShowSubmitButton] = useState(true);
  let onSubmit;
  if (overrideSubmit) {
    onSubmit = handleSubmit;
  } else {
    onSubmit = getOnSubmit({ intake, onSuccessActionCreator, question, dispatch });
  }

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={IntakeSchema} validateOnMount>
      {({ handleSubmit, isValid }) => (
        <>
          <DiscountBanner />
          <ProgressBar className="animated-deep-hidden" position={position} total={total} />
          <div className={`intake animated-deep ${question?.name}`}>
            <BlackTitle question={question} position={position} />
            <Component setShowSubmitButton={setShowSubmitButton} {...props} position={position} total={total} />
            {showSubmitButton && (
              <div className="centered">
                <BackNextFooter
                  noBack={!isPrevStepRevisitable}
                  onNext={handleSubmit}
                  nextDisabled={!isValid}
                  onBack={redirectPrevious}
                />
              </div>
            )}
          </div>
        </>
      )}
    </Formik>
  );
};

export default connect(mapStateToProps)(IntakeStep);
