import React from 'react';

import { useFormikContext } from 'formik';
import { AvailableProducts, KingV2SubProducts } from 'app/constants/Products';
import { ImmutableList } from 'app/types/admin';
import ProductHeader from 'app/components/customer/steps/Payment/king/ProductHeader';
import PaymentCard from 'app/components/customer/steps/Payment/PaymentCard';
import { SecondaryButton } from 'app/components/common/Button';
import { KingSubProductsConfig } from 'app/components/customer/steps/Payment/king/KingSubProductsConfig';
import { P } from 'app/components/common/Typography';
import PriceFormatter from 'app/components/customer/steps/Payment/Generic/PriceFormatter';
import subProductNames from 'app/utils/subProductNames';
import { KING_MONTHLY_PRICE } from 'app/helpers/productSpecificPriceCalculators/kingPriceCalculator';

type Props = {
  availableSubProducts: ImmutableList<KingV2SubProducts> | null;
};

const KingSubProductsList = ({ availableSubProducts }: Props) => {
  const { setFieldValue } = useFormikContext();

  const renderProductCard = (subProductName: any) => {
    const subProduct = KingSubProductsConfig[subProductName];

    return (
      <PaymentCard className="product-selection mb32">
        <ProductHeader
          productName={AvailableProducts.King}
          subProductName={subProductName}
          cardStyle={false}
          smallImage={true}
        />
        <div className="price_row mb16">
          <h4 className="subtitle mt8">{subProductNames[AvailableProducts.King][subProductName]}</h4>
          <PriceFormatter price={KING_MONTHLY_PRICE[subProductName][12]} from={true} />
        </div>
        <P>
          <b className="bold">Recommended for: </b>
          {subProduct.recommended_for}
        </P>
        <SecondaryButton
          text="Continue"
          className="mt16"
          data-testid="own-lab-modal-close"
          onClick={() => setFieldValue('selected_king_v2_product', subProductName)}
        />
      </PaymentCard>
    );
  };

  if (!availableSubProducts) return null;

  return (
    <div className="choose_product">
      {availableSubProducts.map((subProductName) => renderProductCard(subProductName))}
    </div>
  );
};

export default KingSubProductsList;
