import React, { ComponentProps, FC } from 'react';
import CommonCheckbox from 'app/components/common/Checkbox';
import { useField } from 'formik';

export const Checkbox: FC<
  {
    id?: string;
    name: string;
  } & ComponentProps<typeof CommonCheckbox>
> = ({ id, name, ...restProps }) => {
  const [{ value, onChange, onBlur }] = useField({ id, name });

  return (
    <div>
      <CommonCheckbox
        id={id}
        name={name}
        testId={id}
        checked={value}
        onChange={onChange}
        onFocus={onBlur}
        onBlur={onBlur}
        {...restProps}
      />
    </div>
  );
};
