import React, { useState, useCallback, useEffect, FC } from 'react';
// import { Map, Marker, GoogleApiWrapper } from 'google-maps-react'
import GoogleMapReact from 'google-map-react';
import { connect } from 'react-redux';
import * as actions from 'app/actions/customer';

const mapStyles = {
  width: '100%',
  height: '100%',
  // position: 'relative',
};
const mapStateToProps = () => {
  const reportBrowserProblem = actions.reportBrowserProblem({
    problemType: 'google_mag_widget_error',
    error: { code: 1 },
    details: { apiKey: (window as any).googleMapsAPIKey },
  });

  return {
    reportBrowserProblem,
  };
};
const Marker: FC<{
  lat: number | string;
  lng: number | string;
}> = () => (
  <img
    src="https://maps.gstatic.com/mapfiles/api-3/images/spotlight-poi2.png"
    style={{
      position: 'absolute',
      transform: 'translate(-50%, -100%)',
    }}
  />
);

const GoogleMapsComponent = ({ initialPosition, onPositionChange, dispatch, reportBrowserProblem }) => {
  const [position, setPosition] = useState(initialPosition);

  useEffect(() => {
    setPosition(initialPosition);
  }, [initialPosition]);

  // The only way to catch GoogleMap API errors is having such global function

  useEffect(() => {
    (window as any).gm_authFailure = () => {
      dispatch(reportBrowserProblem);
    };
  }, [dispatch, reportBrowserProblem]);

  const mapOnClick = useCallback(
    (coords) => {
      const { lat, lng } = coords;
      const position = { lat, lng };
      setPosition(position);
      onPositionChange(position);
    },
    [onPositionChange, setPosition],
  );

  return (
    <GoogleMapReact
      bootstrapURLKeys={{
        key: (window as any).googleMapsAPIKey,
        libraries: ['places'],
      }}
      options={{
        mapTypeControl: true,
        streetViewControl: true,
        styles: [
          {
            featureType: 'all',
            elementType: 'labels',
            stylers: [{ visibility: 'on' }],
          },
        ],
      }}
      center={position}
      defaultZoom={16}
      defaultCenter={position}
      onClick={mapOnClick}
      style={mapStyles}
    >
      <Marker lat={position.lat} lng={position.lng} />
    </GoogleMapReact>
  );
};

export default connect(mapStateToProps)(GoogleMapsComponent);
