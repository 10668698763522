import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useAppDispatch } from 'app/helpers/hooks';
import { P } from 'app/components/common/Typography';
import { PrimaryButton, SecondaryButton } from 'app/components/common/Button';
import '../css/UploadPhoto.scss';
import * as selectors from 'app/selectors/customer';
import { push } from 'connected-react-router/immutable';
import HeadingWithIcon from 'app/components/common/HeadingWithIcon';
import BulletCheckmark from 'images/Icon-Bullet-Checkmark.svg';
import { getCSRF } from 'app/api';
import { apiRequestFailure, apiRequestSuccess } from 'app/helpers/commandHelpers';
import Webcam from 'react-webcam';
import Spinner from 'app/components/customer/Spinner';
import SetProductDialog from '@setproduct-ui/core/Dialog';
import ModalHavingTrouble from 'app/components/customer/steps/UploadPhoto/ModalHavingTrouble';
import heic2any from 'heic2any';

const nonIntakeSteps = ['top_of_head', 'front_of_head', 'back_of_head'];
const mapStateToProps = (state) => {
  const onSuccessAction = push(selectors.selectNextStepPath(state));
  const stepName = selectors.selectCurrentIntakeStep(state)?.get('name');
  const userId = selectors.selectCustomerId(state);
  const intakeName = selectors.selectCurrentIntake(state)?.get('name');

  return {
    onSuccessAction,
    stepName,
    userId,
    intakeName,
  };
};

const UserPhotoComponent = ({
  title,
  header,
  description,
  imgClass,
  onSuccessAction,
  stepName,
  nonIntakeStep,
  redirect,
  userId,
  intakeName,
}) => {
  const dispatch = useAppDispatch();
  const inputFile = useRef<HTMLInputElement>(null);
  const webcamRef = useRef<Webcam>(null);
  const [cameraCapture, setCameraCapture] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isReview, setIsReview] = useState(false);
  const [image, setImage] = useState<Blob | null>(null);
  const guidelines = [
    `The photo shows the ${description.boldText}`,
    'The photo is not edited or filtered',
    'The photo is not blurry or dark',
    'The photo was taken within the past 30 days',
  ];
  const [loading, setLoading] = useState(true);
  const handleUserMedia = () => setTimeout(() => setLoading(false), 1_500);
  const [havingTroubleModalOpen, setHavingTroubleModalOpen] = useState(false);

  const HavingTroubleModal = () => (
    <SetProductDialog
      isOpen={havingTroubleModalOpen}
      text={<ModalHavingTrouble setHavingTroubleModalOpen={setHavingTroubleModalOpen} />}
      onClose={() => setHavingTroubleModalOpen(false)}
      className="referral-source-modal having-troubles"
    />
  );

  const handleClick = () => {
    inputFile.current!.click();
  };

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      if (file.type.toLowerCase() === 'image/heic' || file.name.toLowerCase().includes('.heic')) {
        setIsLoading(true);
        heic2any({ blob: file, toType: 'image/jpeg', quality: 0.7 })
          .then((newImage) => {
            setImage(newImage as Blob);
            setIsLoading(false);
          })
          .catch(() => {
            setIsLoading(false);
          });
      } else {
        setImage(event.target.files[0]);
      }
      handleSubmission();
    }
  };

  const handleSubmission = () => {
    setIsReview((isReview) => !isReview);
  };

  const retakePhoto = () => {
    setIsReview(false);
  };

  const confirmPhoto = async () => {
    setIsLoading(true);
    const data = new FormData();
    data.append('file', image || '');
    data.append('type', 'upload_head_photo');
    data.append('user_id', userId);
    data.append('photo_purpose', stepName || nonIntakeStep);
    data.append('cmdType', 'upload_head_photo');
    data.append('intake_name', intakeName);
    const context = { onSuccessAction };
    const request = {
      method: 'post',
      path: '/api/commands',
      body: data,
      context,
    };
    const res = await fetch('/api/commands', {
      method: 'post',
      body: data,
      headers: { 'X-CSRF-Token': getCSRF() },
    });
    if (res?.ok) {
      if (!nonIntakeStep) {
        dispatch(
          apiRequestSuccess({
            reqId: 0,
            status: res.status,
            json: await res.json(),
            context,
            request,
          }),
        );
      } else {
        const index = nonIntakeSteps.indexOf(nonIntakeStep);
        const nextStep = nonIntakeSteps[index + 1];
        const path = nextStep ? `/dashboard/warrior-retake-photos/${nextStep}` : '/dashboard';
        setIsReview(false);
        redirect(path);
      }
    } else {
      setHavingTroubleModalOpen(true);
      // TODO: Looks like status is not uset at all. Check implementation of `#apiRequestFailure`
      dispatch(apiRequestFailure({ status: res.status, context, request } as any));
    }
    setIsLoading(false);
  };

  const handleTakePhoto = () => {
    if (navigator.maxTouchPoints > 0) {
      inputFile.current!.setAttribute('capture', 'user');
      handleClick();
      inputFile.current!.removeAttribute('capture');
    } else {
      setCameraCapture(true);
    }
  };

  const UploadPhoto = () => (
    <div className="main-area">
      <div className="text-container">
        <p className="text-wrapper indicator-text">{title}</p>
        <h1 className="center-text-container upload-text">{header}</h1>
        <p className="center-text-container diagnosis-text">
          {description.text} <span className="weight-font">{description.boldText}</span>.
        </p>
      </div>
      {cameraCapture ? (
        <WebcamCapture />
      ) : (
        <>
          <div className={imgClass} />
          <div className="button-container">
            <SecondaryButton
              className="select-photo-button"
              data-testid="select-photo"
              text="Select Photo"
              onClick={handleClick}
            />
            <input
              ref={inputFile}
              type="file"
              id="file"
              style={{ display: 'none' }}
              accept="image/*"
              onChange={onImageChange}
            />
            <PrimaryButton
              className="take-photo-button"
              data-testid="upload-photo"
              text="Take Photo"
              onClick={handleTakePhoto}
            >
              Take Photo
            </PrimaryButton>
          </div>
        </>
      )}
      <div className="text-container">
        <p onClick={() => setHavingTroubleModalOpen(true)} className="center-text-container pointer support-text">
          Having trouble?
        </p>
      </div>
      <div className="tls-security-text">
        <img className="lock-img" />
        <p className="">256-BIT TLS SECURITY</p>
      </div>
      <HavingTroubleModal />
    </div>
  );

  const ReviewPhoto = () => (
    <div className="main-area">
      <div className="text-container">
        <p className="text-wrapper indicator-text">{title}</p>
        <h1 className="center-text-container upload-text">{header}</h1>
        <p className="center-text-container diagnosis-text">
          {description.text} <span className="weight-font">{description.boldText}</span>.
        </p>
      </div>
      {image && (
        <div className="photo-wrapper mt20">
          <img src={URL.createObjectURL(image)} />
          <PrimaryButton className="confirm-button mb-20" text="Retake" onClick={retakePhoto} />
        </div>
      )}
      <br />
      <div className="review-text-container">
        <p className="center-text-container ensure-that-text">Ensure that...</p>
        <ul>
          {guidelines.map((guideline) => (
            <li key={guideline}>
              <HeadingWithIcon icon={BulletCheckmark} text={guideline} tag={P} className="mv8 text-left" />
            </li>
          ))}
        </ul>
      </div>
      <div className="button-container">
        <PrimaryButton className="confirm-button" data-testid="upload-photo" text="Confirm" onClick={confirmPhoto} />
      </div>
      <div className="text-container">
        <p onClick={() => setHavingTroubleModalOpen(true)} className="center-text-container support-text pointer">
          Having trouble?
        </p>
        <div className="tls-security-text">
          <img className="lock-img" />
          <p>256-BIT TLS SECURITY</p>
        </div>
        <HavingTroubleModal />
      </div>
    </div>
  );

  const videoConstraints = {
    width: 1280,
    height: 720,
    facingMode: 'user',
  };

  const WebcamCapture = () => {
    const capture = async () => {
      const imageSrc = webcamRef.current?.getScreenshot();
      if (!imageSrc) {
        return;
      }
      const blob = await (await fetch(imageSrc)).blob();
      setImage(blob);
      setIsReview(true);
      setCameraCapture(false);
    };

    return (
      <>
        <Webcam
          audio={false}
          height={300}
          ref={webcamRef}
          screenshotFormat="image/jpeg"
          width={456}
          videoConstraints={videoConstraints}
          onUserMedia={handleUserMedia}
        />
        <PrimaryButton className="confirm-button" onClick={capture} disabled={loading} text="Capture photo" />
      </>
    );
  };

  const RenderPage = () => {
    if (isLoading) {
      return (
        <div className="global-spinner">
          <Spinner isCenter />
        </div>
      );
    }

    if (isReview) {
      return <ReviewPhoto />;
    }

    return <UploadPhoto />;
  };

  return <RenderPage />;
};

const mapDispatchToProps = {
  redirect: push,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserPhotoComponent);
