import { List } from 'immutable';

export const updateOneThread = (thread, message) => {
  const newMessages = (thread || List()).push(message);

  return uniqBy(messageKey, newMessages);
};
export const updateThread = (initialState, message) => {
  const path = ['threads', message.get('user_id'), message.get('thread')];

  return initialState.updateIn(path, (messages = List()) => {
    const newMessages = messages.push(message);

    return uniqBy(messageKey, newMessages);
  });
};

export const uniqBy = (fn, list) =>
  list
    .groupBy(fn)
    .map((value) => value.first())
    .toList();
export const messageKey = (m) => `${m.get('created_at')}/${m.get('id')}`;
