import { CustomerUserImm, WarriorIntake, WarriorProduct } from 'app/types/admin/customerUser';
import { ImmutableMap } from 'app/types/admin';
import BaseCheckoutHelper from 'app/components/customer/steps/Payment/Generic/BaseCheckoutHelper';
import { AvailableProducts } from 'app/constants/Products';
import WarriorPriceCalculator from 'app/helpers/productSpecificPriceCalculators/warriorPriceCalculator';
import PriceCalculator from 'app/helpers/priceCalculator';

class WarriorCheckoutHelper extends BaseCheckoutHelper {
  intake: ImmutableMap<WarriorIntake>;
  product: ImmutableMap<WarriorProduct>;

  constructor(
    customer: CustomerUserImm,
    intake: ImmutableMap<WarriorIntake>,
    product: ImmutableMap<WarriorProduct>,
    isOnboarding: boolean,
  ) {
    super(customer, intake, isOnboarding);

    this.intake = intake;
    this.product = product;
  }

  currentProductName = () => AvailableProducts.Warrior;

  productSpecificInitialValues = () => {
    return { warrior_strength: 'extra_strength_rx' };
  };
  priceCalculator = (values: any, _subscription_paid: boolean = false, _lab_paid: boolean = false) => {
    const warriorPriceCalculator = new WarriorPriceCalculator(
      values.include_supplement,
      this.supplementsOnAnotherIntake(),
      this.intake.get('name'),
    );

    return new PriceCalculator(warriorPriceCalculator, this.product.get('discounts'));
  };
}

export default WarriorCheckoutHelper;
