import React from 'react';

const GenericOnboardingServices = () => {
  return (
    <>
      <div className="price_row mt24">
        <div>Unlimited Doctor Messaging</div>
        <div>Included</div>
      </div>
      <div className="price_row mt24">
        <div>Concierge Support</div>
        <div>Included</div>
      </div>
      <div className="price_row mt24">
        <div>Ongoing Treatment Customization</div>
        <div>Included</div>
      </div>
      <div className="price_row mt24">
        <div>Exclusive Content & Coaching</div>
        <div>Included</div>
      </div>
    </>
  );
};

export default GenericOnboardingServices;
