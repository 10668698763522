import React from 'react';
import './css/Menu.scss';
import { Divider70 } from '@setproduct-ui/core/Divider';
import { H6 } from 'app/components/common/Typography';
import IconClose from 'images/Icon-Close.svg';
import MaximusLogoSmall from 'images/Maximus-Logo-Small.svg';
import { BOTTOM_LINKS, LOGGED_IN_TOP, LOGGED_OUT_TOP } from 'app/constants/Menu';

const onElementClick = ({ path, type }, { onLogoutClick, redirect }) => {
  switch (type) {
    case 'internal-link':
      return redirect(path);
    case 'external-link':
      return window.open(path, '_blank');
    case 'external-link-redirect':
      return (window.location.href = path);
    case 'logout':
      return onLogoutClick();
    default:
      return null;
  }
};

const MenuElements = ({ elements, onClickProps }) =>
  elements.map((element, i) => <MenuElement key={i} onClickProps={onClickProps} element={element} />);

const MenuElement = ({ element: { testId, title }, element, onClickProps: { onMenuClick, ...restProps } }) => {
  const onClick = () => {
    onElementClick(element, restProps);
    onMenuClick();
  };

  return (
    <div className="element" onClick={onClick} data-testid={testId}>
      <H6>{title}</H6>
      <Divider70 />
    </div>
  );
};

const Menu = ({ loggedIn, onMenuClick, menuOpened, ...restProps }) => {
  const top = loggedIn ? LOGGED_IN_TOP : LOGGED_OUT_TOP;
  const onClickProps = {
    onMenuClick,
    ...restProps,
  };

  return (
    <nav id="sidebar" className={menuOpened ? '' : 'collapsed'}>
      <div className="left-side">
        <div className="pl16 mt12 mb24 element" onClick={onMenuClick}>
          <img src={IconClose} />
        </div>
        <div className="pl32">
          <MenuElements elements={top} onClickProps={onClickProps} />
        </div>
        <div className="end-elements pl32">
          <MenuElements elements={BOTTOM_LINKS} onClickProps={onClickProps} />
          <div className="element center-align mb24">
            <img src={MaximusLogoSmall} />
          </div>
        </div>
      </div>
      <div className="right-side" onClick={onMenuClick} />
    </nav>
  );
};

export default Menu;
