import React, { useContext, useState } from 'react';
import PageHeader from 'app/components/common/PageHeader';
import { useSelector } from 'react-redux';
import {
  selectCustomer,
  selectCustomerFlags,
  selectCustomerKingProduct,
  selectCustomerShippingAddress,
} from 'app/selectors/customer';
import SetProductDialog from '@setproduct-ui/core/Dialog';
import ShippingAddressWidget from 'app/components/customer/steps/Payment/Generic/ShippingAddressWidget';
import PriceFormatter from 'app/components/customer/steps/Payment/Generic/PriceFormatter';
import KingPriceCalculator from 'app/helpers/productSpecificPriceCalculators/kingPriceCalculator';
import { AvailableProducts, KingV2SubProducts, LabKitTypes } from 'app/constants/Products';
import { P } from 'app/components/common/Typography';
import Divider from 'app/components/common/Divider';
import GenericSelfPay from 'app/components/customer/steps/Payment/Generic/GenericSelfPay';
import PaymentContextProvider from 'app/components/customer/steps/Payment/PaymentContext/provider';
import { Formik } from 'formik';
import { BillingSchema } from 'app/helpers/validators';
import Spinner from 'app/components/customer/Spinner';
import { useAppDispatch, useAppSelector } from 'app/helpers/hooks';
import * as actions from 'app/actions/customer';
import { reportBrowserProblem } from 'app/actions/customer';
import { PaymentContext, PaymentContextUserInfo } from 'app/components/customer/steps/Payment/PaymentContext/context';
import { Redirect } from 'react-router';
import * as Routes from 'app/constants/Routes';
import { PrimaryButton } from 'app/components/common/Button';
import { useParams } from 'react-router-dom';
import ModalBodyAtHomeLab from 'app/components/customer/Checkout/ModalBodyAtHomeLab';
import CardInput from 'app/components/customer/steps/Payment/CardInput';
const AnnualLabCheckout = () => {
  const flags = useAppSelector(selectCustomerFlags);
  const [productPharmacyValid, setProductPharmacyValid] = useState(true);
  const dispatch = useAppDispatch();
  const [shippingFormValid, setShippingFormValid] = useState(false);
  const product = useSelector(selectCustomerKingProduct);
  const intake = product.get('intakes').last();
  const [openModalAtHomeLab, setOpenModalAtHomeLab] = useState<string | boolean | null>(false);
  const shippingAddress = useAppSelector(selectCustomerShippingAddress);
  const pending = flags.includes('pending_payment');
  const paymentCtx = useContext(PaymentContext)!;
  const billingIssue = flags.includes('billing_issue');
  const { lab_type = LabKitTypes.AtHomeLabKit } = useParams<{ lab_type: LabKitTypes }>();

  const productPriceCalculator = new KingPriceCalculator(
    false,
    false,
    KingV2SubProducts.EncloPregnolone,
    1,
    false,
    'onboarding',
    null,
    false,
    LabKitTypes.AtHomeLabKit,
    true,
  );
  const customer = useAppSelector(selectCustomer);
  const currentPaymentMethod = customer.get('payment_method');

  const initialShippingAddress = {
    address_line_1: '',
    address_line_2: '',
    city: '',
    state: '',
    full_name: '',
    postal_code: '',
    ...shippingAddress?.toJS(),
  };

  const labModalInfoTitle = () => {
    switch (lab_type) {
      case LabKitTypes.AtHomeLabKit:
        return 'At-Home Lab Testing';
      case LabKitTypes.WalkIn:
        return 'Walk-In Lab';
      case LabKitTypes.UseYourOwnLab:
        return 'Use Your Own Lab Tests';
      default:
        return '';
    }
  };

  const initialValues = {
    ...initialShippingAddress,
    selfPay: false,
    refundPolicy: false,
    use_own_lab: false,
    lab_kit_type: lab_type === LabKitTypes.WalkIn ? LabKitTypes.WalkIn : LabKitTypes.AtHomeLabKit,
    own_lab_file_uploaded: false,
    applePayPaymentMethod: null,
  };

  const onSubmit = async (values, formHelper) => {
    try {
      // Update when apply to the rest of the protocols
      const { address_line_1, address_line_2, city, state, postal_code, lab_kit_type } = values;
      let params = {
        address_line_1,
        address_line_2,
        city,
        postal_code,
        state,
        lab_kit_type,
        async: true,
      };
      if (billingIssue) {
        const result = await paymentCtx?.createPaymentMethod({
          ...params,
          full_name: `${customer.get('first_name')} ${customer.get('last_name')}`,
        } as PaymentContextUserInfo);
        const action = actions.apiRequestUserCommand({
          cmdType: 'add_stripe_payment_method',
          params: {
            ...params,
            card_brand: result?.cardBrand,
            card_last4: result?.cardLastFour,
            payment_method_id: result?.paymentMethodId,
            payment_method_source: paymentCtx?.paymentProvider,
            payment_method_type: 'credit_card',
          },
          context: {},
        });
        dispatch(action);
      }
      if (!currentPaymentMethod?.get('card_last4')) {
        const full_name = `${customer.get('first_name')} ${customer.get('last_name')}`;
        const result = await paymentCtx.createPaymentMethod({ ...values, full_name });

        const onboardingOnly = {
          card_brand: result.cardBrand,
          card_last4: result.cardLastFour,
          payment_method_id: result.paymentMethodId,
          payment_method_source: paymentCtx.paymentProvider,
        };
        params = { ...params, ...onboardingOnly };
      }
      const action = actions.apiRequestUserCommand({ cmdType: 'king_annual_lab_payment', params });
      dispatch(action);
    } catch (e) {
      console.log(e);
      dispatch(
        reportBrowserProblem({
          error: { code: 1 },
          problemType: `${paymentCtx.paymentProvider}_failed`,
          details: e,
        }),
      );
      return;
    } finally {
      formHelper.setSubmitting(false);
    }
  };

  if (!flags.includes('pending_annual_lab')) {
    return <Redirect to={Routes.Dashboard} />;
  }

  return (
    <div className="generic_payment_page centered updated-design payments">
      <PageHeader title="Checkout" className="treatment_plan__header mb24 -edged" />
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={BillingSchema}
        enableReinitialize={false}
        validateOnMount
      >
        {({ isValid, isSubmitting, handleSubmit }) => {
          return pending ? (
            <Spinner isCenter />
          ) : (
            <div className="mb24 annual_lab_checkout">
              <div className="content">
                <ShippingAddressWidget
                  product={product}
                  intake={intake}
                  productPharmacyValid={productPharmacyValid}
                  shippingFormValid={shippingFormValid}
                  setProductPharmacyValid={setProductPharmacyValid}
                  setShippingFormValid={setShippingFormValid}
                />
                <div className="payment_card mt52">
                  <div className="price_row">
                    <div>
                      <p>{lab_type === LabKitTypes.AtHomeLabKit ? 'At-Home Lab Kit' : 'Walk-in (Quest Labs)'}</p>
                    </div>
                    <PriceFormatter
                      price={
                        lab_type === LabKitTypes.AtHomeLabKit
                          ? productPriceCalculator.KING_LAB_COST
                          : productPriceCalculator.KING_WALK_IN_LAB_COST
                      }
                      showCents={true}
                    />
                  </div>
                  {lab_type === LabKitTypes.AtHomeLabKit && (
                    <P className="mb12 mt16 lab_step_paragraph_format">
                      Maximus uses CLIA-certified lab tests that ship straight to your door. The tests are painless and
                      do not require finger-pricking. Results are available FAST (typically within four business days).{' '}
                      <a href="#" onClick={() => setOpenModalAtHomeLab(LabKitTypes.AtHomeLabKit)}>
                        Full details
                      </a>
                    </P>
                  )}
                  {lab_type === LabKitTypes.WalkIn && (
                    <P className="mb12 lab_step_paragraph_format color_gray_3">
                      We’ve partnered with Quest Labs to make it easy. We’ll provide you with a pre-paid requisition
                      form that you can take to your nearest Quest Lab at your convenience.{' '}
                      <a href="#" onClick={() => setOpenModalAtHomeLab(LabKitTypes.WalkIn)}>
                        Full details
                      </a>
                    </P>
                  )}
                  <Divider className="mt24" />
                  <GenericSelfPay />
                  {!billingIssue && !!currentPaymentMethod && (
                    <P className="align-center mb8">
                      Pay with {currentPaymentMethod.get('card_brand')} {currentPaymentMethod.get('card_last4')}
                    </P>
                  )}
                  {billingIssue && (
                    <>
                      <P className="billing_error align-center mb16">
                        Billing error with Mastercard {currentPaymentMethod?.get('card_last4')}. Please update your
                        payment method.
                      </P>

                      <div className="mb24">
                        <CardInput />
                      </div>
                    </>
                  )}
                  <PrimaryButton
                    text={billingIssue ? 'Update & Place Order' : 'Place Order'}
                    type="submit"
                    disabled={!isValid || isSubmitting}
                    onClick={() => handleSubmit()}
                    className="mb24"
                  />
                </div>
              </div>
            </div>
          );
        }}
      </Formik>
      <SetProductDialog
        isOpen={!!openModalAtHomeLab}
        title={labModalInfoTitle()}
        text={
          <ModalBodyAtHomeLab
            productName={AvailableProducts.King}
            openModalAtHomeLab={openModalAtHomeLab}
            setOpenModalAtHomeLab={setOpenModalAtHomeLab}
          />
        }
        onClose={() => setOpenModalAtHomeLab(false)}
        className="manage_subscription__cancel_modal align-left"
      />
    </div>
  );
};

export default () => (
  <PaymentContextProvider>
    <AnnualLabCheckout />
  </PaymentContextProvider>
);
