/* eslint-disable no-case-declarations */
import { Discount } from 'app/types/admin/customerUser';
import { ImmutableList, ImmutableMap } from 'app/types/admin';
import KingPriceCalculator from 'app/helpers/productSpecificPriceCalculators/kingPriceCalculator';
import LoverPriceCalculator from 'app/helpers/productSpecificPriceCalculators/loverPriceCalculator';
import MagicianPriceCalculator from 'app/helpers/productSpecificPriceCalculators/magicianPriceCalculator';
import WarriorPriceCalculator from 'app/helpers/productSpecificPriceCalculators/warriorPriceCalculator';

class PriceCalculator {
  productSpecificPriceCalculator:
    | KingPriceCalculator
    | LoverPriceCalculator
    | MagicianPriceCalculator
    | WarriorPriceCalculator;
  discounts: ImmutableList<Discount> | Discount[];

  constructor(
    productSpecificPriceCalculator:
      | KingPriceCalculator
      | LoverPriceCalculator
      | MagicianPriceCalculator
      | WarriorPriceCalculator,
    discounts: ImmutableList<Discount> | Discount[] = [],
  ) {
    this.productSpecificPriceCalculator = productSpecificPriceCalculator;
    this.discounts = discounts;
  }

  REGULAR_BB_PRICE = 49_99;

  bbPrice = () => this.productSpecificPriceCalculator.bbPrice();

  monthlyCost = () => this.productSpecificPriceCalculator.monthlyCost();

  fullMonthlyCost = () => this.productSpecificPriceCalculator.fullMonthlyCost();

  montlyPayment = () => this.productSpecificPriceCalculator.montlyPayment();

  quarterlyPayment = () => this.productSpecificPriceCalculator.quarterlyPayment();

  yearlyPayment = () => this.productSpecificPriceCalculator.yearlyPayment();

  subscriptionCost = () => this.productSpecificPriceCalculator.subscriptionCost();

  appointmentCost = () => this.productSpecificPriceCalculator.appointmentCost();

  bbDiscount = () => this.productSpecificPriceCalculator.bbDiscount();

  bbPotentialDiscount = () => this.productSpecificPriceCalculator.bbPotentialDiscount();

  atHomeLabPrice = () => this.productSpecificPriceCalculator.atHomeLabPrice();

  labCost = () => this.productSpecificPriceCalculator.labCost();

  totalDueToday = () =>
    this.productSpecificPriceCalculator.totalDueTodayForCurrentProduct() - this.totalDiscountsValue();

  totalDiscountsValue() {
    let total = 0;

    this.discounts.map((discount) => {
      total += this.discountPrice(discount)!;
    });

    return total;
  }

  freeShipping = () => this.productSpecificPriceCalculator.freeShipping();

  shippingPrice = () => this.productSpecificPriceCalculator.shippingPrice();

  nonDiscountedPrice = () => this.productSpecificPriceCalculator.nonDiscountedPrice();

  discountPrice(discount: ImmutableMap<Discount>) {
    const isLab = discount?.get('product_name')?.slice(-4) === '_lab';
    const amountUsed = discount.get('uses').reduce((sum, use) => sum + use.get('amount_used'), 0);

    const coupon = discount?.get('coupon');
    const couponParams = coupon?.get('params');

    const discountType = coupon?.get('discount_type');

    const basePrice = isLab ? this.labCost() : this.fullMonthlyCost()!;

    switch (discountType) {
      case 'subscription_payments_off_forever':
        const discountForeverPercentage = couponParams?.get('percentage_off');

        const basePriceForForeverDiscounted = isLab
          ? basePrice
          : basePrice * this.productSpecificPriceCalculator.multiMonthPlan + this.appointmentCost();

        return discountForeverPercentage * 0.01 * basePriceForForeverDiscounted;
      case 'subscription_payments_off':
        const discountPercentage = couponParams?.get('percentage_off');
        const discountedMonthPayments = couponParams?.get('number_of_payments');
        const discountedMonths = Math.min(
          this.productSpecificPriceCalculator.multiMonthPlan || 0,
          discountedMonthPayments,
        );

        const basePriceForDiscountedMonths = isLab ? basePrice : basePrice * discountedMonths;

        const totalDiscountedPrice = isLab
          ? basePriceForDiscountedMonths * discountPercentage * 0.01
          : basePriceForDiscountedMonths * discountPercentage * 0.01 - amountUsed;

        return totalDiscountedPrice > basePriceForDiscountedMonths
          ? basePriceForDiscountedMonths
          : totalDiscountedPrice;
      case 'fixed_amount_off':
        if (this.productSpecificPriceCalculator.subscriptionPaid) return 0;

        const basePriceForDiscount = isLab ? basePrice : basePrice * this.productSpecificPriceCalculator.multiMonthPlan;
        const discountAmount = couponParams?.get('fixed_amount_off') - amountUsed;

        return discountAmount > basePriceForDiscount ? basePriceForDiscount : discountAmount;
      default:
        throw new Error('Invalid coupon type');
    }
  }
}

export default PriceCalculator;
