import React, { useMemo } from 'react';
import { connect } from 'react-redux';

import Card from '@setproduct-ui/core/Card';

import { Formik } from 'formik';
import ProgressBar from 'app/components/customer/questions/ProgressBar';
import RadioQuestion from 'app/components/customer/questions/Radio';
import { BlackTitle } from 'app/components/customer/questions/Title';
import BackNextFooter from 'app/components/customer/steps/BackNextFooter';
import * as selectors from 'app/selectors/customer';
import { push } from 'connected-react-router/immutable';
import { apiRequestUserCommand } from 'app/actions/customer';
import Question from 'app/constants/Question';

const MagicianRefillConditions = ({
  dispatch,
  previousStepAction,
  position,
  total,
  intake,
  hideBackButton,
  questionName,
  initialValues,
  onSuccessActionCreator,
}) => {
  const redirectPrevious = () => dispatch(previousStepAction);
  const onSubmit = (params) => {
    const name = 'conditions_and_medications';
    if (intake) {
      params.intake = intake.get('name');
    }
    const action = apiRequestUserCommand({
      cmdType: 'answer_questions',
      params,
      context: { onSuccessActionCreator, form: name },
    });
    dispatch(action);
  };
  const refillConditionsQuestion = useMemo(() => new Question(questionName), [questionName]);

  return (
    <>
      <ProgressBar title="Refill Conditions" position={position} total={total} />
      <Formik
        initialValues={{
          [questionName]: initialValues[questionName],
          [`${questionName}_description`]: initialValues[`${questionName}_description`],
        }}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, setFieldValue, values }) => (
          <div className="mb16">
            <BlackTitle question={refillConditionsQuestion} position={position} />
            <RadioQuestion
              name={questionName}
              submit={(current_answer: string) => {
                return current_answer.toLowerCase() != 'yes';
              }}
            />
            {values[questionName] === 'yes' && (
              <Card view="raised" className="checkbox-component multi-select-question__other_card">
                <textarea
                  className="multi-select-question__other_textarea"
                  style={{ width: '100%' }}
                  onChange={({ target: { value: content } }) => {
                    setFieldValue(`${questionName}_description`, content);
                  }}
                  data-testid="other-textarea"
                  defaultValue={values[`${questionName}_description`]}
                  placeholder="Let us know here..."
                />
              </Card>
            )}
            <BackNextFooter
              noBack={hideBackButton}
              onNext={handleSubmit}
              onBack={redirectPrevious}
              testId="submit-medical-conditions"
            />
          </div>
        )}
      </Formik>
    </>
  );
};

const mapStateToProps = (state) => {
  const productName = selectors.selectCurrentIntakeProduct(state);
  const previousStepAction = push(selectors.selectPreviousStepPath(state));
  const onSuccessActionCreator = (_, stateAfterSuccess) =>
    push(selectors.selectFurthestProductStepPath({ customer: stateAfterSuccess }, productName));
  const prevStep = selectors.selectPreviousStep(state)?.toJS();
  const hideBackButton = !prevStep?.revisitable;
  const currentStrictlyIntakeSteps = selectors.selectStrictlyProductIntakeStepsByLastIntake(state, productName);
  const position = (selectors.selectCurrentIntakeStepIndex(state) as number) + 1;
  const total = currentStrictlyIntakeSteps.size;
  const questionName = selectors.selectCurrentIntakeStep(state).get('name');
  const initialValues = selectors.selectInitialIntakeFormValues(state).toJS();

  return {
    position,
    total,
    intake: selectors.selectCurrentIntake(state),
    previousStepAction,
    hideBackButton,
    questionName,
    initialValues,
    onSuccessActionCreator,
  };
};

export default connect(mapStateToProps)(MagicianRefillConditions);
