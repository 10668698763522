import React, { useState } from 'react';

import { SecondaryButton } from './common/Button';
import PopupModal from './common/PopupModal';

import './css/EmbeddedModal.scss';

export default function EmbeddedModal() {
  const [isShow, setIsShow] = useState(true);

  return (
    <PopupModal
      isShow={isShow}
      className="embedded-modal"
      headerText="Welcome!"
      submitButtonText=""
      cancelButtonText=""
      size="small"
      closeModal={() => {
        setIsShow(false);
      }}
    >
      <h2>Open Maximus In Your Mobile Browser.</h2>
      <p className="mb16 mt8">
        So we can give you the best possible experience we recommend opening Maximus in your normal device browser.
      </p>
      <a className="mb12 mt16 default-button primary-button link" href={window.location.href} download>
        OPEN BROWSER
      </a>
      <SecondaryButton type="button" text="CONTINUE IN INSTAGRAM" onClick={() => setIsShow(false)} />
    </PopupModal>
  );
}
