import { matchPath } from 'react-router-dom';

import { createSelector } from 'reselect';
import { Selector } from 'app/configureStore';
import INTAKE_STEPS from 'app/constants/intake-steps.json';
import { IStep } from 'app/types';
import { ImmutableList, ImmutableMap } from 'app/types/admin';
import FOLLOW_UP from 'app/constants/follow-up.json';
import FOLLOW_UP_SUSTAIN from 'app/constants/follow-up-sustain.json';
import MAGICIAN_SUSTAIN_STEPS_SKIP from 'app/constants/magician-sustain-steps-skip.json';
import SIMPLE_LOVER_ONBOARDING_STEPS from 'app/constants/steps/simple_lover_onboarding.json';
import SIMPLE_LOVER_SUSTAIN_STEPS from 'app/constants/steps/simple_lover_sustain.json';
import SIMPLE_WARRIOR_ONBOARDING_STEPS from 'app/constants/steps/simple_warrior_onboarding.json';
import SIMPLE_WARRIOR_SUSTAIN_STEPS from 'app/constants/steps/simple_warrior_sustain.json';
import SIMPLE_MAGICIAN_ONBOARDING_STEPS from 'app/constants/steps/simple_magician_onboarding.json';
import SIMPLE_MAGICIAN_SUSTAIN_STEPS from 'app/constants/steps/simple_magician_sustain.json';
import STEPS_SKIP from 'app/constants/steps-skip.json';
import { List, Map, Set } from 'immutable';
import * as Routes from 'app/constants/Routes';
import { switchCase } from 'app/helpers/utils';

import * as baseSelectors from './base';

import * as reducerSelectors from '../customerReducer';

import { selectRouterPathname, selectRouterQuery } from '../router';
import { BaseIntake, BaseKingIntake, ProductIntake } from 'app/types/admin/customerUser';
import { AvailableProducts } from 'app/constants/Products';

export * from './base';

export const urlFriendlyName = (stepObject: ImmutableMap<IStep>) =>
  stepObject.get('url_friendly_name') || stepObject.get('name');

export const selectValidStates: (state: any) => string[] = createSelector(
  baseSelectors.selectCustomer,
  (customer) => customer?.get('valid_states')?.toJS() || [],
);

export const selectCustomerFullName = createSelector(
  baseSelectors.selectCustomerFirstName,
  baseSelectors.selectCustomerLastName,
  (firstName, lastName) => `${firstName} ${lastName}`.trim(),
);

export const selectPaymentErrorMessageStripe = createSelector(baseSelectors.selectCustomer, (customer) =>
  customer.get('payment_error_message_stripe'),
);
export const selectCustomerState = createSelector(baseSelectors.selectCustomer, (customer) => customer.get('state'));

export const selectPharmacyChoice = createSelector(baseSelectors.selectCustomerProduct, (product) =>
  product.get('pharmacy_choice'),
);

export const selectIsProductCancelled = createSelector(baseSelectors.selectCustomerProduct, (product) =>
  product?.get('cancelled'),
);

export const selectStepFromPath = createSelector(selectRouterPathname, (path) => {
  const match = matchPath(path, { path: Routes.Onboarding });
  if (match?.params) {
    return (match.params as { step?: string }).step;
  }

  return null;
});

const createSelectPartsFromPath = (route: string) =>
  createSelector(selectRouterPathname, (path) => {
    const match = matchPath(path, { path: route });
    if (match) {
      return match.params as Record<string, string>;
    }

    return null;
  });

export const selectIntakePartsFromPath = createSelectPartsFromPath(Routes.Intake);

export const selectCurrentIntake = createSelector(
  [baseSelectors.selectCustomerAllIntakesByName, selectIntakePartsFromPath] as Selector<any>[],
  (intakesByName: ImmutableList<BaseIntake>, intakeParts: any) => intakesByName[intakeParts?.intakeName],
);

const createDynamicQuestionnaireSelector = (attributePath) =>
  createSelector([selectCurrentIntake], (currentIntake) => currentIntake?.getIn(attributePath));

export const selectMagicianDiagnosesAttr = (attribute) =>
  createDynamicQuestionnaireSelector(['questionnaire', 'magician_diagnoses', attribute]);

export const selectMagicianDiagnoses2Attr = (attribute) =>
  createDynamicQuestionnaireSelector(['questionnaire', 'magician_diagnoses_2', attribute]);

export const selectCurrentIntakeProduct: Selector<AvailableProducts> = createSelector(
  selectCurrentIntake,
  (intake: ImmutableMap<BaseIntake>) => intake?.get('product_name'),
);

export const selectLastKingIntake = createSelector(
  baseSelectors.selectCustomerKingIntakes,
  (intakes: ImmutableList<BaseKingIntake>) => intakes.last(),
);

export const selectLastProductIntake = createSelector(baseSelectors.selectCustomerProductIntakes, (product) =>
  product.last(),
);

export const selectIsBillingFeature = createSelector(baseSelectors.selectCustomer, (_customer) => true);

export const selectCurrentIntakeStep = createSelector(
  [selectCurrentIntake, selectIntakePartsFromPath] as Selector<any>[],
  (intake: ImmutableMap<BaseIntake>, intakeParts: { intakeStep?: string } | undefined) =>
    intake?.get('steps').find((step) => urlFriendlyName(step) === intakeParts?.intakeStep),
);

export const selectCurrentIntakeStepIndex = createSelector(
  [selectCurrentIntake, selectIntakePartsFromPath] as Selector<any>[],
  (intake: ImmutableMap<ProductIntake>, intakeParts: any) =>
    intake?.get('steps').findIndex((step) => urlFriendlyName(step) === intakeParts?.intakeStep),
);

const selectCurrentOnboardingStepObject = createSelector(
  [baseSelectors.selectCustomerKingOnboardingSteps, selectStepFromPath] as Selector<any>[],
  (onboardingSteps: any, stepUrlName) =>
    onboardingSteps.find((stepObject) => urlFriendlyName(stepObject) === stepUrlName),
);

export const selectCurrentStepObject = createSelector(
  selectCurrentOnboardingStepObject,
  selectCurrentIntakeStep,
  (onboardingStep, intakeStep) => intakeStep || onboardingStep,
);

export const selectCurrentStepName = createSelector(selectCurrentStepObject, (currentOnboardingStepObject) =>
  currentOnboardingStepObject?.get('name'),
);

function keyIn(...keys) {
  const keySet = Set(keys);

  return (v, k) => keySet.has(k);
}
export const selectCustomerFormPrefill = createSelector(baseSelectors.selectCustomer, (customer) => {
  const keys = ['first_name', 'last_name', 'date_of_birth', 'email'];

  return (customer || Map()).filter(keyIn(...keys));
});

export const selectInitialIntakeFormValues = createSelector(
  selectCustomerFormPrefill,
  baseSelectors.selectCustomerQAdam,
  baseSelectors.selectCustomerKingOnboardingQuestionnaire,
  selectCurrentIntake,
  baseSelectors.selectCustomerQuestionnaire,
  (prefill, qadam, onboardingQuestionnaire, intake, customerQuestionnaire) => {
    if (intake) {
      let q = intake.get('questionnaire');

      q = q.set('qadam_q10', customerQuestionnaire.get('qadam_q10'));

      return q.set('bodyfat', q.get('bodyfat') || '30');
    }
    const merged = prefill.merge(qadam).merge(customerQuestionnaire).merge(onboardingQuestionnaire);

    return merged.set('bodyfat', merged.get('bodyfat') || '30');
  },
);

export const selectCustomerLastWithPersonaTransaction = (state) =>
  reducerSelectors.selectCustomerLastWithPersonaTransaction(reducerSelectors.selectCustomer(state));

export const selectAllErxShipmentDates = createSelector(
  [baseSelectors.selectCustomer, baseSelectors.defProduct],
  (customer, product) => {
    const intakes = customer.getIn(['maximus_products', product, 'intakes']);
    if (!intakes) {
      return null;
    }
    const nameWithDates = intakes.map((intake) => {
      const pharmacyShipments = customer.getIn(['maximus_products', product, 'pharmacy_shipments'], List());
      const intakePharmacyShipment = pharmacyShipments.find((s) => s.get('intake_name') === intake.get('name'));

      return {
        date: intake.get('erx_shipped_at'),
        intake: intake.get('name'),
        intakePharmacyShipment,
      };
    });

    return nameWithDates
      .filter((n) => !!n.date)
      .toJS()
      ?.reverse();
  },
);

export const selectAllErxShipmentDatesV2 = createSelector([baseSelectors.selectCustomer], (customer) => {
  const prescriptions = customer.get('prescriptions');
  const nameWithDates: { date: string; intake: string }[] = prescriptions
    .map((prescription) => {
      return prescription
        .get('orders')
        .toJS()
        .flatMap((prescriptionOrder) => {
          return {
            date: prescriptionOrder.date_shipped || '',
            intake: prescriptionOrder.intake_name || '',
          };
        });
    })
    .toJS()
    .flat();

  return nameWithDates?.reverse();
});

export const selectCurrentSteps: Selector<List<ImmutableMap<IStep>>> = createSelector(
  selectCurrentIntake,
  baseSelectors.selectCustomerKingOnboardingSteps,
  (intake, onboardingSteps) => {
    if (intake) {
      return intake.get('steps');
    }

    return onboardingSteps;
  },
);

export const selectPreviousStep = createSelector(selectCurrentStepObject, selectCurrentSteps, (currentStep, steps) => {
  const stepIndex = steps.indexOf(currentStep);
  if (stepIndex > 0) {
    return steps.get(stepIndex - 1);
  }

  return null;
});

export const isPrevStepRevisitable = createSelector(selectPreviousStep, (prevStep) => !!prevStep?.get('revisitable'));

export const selectNextStep = createSelector(selectCurrentStepObject, selectCurrentSteps, (currentStep, steps) => {
  const stepIndex = steps.indexOf(currentStep);

  return steps.get(stepIndex + 1);
});

export const selectFurthestStep = createSelector(selectCurrentSteps, (currentSteps) =>
  currentSteps.find((s) => !s.get('completed')),
);
export const selectFurthestStepPath = createSelector(selectLastKingIntake, (intake) => {
  if (intake) {
    const step = intake.get('steps').find((s) => !s.get('completed'));
    if (step) {
      return Routes.createIntake(intake.get('name'), urlFriendlyName(step));
    }
  }

  return Routes.Dashboard;
});

export const selectFurthestProductStepPath = createSelector(selectLastProductIntake, (intake) => {
  if (intake) {
    const step = intake.get('steps').find((s) => !s.get('completed'));
    const product = intake.get('product_name');
    const intakeName = intake.get('name');

    if (step) {
      return Routes.createIntake(intakeName, urlFriendlyName(step));
    }

    if (product === 'king' && intakeName === 'onboarding') {
      const selected_king_v2_product = intake.get('selected_king_v2_product');
      return Routes.CongratsKing + (selected_king_v2_product ? `?product=${selected_king_v2_product}` : '');
    }

    if (product === 'magician' && intakeName === 'magician_onboarding') {
      return Routes.CongratsMagician;
    }

    return Routes.ProductDashboard(product);
  }

  return Routes.Dashboard;
});

export const selectFurthestProductOnboardingStep = createSelector(
  baseSelectors.selectCustomerProductOnboardingSteps,
  (onboardingSteps) => onboardingSteps.find((s) => !s.get('completed')),
);

export const selectFurthestProductLastIntakeStep = createSelector(selectLastProductIntake, (intake) =>
  intake?.get('steps').find((s) => !s.get('completed')),
);
export const selectFurthestProductLastIntakeStepPath = createSelector(selectLastProductIntake, (intake) => {
  if (!intake) {
    return null;
  }
  const step = intake.get('steps').find((s) => !s.get('completed'));
  if (step) {
    return Routes.createIntake(intake.get('name'), urlFriendlyName(step));
  }
});

export const selectFurthestOnboardingStepIndex = createSelector(
  baseSelectors.selectCustomerProductOnboardingSteps,
  (onboardingSteps) => onboardingSteps.findIndex((s) => !s.get('completed')),
);
export const selectFurthestOnboardingStep = createSelector(
  baseSelectors.selectCustomerKingOnboardingSteps,
  (onboardingSteps) => onboardingSteps.find((s) => !s.get('completed')),
);

export const selectFurthestOnboardingStepPath = createSelector(
  selectFurthestOnboardingStep,
  (furthestOnboardingStep) => {
    if (furthestOnboardingStep) {
      return Routes.createIntake('onboarding', urlFriendlyName(furthestOnboardingStep));
    }
  },
);
export const selectPreviousStepPath = createSelector(
  selectPreviousStep,
  selectCurrentIntake,
  selectCurrentIntakeProduct,
  (step, intake, productName) => {
    if (!step) {
      return Routes.ProductDashboard(productName);
    }
    if (intake) {
      return Routes.createIntake(intake.get('name'), urlFriendlyName(step));
    }

    return Routes.createIntake('onboarding', urlFriendlyName(step));
  },
);

export const selectNextStepPath = createSelector(
  selectNextStep,
  selectCurrentIntake,
  selectCurrentIntakeProduct,
  (step, intake, productName) => {
    if (!step) {
      return Routes.ProductDashboard(productName);
    }
    if (intake) {
      return Routes.createIntake(intake.get('name'), urlFriendlyName(step));
    }

    return Routes.createIntake('onboarding', urlFriendlyName(step));
  },
);

export const selectEarlierOrSameStep = createSelector(
  selectFurthestStep,
  selectCurrentStepName,
  selectCurrentSteps,
  (furthestStep, currentStep, steps) => {
    if (!furthestStep) {
      return false;
    }
    const stepNames = steps.map((obj) => obj.get('name'));
    const furthestIndex = stepNames.indexOf(furthestStep.get('name'));
    const currentIndex = stepNames.indexOf(currentStep);

    return currentIndex <= furthestIndex;
  },
);
const intakesMapper = {
  lover_onboarding: SIMPLE_LOVER_ONBOARDING_STEPS,
  lover_sustain: SIMPLE_LOVER_SUSTAIN_STEPS,
  warrior_onboarding: SIMPLE_WARRIOR_ONBOARDING_STEPS,
  warrior_sustain: SIMPLE_WARRIOR_SUSTAIN_STEPS,
  magician_onboarding: SIMPLE_MAGICIAN_ONBOARDING_STEPS,
  magician_sustain: SIMPLE_MAGICIAN_SUSTAIN_STEPS,
  onboarding: INTAKE_STEPS,
};
export const selectStrictlyIntakeSteps = createSelector(selectCurrentIntake, (intake) => {
  const steps = switchCase(intakesMapper)(FOLLOW_UP)(intake.get('type')) as string[];

  return intake.get('steps').filter((step) => steps.includes(step.get('name')));
});

export const selectStrictlyProductIntakeStepsByLastIntake: (state: any, productName: string | null) => List<any> =
  createSelector(selectLastProductIntake, (intake) => {
    const steps = intake?.get('steps') || List();
    let filterSteps;
    switch (intake?.get('type')) {
      case 'follow_up':
        filterSteps = (step) => FOLLOW_UP.includes(step.get('name'));
        break;
      case 'king_sustain':
        filterSteps = (step) => !STEPS_SKIP.includes(step.get('name'));
        break;
      case 'lover_onboarding':
        filterSteps = (step) => !['lover_intro'].includes(step.get('name'));
        break;
      case 'warrior_onboarding':
        filterSteps = (step) => !['warrior_intro'].includes(step.get('name'));
        break;
      case 'magician_onboarding':
        filterSteps = (step) => !['magician_intro'].includes(step.get('name'));
        break;
      case 'magician_sustain':
        filterSteps = (step) => !MAGICIAN_SUSTAIN_STEPS_SKIP.includes(step.get('name'));
        break;
      case 'lover_sustain':
      case 'warrior_sustain':
        filterSteps = (step) => FOLLOW_UP_SUSTAIN.includes(step.get('name'));
        break;
      default:
        filterSteps = (step) => INTAKE_STEPS.includes(step.get('name'));
        break;
    }

    return steps.filter(filterSteps);
  });
export const selectStrictlyIntakeStepsByLastIntake = createSelector(
  selectLastKingIntake,
  baseSelectors.selectCustomerKingOnboardingSteps,
  (intake, onboardingSteps) => {
    if (intake?.get('type') === 'king_sustain') {
      return intake.get('steps').filter((step) => !STEPS_SKIP.includes(step.get('name')));
    }

    if (intake?.get('type') === 'follow_up') {
      return intake.get('steps').filter((step) => {
        const stepName = step.get('name');

        return FOLLOW_UP.includes(stepName);
      });
    }

    return onboardingSteps.filter((step) => {
      const stepName = step.get('name');

      return INTAKE_STEPS.includes(stepName);
    });
  },
);

export const selectFurthestFollowUpStepPath = createSelector(baseSelectors.selectKingFollowUpIntake, (intake) => {
  if (!intake) {
    return null;
  }
  const step = intake.get('steps').find((s) => !s.get('completed'));

  if (step) {
    return Routes.createIntake(intake.get('name'), urlFriendlyName(step));
  }

  const productName = intake.get('product_name');

  return Routes.ProductDashboard(productName);
});

export const selectFurthestSustainStepPath = createSelector(baseSelectors.selectKingActiveSustainIntake, (intake) => {
  if (!intake) {
    return null;
  }
  const step = intake.get('steps').find((s) => !s.get('completed'));
  if (step) {
    return Routes.createIntake(intake.get('name'), urlFriendlyName(step));
  }

  const productName = intake.get('product_name');

  return Routes.ProductDashboard(productName);
});

export const selectHasFollowUp = createSelector(baseSelectors.selectCustomerProductIntakes, (intakes) =>
  intakes.some((i) => baseSelectors.FOLLOW_UP_TYPES.includes(i.get('type'))),
);

export const selectHasActiveSustain = createSelector(baseSelectors.selectCustomerProductIntakes, (intakes) =>
  intakes.some((i) => baseSelectors.SUSTAIN_TYPES.includes(i.get('type')) && i.get('enabled')),
);

export const selectAllSustainsCompleted = createSelector(baseSelectors.selectCustomerProductIntakes, (allIntakes) => {
  const intakes = allIntakes.filter((intake) => baseSelectors.SUSTAIN_TYPES.includes(intake.get('type')));

  return !intakes.isEmpty() && intakes.every((i) => i.get('successful'));
});
export const selectFollowUpComplete = createSelector(
  baseSelectors.selectCustomerProductIntakes,
  (intakes) => intakes.some((i) => i.get('type') === 'follow_up' && i.get('completed')) || false,
);

export const selectAcknowledgementServices: (state: any, noLab: never, pharmacyChoice: string) => string[] =
  createSelector(
    [
      selectCurrentIntakeProduct,
      (_state: unknown, noLab: boolean) => noLab,
      (_state: unknown, pharmacyChoice: string) => pharmacyChoice,
    ],
    (productName, noLab, pharmacyChoice) => {
      if (productName === 'lover') {
        return [
          'Daily prescription medication tablets (if qualified)',
          'Licensed doctor consultations',
          'Live coaching & community',
          'Free 1-3 day priority shipping for meds',
        ];
      }

      return [
        !noLab && 'In-home hormone testing',
        noLab && 'At-home lab tests if indicated by clinician',
        'Clinician consultations',
        'Premium content & coaching',
        pharmacyChoice !== 'other' && 'Prescription medications',
        'Free 2-day shipping',
      ].filter((o) => !!o) as Array<string>;
    },
  );

export const selectHasOnboarding = createSelector(
  baseSelectors.selectCustomerProductOnboarding,
  (onboarding) => !onboarding?.get('completed'),
);

export const selectLatestIntakePaid = createSelector(
  baseSelectors.selectCustomer,
  selectCurrentIntake,
  (customer, intake) => {
    if (!customer || !intake) {
      return null;
    }

    return customer.getIn(['products', `${intake.get('product_name')}_subscription_monthly`, 'state']) === 'active';
  },
);

export const selectCurrentIntakeLabVendor: (state: any) => string | undefined = createSelector(
  selectCurrentIntake,
  (intake) => intake?.get('effective_lab'),
);

export const selectCurrentIntakeLabPrice: (state: any) => string | undefined = createSelector(
  selectCurrentIntake,
  (intake) => intake?.get('lab_pricing'),
);

export const selectLatestPaymentMethodErrorMessage = createSelector(baseSelectors.selectCustomer, (customer) =>
  customer.get('latest_payment_method_error_message'),
);

export const selectParamsFromQuery = createSelector(selectRouterQuery, (params) => {
  return params;
});

export const selectManagedProduct = createSelector(
  [baseSelectors.selectCustomer, selectParamsFromQuery] as Selector<any>[],
  (customer: any, params: any) => customer.getIn(['maximus_products', params?.get('product')]),
);
