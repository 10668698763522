import React, { useMemo } from 'react';
import { connect } from 'react-redux';

import Card from '@setproduct-ui/core/Card';

import { Formik, FormikValues, useFormikContext } from 'formik';
import ProgressBar from 'app/components/customer/questions/ProgressBar';
import RadioQuestion from 'app/components/customer/questions/Radio';
import { P, S } from 'app/components/common/Typography';
import BackNextFooter from 'app/components/customer/steps/BackNextFooter';
import * as selectors from 'app/selectors/customer';
import { push } from 'connected-react-router/immutable';
import { apiRequestUserCommand } from 'app/actions/customer';
import Question from 'app/constants/Question';

export const RefillConditionsQuestion = () => {
  const { values, setFieldValue } = useFormikContext<FormikValues>();
  return (
    <>
      <RadioQuestion name="refill_conditions" submit={false} />
      {values.refill_conditions === 'yes' && (
        <Card view="raised" className="checkbox-component multi-select-question__other_card">
          <textarea
            className="multi-select-question__other_textarea"
            style={{ width: '100%' }}
            onChange={({ target: { value: content } }) => {
              setFieldValue('refill_conditions_description', content);
            }}
            data-testid="other-textarea"
            placeholder="Let us know here..."
          />
        </Card>
      )}
    </>
  );
};

export const ConditionsAndMedicationsQuestion = () => {
  const refillConditionsQuestion = useMemo(() => new Question('refill_conditions'), []);
  const refillMedicationQuestion = useMemo(() => new Question('refill_medications'), []);
  const { values, setFieldValue } = useFormikContext<FormikValues>();
  return (
    <>
      <P type="body_bold" large className="mh16 mt24 question-label">
        <S color="primary" type="body_bold" large>
          1.
        </S>{' '}
        {refillMedicationQuestion.sentence}
      </P>
      <RadioQuestion name="refill_medications" submit={false} />
      {values.refill_medications === 'yes' && (
        <Card view="raised" className="checkbox-component multi-select-question__other_card">
          <textarea
            className="multi-select-question__other_textarea"
            style={{ width: '100%' }}
            onChange={({ target: { value: content } }) => {
              setFieldValue('refill_medications_description', content);
            }}
            data-testid="other-textarea"
            placeholder="Let us know here..."
          />
        </Card>
      )}
      <P type="body_bold" large className="mh16 mt24 question-label">
        <S color="primary" type="body_bold" large>
          2.
        </S>{' '}
        {refillConditionsQuestion.sentence}
      </P>
      <RefillConditionsQuestion />
    </>
  );
};

const ConditionsAndMedications = ({ dispatch, previousStepAction, intake, hideBackButton, onSuccessActionCreator }) => {
  const redirectPrevious = () => dispatch(previousStepAction);
  const onSubmit = (params) => {
    const name = 'conditions_and_medications';
    if (intake) {
      params.intake = intake.get('name');
    }
    const action = apiRequestUserCommand({
      cmdType: 'answer_questions',
      params,
      context: { onSuccessActionCreator, form: name },
    });
    dispatch(action);
  };

  return (
    <>
      <ProgressBar title="Refill Intake" refill position={67} total={100} />
      <Formik initialValues={{ refill_medications: 'no', refill_conditions: 'no' }} onSubmit={onSubmit}>
        {({ handleSubmit }) => (
          <div className="mb16">
            <ConditionsAndMedicationsQuestion />
            <BackNextFooter
              noBack={hideBackButton}
              onNext={handleSubmit}
              onBack={redirectPrevious}
              testId="submit-medical-conditions"
            />
          </div>
        )}
      </Formik>
    </>
  );
};

const mapStateToProps = (state) => {
  const previousStepAction = push(selectors.selectPreviousStepPath(state));
  const onSuccessActionCreator = (_, stateAfterSuccess) =>
    push(selectors.selectFurthestStepPath({ customer: stateAfterSuccess }));
  const prevStep = selectors.selectPreviousStep(state)?.toJS();
  const hideBackButton = prevStep ? !prevStep.revisitable : false;

  return {
    intake: selectors.selectCurrentIntake(state),
    previousStepAction,
    hideBackButton,
    onSuccessActionCreator,
  };
};

export default connect(mapStateToProps)(ConditionsAndMedications);
