import React from 'react';
import PropTypes from 'prop-types';

import './css/ApplePayButton.scss';

import ApplePayIcon from './ApplePayIcon';

/* eslint-disable no-useless-computed-key */
const ApplePayButton = ({
  ['aria-label']: ariaLabel,
  children = null,
  disabled,
  amount,
  onSuccessfulPayment,
  ...extraProps
}) => {
  const clickHandler = () => {
    const dollarAmount = amount / 100.0; // This can be a long float: i.e: 4.9899999999999
    const roundedAmount = Math.round(dollarAmount * 100.0) / 100.0; // This will round to 2 decimal places: i.e: 4.99
    const paymentRequest: ApplePayJS.ApplePayPaymentRequest = {
      countryCode: 'US',
      currencyCode: 'USD',
      supportedNetworks: ['visa', 'masterCard', 'amex'],
      merchantCapabilities: ['supports3DS'],
      total: { label: 'Pay Maximus Health, Inc', amount: String(roundedAmount) },
    };
    const session = new ApplePaySession(3, paymentRequest);
    session.onvalidatemerchant = function (event) {
      performValidation(event.validationURL).then((merchantSession) => {
        session.completeMerchantValidation(merchantSession);
      });
    };
    session.onpaymentauthorized = function (event) {
      processPayment(event.payment.token).then(function (success) {
        session.completePayment(success ? ApplePaySession.STATUS_SUCCESS : ApplePaySession.STATUS_FAILURE);
      });
    };
    session.begin();
  };

  const performValidation = (validationURL) => {
    return fetch('/api/apple_pay/validate_merchant', {
      method: 'POST',
      body: JSON.stringify({ validation_url: validationURL }),
      headers: { 'Content-type': 'application/json' },
    }).then((response) => response.json());
  };

  function processPayment(paymentToken) {
    return fetch('/api/apple_pay/process_payment', {
      method: 'POST',
      body: JSON.stringify(paymentToken),
      headers: { 'Content-type': 'application/json' },
    })
      .then((response) => response.json())
      .then((data) => {
        onSuccessfulPayment(data);

        return true;
      });
  }

  return (
    <button
      className={`default-button apple-pay-button apple-pay-button-dark`}
      onClick={clickHandler}
      disabled={disabled}
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      {...extraProps}
      aria-label={ariaLabel}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <span
          style={{
            fontFamily: 'HelveticaNeue-Medium',
            fontSize: 17,
            paddingBottom: 1.7,
            paddingRight: 6,
            letterSpacing: 0,
            color: 'white',
          }}
        >
          {children}
        </span>
        <ApplePayIcon
          style={{
            width: 32 * 1.6,
            height: 13.15 * 1.6,
          }}
          fill={disabled ? 'grey' : 'white'}
        />
      </div>
    </button>
  );
};
/* eslint-enable no-useless-computed-key */

ApplePayButton.propTypes = {
  'title': PropTypes.string,
  'aria-label': PropTypes.string,
};

ApplePayButton.defaultProps = {
  'aria-label': 'Pay with Apple Pay',
  'children': '',
};

export default ApplePayButton;
