import React from 'react';
import '../css/ProtocolHeader.scss';
import cx from 'classnames';
import protocolNames from 'app/utils/protocolNames';

type Props = {
  protocol: 'king' | 'lover' | 'warrior' | 'magician';
  className?: string;
  inline?: boolean;
  postfix?: boolean;
};
const ProtocolHeader = ({ protocol, className = '', inline = false, postfix = true }: Props) => {
  return (
    <h1 className={cx('protocol', protocol, { inline, className })}>
      <span>{protocolNames[protocol]}</span> {postfix && 'Protocol'}
    </h1>
  );
};

export default ProtocolHeader;
