import React, { useState } from 'react';

import { P } from 'app/components/common/Typography';
import { useFormikContext } from 'formik';
import ProductHeader from 'app/components/customer/steps/Payment/king/ProductHeader';
import { AvailableProducts, KingV2SubProducts } from 'app/constants/Products';
import PaymentCard from 'app/components/customer/steps/Payment/PaymentCard';
import Divider from '@setproduct-ui/core/Divider';
import { KingSubProductsConfig } from 'app/components/customer/steps/Payment/king/KingSubProductsConfig';
import SlidersIcon from 'images/icons/sliders.svg';
import FreeshippingIcon from 'images/icons/fast-free-shipping-2.svg';
import AtHomeIcon from 'images/icons/at-home-monitoring-2.svg';
import SetProductDialog from '@setproduct-ui/core/Dialog/Dialog';
import { ModalBodySafetyInfo } from 'app/components/customer/steps/Payment/king/ModalBodySafetyInfo';
import { PrimaryButton } from 'app/components/common/Button';
import { KING_MONTHLY_PRICE } from 'app/helpers/productSpecificPriceCalculators/kingPriceCalculator';

type Props = {
  handleSubmit: () => void;
};

const KingSubProductScreen = ({ handleSubmit }: Props) => {
  const [openModalSideEffects, setOpenModalSideEffects] = useState(false);
  const [openModalSafetyInfo, setOpenModalSafetyInfo] = useState(false);
  const { values, setFieldValue } = useFormikContext<{
    selected_king_v2_product: KingV2SubProducts;
  }>();

  const subProduct = KingSubProductsConfig[values.selected_king_v2_product];

  const renderFooter = () => (
    <div className="fixed-footer">
      <P>Plans Starting at ${KING_MONTHLY_PRICE[values.selected_king_v2_product][12] / 100}/mo</P>

      <PrimaryButton
        className="mt24 mb24"
        text="Continue"
        type="button"
        onClick={handleSubmit}
        disabled={false}
        data-testid="submit-product-selection"
      />

      <P>
        <a onClick={() => setFieldValue('selected_king_v2_product', false)} className="link">
          Explore Other Protocols
        </a>
      </P>
    </div>
  );

  return (
    <div style={{ marginBottom: '200px' }}>
      <PaymentCard className="product-selection">
        <ProductHeader
          productName={AvailableProducts.King}
          subProductName={values.selected_king_v2_product}
          showTitle={true}
          cardStyle={false}
        />

        <Divider className="mt16 mb16" />

        <h5 className="subtitle">Recommended for</h5>
        <p>{subProduct.recommended_for}</p>

        <Divider className="mt16 mb16" />

        <h5 className="subtitle">Active Ingredients</h5>
        <p className="mh8">
          {subProduct.active_ingredients.map((ingredient) => (
            <p className="mb16" key={ingredient.name}>
              <strong className="bold">{ingredient.name}</strong>
              <br />
              {ingredient.description}
            </p>
          ))}
        </p>

        <Divider className="mb16" />

        <h5 className="subtitle">What to Expect</h5>

        <div className="what-to-expect">
          <div className="mb16">
            <img src={AtHomeIcon} />
            <p>
              <strong className="bold">Check Your Levels</strong>
              <br />
              Receive your At-Home Lab Kit to check your testosterone levels quickly.
            </p>
          </div>
          <div className="mb16">
            <img src={FreeshippingIcon} />
            <p>
              <strong className="bold">Get Your Prescription</strong>
              <br />
              Once approved, your prescription is shipped straight to your door.
            </p>
          </div>
          <div className="mb16">
            <img src={SlidersIcon} />
            <p>
              <strong className="bold">Continuous Optimization</strong>
              <br />
              After your first month, we send another lab kit to ensure progress. Your doctor will adjust your dosage
              for optimal results.
            </p>
          </div>
        </div>

        <Divider className="mb16 mt16" />

        <a onClick={() => setOpenModalSideEffects(true)} className="link">
          Side Effects
        </a>

        <Divider className="mb16 mt16" />

        <a onClick={() => setOpenModalSafetyInfo(true)} className="link">
          Important Safety Information
        </a>
      </PaymentCard>

      {renderFooter()}

      <SetProductDialog
        isOpen={openModalSideEffects}
        title={<h4 className="subtitle bold mb0">Side Effects</h4>}
        text={subProduct.side_effects}
        onClose={() => setOpenModalSideEffects(false)}
        className="manage_subscription__cancel_modal align-left"
      />
      <SetProductDialog
        isOpen={openModalSafetyInfo}
        title={<h4 className="subtitle bold mb0">Important Safety Information</h4>}
        text={<ModalBodySafetyInfo />}
        onClose={() => setOpenModalSafetyInfo(false)}
        className="manage_subscription__cancel_modal align-left"
      />
    </div>
  );
};

export default KingSubProductScreen;
