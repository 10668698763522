import React from 'react';
import { P, H4, H5 } from 'app/components/common/Typography';
import { useAppDispatch, useAppSelector } from 'app/helpers/hooks';
import { push } from 'connected-react-router/immutable';
import * as actions from 'app/actions/customer';
import * as selectors from 'app/selectors/customer';
import { PrimaryButton } from 'app/components/common/Button';

const LoverPostScript = () => {
  const customer = useAppSelector(selectors.selectCustomer);
  const physicianTitle = customer.getIn(['physician', 'short_name']);
  const intake = useAppSelector(selectors.selectCurrentIntake);
  const currentStep = useAppSelector(selectors.selectCurrentStepName);
  const nextStep = useAppSelector(selectors.selectNextStepPath);
  const onSuccessAction = push(nextStep);
  const dispatch = useAppDispatch();

  const onClick = () => {
    const cmdType = 'answer_questions';
    const params: any = { [currentStep]: true };
    if (intake) {
      params.intake = intake.get('name');
    }
    const action = actions.apiRequestUserCommand({
      cmdType,
      params,
      context: { onSuccessAction },
    });
    dispatch(action);
  };
  const isNeedPressure =
    !intake.getIn(['questionnaire', 'blood_pressure_reading']) ||
    intake.getIn(['questionnaire', 'blood_pressure_systolic']) === 'dont_remember' ||
    intake.getIn(['questionnaire', 'blood_pressure_diastolic']) === 'dont_remember';

  return (
    <div className="p16">
      <H4>Almost there!</H4>
      <H5 className="mt16">We have successfully received your questionnaire. There is one step left.</H5>
      {isNeedPressure && (
        <P className="mt16">
          To evaluate which treatment plan is best for you, your doctor needs you to take your blood pressure at a local
          pharmacy, grocery store, or with a healthcare provider. Return once you know your blood pressure and{' '}
          {physicianTitle} will make a treatment recommendation.
        </P>
      )}
      <PrimaryButton className="mt16 mb24" data-testid="next-step" text="Continue" onClick={onClick} />
    </div>
  );
};

export default LoverPostScript;
