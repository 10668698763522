import React from 'react';
import UncheckedBox from 'images/UncheckedBox.svg';
import CheckedBox from 'images/CheckedBox.svg';
import CheckBoxGray from 'images/check_box_gray.svg';
import cx from 'classnames';
import './css/Checkbox.scss';

export const CardCheckbox = ({ contained = true, ...restProps }) => (
  <Checkbox contained={contained} fill color="primary" view="raised" {...restProps} />
);

export const Checkbox = ({
  contained = undefined,
  topAligned = undefined,
  size = 'small',
  style = 'blue',
  ...restProps
}) => {
  const targetImage = style === 'blue' ? CheckedBox : CheckBoxGray;
  const { checked, disabled, onChange, id, testId, onBlur, onFocus, label, helperText } = restProps;
  const { checkedImage = targetImage, uncheckedImage = UncheckedBox } = restProps;
  const inputProps = { checked, onChange, disabled, id, onBlur, onFocus };

  return (
    <div
      data-testid={testId || id}
      className={cx('hs2-checkbox', 'flex', 'flexAlignCenter', {
        'hs2-checkbox--disabled': disabled,
        'hs2-checkbox--contained': contained,
        'hs2-checkbox--checked': checked,
      })}
    >
      <label className={cx({ 'top-aligned': topAligned })} htmlFor={id}>
        <input type="checkbox" className="hs2-checkbox__input" {...inputProps} />
        {checked && (
          <img
            className={cx('hs2-checkbox__checkbox', {
              'hs2-checkbox__checkbox--small': size === 'small',
              'hs2-checkbox__checkbox--large': size === 'large',
            })}
            src={checkedImage}
          />
        )}
        {!checked && (
          <img
            className={cx('hs2-checkbox__checkbox', {
              'hs2-checkbox__checkbox--default': size === 'default',
              'hs2-checkbox__checkbox--small': size === 'small',
              'hs2-checkbox__checkbox--large': size === 'large',
            })}
            src={uncheckedImage}
          />
        )}
        <div className="hs2-checkbox__children">
          {label}
          {helperText}
        </div>
      </label>
    </div>
  );
};

export default Checkbox;
