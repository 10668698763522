import React from 'react';
import ProgressTrackerHeader from 'app/components/customer/ProgressTracker/ProgressTrackerHeader';
import ProgressTrackerUpNext from 'app/components/customer/ProgressTracker/ProgressTrackerUpNext';
import ProgressTrackerResources from 'app/components/customer/ProgressTracker/ProgressTrackerResources';
import ProgressTrackerFAQ from 'app/components/customer/ProgressTracker/ProgressTrackerFAQ';

import './css/ProgressTracker.scss';

const ProgressTracker = () => (
  <>
    <ProgressTrackerHeader />
    <ProgressTrackerUpNext />
    <ProgressTrackerResources />
    <ProgressTrackerFAQ />
  </>
);

export default ProgressTracker;
