import { Map } from 'immutable';
import { NOTIFY_USER } from 'app/actions/customer';

export default (state = Map(), action) => {
  if (action.type === 'INIT_CUSTOMER') {
    return state.set('authentication', 'customer');
  }
  if (action.type === '@common/INIT_USERS') {
    return state.set('authentication', 'admin');
  }
  if (action.type === 'INIT_UNAUTHENTICATED') {
    return state.set('authentication', 'stranger');
  }
  if (action.type === NOTIFY_USER) {
    return state.set('notifyUser', action.payload);
  }

  return state;
};
