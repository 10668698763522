import React, { FC } from 'react';
import cx from 'classnames';
import './css/SocialMedia.scss';

import SOCIALS from 'app/constants/socials.json';

const SocialMedia: FC<{
  className?: string;
  socials?: Array<keyof typeof SOCIALS>;
}> = ({ className = '', socials = ['reddit', 'facebook', 'instagram', 'twitter'] }) => {
  const socialsList = socials.map((type) => ({ type, ...SOCIALS[type] }));

  return (
    <section className={cx('social-media center-align', className)}>
      <p className="social-media__text mv16">
        Learn more and join our social community&nbsp;
        <a href="https://linktr.ee/maximustribe" rel="noreferrer" target="_blank">
          here
        </a>
        .
      </p>
      <nav className="social-media__list">
        {socialsList.map(({ type, name, url }) => (
          <a href={url} key={type} className="social-media__item" target="_blank" rel="noreferrer">
            <i className={cx('social-media__icon', `iconfont-${type}`)} aria-hidden="true" />
            <span className="ghost">{name}</span>
          </a>
        ))}
      </nav>
    </section>
  );
};

export default SocialMedia;
