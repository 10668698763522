import React, { FC } from 'react';
import '../css/ResourceSection.scss';

export const ResourceSection: FC<{
  image?: string;
  title: string;
  text: string;
  urls?: [string, string][];
}> = ({ image, title, text, urls }) => (
  <section className="resource-section section-divider--primary mv32">
    {image && <img className="resource-section__image" src={image} alt={title} />}
    <h4 className="resource-section__title mt28">{title}</h4>
    <p className="resource-section__text mt16">{text}</p>
    {!!urls?.length &&
      urls.map(([u, t]) => (
        <a key={u} className="resource-section__link mt20" href={u} target="_blank" rel="noreferrer">
          {t}
        </a>
      ))}
  </section>
);
