import * as Sentry from '@sentry/react';
import { EventHint } from '@sentry/types/types';
import suppressInfoMessages from 'app/utils/sentry/info';
import suppressWarnings from 'app/utils/sentry/warnings';
import suppressExceptions from 'app/utils/sentry/exceptions';
import type { ErrorEvent } from '@sentry/types/types/event';

class MaximusSentry {
  static ignoreSentryEvent(event: ErrorEvent, hint: EventHint): boolean {
    return suppressInfoMessages(event, hint) || suppressWarnings(event) || suppressExceptions(event);
  }

  static init(): void {
    if (window.sentryDSN) {
      const options = {
        dsn: window.sentryDSN,
        integrations: [
          Sentry.browserTracingIntegration(),
          Sentry.captureConsoleIntegration(),
          Sentry.browserApiErrorsIntegration({
            eventTarget: false,
          }),
        ],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: parseFloat(window.sentryTracesSampleRate || '0.0'),
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        environment: window.currentEnvironment,
        release: window.SENTRY_RELEASE || window.sentryRelease,
        enabled: ['production', 'staging', 'anon'].includes(window.currentEnvironment || 'development'),
        // TODO: Set a custom tag on the Event instead of interrupting the send and filter on Sentry side (Inbound Filters)
        // https://github.com/getsentry/sentry/issues/22874
        beforeSend: (event: ErrorEvent, hint: EventHint) => (this.ignoreSentryEvent(event, hint) ? null : event),
      };
      if (window.sentryEnableReplays) {
        options['replaysSessionSampleRate'] = 0.1;
        options['replaysOnErrorSampleRate'] = 1.0;
        options['integrations'].push(Sentry.replayIntegration());
      }
      Sentry.init(options);
    }
    Sentry.setUser({ id: window.sentryUserId });
  }
}

export default MaximusSentry;
