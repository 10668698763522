import React, { FC } from 'react';
import { Spinner } from '@blueprintjs/core';
import mapImage from 'images/map.svg';
import { ReactSVG } from 'react-svg';

export const USMap: FC<{
  validStates: string[];
}> = ({ validStates }) => (
  <ReactSVG
    aria-label="The US map with states where Maximus is available"
    beforeInjection={(svg) => {
      [...svg.querySelectorAll('[aria-label]')]
        .filter((el) => validStates.includes(el.getAttribute('aria-label') as string))
        .forEach((el) => el.classList.add('--active'));

      const title = document.createElementNS('http://www.w3.org/2000/svg', 'title');
      title.innerHTML = 'The US map with states where Maximus is available';
      svg.prepend(title);
    }}
    loading={Spinner}
    role="img"
    src={mapImage}
  />
);
