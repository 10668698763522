import React from 'react';
import { P, H4 } from 'app/components/common/Typography';
import { connect } from 'react-redux';
import { push } from 'connected-react-router/immutable';
import * as actions from 'app/actions/customer';
import * as selectors from 'app/selectors/customer';
import { PrimaryButton } from 'app/components/common/Button';
import SocialMedia from 'app/components/customer/SocialMedia';
import DumbServicesList from 'app/components/customer/DumbServicesList';
import { selectIsBillingFeature } from 'app/selectors/customer';

const mapStateToProps = (state) => {
  const onSuccessAction = push(selectors.selectNextStepPath(state));
  const firstName = selectors.selectCustomerFirstName(state);
  const intake = selectors.selectCurrentIntake(state);
  const featureBilling = selectIsBillingFeature(state);

  return {
    onSuccessAction,
    intake,
    firstName,
    featureBilling,
  };
};
const FollowUpAcknowledgement = ({ firstName, onSuccessAction, step, intake, dispatch, featureBilling }) => {
  const onClick = () => {
    const cmdType = 'answer_questions';
    const params = { [step]: true };
    if (intake) {
      params.intake = intake.get('name');
    }
    const action = actions.apiRequestUserCommand({
      cmdType,
      params,
      context: { onSuccessAction },
    });
    dispatch(action);
  };
  let sentence = '';

  const welcomeMessage = 'Welcome back,';
  const endSentence =
    'So our licensed clinicians can better manage your care and determine the best course of treatment for you, please complete the following clinical intake as thoroughly and honestly as possible.';

  if (featureBilling) {
    sentence = 'For $149.99 per 30 days, plus cost of medication, you will have access to:';
  }

  return (
    <div className="p16">
      <H4>
        {welcomeMessage}{' '}
        <P color="primary" tagName="span" className="font-size-inherit font-weight-inherit">
          {firstName}.
        </P>
      </H4>
      <P className="mt16">{sentence}</P>
      <DumbServicesList />
      <P className="mt16">{endSentence}</P>
      <PrimaryButton className="mt16 mb24" data-testid="next-step" text="Get Started" onClick={onClick} />
      <div className="flex1" />
      <SocialMedia />
    </div>
  );
};

export default connect(mapStateToProps)(FollowUpAcknowledgement);
