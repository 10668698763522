import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';

import * as selectors from 'app/selectors/customer';
import BlackIntake from 'app/components/customer/Intake';
import * as Routes from 'app/constants/Routes';

const mapStateToProps = (state) => {
  const intake = selectors.selectCurrentIntake(state);
  if (!intake) {
    return {};
  }

  const productName = selectors.selectCurrentIntakeProduct(state);

  const furthestStep = selectors.selectFurthestProductLastIntakeStep(state, productName);

  if (!furthestStep) {
    const redirectUrl = Routes.ProductDashboard(productName);

    return { redirectUrl, allowedHere: true };
  }

  const isAppropriateStep = selectors.selectEarlierOrSameStep(state);

  const intakeName = intake.get('name');
  const product = intake.get('product_name');
  const stepName = selectors.urlFriendlyName(furthestStep);
  const furthestStepPath = Routes.createIntake(intakeName, stepName);
  const redirectUrl = !isAppropriateStep && (furthestStepPath || Routes.ProductDashboard(productName));
  const cancelled = selectors.selectIsProductCancelled(state, product);
  const productFlags = selectors.selectProductFlags(state, product);
  const rejected = productFlags.includes('health_rejected') || productFlags.includes('location_rejected');
  const allowedHere = !rejected && !cancelled;

  let allowedToReviewStep = false;
  try {
    // Sometimes the current step object is not available for example on location_confirmation step
    const selectCurrentStepObject = selectors.selectCurrentStepObject(state);
    allowedToReviewStep = selectCurrentStepObject?.get('editable');
  } catch (e) {
    // ignore
  }

  return {
    redirectUrl,
    allowedHere,
    allowedToReviewStep,
  };
};

const Intake = ({ allowedHere, redirectUrl, allowedToReviewStep, match }) => {
  if (!allowedHere && !allowedToReviewStep) {
    return <Redirect to={Routes.Dashboard} />;
  }
  if (redirectUrl && !allowedToReviewStep) {
    return <Redirect to={redirectUrl} />;
  }

  return <BlackIntake intakeName={match.params.intakeName} />;
};

export default connect(mapStateToProps)(Intake);
