import React, { useContext, useMemo } from 'react';
import TitleWithIcon from 'app/components/customer/ProgressTracker/common/TitleWithIcon';
import IconLamp from 'images/progress-tracker/Lamp.svg';
import OPTIONS from 'app/constants/option-groups.json';
import ExpandButton from 'app/components/common/ExpandButton';
import { ReactSVG } from 'react-svg';
import { ProductContext } from '../Product';
import './css/FAQ.scss';

const ProgressTrackerFAQ = () => {
  const productCtx = useContext(ProductContext);
  const faqItemsProduct = window.faqItems?.filter((i) => i.product === productCtx?.selectedProduct);

  const availableStatesList = useMemo(
    () =>
      OPTIONS.states
        .filter((state: { label: string; value: string }) => window.signupStates.includes(state.value))
        .map((state) => state.label)
        .join(', '),
    [window.signupStates],
  );

  const parseAnswer = (answer: string) => answer.replace('[AVAILABLE_STATES]', availableStatesList);

  if (!faqItemsProduct?.length) {
    return null;
  }

  return (
    <div className="progress_tracker__faq">
      <div className="progress_tracker__content">
        <TitleWithIcon icon={<ReactSVG src={IconLamp} className="svg-icon" />}>
          Frequently Asked Questions
        </TitleWithIcon>
        <div className="progress_tracker__faq__questions">
          {faqItemsProduct.map((item) => (
            <div key={item.id} className="question">
              <ExpandButton text={item.question}>
                <p dangerouslySetInnerHTML={{ __html: parseAnswer(item.answer) }} />
              </ExpandButton>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default ProgressTrackerFAQ;
