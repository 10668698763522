import React from 'react';
import { PrimaryButton } from 'app/components/common/Button';
import IconGeolocation from 'images/Icon-Geolocation.svg';
import { P2, H5 } from 'app/components/common/Typography';

const Denied = () => (
  <div className="center-align">
    <div className="mh16 mt16">
      <img src={IconGeolocation} />
    </div>
    <H5 className="mh24 mt24">We Apologize</H5>
    <P2 className="flex1 mh24 mt8">
      We&apos;re sorry but we need to have access to your device location in order to confirm your current position.
      Please enable location settings in your Site Settings / Browser settings and reload this page.
    </P2>
    <div className="mh16 mt16">
      <PrimaryButton data-testid="reload-page" text="Reload Page" onClick={() => window.location.reload()} />
    </div>
  </div>
);

export default Denied;
