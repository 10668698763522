import React, { FC, useState } from 'react';
import { connect } from 'react-redux';

import { push } from 'connected-react-router/immutable';
import * as actions from 'app/actions/customer';
import * as Routes from 'app/constants/Routes';
import OpaqueHeader from '../Header/OpaqueHeader';
import TransparentHeader from '../Header/TransparentHeader';

import Menu from './Menu';
import './css/Header.scss';

const getHeader = (style: string | undefined) => {
  switch (style) {
    case 'black':
      return OpaqueHeader;
    case 'transparent':
      return TransparentHeader;
    default:
      return TransparentHeader;
  }
};

const Header: FC<{
  style?: 'black' | 'transparent';
  loggedIn?: boolean;
  redirect: (route: string) => void;
  logout: () => void;
  onLogoutClick: () => void;
  children?: React.ReactNode;
}> = ({ style, loggedIn = false, redirect, logout, onLogoutClick, children = undefined }) => {
  const [menuOpened, setMenuOpened] = useState(false);
  const onMenuClick = () => {
    setMenuOpened(!menuOpened);
  };
  const onLogoClick = () => {
    redirect(Routes.Dashboard);
  };
  const onSettingsClick = () => {
    redirect(Routes.Settings);
  };
  const onDashboardClick = () => {
    redirect(Routes.Dashboard);
  };
  const headerProps = {
    onMenuClick,
    loggedIn,
    logout,
    onLogoClick,
    redirect,
    onLogoutClick,
    onSettingsClick,
    onDashboardClick,
    style,
    children,
  };
  const Header = getHeader(style);
  const menuProps = {
    onLogoutClick,
    onMenuClick,
    menuOpened,
    redirect,
    loggedIn,
    children,
  };

  return (
    <>
      <Header {...headerProps} />
      <Menu {...menuProps} />
    </>
  );
};

const mapDispatchToProps = {
  redirect: push,
  logout: actions.logout,
  onLogoutClick: actions.logout,
};

export default connect(null, mapDispatchToProps)(Header);
