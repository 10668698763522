import maximusDebug from './maximusDebug';

const logger = maximusDebug('LogFrontendEvents');

export const backendLog = async (type: string, body: Record<string, unknown>): Promise<void> => {
  try {
    logger('Starting Log Event [%o] to backend: %O', type, body);
    await fetch('/api/frontend/event', {
      method: 'POST',
      body: JSON.stringify({
        type,
        body,
      }),
      headers: {
        'Content-type': 'application/json',
      },
    });
    logger('Logged Event [%o] on backend: %O', type, body);
  } catch (e) {
    logger('Logging failed:\n%O', e);
  }
};
