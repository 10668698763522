import { AvailableProducts } from 'app/constants/Products';
import { createContext } from 'react';

export interface IProductContext {
  /** Selected Product Type */
  selectedProduct: AvailableProducts | null;
  /** Selected Product Title */
  selectedProductName: string | null;
  /** Change Product */
  change: (arr: AvailableProducts) => void;
}

export const ProductContext = createContext<IProductContext>({
  selectedProduct: null,
  selectedProductName: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  change: () => {},
});
