import React, { useRef, useState, useMemo, useCallback, useEffect, ComponentProps, FC } from 'react';
import { useAppSelector } from 'app/helpers/hooks';
import cx from 'classnames';
import * as Routes from 'app/constants/Routes';
import { push } from 'connected-react-router/immutable';
import { Checkbox } from 'app/components/common/formik/Checkbox';
import * as actions from 'app/actions/customer';
import * as selectors from 'app/selectors/customer';
import { AvailableProducts } from 'app/constants/Products';
import { Formik, Form } from 'formik';
import '../css/ReferralSourceModal.scss';
import '../css/WhyCancelModal.scss';
import TextArea from 'app/components/common/formik/TextArea';
import BottleEncloPregnolone from 'images/sub-products/king/bottle_enclo_pregnolone.png';
import BottleTrt from 'images/sub-products/king/bottle_trt.png';
import REASONS from 'app/constants/cancellation-reasons.json';
import { PrimaryButton, SecondaryButton } from 'app/components/common/Button';
import { FullscreenModal } from 'app/components/common/FullscreenModal';
import _ from 'lodash';
import protocolNames from 'app/utils/protocolNames';
import PriceFormatter from 'app/components/common/PriceFormatter';
import PageHeader from 'app/components/common/PageHeader';
import { useDispatch } from 'react-redux';

const ModalBody = ({
  productName,
  setSuccessOpen,
  setDialogOpen,
  applyCommand,
  fullScreenModalRef,
}: {
  productName: 'king' | 'lover' | 'warrior' | 'magician';
  setDialogOpen: (arg: boolean) => void;
  setSuccessOpen: (arg: boolean) => void;
  applyCommand?: (cmdType: string, values?: any) => void;
  fullScreenModalRef: any;
}) => {
  const isKing = productName === AvailableProducts.King;
  const [currentStep, setCurrentStep] = useState(1);
  const [isProductChangeAllowed, setProductChangeAllowed] = useState(false);
  const customerId = useAppSelector(selectors.selectCustomerId);
  const productsImm = useAppSelector(selectors.selectCustomerProducts);
  const product = productsImm?.get(productName);
  const intakes = product?.get('intakes');
  const intake = intakes?.get(intakes.size - 1);
  const kingV2Offered = isKing && isProductChangeAllowed && intake?.get('king_v2_offered');

  const dispatch = useDispatch();
  const defaultValues = useMemo(
    () => REASONS[productName].reduce((ac, a: any) => ({ ...ac, [a.value]: a.default || false }), {} as any),
    [productName],
  );

  const submit = (data) => {
    const cancelParams = {
      type: 'cancel_account',
      user_id: customerId,
      params: {
        product_name: productName,
      },
    };
    const cancelAction = actions.apiRequestCommand({
      params: cancelParams,
      context: {},
    });
    dispatch(cancelAction);

    const cmdType = 'answer_questions';
    const params = { cancel_reasons: data, product_name: productName };

    if (!applyCommand) {
      dispatch(
        actions.apiRequestUserCommand({
          cmdType,
          params,
          context: {},
        }),
      );
    } else {
      applyCommand(cmdType, params);
    }

    setDialogOpen(false);
    setSuccessOpen(true);
  };

  function getQuestionsForQueue(questionsQueue, values, baseQ) {
    baseQ.forEach((q) => {
      for (let i = 0; i < q.questions.length; i++) {
        const question = q.questions[i];
        if (values[question.value] && question.more_info) {
          questionsQueue.push(question.more_info);

          getQuestionsForQueue(questionsQueue, values, question.more_info);
        }
      }
    });
  }

  const baseQuestions = REASONS[productName];
  const initialQuestion = {
    number: 'Question: 1 of 3',
    questions: [
      {
        title: <>Why are you canceling your {protocolNames[productName]} subscription (select all that apply):</>,
        questions: [
          ...REASONS[productName],
          {
            label: 'Other',
            value: 'other',
            details: true,
          },
        ],
      },
    ],
  };

  const [currentQuestions, setCurrentQuestions] = useState([initialQuestion]);
  const nextStep = useCallback(
    (values, setValues) => {
      const questionsQueue: any = [];
      if (baseQuestions) {
        getQuestionsForQueue(questionsQueue, values, [
          {
            questions: baseQuestions,
          },
        ]);
      }

      setProductChangeAllowed(values.no_improvements || values.labs_not_desired);
      const idx = questionsQueue.indexOf(currentQuestions);
      if (questionsQueue[idx + 1]) {
        const q = questionsQueue[idx + 1];
        setCurrentQuestions(q);
        const val = { ...values };
        q.forEach((iq) => {
          iq.questions.forEach((qv) => {
            qv.questions.forEach((question) => {
              val[question.value] = typeof question.default === 'string' ? question.default : false;
            });
          });
        });
        setValues(val);
      } else {
        if (isKing && currentStep === 1) {
          setCurrentStep(0);
          return fullScreenModalRef.current.scrollTo({ top: 0 });
        }
        setCurrentStep((step) => step + 1);
      }
    },
    [currentQuestions, currentStep],
  );

  const previousStep = useCallback(() => {
    setCurrentQuestions([initialQuestion]);
    setCurrentStep((step) => step - 1);
  }, [currentQuestions]);

  const handleClickCancelAfterPlans = () => {
    setCurrentStep(2);
    fullScreenModalRef.current.scrollTo({ top: 0 });
  };

  const kingUpdateSubscriptionLink = kingV2Offered
    ? Routes.UpdateSubscriptionForProductKingV2(productName, true)
    : Routes.UpdateSubscriptionForProduct(productName);

  return (
    <div className="referral-source-modal__body">
      <div className="referral-source-modal__content">
        <div className="updated-design">
          <PageHeader title="Cancel Subscription" className="-edged" />
        </div>

        <div className="referral-source-modal__options">
          <Formik
            initialValues={{
              ...defaultValues,
              other: false,
              expected_lab_results: '123',
              yes_hormone_optimizing: false,
              no_hormone_optimizing: false,
              open_to_discuss: false,
              other_reason: null,
              not_open_to_discuss: false,
            }}
            onSubmit={submit}
          >
            {({ values, handleSubmit, setValues, dirty }) => (
              <Form onSubmit={handleSubmit}>
                {currentStep === 0 && (
                  <div className="payment_method__flex yolo">
                    <div className="referral-source-modal__title">
                      <p>Before you go</p>
                    </div>
                    {isKing && (
                      <>
                        <div className="referral-source-modal__subtitle">
                          {kingV2Offered ? (
                            <p className="mb16 mt16">
                              Did you know we offer <strong className="bold">Oral TRT+</strong> as part of the
                              Testosterone Protocol. It’s a great product to consider if you’re not responding to
                              Enclomiphone + Pregnenalone alone.
                            </p>
                          ) : (
                            <p>
                              Did you know we offer plans as low as{' '}
                              <PriceFormatter className="product-offer__price" price={119.99} /> a month?
                            </p>
                          )}
                        </div>
                        <div className="product-offer payment_card">
                          <div className="product-offer__image">
                            {kingV2Offered ? (
                              <img src={BottleTrt} alt="Oral TRT+" />
                            ) : (
                              <img src={BottleEncloPregnolone} alt="Enclomiphene + Pregnenolone" />
                            )}
                          </div>
                          <p className="product-offer__name">{kingV2Offered ? 'Oral TRT+' : 'Testosterone Protocol'}</p>
                          <div className="product-offer__plans">
                            <div>Plans starting at</div>
                            <div className="product-offer__plans-right">
                              <PriceFormatter
                                className="product-offer__price"
                                price={kingV2Offered ? 149.99 : 119.99}
                              />
                              /mo
                            </div>
                          </div>
                          <p className="product-offer__info">
                            Covers cost of prescription medication (if qualified), doctor consultation, and
                            psychologist-led health coaching.
                          </p>
                          <div className="product-offer__btns">
                            <PrimaryButton
                              className="referral-source-modal__button referral-source-modal__button-active"
                              data-testid="referral-source-next"
                              text={kingV2Offered ? 'Learn More' : 'See plans'}
                              onClick={() => dispatch(push(kingUpdateSubscriptionLink))}
                            />
                            <SecondaryButton
                              className="referral-source-modal__button referral-source-modal__button-active"
                              data-testid="referral-source-back"
                              text={kingV2Offered ? 'continue with cancel' : 'cancel subscription'}
                              onClick={handleClickCancelAfterPlans}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                )}

                {currentStep > 0 && (
                  <>
                    <div className="referral-source-modal__title">
                      <p>{`Cancelling ${protocolNames[productName]} Protocol`}</p>
                    </div>
                    <div className="referral-source-modal__subtitle">
                      <p>Please answer these three questions to complete your cancelation</p>
                    </div>
                  </>
                )}

                {currentStep === 1 && (
                  <div>
                    {currentQuestions.map((currentQuestion, index) => (
                      <div className="question-wrapper" key={index}>
                        <p className="question-steps">
                          {currentQuestion.number || `Question: ${currentStep} (more info)`}
                        </p>
                        {currentQuestion.questions.map((q: any, i) => (
                          <div key={i} className={cx({ mt32: !!i })}>
                            {q.title && (
                              <h3 className="question_title">
                                {q.title} {q.help_copy && <b>{q.help_copy}</b>}
                              </h3>
                            )}
                            <div className="health-questions">
                              {q.questions.map((question) => {
                                if (question.value === 'expected_lab_results') {
                                  return (
                                    <TextArea
                                      value={null}
                                      defaultValue=""
                                      key={question.value}
                                      success={null}
                                      id="expected_lab_results"
                                      name="expected_lab_results"
                                      placeholder="Enter your answer here"
                                    />
                                  );
                                }

                                return question.details ? (
                                  <React.Fragment key={question.value}>
                                    <div
                                      className={`health-questions__item ${
                                        q.questions.length % 2 === 1 ? 'other' : ''
                                      }`}
                                      key={question.value}
                                    >
                                      <Checkbox
                                        id={question.value}
                                        name={question.value}
                                        label={`${question.label} (Please describe)`}
                                        className="hs2-checkbox"
                                      />
                                    </div>
                                    <div className={cx('describe_reason', { 'display-none': !values[question.value] })}>
                                      <p>Please describe your reason </p>
                                      <TextArea
                                        success={null}
                                        id={`${question.value}_reason`}
                                        name={`${question.value}_reason`}
                                        placeholder="Enter your answer here"
                                      />
                                    </div>
                                  </React.Fragment>
                                ) : (
                                  <div className="health-questions__item" key={question.value}>
                                    <Checkbox
                                      key={question.value}
                                      id={question.value}
                                      name={question.value}
                                      label={question.label}
                                      className="hs2-checkbox"
                                    />
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        ))}
                        <div className="btn-group">
                          <SecondaryButton
                            className="referral-source-modal__button referral-source-modal__button-active"
                            data-testid="referral-source-back"
                            text="Back"
                            onClick={() => setDialogOpen(false)}
                          />
                          <PrimaryButton
                            className="referral-source-modal__button referral-source-modal__button-active"
                            data-testid="referral-source-next"
                            text="Next"
                            disabled={!dirty}
                            onClick={() => nextStep(values, setValues)}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                )}

                {currentStep === 2 && (
                  <div className="question-wrapper">
                    <p className="question-steps">Question 2 of 3</p>
                    <h3 className="question_title">Are you planning use other products instead of Maximus?</h3>
                    <div className="health-questions">
                      <div className="health-questions__item radio-style">
                        <Checkbox
                          id="yes_hormone_optimizing"
                          onChange={() =>
                            setValues({ ...values, yes_hormone_optimizing: true, no_hormone_optimizing: false })
                          }
                          label="Yes (Please describe)"
                          name="yes_hormone_optimizing"
                        />
                      </div>
                      <div className="health-questions__item radio-style">
                        <Checkbox
                          onChange={() =>
                            setValues({
                              ...values,
                              yes_hormone_optimizing: false,
                              yes_hormone_optimizing_description: null,
                              no_hormone_optimizing: true,
                            })
                          }
                          id="no_hormone_optimizing"
                          label="No"
                          name="no_hormone_optimizing"
                        />
                      </div>
                      <div className={cx('describe_reason', { 'display-none': !values.yes_hormone_optimizing })}>
                        <p>Please tell us what product you are using and why</p>
                        <TextArea
                          success={null}
                          id="yes_hormone_optimizing_description"
                          name="yes_hormone_optimizing_description"
                          placeholder="Enter your answer here"
                        />
                      </div>
                    </div>
                    <div className="btn-group">
                      <SecondaryButton
                        className="referral-source-modal__button referral-source-modal__button-active"
                        data-testid="referral-source-back"
                        text="Back"
                        onClick={() => previousStep()}
                      />
                      <PrimaryButton
                        className="referral-source-modal__button referral-source-modal__button-active"
                        data-testid="referral-source-next"
                        text="Next"
                        onClick={() => nextStep(values, setValues)}
                      />
                    </div>
                  </div>
                )}

                {currentStep === 3 && (
                  <div className="question-wrapper">
                    <p className="question-steps">Question: 3 of 3</p>
                    <h3 className="question_title">
                      Are you open to discussing your experience with one of our product specialists?
                    </h3>
                    <div className="health-questions">
                      <div className="health-questions__item radio-style">
                        <Checkbox
                          id="open_to_discuss"
                          label="Yes, a product specialist may contact me"
                          name="open_to_discuss"
                          onChange={() => setValues({ ...values, not_open_to_discuss: false, open_to_discuss: true })}
                        />
                      </div>
                      <div className="health-questions__item radio-style">
                        <Checkbox
                          id="not_open_to_discuss"
                          label="No"
                          name="not_open_to_discuss"
                          onChange={() => setValues({ ...values, not_open_to_discuss: true, open_to_discuss: false })}
                        />
                      </div>
                    </div>
                    <div className="btn-group btn-group_column">
                      <SecondaryButton
                        className="referral-source-modal__button referral-source-modal__button-active"
                        data-testid="referral-source-back"
                        text="Back"
                        onClick={() => previousStep()}
                      />
                      <PrimaryButton
                        className="referral-source-modal__button referral-source-modal__button-active"
                        data-testid="referral-source-next"
                        text="Cancel subscription"
                        onClick={() => handleSubmit()}
                      />
                    </div>
                  </div>
                )}
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

const WhyCancelModal: FC<{
  productName: ComponentProps<typeof ModalBody>['productName'];
  openCancelReasons?: boolean;
  setOpenCancelReasons: ComponentProps<typeof ModalBody>['setDialogOpen'];
  setOpenCancelSuccess: ComponentProps<typeof ModalBody>['setSuccessOpen'];
  applyCommand?: ComponentProps<typeof ModalBody>['applyCommand'];
}> = ({ productName, openCancelReasons, setOpenCancelReasons, setOpenCancelSuccess, applyCommand }) => {
  const fullScreenModalRef = useRef();

  useEffect(() => {
    window.onbeforeunload = function () {
      return 'Your subscription has not been canceled. Are you sure you want to leave?';
    };
  });

  return (
    <FullscreenModal
      refModal={fullScreenModalRef}
      isOpen={openCancelReasons}
      onClose={() => setOpenCancelReasons(false)}
      text={
        <ModalBody
          productName={productName}
          setSuccessOpen={setOpenCancelSuccess}
          setDialogOpen={setOpenCancelReasons}
          applyCommand={applyCommand}
          fullScreenModalRef={fullScreenModalRef}
        />
      }
      className="why-cancel"
    />
  );
};

export default WhyCancelModal;
