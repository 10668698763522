import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from 'app/helpers/hooks';
import cx from 'classnames';
import Checkbox from 'app/components/common/Checkbox';
import checkboxCheckedImage from 'images/CheckedBoxDefault.svg';
import checkboxUncheckedImage from 'images/UncheckedBoxDefault.svg';
import SetProductDialog from '@setproduct-ui/core/Dialog';
import ReferralSourceImage from 'images/Referral-Source-Image.jpg';
import * as actions from 'app/actions/customer';
import '../css/ReferralSourceModal.scss';
import { selectCustomerReferralSourcesOptions } from 'app/selectors/customer';

const CheckboxCustom = ({ title, value, selectedOptions, setSelectedOptions }) => {
  const handleChange = (e) => {
    if (e.target.checked) {
      setSelectedOptions([...selectedOptions, value]);
    } else {
      setSelectedOptions(selectedOptions.filter((option) => option !== value));
    }
  };

  return (
    <Checkbox
      testId={value}
      label={title}
      checked={selectedOptions.includes(value)}
      onChange={handleChange}
      checkedImage={checkboxCheckedImage}
      uncheckedImage={checkboxUncheckedImage}
    />
  );
};

const getCheckbox = (option, selectedOptions, setSelectedOptions, index) => {
  const title = (
    <>
      {option.label}
      {!!option.sub_label && <span>{option.sub_label}</span>}
    </>
  );

  return (
    <CheckboxCustom
      key={index}
      title={title}
      value={option.name}
      selectedOptions={selectedOptions}
      setSelectedOptions={setSelectedOptions}
    />
  );
};

const ModalTitle = () => <div className="referral-source-modal__title">How did you hear about Maximus?</div>;

const ModalBody = ({ setDialogOpen }) => {
  const dispatch = useAppDispatch();
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const options = useAppSelector(selectCustomerReferralSourcesOptions).toJS();
  const [otherInput, setOtherInput] = useState('');

  const submit = () => {
    if (!selectedOptions.length) {
      return;
    }
    setDialogOpen(false);
    let userOptions = selectedOptions.reduce((obj, option) => ({ ...obj, [option]: true }), {});
    if (selectedOptions.includes('other')) {
      userOptions = { ...userOptions, other_content: otherInput };
    }
    const cmdType = 'answer_questions';
    const params = { referral_sources: userOptions };
    const action = actions.apiRequestUserCommand({
      cmdType,
      params,
      context: {},
    });
    dispatch(action);
  };

  const getOtherTitle = () => {
    if (selectedOptions.includes('other')) {
      return (
        <input
          type="text"
          value={otherInput}
          onChange={(e) => setOtherInput(e.target.value)}
          placeholder="Please describe"
          className="referral-source-modal__other"
        />
      );
    }

    return (
      <>
        Other
        <span>(Please describe)</span>
      </>
    );
  };

  return (
    <div className="referral-source-modal__body">
      <div className="referral-source-modal__content">
        <div className="referral-source-modal__options">
          {options.map((option, index) => getCheckbox(option, selectedOptions, setSelectedOptions, index))}
          <CheckboxCustom
            title={getOtherTitle()}
            value="other"
            selectedOptions={selectedOptions}
            setSelectedOptions={setSelectedOptions}
          />
          <div
            className={cx('referral-source-modal__button', {
              'referral-source-modal__button-active': selectedOptions.length,
            })}
            data-testid="referral-source"
            onClick={submit}
          >
            SEND
          </div>
        </div>
      </div>
      <img src={ReferralSourceImage} className="referral-source-modal__image" />
    </div>
  );
};

const ReferralSourcesModal = () => {
  const dispatch = useAppDispatch();
  const [dialogOpen, setDialogOpen] = useState(true);
  const skipModal = () => {
    setDialogOpen(false);
    const cmdType = 'answer_questions';
    const params = { referral_sources: { skipped: true } };
    const action = actions.apiRequestUserCommand({
      cmdType,
      params,
      context: {},
    });
    dispatch(action);
  };

  return (
    <SetProductDialog
      isOpen={dialogOpen}
      onClose={skipModal}
      title={<ModalTitle />}
      text={<ModalBody setDialogOpen={setDialogOpen} />}
      className="referral-source-modal"
    />
  );
};

export default ReferralSourcesModal;
