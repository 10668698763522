import ReactRailsUJS from 'react_ujs';

export function initReactRailsContext(componentRequireContext) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  ReactRailsUJS.useContext(componentRequireContext);

  return componentRequireContext;
}

export function boot(ctxFn, moduleHot) {
  const componentRequireContext = initReactRailsContext(ctxFn());
  if (moduleHot) {
    moduleHot.accept(componentRequireContext.id, () => {
      try {
        initReactRailsContext(ctxFn());
        ReactRailsUJS.mountComponents();
      } catch (e) {
        console.error(e);
      }
    });
  }
}
