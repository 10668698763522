import React, { FC, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from 'app/helpers/hooks';
import { push } from 'connected-react-router';
import { Formik } from 'formik';

import PageHeader from 'app/components/common/PageHeader';

import Question from 'app/constants/Question';
import { apiRequestUserCommand } from 'app/actions/customer';
import {
  selectCurrentIntake,
  selectCustomer,
  selectFurthestProductStepPath,
  selectCurrentIntakeProduct,
} from 'app/selectors/customer';

import { PrimaryButton } from 'app/components/common/Button';
import { getComponentForQuestion } from '../../questions/helpers';
import '../css/OnboardingFreeform.scss';
import protocolNames from 'app/utils/protocolNames';

const OnboardingFreeform: FC<{ step: string }> = ({ step }) => {
  const customer = useAppSelector(selectCustomer);
  const intake = useAppSelector(selectCurrentIntake);
  const dispatch = useAppDispatch();
  const physician = useMemo(() => customer.get('physician')?.toJS(), [customer]);
  const productName = useAppSelector(selectCurrentIntakeProduct).toLowerCase();
  const onSuccessActionCreator = (_, stateAfterSuccess) =>
    push(selectFurthestProductStepPath({ customer: stateAfterSuccess }, productName));
  const question = new Question(step);

  const onSubmit = (params) => {
    if (intake) {
      params.intake = intake.get('name');
    }
    const action = apiRequestUserCommand({
      cmdType: 'answer_questions',
      params,
      context: { onSuccessActionCreator, form: step },
    });
    dispatch(action);
  };
  const Component = getComponentForQuestion({ question }) as FC<any>;

  const theObjective = () => {
    if (productName === 'magician') return `${protocolNames['magician'].toLowerCase()} treatment`;

    return `the ${protocolNames[productName]} Protocol`;
  };
  if (!physician) return null;
  return (
    <>
      <PageHeader title="Meet your doctor" className="-edged" />
      <div className="mv24 onboarding-freeform">
        <div className="physician-card">
          {physician.photo_url && (
            <div className="physician-card__image">
              <img src={physician.photo_url} />
            </div>
          )}
          <div className="physician-card__info">
            <div className="physician-card__title">{physician.full_name}</div>
            <div className="physician-card__specialties">{physician.specialties}</div>
            <div className="physician-card__education">{physician.education}</div>
            <div className="physician-card__residency">{physician.residency}</div>
          </div>
        </div>
        <div className="mv24">
          <p>
            {customer.get('first_name')}, meet {physician.short_name}, your dedicated Maximus doctor.
          </p>

          <p>
            This is your first message to {physician.short_name} regarding {theObjective()}. Please introduce yourself
            and describe your goals for health optimization so that {physician.short_name} can determine which treatment
            protocol is best for you.
          </p>
          <p>
            {physician.short_name} will respond via our patient portal: Elation Passport. You will receive a
            registration link for Elation Passport via email shortly if you have not already registered.
          </p>
        </div>
        <Formik
          initialValues={{
            [step]: '',
          }}
          onSubmit={onSubmit}
        >
          {({ handleSubmit }) => (
            <>
              <Component question={question} />
              <PrimaryButton
                type="button"
                className="mb32 mt32"
                data-testid="next-step"
                text="send message"
                onClick={() => handleSubmit()}
              />
            </>
          )}
        </Formik>
      </div>
    </>
  );
};

export default OnboardingFreeform;
