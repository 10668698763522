export const HARD_REDIRECT_REQUESTED = 'HARD_REDIRECT_REQUESTED';
export const hardRedirectRequested = (to) => ({
  type: HARD_REDIRECT_REQUESTED,
  to,
});

export const GEOLOCATION_FAILED = 'GEOLOCATION_FAILED';
export const geolocationFailed = ({ error, context }) => ({
  type: GEOLOCATION_FAILED,
  error: { code: error?.code, msg: error?.message },
  context,
});

export const REPORT_BROWSER_PROBLEM = 'REPORT_BROWSER_PROBLEM';

export const reportBrowserProblem: (params: {
  error: { code: number; message?: string };
  problemType: string;
  details?: any;
}) => {
  type: typeof REPORT_BROWSER_PROBLEM;
  error: { code: number; msg: string | undefined; problemType: string; details: any };
} = ({ error, problemType, details }) => ({
  type: REPORT_BROWSER_PROBLEM,
  error: { code: error.code, msg: error.message, problemType, details },
});

export const INIT_CUSTOMER = 'INIT_CUSTOMER';
export const initCustomer = ({ customer }) => ({
  type: INIT_CUSTOMER,
  customer,
});

export const API_REQUEST_COMMAND = 'API_REQUEST_COMMAND';
export const apiRequestCommand = ({ params, context }: { params?: any; context?: any }) => ({
  type: API_REQUEST_COMMAND,
  params,
  context,
});

export const API_REQUEST_USER_COMMAND = 'API_REQUEST_USER_COMMAND';
export const apiRequestUserCommand = ({
  params,
  context,
  cmdType,
}: {
  cmdType: string;
  params?: any;
  context?: any;
}) => ({
  type: API_REQUEST_USER_COMMAND,
  params,
  context,
  cmdType,
});

export const API_REQUEST_SUCCESS = 'API_REQUEST_SUCCESS';
export const apiRequestSuccess: (data: any) => {
  type: string;
} = ({ reqId, json, thread, status }) => ({
  type: API_REQUEST_SUCCESS,
  reqId,
  json,
  thread,
  status,
});

export const API_REQUEST_FAILED = 'API_REQUEST_FAILED';
export const apiRequestFailed = ({ reqId, err }) => ({
  type: API_REQUEST_FAILED,
  reqId,
  err,
});

export const API_REQUEST_PROBLEMS = 'API_REQUEST_PROBLEMS';
export const apiRequestProblems = ({ reqId, json, form }) => ({
  type: API_REQUEST_PROBLEMS,
  reqId,
  form,
  json,
});

export const CUSTOMER_SEND_PRIVATE_MESSAGE_FORM_SUBMITTED = '@@customer/SEND_PRIVATE_MESSAGE_FORM_SUBMITTED';
export const sendPrivateMessageFormSubmitted = ({ form, formReset, thread, body, userId, topic, onSuccessAction }) => ({
  type: CUSTOMER_SEND_PRIVATE_MESSAGE_FORM_SUBMITTED,
  form: form || 'send_private_message_form',
  formReset: formReset || true,
  onSuccessAction,
  values: {
    user_id: userId,
    thread,
    body,
    topic,
  },
});

export const PASSWORD_UPDATE_SUCCESS = 'PASSWORD_UPDATE_SUCCESS';
export const PASSWORD_UPDATE_SUBMITTED = 'PASSWORD_UPDATE_SUBMITTED';
export const passwordUpdateSubmitted = ({ params, context }) => ({
  type: PASSWORD_UPDATE_SUBMITTED,
  params,
  context,
});

export const GUEST_REGISTRATION_TERMS_SUBMITTED = 'GUEST_REGISTRATION_TERMS_SUBMITTED';
export const guestRegistrationTermsSubmitted = ({ params, context }) => ({
  type: GUEST_REGISTRATION_TERMS_SUBMITTED,
  params,
  context,
});

export const LOGIN_WITH_EMAIL = 'LOGIN_WITH_EMAIL';
export const loginWithEmail = ({ params, context }) => ({
  type: LOGIN_WITH_EMAIL,
  params,
  context,
});

export const LOGIN_WITH_PASSWORD = 'LOGIN_WITH_PASSWORD';
export const loginWithPassword = ({ params, context }) => ({
  type: LOGIN_WITH_PASSWORD,
  params,
  context,
});

export const PASSWORD_RESET = 'PASSWORD_RESET';
export const passwordReset = ({ params, context }) => ({
  type: PASSWORD_RESET,
  params,
  context,
});

export const LOGOUT = 'LOGOUT';
export const logout = () => ({
  type: LOGOUT,
});

export const CLEAR_ADDRESS_FORM_ERROR = 'CLEAR_ADDRESS_FORM_ERROR';
export const clearAddressFormErrors = () => ({
  type: CLEAR_ADDRESS_FORM_ERROR,
});

export const UNKNOWN_ACTION = 'UNKNOWN_ACTION';
export const unknownAction = ({ json }) => ({
  type: UNKNOWN_ACTION,
  json,
});

export const API_REQUEST_ERRORS = 'API_REQUEST_ERRORS';
export const NOTIFY_USER = 'NOTIFY_USER';
export const MESSAGE_FETCHING_STARTED = 'MESSAGE_FETCHING_STARTED';
export const NOOP = 'NOOP';
