import React from 'react';
import { Redirect, Switch, Route } from 'react-router';
import Registration from 'app/components/customer/Registration';
import * as Routes from 'app/constants/Routes';

const RegistrationRoutes = () => (
  <Switch>
    <Route exact path={Routes.Registration} component={Registration} />
    <Route path={Routes.Registration} render={() => <Redirect to={Routes.Registration} />} />
  </Switch>
);

export default RegistrationRoutes;
