/* eslint-disable @typescript-eslint/no-unsafe-declaration-merging */
import QUESTIONS from './questions.json';
import OPTIONS from './option-groups.json';
import { map } from 'lodash';

/**
 * Get options for group by name
 *
 * @param option_group Option String
 * @returns Array of options
 * @throws Error if no options forund for group
 */
const getOptions = (option_group: keyof typeof OPTIONS): { value: string | boolean; label: string }[] | never => {
  if (option_group in OPTIONS) {
    return OPTIONS[option_group];
  }

  throw Error(`No options found for option_group ${option_group}`);
};

interface Question {
  name: string;

  sentence?: string;

  type?: string;

  label?: string;

  placeholder?: string;

  data: Record<string, any>;

  options: Array<{
    label: string;
    value: string | boolean;
  }> | null;
}

class Question {
  constructor(name: keyof typeof QUESTIONS | string, productName?: string) {
    this.name = name;
    this.data = QUESTIONS[name as keyof typeof QUESTIONS] || {};
    const { label, sentence, type, option_group, placeholder, product_specific } = this.data;
    this.options = option_group ? getOptions(option_group) : null;
    this.sentence = sentence;
    this.label = label;
    this.type = type;
    this.placeholder = placeholder;

    if (product_specific && productName && product_specific[productName]) {
      this.overrideProductSpecificAttributes(product_specific[productName]);
    }
  }

  overrideProductSpecificAttributes(overrideAttributes: object) {
    map(overrideAttributes, (value, key) => {
      switch (key) {
        case 'option_group':
          this.data.option_group = value;
          this.options = getOptions(value);
          break;
        case 'description':
          this.data.description = value;
          break;
      }
    });
  }
}

export default Question;
