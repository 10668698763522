import { List } from 'immutable';
import { Cmd, loop } from 'redux-loop';
import { reportBrowserProblem } from 'app/actions/customer';
import { pollCustomer } from './apiRequest';
import * as selectors from '../selectors/customerReducer';

const aLoop = (retState, cmds) => {
  if (cmds.length === 0) {
    return retState;
  }
  if (cmds.length === 1) {
    return loop(retState, cmds[0]);
  }

  return loop(retState, Cmd.list(cmds));
};

export default (state) => {
  const cmds = [];
  if (state.constructor === Array) {
    cmds.push(state[1]);
    // eslint-disable-next-line no-param-reassign,prefer-destructuring
    state = state[0];
  }
  const customer = state.get('customer');
  if (!customer) {
    return aLoop(state, cmds);
  }
  const pendingUpdates = customer.get('flags', List()).includes('sensitive_updates_pending');
  if (!pendingUpdates) {
    const newState = state.deleteIn(['goals', 'fetchUntilSubscribed']);

    return aLoop(newState, cmds);
  }
  const requestInProgress = selectors.selectInProgressUserFetchesCount(state) > 0;
  if (requestInProgress) {
    return aLoop(state, cmds);
  }

  const nFetches = selectors.selectNCustomerFetches(state);
  const lastFetchProblems = selectors.selectLastFetchProblems(state);
  const pursueGoal = pendingUpdates && nFetches < 8 && !lastFetchProblems;
  if (!pursueGoal) {
    const abandonNow = !state.getIn(['goals', 'fetchUntilSubscribed', 'abandoned']);
    const newState = state
      .setIn(['goals', 'fetchUntilSubscribed', 'abandoned'], true)
      .setIn(['goals', 'fetchUntilSubscribed', 'nFetches'], 0);
    if (abandonNow) {
      const reportCmd = Cmd.action(
        reportBrowserProblem({
          error: { code: 1 },
          problemType: 'given_up_poling',
          details: {
            flags: customer.get('flags', List()).toJS(),
            pollStartTime: state.getIn(['goals', 'fetchUntilSubscribed', 'startTime']),
            givenUpAt: new Date().toISOString(),
          },
        }),
      );
      cmds.push(reportCmd);
    }

    return aLoop(newState, cmds);
  }

  const reqId = selectors.selectGReqId(state);
  let nudge = false;
  if (customer.get('flags', List()).includes('sensitive_updates_pending')) {
    nudge = true;
  }
  const pollCmd = pollCustomer(state, {
    delay: 1.5 ** nFetches * 1000,
    nudge: nudge,
  });
  cmds.push(pollCmd);

  const startTime = state.getIn(['goals', 'fetchUntilSubscribed', 'startTime']);
  let newNFetches = nFetches + 1;
  if (state.getIn(['goals', 'fetchUntilSubscribed', 'location']) !== window.location.href) {
    newNFetches = 0;
  }
  const newState = state
    .setIn(['goals', 'fetchUntilSubscribed', 'nFetches'], newNFetches)
    .setIn(['goals', 'fetchUntilSubscribed', 'location'], window.location.href)
    .setIn(['goals', 'fetchUntilSubscribed', 'startTime'], startTime || new Date().toISOString())
    .setIn(['requestsInProgress', 'userFetch', reqId.toString()], true)
    .set('gReqId', reqId + 1);

  return aLoop(newState, cmds);
};
