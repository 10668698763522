import React from 'react';

import './css/checkpoint.scss';

import { connect } from 'react-redux';

import { H6, H4, P } from 'app/components/common/Typography';
import { PrimaryButton } from 'app/components/common/Button';
import { push } from 'connected-react-router/immutable';
import * as selectors from 'app/selectors/customer';

import checkGray from 'images/payment/check-gray.svg';

const mapStateToProps = (state) => {
  const nextStepAction = push(selectors.selectNextStepPath(state));

  return {
    nextStepAction,
  };
};

const Checkpoint = ({ nextStepAction, dispatch }) => {
  return (
    <>
      <H6 className="mt16">
        <P color="primary" className="font-size-inherit font-weight-inherit">
          THANK YOU!
        </P>
      </H6>
      <H4 className="checkpoint__title">Payment Complete.</H4>
      <P className="mt8">Before we can send your information to the doctor, we first need verify your identity. </P>
      <div className="checkpoint__background">
        <div className="checkpoint__background__item">
          <div>
            <img src={checkGray} />
          </div>
          <div>
            <P className="checkpoint__background__title">
              <span className="bold">Pharmacy Confirmation</span> <span className="required">(required)</span>
            </P>
            <P>Verify pharmacy selection.</P>
          </div>
        </div>
        <div className="checkpoint__background__item">
          <div>
            <img src={checkGray} />
          </div>
          <div>
            <P className="checkpoint__background__title">
              <span className="bold">ID Verification</span> <span className="required">(required)</span>
            </P>
            <P>Please have your government issued ID ready.</P>
          </div>
        </div>
        <div className="checkpoint__background__item">
          <div>
            <img src={checkGray} />
          </div>
          <div>
            <P className="checkpoint__background__title">
              <span className="bold">Location Verification</span> <span className="required">(required)</span>
            </P>
            <P>Double checking that we can provide high quality care in your area.</P>
          </div>
        </div>
        <div className="checkpoint__background__item">
          <div>
            <img src={checkGray} />
          </div>
          <div>
            <P className="checkpoint__background__title">
              <span className="bold">Add Additional Info</span> <span className="gray">(optional)</span>
            </P>
            <P>Have something to tell the doctor? </P>
          </div>
        </div>
        <PrimaryButton
          className="mt1224"
          type="button"
          data-testid="checkpoint_continue"
          text="Continue"
          onClick={() => dispatch(nextStepAction)}
        />
      </div>
    </>
  );
};

export default connect(mapStateToProps)(Checkpoint);
