import { Admin, Dashboard, Login } from 'app/constants/Routes';

export const switchCase =
  <C>(cases: C) =>
  <D>(defaultCase: D) =>
  <K extends keyof C>(key: K): C[K] | D =>
    Object.prototype.hasOwnProperty.call(cases, key) ? cases[key] : defaultCase;

export const authPath = switchCase({
  admin: Admin,
  customer: Dashboard,
})(Login);
