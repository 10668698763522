import React from 'react';

import '../css/TitleWithLine.scss';
import cx from 'classnames';

const TitleWithLine = ({ children, className = '', size = '' }) => (
  <h2 className={cx('title_with_line', size, className)}>{children}</h2>
);

export default TitleWithLine;
