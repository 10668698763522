import React, { FC } from 'react';

import Button from '@setproduct-ui/core/Button';
import SetProductDialog from '@setproduct-ui/core/Dialog';

const CommonDialog = (props) => <SetProductDialog view="raised" type="dense" {...props} />;

export const DangerDialog: FC<
  {
    leftText?: string;
    leftClick?: () => void;
    rightText?: string;
    rightClick?: () => void;
    leftDisabled?: boolean;
  } & {
    leftButton?: FC;
    rightButton?: FC;
  } & Record<string, any>
> = ({ leftText, rightText, leftClick, leftDisabled = false, rightClick, leftButton, rightButton, ...rest }) => {
  const leftBtn = leftButton && (
    <Button
      disabled={!!leftDisabled}
      type="default"
      dense
      view="flat"
      text={leftText}
      intent="default"
      onClick={leftClick}
    />
  );
  const rightBtn = rightButton && (
    <Button view="raised" type="default" dense text={rightText} intent="default" onClick={rightClick} />
  );

  return <CommonDialog color="danger" leftButton={leftBtn} rightButton={rightBtn} {...rest} />;
};
