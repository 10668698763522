// state refers to 'reducer state'
import { List, Map } from 'immutable';

export const selectCustomer = (state) => state.customer || Map();

const selectCustomerOnboarding = (state) =>
  selectCustomer(state)
    .getIn(['intakes'], List())
    .find((i) => i.get('name') === 'onboarding');

export const selectCustomerOnboardingSteps = (state) => (selectCustomerOnboarding(state) || Map()).get('steps', List());

// export const selectPath = (state) => state.get('currentPathname')
export const selectCustomerId = (state) => state.getIn(['customer', 'id']);

export const selectInProgressUserFetchesCount = (state) =>
  state.getIn(['requestsInProgress', 'userFetch'], Map()).count();

export const selectNCustomerFetches = (state) => state.getIn(['goals', 'fetchUntilSubscribed', 'nFetches']) || 0;

export const selectLastFetchProblems = (state) => state.getIn(['problems', 'userFetch']);

export const selectGReqId = (state) => state.get('gReqId') || 0;

export const selectMessagesPollingId = (state) => state.get('messagesPollingId');

export const selectCustomerLastWithPersonaTransaction = (state) => {
  const transactions = state.getIn(['customer', 'with_persona_info'], List());
  if (transactions.isEmpty()) {
    return null;
  }

  return transactions.last();
};

export const selectCustomerFlags = (state) => selectCustomer(state).getIn(['omethingPendingflags'], List());
