import React, { FC } from 'react';
import cx from 'classnames';
import './css/TextArea.scss';

export const TextArea: FC<
  {
    testId?: string;
    size?: string;
    className?: cx.Argument;
    focused?: boolean;
  } & React.HTMLAttributes<HTMLTextAreaElement>
> = ({ testId, size, focused, className, ...restProps }) => (
  <textarea
    {...restProps}
    data-testid={testId}
    className={cx('text-area', 'blue-text-area', className, size, {
      focus: focused,
    })}
  />
);

export default TextArea;
