import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from 'app/helpers/hooks';
import SetProductDialog from '@setproduct-ui/core/Dialog';
import { PrimaryButton } from 'app/components/common/Button';
import * as actions from 'app/actions/customer';
import { selectLastProductIntake } from 'app/selectors/customer';
import { centsToDollarsString } from 'app/helpers/priceHelpers';
import '../css/ReferralSourceModal.scss';
import '../css/BbRelaunchModal.scss';
import protocolNames from 'app/utils/protocolNames';
import { AvailableProducts } from 'app/constants/Products';
import { RootState } from 'app/configureStore';

const ModalTitle = () => <div className="referral-source-modal__title">Dear Maximizer,</div>;

const ModalBody = ({ skipModal }) => {
  const intake = useAppSelector((state: RootState) => selectLastProductIntake(state, AvailableProducts.King));
  const pricing = intake?.get('subscription_choices');
  const priceBuildingBlocksSupplement = pricing?.get('price_building_blocks_supplement');

  return (
    <div className="referral-source-modal__body">
      <div className="referral-source-modal__content">
        <div className="referral-source-modal__options">
          <p>
            We are excited to announce that <b>Maximus Building Blocks</b> is available once again!
          </p>

          <div className="bb-info">
            <div className="bb-info__image" />
            <div className="bb-info__text">
              <p>
                <a href="https://www.maximustribe.com/building-blocks" target="_blank" rel="noreferrer">
                  Maximus Building Blocks
                </a>{' '}
                is the <i>highly</i> recommended supplement to the {protocolNames['king']} Protocol. It is the best
                essential men’s multivitamin on the market, providing the key 'Building Blocks' for your body's hormone
                production. Most multivitamins use insufficient dosages and forms of key fat-soluble Vitamins (A, D, E,
                K) that will never get you to optimal levels. Building Blocks also uniquely adds a bioavailable
                B-complex and patented forms of Magnesium, Annatto (Geranylgeraniol, Tocotrienols) & Saffron extracts
                that improve energy, mood, and focus. It is formulated to work synergistically with{' '}
                <b>The {protocolNames['king']} Protocol</b> to eliminate any nutritional deficiencies that impair
                optimal testosterone production.
              </p>
            </div>
          </div>

          <ul className="bb-options">
            <li>
              Maximus Building Blocks costs <b>{centsToDollarsString(priceBuildingBlocksSupplement)} per month</b> in
              addition to your base {protocolNames['king']} Protocol subscription.
            </li>
            <li>
              You will be able to add the Building Blocks supplement to your {protocolNames['king']} Protocol
              subscription during your next monthly check-in.
            </li>
          </ul>

          <p>
            As always, we are honored to support you on your journey to optimal health. Feel free to reach out to us
            with any questions at any time: <a href="mailto:support@maximustribe.com">support@maximustribe.com</a>
          </p>

          <div className="author">
            <div className="author__image" />
            <div className="author__text">
              In Health,
              <br />
              <b>Dr. Cam</b> and the <b>Maximus Team</b>
            </div>
          </div>

          <PrimaryButton onClick={skipModal} data-testid="bb-relaunch-continue" text="Continue" />
        </div>
      </div>
    </div>
  );
};

const BbRelaunchModal = () => {
  const dispatch = useAppDispatch();
  const [dialogOpen, setDialogOpen] = useState(true);
  const skipModal = () => {
    setDialogOpen(false);
    const cmdType = 'answer_questions';
    const params = { notified_bb_relaunch: true };
    const action = actions.apiRequestUserCommand({
      cmdType,
      params,
      context: {},
    });
    dispatch(action);
  };

  return (
    <SetProductDialog
      isOpen={dialogOpen}
      onClose={skipModal}
      title={<ModalTitle />}
      text={<ModalBody skipModal={skipModal} />}
      className="referral-source-modal bb-relaunch"
    />
  );
};

export default BbRelaunchModal;
