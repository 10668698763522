import React, { FC, ReactNode } from 'react';
import Radio from 'app/components/common/Radio';
import { P } from 'app/components/common/Typography';
import PriceFormatter from '../Generic/PriceFormatter';
import '../css/LoverTreatmentPlan.scss';
import { useFormikContext, Field } from 'formik';
import Select from 'react-select';
import cx from 'classnames';
import Divider from '@setproduct-ui/core/Divider';
import ProductImage from 'app/components/customer/steps/Payment/king/ProductImage';
import { AvailableProducts } from 'app/constants/Products';
import {
  LOVER_SUBSCRIPTION_MONTHLY_COST,
  LOVER_SUBSCRIPTION_MONTHLY_COST_VARIANT_2,
} from 'app/helpers/productSpecificPriceCalculators/loverPriceCalculator';
import { useExperiment } from 'app/utils/useExperiment';

const RadioLabel: FC<{
  title: string;
  subheader?: ReactNode;
  paragraph?: ReactNode;
  icon?: string;
  dosePrice?: string;
  totalPrice: number;
}> = ({ title, subheader, paragraph, icon, totalPrice }) => {
  return (
    <div className="">
      <div className="">
        <div className="titles">
          <div className="title">
            <div className="price_row">
              <div dangerouslySetInnerHTML={{ __html: title || '' }}></div>
              <div>
                <PriceFormatter price={totalPrice} from={true} period="mo" className="mtn15" />
              </div>
            </div>
          </div>
          <P>{subheader}</P>
        </div>
      </div>
      <br />
      <P className="paragraph">{paragraph}</P>
      {icon && <img src={icon} alt="" />}
    </div>
  );
};

const LoverTreatmentPlan = ({ product }) => {
  const { setFieldValue, values, dirty } = useFormikContext<any>();

  const bloodflow_control_variant =
    useExperiment('bloodflow_multimonth', 'bloodflow_multimonth_variation_0') === 'bloodflow_multimonth_variation_0';

  const bloodflow_variant_2 =
    useExperiment('bloodflow_multimonth', 'bloodflow_multimonth_variation_0') === 'bloodflow_multimonth_variation_2';

  const month = bloodflow_control_variant ? 1 : 12;

  const priceConfig = bloodflow_variant_2 ? LOVER_SUBSCRIPTION_MONTHLY_COST_VARIANT_2 : LOVER_SUBSCRIPTION_MONTHLY_COST;

  const options = [4, 8, 12].map((dose) => {
    const monthlyCost = priceConfig[dose.toString()][month] / 100;
    const doseCost = (monthlyCost / dose).toFixed(2);

    return {
      label: `${dose} doses, $${monthlyCost}/mo, $${doseCost}/dose`,
      value: dose,
    };
  });

  const handleLegacyOption = () => {
    setFieldValue('number_of_doses', null);
    setFieldValue('daily_strength', null);
  };
  const handleDailyStrengthChange = (value) => {
    setFieldValue('number_of_doses', null);
    setFieldValue('daily_strength', value);
  };
  const handleNumberOfDosesRadioClick = () => {
    setFieldValue('number_of_doses', 4);
    setFieldValue('daily_strength', null);
  };

  return (
    <div className={`${!bloodflow_control_variant && 'payment_card'} mb32`}>
      <ProductImage productName={AvailableProducts.Lover} subProductName={''} className="mb36" />

      <div className="lover_treatment_plan__option">
        <Radio
          className="no-borders top-aligned mb36"
          onChange={() => handleDailyStrengthChange('2_5_mg')}
          checked={'2_5_mg' === values.daily_strength}
          label={
            <RadioLabel
              paragraph="Best if you’ve never used PDE5 inhibitors before, are looking to optimize cognitive performance, and sexual benefits are your lowest priority."
              subheader="2.5mg Tadalafil + 2.5mg Vardenafil"
              title="Daily Use - 2.5mg"
              dosePrice="3.33"
              totalPrice={priceConfig['2_5_mg'][month]}
            />
          }
          testId="daily_strength_2_5_mg"
        />
      </div>
      <div className="lover_treatment_plan__option">
        <Radio
          className="no-borders top-aligned mb32"
          onChange={() => handleDailyStrengthChange('5_mg')}
          checked={'5_mg' === values.daily_strength}
          label={
            <RadioLabel
              paragraph="Best if you’ve used PDE5 inhibitors before, you’re looking to optimize muscle pumps and recovery, sexual benefits are a priority, or you want to achieve harder erections."
              subheader="5.0mg Tadalafil / 5.0mg Vardenafil"
              title="Daily Use - 5.0mg"
              dosePrice="5"
              totalPrice={priceConfig['5_mg'][month]}
            />
          }
          testId="daily_strength_5_mg"
        />
      </div>
      <Divider className="mb24" />
      <div className="lover_treatment_plan__option">
        <Radio
          checked={!!values.number_of_doses}
          onChange={handleNumberOfDosesRadioClick}
          className="no-borders top-aligned"
          label={
            <RadioLabel
              paragraph="Best if you want something when you need it rather than daily, sexual benefits are your main
                    priority, and you want a hard erection for a longer time."
              subheader="10mg Tadalafil / 10mg Vardenafil"
              title="As Needed (10mg)"
              totalPrice={priceConfig['4'][month]}
            />
          }
          testId="as_needed"
        />
        <div className="ml36 mt4">
          <P>Doses per 30 days</P>
          <Field name="number_of_doses">
            {({ field, form, meta }) => (
              <Select
                className={cx('select', {
                  '-red': meta.error,
                })}
                classNamePrefix="select"
                value={options.find((_) => _.value === field.value || _.value === values.number_of_doses)}
                options={options}
                isSearchable={false}
                onChange={(v) =>
                  form.setFieldValue('number_of_doses', v?.value) && form.setFieldValue('daily_strength', null)
                }
              />
            )}
          </Field>
        </div>
      </div>
      {!!product.get('opt_in_choice')?.get('is_legacy') && (
        <div className="lover_treatment_plan__option">
          <Radio
            className="no-borders top-aligned"
            onChange={handleLegacyOption}
            checked={!dirty || (!values.number_of_doses && !values.daily_strength)}
            label={
              <RadioLabel
                subheader="5mg tadalafil + 2mg bremelanotide"
                title="Lover Legacy (Daily Use)"
                dosePrice="5"
                totalPrice={149.99}
              />
            }
          />
        </div>
      )}
    </div>
  );
};

export default LoverTreatmentPlan;
