import React from 'react';
import { Link } from 'react-router-dom';
import * as Routes from 'app/constants/Routes';
import { useAppSelector } from 'app/helpers/hooks';
import * as selectors from 'app/selectors/customer';
import { AvailableProducts } from 'app/constants/Products';
import LabKitImgSrc from 'images/congrats/lab-kit.jpg';
import JoinImgSrc from 'images/congrats/join.jpg';
import protocolNames from '../../../utils/protocolNames';
import './css/Congrats.scss';

const Magician = () => {
  const products = useAppSelector(selectors.selectCustomerProducts);
  const magician = products.get(AvailableProducts.Magician);
  const labKitType = magician?.get('lab_kit_type');
  const useOwnLab = magician?.get('use_own_lab');

  return (
    <div className="congrats">
      <div className="congrats__wrap">
        <div className="congrats-top">
          <h1 className="congrats-top__title">You’re All Caught Up!</h1>
          <p className="congrats-top__info">That's all the information we need from you to get you started. </p>
        </div>

        {labKitType === 'at_home_lab_kit' && !useOwnLab && (
          <div className="congrats-block congrats-block_single">
            <h2 className="congrats-block__title">Be on the Lookout:</h2>
            <h3 className="congrats-block__title-list">Complete Your At-Home Lab Kit</h3>
            <div className="congrats-block__list block-list">
              <div className="block-list__item">
                <img src={LabKitImgSrc} className="list-item__image" />
              </div>
              <div className="block-list__item">
                <p className="list-item__text">
                  <b>Note:</b> This does not apply if you are using your own labs.
                </p>
                <p className="list-item__text">
                  In order to determine your eligibility, your doctor will need to evaluate your select biomarkers.
                </p>
                <p className="list-item__text">
                  Expect an <b>at-home lab kit</b> to arrive at your door in{' '}
                  <u>
                    <i>2-7 business days</i>
                  </u>
                  . You will receive tracking information once the laboratory ships your kit.
                </p>
                <p className="list-item__text">
                  Your lab kit will come with <b>step-by-step instructions</b> for how to collect your sample, plus what
                  to expect next.
                </p>
              </div>
            </div>
          </div>
        )}

        <div className="congrats-block congrats-block_single">
          <h2 className="congrats-block__title">Start Coaching Today:</h2>
          <h3 className="congrats-block__title-list">Join the Maximus Tribe and Start Coaching</h3>
          <div className="congrats-block__list block-list">
            <div className="block-list__item">
              <img src={JoinImgSrc} className="list-item__image" />
            </div>
            <div className="block-list__item">
              <p className="list-item__text">
                Research has shown <b>abdominal fat loss is 2.2x higher</b> when you pair exercise with a GLP-1 agonist,
                versus a GLP-1 agonist alone!
              </p>
              <p className="list-item__text">
                As part of the {protocolNames['magician']} Protocol you have access to psychologist-led health coaching
                to maximize your weight loss. To access coaching, follow the instructions in the Coaching section here:
                in the {protocolNames['magician']}
                Protocol Quickstart Guide
              </p>
              <p className="list-item__text">
                <b>Note:</b> You can access the Quickstart Guide at any time by visiting the dashboard. A printed
                version will be included with your at-home lab kit as well.
              </p>
            </div>
          </div>
        </div>

        <div className="congrats-dashboard">
          <Link
            data-testid="go-to-dashboard"
            className="congrats-dashboard__link default-button primary-button"
            to={Routes.Dashboard}
          >
            GO TO DASHBOARD
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Magician;
