import React from 'react';

import cx from 'classnames';

import { P } from 'app/components/common/Typography';
import HeadingWithIcon from 'app/components/common/HeadingWithIcon';

import BulletCheckmark from 'images/Icon-Bullet-Checkmark.svg';
import { selectAcknowledgementServices } from 'app/selectors/customer';
import { useAppSelector } from 'app/helpers/hooks';

const DumbServicesList = ({ pharmacyChoice = 'jungle_jims', noLab = false, className = '' }) => {
  const services = useAppSelector((state) => selectAcknowledgementServices(state, noLab as never, pharmacyChoice));

  return (
    <ul className={cx('mt16', className)}>
      {services.map((service, i) => (
        <li key={`${service}-${i}`}>
          <HeadingWithIcon text={service} icon={BulletCheckmark} tag={P} className="mv8 mh16" />
        </li>
      ))}
    </ul>
  );
};

export default DumbServicesList;
