import React, { ComponentProps, FC, forwardRef, useCallback } from 'react';
import { useField, useFormikContext } from 'formik';
import Input from './Input';

export const SanitizedInput: FC<
  {
    id?: string;
    name: string;
    onInputChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  } & ComponentProps<typeof Input>
> = forwardRef(({ id, name, onInputChange, ...restProps }, ref) => {
  const [field] = useField({ id, name });
  const { setFieldValue, setFieldTouched } = useFormikContext();

  const sanitizeInput = useCallback((value: string) => {
    return value.replace(/\s+/g, '');
  }, []);

  // Handler to prevent spaces on change
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const sanitizedValue = sanitizeInput(e.target.value);

      setFieldTouched(name, true);
      setFieldValue(name, sanitizedValue);

      if (onInputChange) {
        onInputChange(e);
      }
    },
    [setFieldValue, setFieldTouched, onInputChange, sanitizeInput],
  );

  return <Input id={id} ref={ref} {...field} {...restProps} onChange={handleChange} />;
});
