import { Event } from '@sentry/types/types';
import { Breadcrumb } from '@sentry/types';
import { Exception } from '@sentry/react';

const blacklistUrls: RegExp[] = [
  // Google Adsense
  /pagead\/js/i,
  /googleads\.g\.doubleclick\.net/i,
  /google-analytics\.com\/plugins\//i,
  // Facebook flakiness
  /graph\.facebook\.com/i,
  // Facebook blocked
  /connect\.facebook\.net\/en_US\/all\.js/i,
  // Other plugins
  /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
  /www\.clarity\.ms\//i,
  /analytics\.tiktok\.com\//i,
  /^cdn\d*(?:\.api)?\.userway\.org$/i,
];

function isNetworkError(event: Event): boolean {
  const typeErrorFetchFailedValues: Set<string> = new Set([
    'Failed to fetch',
    'NetworkError when attempting to fetch resource.',
    'Load failed',
  ]);

  const exception = event.exception?.values?.[0];
  if (exception?.type !== 'TypeError' || !typeErrorFetchFailedValues.has(exception.value as string)) return false;

  return isScriptFromBlacklist(event);
}

function isUnhandledRejection(event: Event): boolean {
  const unhandledRejectionValues: RegExp[] = [
    /^Non-Error promise rejection captured with value: Object Not Found Matching Id: \d.*/,
    /^Non-Error promise rejection captured with value/,
  ];

  const exception: Exception | undefined = event.exception?.values?.[0];
  if (
    exception?.type !== 'UnhandledRejection' ||
    !unhandledRejectionValues.some((regexp) => regexp.test(event.exception?.values?.[0].value || ''))
  )
    return false;

  return isScriptFromBlacklist(event);
}

function isScriptFromBlacklist(event: Event) {
  if (!event.breadcrumbs) return false;

  const now: number = Date.now();

  // We go from the back since the last breadcrumb is most likely the erroneous one
  for (let i = event.breadcrumbs.length - 1; i >= 0; i--) {
    const breadcrumb: Breadcrumb = event.breadcrumbs[i];
    if (!breadcrumb) continue;

    // We only need to check the last 5s of breadcrumbs as any earlier breadcrumbs are definitely unrelated
    if (breadcrumb.timestamp && now - breadcrumb.timestamp * 1000 > 5000) break;

    if (isErroneousBreadcrumb(breadcrumb)) return true;
  }

  return false;
}

function hostnameFromUrl(url: string): string {
  const a: HTMLAnchorElement = document.createElement('a');
  a.href = url;

  return a.hostname;
}

function isErroneousBreadcrumb(breadcrumb: Breadcrumb): boolean {
  if (breadcrumb.level !== 'error' || (breadcrumb.category !== 'xhr' && breadcrumb.category !== 'fetch')) return false;

  const url: string | undefined = breadcrumb.data?.url as string | undefined;
  if (!url) return false;

  const hostname: string = hostnameFromUrl(url);
  return blacklistUrls.some((regexp: RegExp) => regexp.test(hostname));
}

export default function suppressExceptions(event: Event): boolean {
  return isNetworkError(event) || isUnhandledRejection(event);
}
