/* eslint-disable no-case-declarations */
import { Discount } from 'app/types/admin/customerUser';
import { ImmutableList, ImmutableMap } from 'app/types/admin';

class LabsPriceCalculator {
  discounts: ImmutableList<Discount> | Discount[];

  constructor(discounts: ImmutableList<Discount> | Discount[]) {
    this.discounts = discounts;
  }

  atHomeLabPrice = () => 99_99;

  labCost = () => this.atHomeLabPrice();

  totalDueToday() {
    return this.labCost() - this.totalDiscountsValue();
  }

  totalDiscountsValue() {
    let total = 0;

    this.discounts.map((discount) => {
      total += this.discountPrice(discount)!;
    });

    return total;
  }

  discountPrice(discount: ImmutableMap<Discount>) {
    const isLab = discount?.get('product_name')?.slice(-4) === '_lab';

    if (!isLab) {
      return 0;
    }

    const amountUsed = discount.get('uses').reduce((sum, use) => sum + use.get('amount_used'), 0);
    const coupon = discount?.get('coupon');
    const couponParams = coupon?.get('params');
    const discountType = coupon?.get('discount_type');

    const basePrice = this.labCost();

    switch (discountType) {
      case 'subscription_payments_off_forever':
        const discountForeverPercentage = couponParams?.get('percentage_off');

        return discountForeverPercentage * 0.01 * basePrice;
      case 'subscription_payments_off':
        const discountPercentage = couponParams?.get('percentage_off');

        const totalDiscountedPrice = basePrice * discountPercentage * 0.01;

        return totalDiscountedPrice > basePrice ? basePrice : totalDiscountedPrice;
      case 'fixed_amount_off':
        const discountAmount = couponParams?.get('fixed_amount_off') - amountUsed;

        return discountAmount > basePrice ? basePrice : discountAmount;
      default:
        throw new Error('Invalid coupon type');
    }
  }
}

export default LabsPriceCalculator;
