/* eslint-disable react/destructuring-assignment */
import React, { useContext } from 'react';
import { Redirect } from 'react-router';
import { ProductContext } from 'app/components/customer/Product';
import { selectFurthestProductLastIntakeStepPath } from 'app/selectors/customer';
import { useAppSelector } from 'app/helpers/hooks';

const ContinueOnboarding = () => {
  const productCtx = useContext(ProductContext);
  const nextStep = useAppSelector((state) =>
    selectFurthestProductLastIntakeStepPath(state, productCtx.selectedProduct as string),
  );

  return <Redirect to={nextStep as string} />;
};

export default ContinueOnboarding;
