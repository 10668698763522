import React from 'react';
import { connect } from 'react-redux';

import { apiRequestUserCommand, reportBrowserProblem } from 'app/actions/customer';
import { PrimaryButton } from 'app/components/common/Button';
import { getLocation } from 'app/api';
import BackNextFooter from 'app/components/customer/steps/BackNextFooter';
import { P2, H4 } from 'app/components/common/Typography';

import IconGeolocation from 'images/Icon-Geolocation.svg';

const Allow = ({ redirectPrevious, onFailed, dispatch, hideBackButton = false }) => {
  const requestLocation = async () => {
    const action = await getLocation()
      .then(({ location, context }) => {
        const cmdType = 'report_customer_location';
        const params = {
          timestamp: location.timestamp,
          accuracy: location.coords.accuracy,
          latitude: location.coords.latitude.toString(),
          longitude: location.coords.longitude.toString(),
        };

        return apiRequestUserCommand({ cmdType, params, context });
      })
      .catch(async (res) => {
        dispatch(
          reportBrowserProblem({
            error: { code: 1 },
            problemType: 'browser_location_error_or_decline',
            details: res,
          }),
        );
        onFailed?.();
      });
    if (action) {
      dispatch(action);
    }
  };
  const reportDevLocation = () => {
    const FLORIDA_POSITION = {
      timestamp: 0,
      accuracy: 2000,
      latitude: '28.131065',
      longitude: '-82.694294',
    };

    const cmdType = 'report_customer_location';
    const params = FLORIDA_POSITION;

    // TODO: Missing context param in apiRequestUserCommand, is this needed?
    const action = apiRequestUserCommand({ cmdType, params });
    dispatch(action);
  };

  // TODO: Is there a better way to test that we're in a local development environment?
  const isLocalDevelopment = window.location.host === 'localhost:3000';
  return (
    <>
      <div className="center-align">
        <div className="mh16 mt16">
          <img src={IconGeolocation} />
        </div>
        <H4 className="mh16 mt16">Verify Location</H4>
        {isLocalDevelopment ? (
          <>
            <P2 className="mh16 mt16">
              Hi developer! Click Continue below to bypass the Geolocation and set your location as Florida
            </P2>

            <div className="mh16 mt16">
              <PrimaryButton data-testid="allow-devlocation" text="Continue (Dev)" onClick={reportDevLocation} />
            </div>
          </>
        ) : (
          <>
            <P2 className="mh16 mt16">
              Please allow us to use geolocation to make sure you&apos;re in a location that we can provide high quality
              care for you.
            </P2>

            <div className="mh16 mt16">
              <PrimaryButton data-testid="allow-geolocation" text="Continue" onClick={requestLocation} />
            </div>
          </>
        )}
      </div>
      <BackNextFooter noNext noBack={hideBackButton} onBack={redirectPrevious} />
    </>
  );
};

export default connect()(Allow);
