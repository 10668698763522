import React from 'react';
import protocolNames from 'app/utils/protocolNames';
import TitleWithLine from 'app/components/common/Typography/TitleWithLine';

type Props = {
  currentProductName: string;
  multimonthEnabled: boolean;
};

const GenericPaymentHeader = ({ currentProductName, multimonthEnabled }: Props) => {
  const commonHeader = (
    <div>
      <h2 className="generic_payment_page__protocol_header">{protocolNames[currentProductName]} Protocol</h2>
      <TitleWithLine className="flex">
        <div>Subscription</div>
      </TitleWithLine>
    </div>
  );

  return !multimonthEnabled ? commonHeader : null;
};

export default GenericPaymentHeader;
