import React from 'react';

import { H4C100 } from 'app/components/common/Typography';
import IconPending from 'images/Icon-Pending.svg';
import { useAppSelector } from 'app/helpers/hooks';
import { selectCurrentIntake, selectNextStepPath } from 'app/selectors/customer';
import { push } from 'connected-react-router/immutable';
import { useDispatch } from 'react-redux';

const IdReview = () => {
  const dispatch = useDispatch();
  const nextStepAction = push(useAppSelector(selectNextStepPath));
  const currentIntake = useAppSelector(selectCurrentIntake);
  const isDone = !!currentIntake.get('id_review_accepted_at');
  if (isDone) {
    setTimeout(() => dispatch(nextStepAction), 0);
  }
  return (
    <div className="mt40 mh16">
      <img src={IconPending} className="mhauto" />
      <H4C100 className="mt24 center-align">Verifying Your Identification</H4C100>
      <div className="mt12 mb40 center-align">
        <p>
          Our customer success reps need to verify your identification before we can send your information over to your
          doctor.
          <br />
          If you have questions, please contact customer support.
        </p>
      </div>
    </div>
  );
};

export default IdReview;
