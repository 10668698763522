import React from 'react';
import { connect } from 'react-redux';
import { P } from 'app/components/common/Typography';
import { push } from 'connected-react-router/immutable';
import * as actions from 'app/actions/customer';
import * as selectors from 'app/selectors/customer';
import { PrimaryButton } from 'app/components/common/Button';
import HeadingWithIcon from 'app/components/common/HeadingWithIcon';
import BulletCheckmark from 'images/Icon-Bullet-Checkmark.svg';
import './css/MagicianIntro.scss';
import * as Routes from 'app/constants/Routes';
import protocolNames from '../../../utils/protocolNames';

const benefits = [
  'You choose which is best for you: Semaglutide (brand name Wegovy®) or Tirzepatide (brand name Mounjaro™) based on your personal goals and needs',
  'Both options compounded with vitamin B6 to mitigate nausea',
  'Both options paired with science-backed guidance for maintaining lean muscle mass',
];
const access = [
  'Prescription medication (if qualified)',
  'Licensed doctor consultations',
  'Health coaching, community, & other exclusive content',
  'Priority shipping for medication',
];

const mapStateToProps = (state) => ({
  intake: selectors.selectCurrentIntake(state),
});

const MagicianOnboardingAcknowledgement = ({ step, intake, dispatch }) => {
  const action = actions.apiRequestUserCommand({
    cmdType: 'answer_questions',
    params: { [step]: true, intake: intake.get('name') },
    context: {
      onSuccessActionCreator: ({ json: { entity } }) =>
        push(
          Routes.createIntake(
            intake.get('name'),
            entity.maximus_products.magician.intakes[0].steps.filter((step) => !step.completed)[0].url_friendly_name,
          ),
        ),
    },
  });

  const onClick = () => {
    dispatch(action);
  };

  return (
    <div className="p16 warrior-intro">
      <h1>{protocolNames['magician']} Protocol</h1>
      <P className="mb20">
        A comprehensive {protocolNames['magician']} Protocol developed by Maximus to help men preserve lean muscle mass
        while achieving their weight loss goals. The protocol uses cutting-edge compound medications, health coaching,
        and exclusive, science-backed educational content.
      </P>

      <P className="bold">The Maximus approach:</P>
      <ul>
        {benefits.map((benefit) => (
          <li key={benefit}>
            <HeadingWithIcon icon={BulletCheckmark} text={benefit} tag={P} className="mv8" />
          </li>
        ))}
      </ul>
      <P className="bold mt36">Your subscription covers the cost of:</P>
      <ul>
        {access.map((a) => (
          <li key={a}>
            <HeadingWithIcon icon={BulletCheckmark} text={a} tag={P} className="mv8" />
          </li>
        ))}
      </ul>
      <PrimaryButton className="mt16 mb24" data-testid="next-step" text="Start my journey" onClick={onClick} />
    </div>
  );
};

export default connect(mapStateToProps)(MagicianOnboardingAcknowledgement);
