function isIos(): boolean {
  return /iPhone|iPod|iPad/i.test(window.navigator.userAgent);
}

function isGoogleSearchApp(): boolean {
  return /\bGSA\b/.test(window.navigator.userAgent);
}

function isIosWebview(): boolean {
  if (isIos()) {
    // The Google Search iOS app is technically a webview and doesn't support popups.
    if (isGoogleSearchApp()) {
      return true;
    }

    return /.+AppleWebKit(?!.*Safari)/.test(window.navigator.userAgent);
  }

  return false;
}

function isInstagram() {
  return /.Instagram/.test(window.navigator.userAgent);
}

function isFacebook() {
  return /.(FBAN|FBAV|FB_IAB)/.test(window.navigator.userAgent);
}

function isWhatsApp() {
  return /WhatsApp/.test(window.navigator.userAgent);
}

export const isEmbeddedBrowser = (): boolean => isIosWebview() || isInstagram() || isFacebook() || isWhatsApp();
