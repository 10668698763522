import { fromJS, Map } from 'immutable';
import { Cmd, loop } from 'redux-loop';
import { apiRequestFailure, apiRequestSuccess } from 'app/helpers/commandHelpers';
import { apiRequest } from 'app/api';
import * as actions from 'app/actions/customer';

import {
  apiRequestGuestMaker,
  apiRequestPasswordMaker,
  apiRequestLogoutMaker,
  apiRequestLoginWithEmailMaker,
  apiRequestLoginWithPasswordMaker,
  apiRequestPasswordResetMaker,
  apiRequestCommandMaker,
  apiRequestUserCommandMaker,
} from './apiRequest';
import {
  apiRequestErrorsHandler,
  apiRequestFailedHandler,
  apiRequestProblemsHandler,
  apiRequestSuccessHandler,
} from './apiRequestHandlers';
import loopCustomer from './loopCustomer';

const immediateReducer = (state = Map(), action) => {
  const actionType = (action.get && action.get('type')) || action.type;
  if (actionType === actions.INIT_CUSTOMER) {
    return state.set('customer', fromJS(action.customer));
  }
  if (actionType === actions.API_REQUEST_USER_COMMAND) {
    const command = apiRequestUserCommandMaker(state, {
      params: action.params,
      context: action.context,
      type: action.cmdType,
    });

    return loop(state, command);
  }
  if (actionType === actions.API_REQUEST_COMMAND) {
    const command = apiRequestCommandMaker({
      params: action.params,
      context: action.context,
    });

    return loop(state, command);
  }
  if (actionType === actions.PASSWORD_UPDATE_SUBMITTED) {
    const command = apiRequestPasswordMaker({
      params: action.params,
      context: action.context,
    });

    return loop(state, command);
  }
  if (actionType === actions.PASSWORD_UPDATE_SUCCESS) {
    return state.setIn(['customer', 'has_password'], true);
  }
  if (actionType === actions.LOGIN_WITH_EMAIL) {
    const command = apiRequestLoginWithEmailMaker({
      params: action.params,
      context: action.context,
    });

    return loop(state, command);
  }
  if (actionType === actions.LOGIN_WITH_PASSWORD) {
    const command = apiRequestLoginWithPasswordMaker({
      params: action.params,
      context: action.context,
    });

    return loop(state, command);
  }
  if (actionType === actions.PASSWORD_RESET) {
    const command = apiRequestPasswordResetMaker({
      params: action.params,
      context: action.context,
    });

    return loop(state, command);
  }
  if (actionType === actions.API_REQUEST_SUCCESS) {
    return apiRequestSuccessHandler(state, action);
  }
  if (actionType === actions.API_REQUEST_PROBLEMS) {
    return apiRequestProblemsHandler(state, action);
  }
  if (actionType === actions.API_REQUEST_ERRORS) {
    return apiRequestErrorsHandler(state, action);
  }
  if (actionType === actions.API_REQUEST_FAILED) {
    return apiRequestFailedHandler(state, action);
  }
  if (actionType === actions.GUEST_REGISTRATION_TERMS_SUBMITTED) {
    const command = apiRequestGuestMaker({
      params: action.params,
      context: action.context,
    });

    return loop(state, command);
  }
  if (actionType === actions.CUSTOMER_SEND_PRIVATE_MESSAGE_FORM_SUBMITTED) {
    return loop(
      state,
      Cmd.run(apiRequest, {
        successActionCreator: apiRequestSuccess,
        failActionCreator: apiRequestFailure,
        args: [
          42,
          'POST',
          '/api/commands',
          {
            type: 'send_private_message',
            user_id: action.values.user_id,
            params: action.values,
          },
          {
            onSuccessAction: action.onSuccessAction,
            formReset: action.formReset,
            form: action.form,
          },
        ],
      }),
    );
  }
  if (actionType === actions.GEOLOCATION_FAILED) {
    const command = apiRequestUserCommandMaker(state, {
      params: {
        body: { type: 'browser_problem', error: action.error },
      },
      type: 'add_user_note',
    });

    return loop(state.set('geolocationDenied', action.error), command);
  }
  if (actionType === actions.REPORT_BROWSER_PROBLEM) {
    const command = apiRequestUserCommandMaker(state, {
      params: {
        body: {
          type: 'browser_problem',
          error: action.error,
          href: window.location.href,
        },
      },
      type: 'add_user_note',
    });

    return loop(state, command);
  }
  if (actionType === actions.LOGOUT) {
    const context = { onSuccessAction: actions.hardRedirectRequested('/') };
    const command = apiRequestLogoutMaker(context);

    return loop(state, command);
  }
  if (actionType === actions.HARD_REDIRECT_REQUESTED) {
    window.location.replace(action.to);

    return state;
  }
  if (actionType === actions.NOOP) {
    return state;
  }
  if (actionType === actions.CLEAR_ADDRESS_FORM_ERROR) {
    return state.setIn(['forms', 'update_address_form', 'errors'], {});
  }

  return state;
};

export default (state = Map(), action) => {
  const immediateState = immediateReducer(state, action);

  return loopCustomer(immediateState);
};
