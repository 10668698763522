import React, { ComponentProps, RefObject } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { Radio as RadioSource } from '@blueprintjs/core';

import Type from './_type.module.css';
import View from './_view.module.css';
import Color from '../../styles/color.module.css';
import { ThemeContext } from '../ThemeContext';

const Radio = React.forwardRef(function Radio(
  props: {
    type?: 'def' | 'dense';
    view?: 'flat' | 'smooth' | 'outlined' | 'raised';
    color?:
      | 'default'
      | 'primary'
      | 'warning'
      | 'danger'
      | 'success'
      | 'primary_alt'
      | 'warning_alt'
      | 'danger_alt'
      | 'success_alt';
    large?: boolean;
    checked?: boolean;
    value?: string;
    inline?: boolean;
    disabled?: boolean;
    helperText?: string;
    leftPosition?: boolean;
    rtl?: boolean;
    testId?: string;
    fill?: boolean;
    style?: React.CSSProperties;
    label?: React.ReactNode;
    className?: string;
    onChange?: ComponentProps<typeof RadioSource>['onChange'];
    // onClick?: ComponentProps<typeof RadioSource>['onClick'];
  },
  ref,
) {
  const {
    type = 'def',
    view = 'flat',
    color = 'default',
    large,
    checked,
    inline,
    disabled,
    helperText,
    leftPosition,
    rtl,
    testId,
    fill,
    style,
    label,
    className,
    onChange,
  } = props;

  return (
    <ThemeContext.Consumer>
      {({ isDark }) => (
        <div
          className={cx(
            className,
            Type['container'],
            Type[type],
            inline && Type['inline'],
            View[view],
            checked && View['checked'],
            Color[color],
            helperText && Type['with_helper'],
            leftPosition && Type['leftPosition'],
            disabled && View['disabled'],
            fill && Type['fill'],
          )}
          style={style && style}
        >
          <RadioSource
            onChange={onChange}
            ref={ref as RefObject<RadioSource>}
            className={cx(Color[color], helperText && Type['helperText'], rtl && Type['rtl'])}
            checked={!!checked}
            large={type === 'def'}
            // Readonly is needed because we control checked state from outside
            readOnly
            disabled={disabled}
          >
            <div data-testid={testId} className={cx(helperText && Type['helperBox'], Type['text'])}>
              {label}
              {helperText && <div className={cx(Type['helperText'], View['helperText'])}>{helperText}</div>}
            </div>
          </RadioSource>
        </div>
      )}
    </ThemeContext.Consumer>
  );
});

Radio.propTypes = {
  /**
   `The type of the component.
   * Variants: `def` `dense` 
   * Default value (if undefined): `def` `
   */
  type: PropTypes.oneOf(['def', 'dense']),
  /**
   ` The view of the component.
   * Variants: `flat` `smooth` `outlined` `raised`
   * Default value (if undefined): `flat` `
   */
  view: PropTypes.oneOf(['flat', 'smooth', 'outlined', 'raised']),
  /**
  ` The color of the component.
   * Variants: `default` `primary` `warning` `danger` `success` `primaryAlt` `warningAlt` `dangerAlt` `successAlt`
   * Default value (if undefined): `default` `
   */
  color: PropTypes.oneOf([
    'default',
    'primary',
    'warning',
    'danger',
    'success',
    'primary_alt',
    'warning_alt',
    'danger_alt',
    'success_alt',
  ]),
  /**
   * Value of this option.
   */
  value: PropTypes.string,
  /**
   * Label text for this option. If omitted, value is used as the label.
   */
  label: PropTypes.object,
  /**
   * Whether this option is non-interactive.
   */
  disabled: PropTypes.bool,
  /**
   * Whether the radio buttons are to be displayed inline horizontally.
   */
  inline: PropTypes.bool,
  /**
   * Second line Helper Text
   */
  helperText: PropTypes.string,
  /**
   * RTL text
   */
  rtl: PropTypes.bool,
  /**
   * Fill container
   */
  fill: PropTypes.bool,
  /**
   * Left text position
   */
  leftPosition: PropTypes.bool,
  /**
   * Whether the control is checked.
   */
  checked: PropTypes.bool,
  /**
   * Event handler invoked when input value is changed.
   */
  onChange: PropTypes.func,
  /**
   * Event handler invoked when input is clicked
   */
  // onClick: PropTypes.func,
};

export default Radio;
