import React, { useState, useRef, useEffect } from 'react';
import PageHeader from 'app/components/common/PageHeader';
import { P } from 'app/components/common/Typography';
import PaymentCard from 'app/components/customer/steps/Payment/PaymentCard';
import InputRow from 'app/components/customer/Checkout/InputRow';
import Divider from '@setproduct-ui/core/Divider';
import { Formik, Field, Form, useFormikContext, FormikValues, FormikProps } from 'formik';
import { Title } from 'app/components/common/Typography';
import { SecondaryButton, PrimaryButton } from 'app/components/common/Button';
import * as Yup from 'yup';
import axios from 'axios';
import { apiRequestPromise, getCSRF } from 'app/api';
import './css/ListItem.scss';
import {
  selectCustomer,
  selectLastKingIntake,
  selectNextStepPath,
  selectPreviousStepPath,
} from 'app/selectors/customer';
import { isEmpty, pickBy } from 'lodash';
import { useAppSelector } from 'app/helpers/hooks';
import heic2any from 'heic2any';
import { Icon } from '@blueprintjs/core';
import ProgressBar from '@setproduct-ui/core/ProgressBar';
import { push } from 'connected-react-router/immutable';
import { apiRequestSuccess } from 'app/helpers/commandHelpers';
import CustomInputRow from 'app/components/common/CustomInputRow';
import { Action, Dispatch } from 'redux';
import { useDispatch } from 'react-redux';
import { CallHistoryMethodAction } from 'connected-react-router';

const limitations = {
  total_testosterone: { min: 10.0, max: 2000.0 },
  shbg: { min: 1.0, max: 150.0 },
  free_testosterone: { 'pg/mL': { min: 1.0, max: 250.0 }, 'ng/dL': { min: 1.0, max: 100.0 } },
  lh: { min: 0.1, max: 30.0 },
  estradiol: { min: 1.0, max: 150.0 },
  hematocrit: { min: 20.0, max: 65.0 },
  psa: { min: 0.0, max: 8.0 },
  alt: { min: 0.0, max: 100.0 },
  fsh: { min: 1.0, max: 30.0 },
  prolactin: { min: 1.0, max: 30.0 },
  ggt: { min: 0.0, max: 100.0 },
};

const minError = (val) => `Minimum allowed value is ${val}`;
const maxError = (val) => `Maximum allowed value is ${val}`;
const reqError = (attr) => `Please provide ${attr} Value`;
const allowedTypes = ['image/jpeg', 'image/png', 'application/pdf'];

const validationSchema = (isOralTrt) =>
  Yup.object().shape(
    {
      collected_at: Yup.string().required('Please provide Sample Collection Date'),
      total_testosterone: Yup.lazy(() =>
        Yup.string().when('free_testosterone', (free_testosterone) => {
          const baseConditions = Yup.number()
            .min(limitations.total_testosterone.min, minError(limitations.total_testosterone.min))
            .max(limitations.total_testosterone.max, maxError(limitations.total_testosterone.max));

          if (free_testosterone) {
            return baseConditions;
          } else return baseConditions.required(reqError('Total Testosterone'));
        }),
      ),
      shbg: Yup.lazy(() =>
        Yup.string().when('free_testosterone', (free_testosterone) => {
          const baseConditions = Yup.number()
            .min(limitations.shbg.min, minError(limitations.shbg.min))
            .max(limitations.shbg.max, maxError(limitations.shbg.max));

          if (free_testosterone) {
            return baseConditions;
          } else return baseConditions.required(reqError('SHBG'));
        }),
      ),
      free_testosterone: Yup.string().when(
        ['testosterone_unit', 'total_testosterone', 'shbg'],
        // @ts-expect-error TODO: Check the types
        (testosterone_unit, total_testosterone, shbg) => {
          const baseConditions = Yup.number()
            .min(
              limitations.free_testosterone[testosterone_unit].min,
              minError(limitations.free_testosterone[testosterone_unit].min),
            )
            .max(
              limitations.free_testosterone[testosterone_unit].max,
              maxError(limitations.free_testosterone[testosterone_unit].max),
            );

          if (shbg) {
            return baseConditions;
          } else return baseConditions.required(reqError('Free Testosterone'));
        },
      ),
      lh: Yup.number()
        .required(reqError('LH'))
        .min(limitations.lh.min, minError(limitations.lh.min))
        .max(limitations.lh.max, maxError(limitations.lh.max)),
      estradiol: Yup.number()
        .min(limitations.estradiol.min, minError(limitations.estradiol.min))
        .max(limitations.estradiol.max, maxError(limitations.estradiol.max)),
      hematocrit: Yup.string().when([], () => {
        const baseConditions = Yup.number()
          .min(limitations.hematocrit.min, minError(limitations.hematocrit.min))
          .max(limitations.hematocrit.max, maxError(limitations.hematocrit.max));

        if (isOralTrt) {
          return baseConditions.required(reqError('Hematocrit'));
        } else return baseConditions;
      }),
      psa: Yup.string().when([], () => {
        const baseConditions = Yup.number()
          .min(limitations.psa.min, minError(limitations.psa.min))
          .max(limitations.psa.max, maxError(limitations.psa.max));

        if (isOralTrt) {
          return baseConditions.required(reqError('PSA'));
        } else return baseConditions;
      }),
      alt: Yup.string().when([], () => {
        const baseConditions = Yup.number()
          .min(limitations.alt.min, minError(limitations.alt.min))
          .max(limitations.alt.max, maxError(limitations.alt.max));
        return baseConditions;
      }),
      fsh: Yup.number()
        .min(limitations.fsh.min, minError(limitations.fsh.min))
        .max(limitations.fsh.max, maxError(limitations.fsh.max)),
      prolactin: Yup.number()
        .min(limitations.prolactin.min, minError(limitations.prolactin.min))
        .max(limitations.prolactin.max, maxError(limitations.prolactin.max)),
      ggt: Yup.number()
        .min(limitations.ggt.min, minError(limitations.ggt.min))
        .max(limitations.ggt.max, maxError(limitations.ggt.max)),
    },
    // @ts-expect-error TODO: Check the types
    [['free_testosterone'], ['testosterone_unit', 'total_testosterone', 'shbg']],
  );

const UseOwnLabForm = ({ canSubmit, isOralTrt }) => {
  const { values, setFieldValue } = useFormikContext<FormikValues>();

  const [fileError, setFileError] = useState('');
  const [ownLabFile, setOwnLabFile] = useState<File | null>(null);
  const [actionHover, setActionHover] = useState(false);
  const [showCompleted, setShowCompleted] = useState(false);
  const [ownLabFileIsDeleting, setOwnLabFileIsDeleting] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [ownLabFileIsLoading, setOwnLabFileIsLoading] = useState(false);
  const [showWrongFileErrorMessage, setShowWrongFileErrorMessage] = useState(false);

  useEffect(() => {
    if (uploadProgress === 1) setShowCompleted(true);
  }, [uploadProgress]);

  useEffect(() => {
    if (ownLabFile) submitOwnLabFile();
  }, [ownLabFile]);

  const customer = useAppSelector(selectCustomer);
  const intake = useAppSelector(selectLastKingIntake)!;
  const intake_name = intake.get('name');
  const productName = intake.get('product_name');

  const inputFile = useRef<HTMLInputElement>(null);
  const handlePhotoUpload = () => inputFile.current?.click();

  const photoPurpose = `${productName}_onboarding_lab`;
  const uploadLabResults = async (params) => {
    try {
      const resp = await apiRequestPromise('POST', '/api/commands', {
        type: 'upload_own_lab_results',
        user_id: customer.get('id'),
        params: {
          ...params,
          intake_name,
          photo_purpose: photoPurpose,
        },
      });
      dispatch(apiRequestSuccess(resp));
    } catch (e: any) {
      setFileError(e.message);
    }
  };

  type AppAction = Action<string> | CallHistoryMethodAction<[string, any?]>;
  type AppDispatch = Dispatch<AppAction>;
  const dispatch = useDispatch<AppDispatch>();

  const nextStepAction = push(useAppSelector(selectNextStepPath));
  const previousStepAction = push(useAppSelector(selectPreviousStepPath));

  const onSubmit = (params) => {
    uploadLabResults(params).then(() => {
      dispatch(nextStepAction);
    });
  };

  const onCancel = () => {
    if (ownLabFile) removeOwnLabfile();
    dispatch(previousStepAction);
  };

  const onImageChange = ({ target }) => {
    const file = target.files?.[0];
    target.value = '';
    if (file) {
      if (file.type.toLowerCase() === 'image/heic' || file.name.toLowerCase().includes('.heic')) {
        heic2any({ blob: file, toType: 'image/jpeg', quality: 0.7 }).then((newImage) => {
          // @ts-expect-error TODO: check the types
          setOwnLabFile(newImage);
        });
      } else if (!allowedTypes.includes(file.type)) {
        setShowWrongFileErrorMessage(true);
        setOwnLabFile(null);
      } else {
        setShowWrongFileErrorMessage(false);
        setOwnLabFile(file);
      }
    }
  };

  const submitOwnLabFile = async () => {
    const raw_params = {
      file: ownLabFile,
      user_id: customer.get('id'),
      intake_name,
      photo_purpose: photoPurpose,
      cmdType: 'upload_own_lab',
      type: 'upload_own_lab',
    };

    const data = new FormData();

    for (const key in raw_params) {
      data.append(key, raw_params[key]);
    }

    setOwnLabFileIsLoading(true);
    setFileError('');

    const onUploadProgress = ({ loaded, total }: { loaded: number; total?: number }) => {
      if (!total) return;
      setUploadProgress(loaded / total);
    };

    await axios
      .post('/api/commands', data, { headers: { 'X-CSRF-Token': getCSRF() }, onUploadProgress })
      .then(() => {
        setOwnLabFileIsLoading(false);
        setFieldValue('own_lab_file_uploaded', true);
      })
      .catch(({ response }) => {
        setFileError(response?.data?.errors?.file);
        setOwnLabFileIsLoading(false);
      });
  };

  const removeOwnLabfile = async () => {
    setOwnLabFileIsDeleting(true);

    await axios
      .post(
        '/api/commands',
        {
          user_id: customer.get('id'),
          intake_name,
          photo_purpose: photoPurpose,
          cmdType: 'delete_own_lab',
          type: 'delete_own_lab',
        },
        { headers: { 'X-CSRF-Token': getCSRF() } },
      )
      .then(() => {
        setFieldValue('own_lab_file_uploaded', false);
        setFieldValue('own_lab_file', null);
        setFieldValue('own_lab_file_name', null);
        setOwnLabFile(null);
        setOwnLabFileIsDeleting(false);
        setFileError('');
      })
      .catch(() => setOwnLabFileIsDeleting(false));
  };

  const labPrefix = productName === 'king' ? '' : 'magician_';
  const {
    ownLabFileUploaded = customer.get(`${labPrefix}own_lab_file_uploaded`),
    own_lab_file_name = customer.get(`${labPrefix}own_lab_file_name`),
  } = values;

  const ownLabFileName = ownLabFile?.name || own_lab_file_name;

  return (
    <>
      <PaymentCard>
        <Title className="mb20" size="l">
          Required
        </Title>
        <InputRow
          className="mb20 lab_upload_paragraph_format"
          label="Sample Collection Date"
          name="collected_at"
          testId="own-lab-modal-collected-at"
          type="date"
        />
        <InputRow
          className="mb20 lab_upload_paragraph_format"
          label="Total Testosterone"
          name="total_testosterone"
          rightElement={<span>ng/dL</span>}
        />
        <InputRow
          className="mb20 lab_upload_paragraph_format"
          label="Sex Hormone Binding Globulin (SHBG)"
          name="shbg"
          rightElement={<span>nmol/L</span>}
        />
        {isOralTrt && (
          <>
            <InputRow
              className="mb20 lab_upload_paragraph_format"
              label="Hematocrit"
              name="hematocrit"
              rightElement={<span>%</span>}
            />
            <InputRow
              className="mb20 lab_upload_paragraph_format"
              label="Prostate-Specific Antigen (PSA)"
              name="psa"
              rightElement={<span>ng/mL</span>}
            />
          </>
        )}
        <InputRow
          className="mb20 lab_upload_paragraph_format"
          label="Luteinizing Hormone (LH)"
          name="lh"
          testId="own-lab-modal-lh"
          rightElement={<span>IU/L</span>}
        />
        <div className="display_flex">
          <CustomInputRow
            className="mb20 lab_upload_paragraph_format"
            label="Free Testosterone (Required if SHBG not provided)"
            name="free_testosterone"
            testId="own-lab-free-testosterone"
          />
          <div className="ml8 mt40">
            <span className="display_flex" style={{ alignItems: 'center', marginRight: '10px' }}>
              <Field type="radio" name="testosterone_unit" value="ng/dL" className="radio_button_small" />
              <label>ng/dL</label>
            </span>
            <span className="display_flex" style={{ alignItems: 'center', marginRight: '10px' }}>
              <Field type="radio" name="testosterone_unit" value="pg/mL" className="radio_button_small" />
              <label>pg/mL</label>
            </span>
          </div>
        </div>
        <Divider className="mb32 color-gray" />
        <Title className="mb24" size="l">
          Optional
        </Title>
        <InputRow
          className="mb20 lab_upload_paragraph_format"
          label="Estradiol (E2) (Optional)"
          name="estradiol"
          testId="own-lab-modal-estradiol"
          rightElement={<span>pg/mL</span>}
        />
        <InputRow
          className="mb20 lab_upload_paragraph_format"
          label="Prolactin (Optional)"
          name="prolactin"
          rightElement={<span>µg/mL</span>}
        />
        {!isOralTrt && (
          <InputRow
            className="mb20 lab_upload_paragraph_format"
            label="Hematocrit (Optional)"
            name="hematocrit"
            rightElement={<span>%</span>}
          />
        )}
        <InputRow
          className="mb20 lab_upload_paragraph_format"
          label="Alanine Aminotransferase (ALT) (Optional)"
          name="alt"
          rightElement={<span>IU/L</span>}
        />
        {!isOralTrt && (
          <InputRow
            className="mb20 lab_upload_paragraph_format"
            label="Prostate-Specific Antigen (PSA) Optional"
            name="psa"
            rightElement={<span>ng/mL</span>}
          />
        )}
        <InputRow
          className="mb20 lab_upload_paragraph_format"
          label="Follicular Stimulating Hormone (FSH) (Optional)"
          name="fsh"
          rightElement={<span>IU/L</span>}
        />
        <InputRow
          className="mb20 lab_upload_paragraph_format"
          label="Gamma-Glutamyl Transferase (GGT) (Optional)"
          name="ggt"
          rightElement={<span>IU/L</span>}
        />
        <Divider className="color-gray mt12" />

        <P className="mb12 lab_upload_paragraph_format">
          By uploading your results, you attest your baseline lab work meets the criteria outlined above, and agree to
          use the same lab vendor and panel for follow-up testing.
        </P>
        <P className="mb12 lab_upload_paragraph_format">
          A member of the Maximus clinical team will review your lab work within 2 business days to ensure lab criteria
          are met.
        </P>
        <div className="photo_upload">
          <SecondaryButton
            className="action_button mr12"
            text="UPLOAD FILE"
            data-testid="own-lab-modal-choose"
            onClick={handlePhotoUpload}
            disabled={ownLabFileIsLoading || ownLabFileIsDeleting}
          />
          <input
            ref={inputFile}
            type="file"
            id="file"
            style={{ display: 'none' }}
            accept="image/*, application/pdf"
            onChange={onImageChange}
          />
          <div className="full_width">
            {ownLabFileName && <span className="color_44 lab_upload_paragraph_format">{ownLabFileName}</span>}
            {!ownLabFile && !showWrongFileErrorMessage && (
              <span className="color_44 lab_upload_paragraph_format">No file selected</span>
            )}
            {showWrongFileErrorMessage && (
              <span className="error_message lab_upload_paragraph_format">Only images and pdf are supported</span>
            )}
            {ownLabFileUploaded && (
              <span
                className="action_icons"
                onMouseEnter={() => setActionHover(true)}
                onMouseLeave={() => setActionHover(false)}
              >
                <Icon
                  className="action_icon"
                  icon={actionHover ? 'cross' : 'tick'}
                  size={20}
                  onClick={removeOwnLabfile}
                />
              </span>
            )}
            {ownLabFileIsLoading && (
              <div className="progress_bar_container">
                {showCompleted && (
                  <div className="progress_bar_completed">
                    <Icon icon="tick" size={20} />
                    <span>Completed</span>
                  </div>
                )}
                <ProgressBar value={uploadProgress} color="primary" stripes={false} />
              </div>
            )}
          </div>
        </div>
      </PaymentCard>
      <div className="action_buttons">
        <PrimaryButton
          disabled={!ownLabFileName || ownLabFileIsLoading || ownLabFileIsDeleting || !canSubmit || !!fileError}
          className="mt32 mb24"
          text="SUBMIT"
          data-testid="own-lab-modal-confirm"
          onClick={() => onSubmit(pickBy(values, (p) => p))}
        />
        <SecondaryButton
          className="action_button cancel-no-background"
          text="Cancel"
          data-testid="own-lab-modal-close"
          disabled={ownLabFileIsDeleting}
          onClick={onCancel}
        />
      </div>
    </>
  );
};

interface FormValues {
  collected_at: string;
  total_testosterone: number;
  shbg: number;
  free_testosterone: number;
  free_testosterone_unit: string;
  lh: number;
  fsh: number;
  estradiol: number;
  hematocrit: number;
  psa: number;
  alt: number;
  prolactin: number;
  ggt: number;
}

const UseOwnLabStep = () => {
  const intake = useAppSelector(selectLastKingIntake)! as any;
  const isOralTrt = intake.get('selected_king_v2_product')?.includes('oral_trt');

  const byolForm = useRef<FormikProps<any> | null>(null);
  const customer = useAppSelector(selectCustomer);
  const lab_orders = customer.get('lab_orders')?.toArray();
  const lab_order = lab_orders.find((order) => order.get('intake_name') === 'onboarding');
  const initialValues: Partial<FormValues> | undefined = lab_order?.get('own_results')?.toJS();

  const {
    collected_at,
    total_testosterone,
    shbg,
    free_testosterone,
    free_testosterone_unit,
    lh,
    fsh,
    estradiol,
    hematocrit,
    psa,
    alt,
    prolactin,
    ggt,
  } = initialValues || {};

  const filteredValues = {
    ...(collected_at !== null && { collected_at }),
    ...(total_testosterone !== null && { total_testosterone }),
    ...(shbg !== null && { shbg }),
    ...(free_testosterone !== null && { free_testosterone }),
    ...(lh !== null && { lh }),
    ...(fsh !== null && { fsh }),
    ...(estradiol !== null && { estradiol }),
    ...(hematocrit !== null && { hematocrit }),
    ...(psa !== null && { psa }),
    ...(alt !== null && { alt }),
    ...(prolactin !== null && { prolactin }),
    ...(ggt !== null && { ggt }),
  };

  return (
    <div className="centered updated-design payments mb24">
      <PageHeader title="Use Your own Labs" className="treatment_plan__header mb24 -edged" />
      <PaymentCard className="mb32">
        <div className="content">
          <Title className="mb8" size="l">
            Using your own lab test.
          </Title>
          <Title className="mb16" size="l">
            Read carefully.
          </Title>
          <P className="mb16 mt4 lab_upload_paragraph_format">
            You may use your own lab work if it meets the following criteria:
          </P>
          <P className="list-item lab_upload_paragraph_format">
            Labs must include Total Testosterone, LH, & SHBG (or Free Testosterone).
          </P>
          <P className="list-item lab_upload_paragraph_format">Must have been collected within the last six months.</P>
          {isOralTrt && (
            <>
              <P className="list-item lab_upload_paragraph_format">
                (Oral TRT & Oral TRT+) Labs must also include Hematocrit & Prostate-Specific Antigen (PSA).
              </P>
              <P className="list-item lab_upload_paragraph_format">
                (Oral TRT+ only) Must have been collected at least two weeks after stopping Testosterone Replacement
                Therapy (TRT), Anabolic Steroids, SARMs, & SERMS.
              </P>
            </>
          )}
          <P className="mt16 lab_upload_paragraph_format">
            You will be asked to use the same lab vendor and panel for your follow-up lab test (completed about 30 days
            after starting treatment, or as required by your licensed Maximus doctor).
          </P>
        </div>
      </PaymentCard>
      <Formik
        innerRef={(formik) => (byolForm.current = formik)}
        validationSchema={validationSchema(isOralTrt)}
        validateOnMount={true}
        validateOnBlur={true}
        onSubmit={() => {}}
        initialValues={{
          ...filteredValues,
          testosterone_unit: free_testosterone_unit || 'ng/dL',
        }}
        initialTouched={{
          collected_at: !!collected_at,
          total_testosterone: !!total_testosterone,
          shbg: !!shbg,
          free_testosterone: !!free_testosterone,
          lh: !!lh,
          fsh: !!fsh,
          estradiol: !!estradiol,
          hematocrit: !!hematocrit,
          psa: !!psa,
          alt: !!alt,
          prolactin: !!prolactin,
          ggt: !!ggt,
        }}
      >
        {({ errors, touched }) => (
          <Form className="mb20">
            <UseOwnLabForm canSubmit={!isEmpty(touched) && isEmpty(errors)} isOralTrt={isOralTrt} />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default UseOwnLabStep;
