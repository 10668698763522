import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import cx from 'classnames';
import { push } from 'connected-react-router/immutable';
import * as routerSelectors from 'app/selectors/router';
import * as selectors from 'app/selectors/customer';
import * as Routes from 'app/constants/Routes';
import Input from 'app/components/common/formik/Input';
import { PasswordUpdateIfExistSchema, PasswordUpdateIfNewSchema } from 'app/helpers/validators';
import WhiteButton from 'app/components/common/WhiteButton';
import { PrimaryButton } from 'app/components/common/Button';
import { passwordUpdateSubmitted, PASSWORD_UPDATE_SUCCESS, NOOP } from 'app/actions/customer';

const mapStateToProps = (state) => {
  const customerHasPassword = selectors.selectHasPassword(state);
  const enableResetMode = routerSelectors.selectRouterQuery(state).get('reset') === 'true';

  return {
    customerHasPassword,
    enableResetMode,
  };
};

const PasswordPreferences = ({ customerHasPassword, enableResetMode, dispatch }) => {
  const [success, setSuccess] = useState(false);
  const [newPassword, setNewPassword] = useState(enableResetMode);

  let validationSchema = PasswordUpdateIfNewSchema;
  const initialValues: Record<string, string> = {
    password: '',
    password_confirmation: '',
  };

  if (customerHasPassword && !newPassword) {
    initialValues.password_current = '';
    validationSchema = PasswordUpdateIfExistSchema;
  }

  const onCancel = () => {
    dispatch(push(Routes.Settings));
  };

  const onSubmit = async (values, form) => {
    const action = passwordUpdateSubmitted({
      params: enableResetMode ? { ...values, skip_verify: true } : values,
      context: {
        onSuccessActionCreator: () => {
          setSuccess(true);
          if (!values.password_current) {
            setNewPassword(true);
          }

          return { type: PASSWORD_UPDATE_SUCCESS };
        },
        onFailureActionCreator: ({ json: { error } }) => {
          if (error === 'invalid_password') {
            form.setFieldError('password_current', 'Incorrect current password');
          } else {
            form.setErrors({ api: error });
          }

          return { type: NOOP };
        },
      },
    });
    await dispatch(action);
  };

  return (
    <Formik onSubmit={onSubmit} initialValues={initialValues} validationSchema={validationSchema}>
      {({ handleSubmit, isSubmitting, errors }) => (
        <div className="centered">
          <div className="margin-centered" style={{ maxWidth: '456px', padding: '0 10px' }}>
            <div className="password-settings">
              <p className="password-settings__info">
                {customerHasPassword && !newPassword ? (
                  <>To update your password, provide your current password along with your preferred new password.</>
                ) : (
                  <>
                    Please enter and confirm your new password. Once you set your new password you will be able to use
                    it to log in. You can update your password at any time by returning to this page.
                  </>
                )}
              </p>

              <div
                className={cx('password-settings__box', {
                  'password-settings__box-success': success,
                })}
              >
                <div className="password-settings__inputs">
                  {customerHasPassword && !newPassword && (
                    <div className="mb20">
                      <Input
                        id="password_current"
                        name="password_current"
                        label="Current Password"
                        type="password"
                        className="text-field"
                      />
                    </div>
                  )}
                  <div className="mb20">
                    <Input id="password" name="password" label="New Password" type="password" className="text-field" />
                  </div>
                  <div className="mb20">
                    <Input
                      id="password_confirmation"
                      name="password_confirmation"
                      type="password"
                      label="Confirm New Password"
                      className="text-field"
                    />
                  </div>
                </div>
                <div className="password-settings__success">Password successfully updated</div>
                {errors && errors.api && <div className="password-settings__error">{errors.api}</div>}
                <hr />
                <div className="flex">
                  {success ? (
                    <PrimaryButton onClick={onCancel} text="Back to Settings" />
                  ) : (
                    <>
                      <WhiteButton onClick={onCancel} text="Cancel" />
                      <PrimaryButton onClick={() => handleSubmit()} text="Update" disabled={isSubmitting} />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Formik>
  );
};

export default connect(mapStateToProps)(PasswordPreferences);
