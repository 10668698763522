import React, { ReactNode, useState } from 'react';
import cx from 'classnames';
import './css/ExpandButton.scss';

type Props = {
  text?: string;
  initialOpen?: boolean;
  expandText?: string;
  size?: 'small' | 'large';
  disabled?: boolean;
  children?: ReactNode;
  className?: string;
  defaultValue?: string;
};

const ArrowRight = () => (
  <svg width="12" height="19" viewBox="0 0 12 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.5 18L9.5 9.35088L1.5 1" stroke="var(--primary-color)" strokeWidth="2.61538" />
  </svg>
);

const ArrowDown = () => (
  <svg width="19" height="11" viewBox="0 0 19 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 1L9.64912 9L18 1" stroke="var(--primary-color)" strokeWidth="2.61538" />
  </svg>
);

const ExpandButton = ({
  initialOpen = false,
  text = 'MORE DETAILS',
  disabled = false,
  size,
  children,
  className,
  ...rest
}: Props) => {
  const [open, setOpen] = useState(initialOpen);
  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <div className="expand-button__flex">
      <div className="expand-button__icon" onClick={handleClick}>
        {!open && <ArrowRight />}
        {open && <ArrowDown />}
      </div>
      <div>
        <span className={cx('expand-button__container', { disabled }, size, className)}>
          <button
            onClick={handleClick}
            {...rest}
            disabled={disabled}
            className={cx('expand-button', { open })}
            type="button"
          >
            {text}
          </button>
        </span>
        {open && <div className="expand-button__content">{children}</div>}
      </div>
    </div>
  );
};

export default ExpandButton;
