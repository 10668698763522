import React, { ComponentProps, FC, ReactNode } from 'react';

import cx from 'classnames';

import CoreButton from '@setproduct-ui/core/Button';

import ChevronUp from 'images/Chevron-Up.svg';
import './css/Button.scss';
import { useRedesign } from 'app/utils/redesign/RedesignProvider';

export const RaisedButton: FC<ComponentProps<typeof CoreButton>> = ({ className, ...restProps }) => (
  <CoreButton
    view="raised"
    type="default"
    intent="default"
    fill
    className={cx(className, 'default-button')}
    {...restProps}
  />
);

interface ButtonProps extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'className'> {
  className?: string;
  toggled?: boolean;
  size?: 'small' | 'large' | 'medium';
  text: ReactNode;
}

export const Button: FC<ButtonProps> = ({
  className,
  disabled = false,
  toggled = false,
  type = 'button',
  size,
  text,
  ...restProps
}) => {
  const overrideColor = useRedesign();
  const overridenStyle = overrideColor ? { backgroundColor: '#fc3c4f' } : {};

  return (
    <button
      className={cx('default-button', className, { size }, { toggled })}
      type={type}
      disabled={disabled}
      style={overridenStyle}
      {...restProps}
    >
      {text}
    </button>
  );
};

export const PrimaryButton: FC<ButtonProps> = ({ className = '', ...restProps }) => (
  <Button className={cx('primary-button', className)} {...restProps} />
);

export const SecondaryButton: FC<ButtonProps> = ({ className = '', ...restProps }) => (
  <Button className={cx('outlined-button', className)} {...restProps} />
);

type Props = {
  down?: boolean;
  disabled?: boolean;
  className?: string;
  onClick?: () => void;
  testId?: string;
};
export const IconButton: React.FC<Props> = ({ down, disabled, className, onClick, testId }) => (
  <button
    data-testid={testId}
    type="button"
    disabled={disabled}
    className={cx('icon-button', className, {
      down,
      disabled,
    })}
    onClick={onClick}
  >
    <img className="icon-button__icon" src={ChevronUp} alt="" />
  </button>
);
