/* eslint-disable @typescript-eslint/ban-ts-comment */
import { P } from 'app/components/common/Typography';
import { PrimaryButton, SecondaryButton } from 'app/components/common/Button';
import React, { useState, useEffect, FC } from 'react';
import ProgressBar from '@setproduct-ui/core/ProgressBar';
import { Icon } from '@blueprintjs/core';
import Divider from '@setproduct-ui/core/Divider';
import { AvailableProducts } from 'app/constants/Products';
import 'react-day-picker/lib/style.css';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { isEmpty, pickBy } from 'lodash';
import InputRow from './InputRow';

const limitations = {
  total_testosterone: { min: 10.0, max: 2000.0 },
  shbg: { min: 1.0, max: 150.0 },
  free_testosterone: { 'pg/mL': { min: 1.0, max: 250.0 }, 'ng/dL': { min: 1.0, max: 100.0 } },
  lh: { min: 0.1, max: 30.0 },
  estradiol: { min: 1.0, max: 150.0 },
  hematocrit: { min: 20.0, max: 65.0 },
  psa: { min: 0.0, max: 8.0 },
  alt: { min: 0.0, max: 100.0 },
  fsh: { min: 1.0, max: 30.0 },
  prolactin: { min: 1.0, max: 30.0 },
  ggt: { min: 0.0, max: 100.0 },
};

const minError = (val) => `Minimum allowed value is ${val}`;
const maxError = (val) => `Maximum allowed value is ${val}`;
const reqError = (attr) => `Please provide ${attr} Value`;

const validationSchema = (isOralTrt) =>
  Yup.object().shape(
    {
      collected_at: Yup.string().required('Please provide Sample Collection Date'),
      total_testosterone: Yup.lazy(() =>
        Yup.string().when('free_testosterone', (free_testosterone) => {
          const baseConditions = Yup.number()
            .min(limitations.total_testosterone.min, minError(limitations.total_testosterone.min))
            .max(limitations.total_testosterone.max, maxError(limitations.total_testosterone.max));

          if (free_testosterone) {
            return baseConditions;
          } else return baseConditions.required(reqError('Total Testosterone'));
        }),
      ),
      shbg: Yup.lazy(() =>
        Yup.string().when('free_testosterone', (free_testosterone) => {
          const baseConditions = Yup.number()
            .min(limitations.shbg.min, minError(limitations.shbg.min))
            .max(limitations.shbg.max, maxError(limitations.shbg.max));

          if (free_testosterone) {
            return baseConditions;
          } else return baseConditions.required(reqError('SHBG'));
        }),
      ),
      free_testosterone: Yup.string().when(
        ['testosterone_unit', 'total_testosterone', 'shbg'],
        // TODO: Check the types
        // @ts-ignore
        (testosterone_unit, total_testosterone, shbg) => {
          const baseConditions = Yup.number()
            .min(
              // TODO: Check the types
              // @ts-ignore
              limitations.free_testosterone[testosterone_unit].min,
              // TODO: Check the types
              // @ts-ignore
              minError(limitations.free_testosterone[testosterone_unit].min),
            )
            .max(
              // TODO: Check the types
              // @ts-ignore
              limitations.free_testosterone[testosterone_unit].max,
              // TODO: Check the types
              // @ts-ignore
              maxError(limitations.free_testosterone[testosterone_unit].max),
            );

          if (total_testosterone && shbg) {
            return baseConditions;
          } else return baseConditions.required(reqError('Free Testosterone'));
        },
      ),
      lh: Yup.number()
        .required(reqError('LH'))
        .min(limitations.lh.min, minError(limitations.lh.min))
        .max(limitations.lh.max, maxError(limitations.lh.max)),
      estradiol: Yup.number()
        .min(limitations.estradiol.min, minError(limitations.estradiol.min))
        .max(limitations.estradiol.max, maxError(limitations.estradiol.max)),
      hematocrit: Yup.string().when([], () => {
        const baseConditions = Yup.number()
          .min(limitations.hematocrit.min, minError(limitations.hematocrit.min))
          .max(limitations.hematocrit.max, maxError(limitations.hematocrit.max));

        if (isOralTrt) {
          return baseConditions.required(reqError('Hematocrit'));
        } else return baseConditions;
      }),
      psa: Yup.string().when([], () => {
        const baseConditions = Yup.number()
          .min(limitations.psa.min, minError(limitations.psa.min))
          .max(limitations.psa.max, maxError(limitations.psa.max));

        if (isOralTrt) {
          return baseConditions.required(reqError('PSA'));
        } else return baseConditions;
      }),
      alt: Yup.string().when([], () => {
        const baseConditions = Yup.number()
          .min(limitations.alt.min, minError(limitations.alt.min))
          .max(limitations.alt.max, maxError(limitations.alt.max));
        return baseConditions;
      }),
      fsh: Yup.number()
        .min(limitations.fsh.min, minError(limitations.fsh.min))
        .max(limitations.fsh.max, maxError(limitations.fsh.max)),
      prolactin: Yup.number()
        .min(limitations.prolactin.min, minError(limitations.prolactin.min))
        .max(limitations.prolactin.max, maxError(limitations.prolactin.max)),
      ggt: Yup.number()
        .min(limitations.ggt.min, minError(limitations.ggt.min))
        .max(limitations.ggt.max, maxError(limitations.ggt.max)),
    },
    // TODO: Check the types
    // @ts-ignore
    [['free_testosterone'], ['testosterone_unit', 'total_testosterone', 'shbg']],
  );

type Props = {
  removeOwnLabfile: () => void;
  setOpenModalOwnLab: (open: boolean) => void;
  handlePhotoUpload: () => void;
  ownLabFileName: string | null;
  product: AvailableProducts;
  isOralTrt: boolean;
  isLoading: boolean;
  isDeleting: boolean;
  uploadProgress: number;
  ownLabFileUploaded: boolean | null;
  uploadLabResults: (params: any) => void;
  fileError: any;
  setByolFormSubmitted?: (submitted: boolean) => void;
  setByolParams?: (params: any) => void;
  byolParams?: any;
  byolForm?: any;
};

const ModalBodyOwnLab = ({
  removeOwnLabfile,
  setOpenModalOwnLab,
  handlePhotoUpload,
  ownLabFileName,
  product,
  isOralTrt,
  isLoading,
  isDeleting,
  uploadProgress,
  ownLabFileUploaded,
  uploadLabResults,
  fileError,
  setByolFormSubmitted,
  setByolParams,
  byolParams,
  byolForm,
}: Props) => {
  const [actionHover, setActionHover] = useState(false);
  const [showCompleted, setShowCompleted] = useState(false);
  const isMagician = product === AvailableProducts.Magician;

  useEffect(() => {
    if (uploadProgress === 1) setShowCompleted(true);
  }, [uploadProgress]);

  const onSubmit = (params) => {
    if (setByolParams) {
      setByolParams(params);
      if (setByolFormSubmitted) {
        setByolFormSubmitted(true);
      }
      setOpenModalOwnLab(false);
    } else {
      uploadLabResults(params);
    }
  };

  const CommonFooter: FC<{
    canSubmit?: boolean;
    params?: Record<string, any>;
  }> = ({ canSubmit = true, params }) => {
    return (
      <>
        <div className="photo_upload">
          <SecondaryButton
            className="action_button mr12"
            text="UPLOAD FILE"
            data-testid="own-lab-modal-choose"
            onClick={handlePhotoUpload}
            disabled={isLoading || isDeleting}
          />
          <div className="full_width">
            {(ownLabFileName && <span className="color_44">{ownLabFileName}</span>) || (
              <span className="color_44">No file selected</span>
            )}
            {ownLabFileUploaded && (
              <span
                className="action_icons"
                onMouseEnter={() => setActionHover(true)}
                onMouseLeave={() => setActionHover(false)}
              >
                <Icon
                  className="action_icon"
                  icon={actionHover ? 'cross' : 'tick'}
                  size={20}
                  onClick={removeOwnLabfile}
                />
              </span>
            )}
            {isLoading && (
              <div className="progress_bar_container">
                {showCompleted && (
                  <div className="progress_bar_completed">
                    <Icon icon="tick" size={20} />
                    <span>Completed</span>
                  </div>
                )}
                <ProgressBar value={uploadProgress} color="primary" stripes={false} />
              </div>
            )}
          </div>
        </div>
        {fileError && <span className="file_error_message">{fileError}</span>}
        <Divider className="divider mb16 mt20" />
        <div className="action_buttons">
          <PrimaryButton
            disabled={!ownLabFileName || isLoading || isDeleting || !canSubmit || fileError}
            className="action_button mb16"
            text="SUBMIT"
            data-testid="own-lab-modal-confirm"
            onClick={() => onSubmit(pickBy(params, (p) => p))}
          />
          <SecondaryButton
            className="action_button cancel"
            text="Cancel"
            data-testid="own-lab-modal-close"
            disabled={isDeleting}
            onClick={() => {
              setOpenModalOwnLab(false);
            }}
          />
        </div>
      </>
    );
  };

  const MagicianCopy = () => {
    return (
      <div className="own_lab_dialog_content">
        <P>
          You will be asked to use the same lab vendor and panel for your follow-up lab test (completed about 90 days
          after starting treatment, or as required by your licensed Maximus doctor).
        </P>
        <Divider className="mb20" />
        <P className="mb12">You may use your own lab work if it meets the following criteria:</P>
        <ul className="ml24 mb12" style={{ listStyle: 'disc' }}>
          <li>The lab panel must include HbA1c</li>
          <li>The lab panel must also include creatinine.</li>
          <li>The sample must have been collected within the last six months.</li>
        </ul>
        <P>
          By uploading your results and clicking <span className="bold">“Use My Own Lab”</span> you attest your baseline
          lab work meets the criteria outlined above, and agree to use the same lab vendor and panel for follow-up
          testing.
        </P>
        <P className="mt12 mb24">
          Once uploaded, a member of the <span className="bold">Maximus clinical team</span> will review your lab work
          within 2 business days to ensure lab criteria are met. If lab criteria are not met you will be contacted by a
          member of the concierge team.
        </P>

        <CommonFooter />
      </div>
    );
  };

  if (isMagician) return <MagicianCopy />;

  return (
    <div className="own_lab_dialog_content">
      <P className="mb12">You may use your own lab work if it meets the following criteria:</P>

      <span>
        <ul className="ml24 mb12" style={{ listStyle: 'disc' }}>
          <li>Lab panel must include:</li>
          <ul className="ml24" style={{ listStyle: 'disc' }}>
            <li>Hormone markers:</li>
            <li style={{ listStyle: 'none' }}>
              <ul className="ml24" style={{ listStyle: 'disc' }}>
                <li>Total Testosterone</li>
                <li>SHBG and/or Free Testosterone</li>
                <li>Luteinizing Hormone (LH)</li>
              </ul>
            </li>
            {isOralTrt && (
              <>
                <li>Metabolic function markers:</li>
                <li style={{ listStyle: 'none' }}>
                  <ul className="ml24" style={{ listStyle: 'disc' }}>
                    <li>Hematocrit</li>
                    <li>Prostate-Specific Antigen (PSA)</li>
                  </ul>
                </li>
              </>
            )}
            <li>Optional but recommended:</li>
            <li style={{ listStyle: 'none' }}>
              <ul className="ml24" style={{ listStyle: 'disc' }}>
                <li>Estradiol</li>
                <li>Follicle Stimulating Hormone (FSH)</li>
              </ul>
            </li>
          </ul>
          <li>The sample must have been collected within the last six months.</li>
          {isOralTrt ? (
            <li>
              The sample must have been collected at least two weeks after discontinuation of all of the following:
            </li>
          ) : (
            <li>
              The sample will ideally be collected at least two weeks after discontinuation of all of the following:
            </li>
          )}

          <li style={{ listStyle: 'none' }}>
            <ul className="ml24" style={{ listStyle: 'disc' }}>
              <li>Testosterone Replacement Therapy (TRT), Anabolic Steroids, SARMs, SERMS, Aromatase Inhibitors</li>
            </ul>
          </li>
        </ul>
      </span>

      <P className="mb12">
        You will be asked to use the same lab vendor and panel for your follow-up lab test (completed about 30 days
        after starting treatment, or as required by your licensed Maximus clinician).
      </P>

      <P className="mb12">
        By uploading your results and clicking
        <span className="bold"> “USE MY OWN LAB” </span>
        you attest your baseline lab work meets the criteria outlined above, and agree to use the same lab vendor and
        panel for follow-up testing.
      </P>

      <P className="mb12">
        Once uploaded, a member of the Maximus clinical team will review your lab work within 2 business days to ensure
        lab criteria are met. If lab criteria are not met you will be contacted by a member of the concierge team.
      </P>

      <P className="mb16">
        Press <span className="bold"> “UPLOAD FILE” </span>to browse and select your lab work file from your device.
      </P>

      <Formik
        innerRef={(formik) => (byolForm.current = formik)}
        initialValues={{
          collected_at: byolParams?.collected_at,
          total_testosterone: byolParams?.total_testosterone,
          shbg: byolParams?.shbg,
          free_testosterone: byolParams?.free_testosterone,
          testosterone_unit: byolParams?.testosterone_unit || 'ng/dL',
          lh: byolParams?.lh,
          fsh: byolParams?.fsh,
          estradiol: byolParams?.estradiol,
          hematocrit: byolParams?.hematocrit,
          psa: byolParams?.psa,
          alt: byolParams?.alt,
          prolactin: byolParams?.prolactin,
          ggt: byolParams?.ggt,
        }}
        validationSchema={validationSchema(isOralTrt)}
        validateOnMount={true}
        validateOnBlur={true}
        // TODO: rewrite to user onSubmit method
        onSubmit={() => {}}
        initialTouched={{
          collected_at: !!byolParams?.collected_at,
          total_testosterone: !!byolParams?.total_testosterone,
          shbg: !!byolParams?.shbg,
          free_testosterone: !!byolParams?.free_testosterone,
          lh: !!byolParams?.lh,
          fsh: !!byolParams?.fsh,
          estradiol: !!byolParams?.estradiol,
          hematocrit: !!byolParams?.hematocrit,
          psa: !!byolParams?.psa,
          alt: !!byolParams?.alt,
          prolactin: !!byolParams?.prolactin,
          ggt: !!byolParams?.ggt,
        }}
      >
        {({ values, errors, touched }) => (
          <>
            <Form className="mb20">
              <P className="mb20 text18 bold">Required</P>
              <InputRow
                className="mb20"
                label="Sample Collection Date"
                name="collected_at"
                testId="own-lab-modal-collected-at"
                type="date"
              />
              <InputRow
                className="mb20"
                label="Total Testosterone"
                name="total_testosterone"
                rightElement={<span>ng/dL</span>}
              />
              <InputRow
                className="mb20"
                label="Sex Hormone Binding Globulin (SHBG)"
                name="shbg"
                rightElement={<span>nmol/L</span>}
              />
              <InputRow
                className="mb20"
                label="Free Testosterone (Required if SHBG is not provided)"
                name="free_testosterone"
                testId="own-lab-modal-free-testosterone"
                rightElement={
                  <span style={{ display: 'inline-block', verticalAlign: 'middle' }}>
                    <span className="display_flex">
                      <span className="display_flex" style={{ alignItems: 'center', marginRight: '10px' }}>
                        <Field type="radio" name="testosterone_unit" value="pg/mL" className="radio_button_small" />
                        <label>pg/mL</label>
                      </span>
                      <span className="display_flex" style={{ alignItems: 'center' }}>
                        <Field type="radio" name="testosterone_unit" value="ng/dL" className="radio_button_small" />
                        <label>ng/dL</label>
                      </span>
                    </span>
                  </span>
                }
              />
              <InputRow
                className="mb20"
                label="Luteinizing Hormone (LH)"
                name="lh"
                testId="own-lab-modal-lh"
                rightElement={<span>IU/L</span>}
              />
              {isOralTrt && (
                <>
                  <InputRow className="mb20" label="Hematocrit" name="hematocrit" rightElement={<span>%</span>} />
                  <InputRow
                    className="mb20"
                    label="Prostate-Specific Antigen (PSA)"
                    name="psa"
                    rightElement={<span>ng/mL</span>}
                  />
                </>
              )}
              <Divider className="mb20 divider" />
              <P className="mb20 text18">
                <span className="bold">Optional </span>(highly preferred)
              </P>
              <InputRow
                className="mb20"
                label="Enter Estradiol (E2)"
                name="estradiol"
                testId="own-lab-modal-estradiol"
                rightElement={<span>pg/mL</span>}
              />
              <InputRow
                className="mb20"
                label="Alanine Aminotransferase (ALT)"
                name="alt"
                rightElement={<span>IU/L</span>}
              />
              <InputRow
                className="mb20"
                label="Follicular Stimulating Hormone (FSH) (Optional)"
                name="fsh"
                rightElement={<span>IU/L</span>}
              />
              <InputRow
                className="mb20"
                label="Prolactin (Optional)"
                name="prolactin"
                rightElement={<span>µg/mL</span>}
              />
              <InputRow
                className="mb16"
                label="Gamma-Glutamyl Transferase (GGT) (Optional)"
                name="ggt"
                rightElement={<span>IU/L</span>}
              />
              <Divider className="divider" />
            </Form>
            <CommonFooter canSubmit={!isEmpty(touched) && isEmpty(errors)} params={values} />
          </>
        )}
      </Formik>
    </div>
  );
};

export default ModalBodyOwnLab;
