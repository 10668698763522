import React, { FC } from 'react';

import type { FieldProps } from 'formik';

const FieldError: FC<FieldProps<any>> = ({ field, form }) => {
  if (!form.errors[field.name] || !form.touched[field.name]) {
    return null;
  }

  return <div className="hs2-text-input__error medium">{form.errors[field.name]}</div>;
};

export default FieldError;
