import React, { ComponentProps, useMemo } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { TransitionChildren } from 'react-transition-group/Transition';
import cx from 'classnames';
import reverse from 'lodash/reverse';
import flow from 'lodash/flow';
import toPairs from 'lodash/toPairs';
import zipWith from 'lodash/zipWith';
import fromPairs from 'lodash/fromPairs';

import classNames from 'classnames';

export enum AnimationTypes {
  Fade = 'fade',
  Slide = 'slide',
}

export enum AnimationSides {
  Static = '',
  Down = 'Down',
  DownBig = 'DownBig',
  Left = 'Left',
  LeftBig = 'LeftBig',
  Right = 'Right',
  RightBig = 'RightBig',
  Up = 'Up',
  UpBig = 'UpBig',
}

export enum AnimationDirections {
  Static = 'static',
  Horizontal = 'horizontal',
  HorizontalBig = 'horizontalBig',
  Vertical = 'vertical',
  VerticalBig = 'verticalBig',
}

type AnimateProps = {
  children: TransitionChildren;
  frameKey: ComponentProps<typeof CSSTransition>['key'];
  forward?: boolean;
  className?: classNames.Argument;
};
export const BaseAnimate = ({ forward = true, frameKey, className, children }: AnimateProps) => {
  const animationTypeStored = localStorage.getItem('AnimationTypes') as AnimationTypes;
  const animationType = Object.values(AnimationTypes).find((t) => t === animationTypeStored) || AnimationTypes.Fade;

  const animationLength = localStorage.getItem('AnimationDuration') || '500ms';
  document.documentElement.style.setProperty('--animate-duration', animationLength);

  const animationDirectionStored = localStorage.getItem('AnimationDirection') as AnimationDirections;
  const animationDirection =
    Object.values(AnimationDirections).find((t) => t === animationDirectionStored) || AnimationDirections.Horizontal;

  const animationSideDirections: AnimationSides[] = {
    [AnimationDirections.Static]: [AnimationSides.Static, AnimationSides.Static],
    [AnimationDirections.Horizontal]: [AnimationSides.Right, AnimationSides.Left],
    [AnimationDirections.HorizontalBig]: [AnimationSides.RightBig, AnimationSides.LeftBig],
    [AnimationDirections.Vertical]: [AnimationSides.Up, AnimationSides.Down],
    [AnimationDirections.VerticalBig]: [AnimationSides.UpBig, AnimationSides.DownBig],
  }[animationDirection];

  const classNames = useMemo(() => {
    let sides: AnimationSides[];
    switch (animationDirection) {
      case AnimationDirections.Horizontal:
      case AnimationDirections.HorizontalBig:
        sides = forward ? animationSideDirections : reverse(animationSideDirections);
        break;
      case AnimationDirections.Vertical:
      case AnimationDirections.VerticalBig:
        sides = new Array(2).fill(animationSideDirections[forward ? 0 : 1]);
        break;
      default:
        sides = animationSideDirections;
    }

    return flow(
      toPairs,
      (pairs) =>
        zipWith(pairs, sides, ([key, type], side) => [key, `transitioning ${animationType}${type}${side}Deep`]),
      fromPairs,
    )({ enterActive: 'In', exitActive: 'Out' });
  }, [forward]);

  return (
    <TransitionGroup
      className={cx('steps-transition-wrapper', className)}
      childFactory={(child) => React.cloneElement(child, { classNames })}
    >
      <CSSTransition key={frameKey} timeout={parseInt(animationLength, 10)} classNames={classNames}>
        {children}
      </CSSTransition>
    </TransitionGroup>
  );
};
