import React, { ComponentProps, FC } from 'react';
import { P } from './P';

export const S: FC<
  {
    large?: boolean;
  } & ComponentProps<typeof P>
> = ({ children, ...rest }) => (
  <P tagName="span" {...rest} large>
    {children}
  </P>
);
