import React, { ComponentProps, FC, ReactNode } from 'react';
import SetProductRadio from '@setproduct-ui/core/Radio';
import cx from 'classnames';
import './css/Radio.scss';

const Radio: FC<{
  onChange?: ComponentProps<typeof SetProductRadio>['onChange'];
  label: ReactNode;
  checked: ComponentProps<typeof SetProductRadio>['checked'];
  className?: ComponentProps<typeof SetProductRadio>['className'];
  testId?: string;
  disabled?: boolean;
  fill?: ComponentProps<typeof SetProductRadio>['fill'];
}> = ({ onChange, label, checked, className, testId, fill = true, disabled = false }) => (
  <SetProductRadio
    checked={checked}
    label={<p>{label}</p>}
    testId={testId}
    fill={fill}
    disabled={disabled}
    className={cx('radio-option', className)}
    color="primary"
    view="raised"
    onChange={onChange}
  />
);

export default React.memo(Radio);
