import React from 'react';
import { P } from 'app/components/common/Typography';
import ErrorCard from 'app/components/common/ErrorCard';
import { Checkbox } from 'app/components/common/formik/Checkbox';

const OTHER_PHARMACY_WARNING =
  'While you are free to choose your own pharmacy, our custom doses of specific, speciality compounded drugs are NOT carried at retail pharmacies like CVS or Walgreens. If you still decide to choose another pharmacy, you will need to verify this yourself (which is highly unlikely), and it will significantly delay your shipment. Therefore, we strongly suggest choosing the Maximus Recommended Pharmacies option, as those pharmacies are accustomed to stocking and fulfilling specific lifestyle medications, if deemed clinically appropriate and prescribed by your attending physician at the time of consult.';
const OtherOption = ({ expanded, setFieldValue, className, checkboxValue, toggleCheckbox }) => {
  if (!expanded) {
    return null;
  }

  return (
    <>
      <ErrorCard className={className} errorTitle="WARNING: " errorText={OTHER_PHARMACY_WARNING} textColor="black" />
      <div
        className="other-option-checkbox mv32 pointer"
        onClick={async (e) => {
          e.preventDefault();
          if (checkboxValue) {
            toggleCheckbox(false);
            await setFieldValue('choice', '');
          } else {
            toggleCheckbox(true);
            await setFieldValue('choice', 'other');
          }
        }}
      >
        <Checkbox id="choice" name="choice" checked={checkboxValue} testId="pharmacy-other" />
        <P colorStep={90}>
          I acknowledge that choosing a different pharmacy may delay or prevent my ability to receive treatment. Maximus
          cannot guarantee that my pharmacy of choice can compound the ingredients included in any Maximus protocol I
          subscribe to.
        </P>
      </div>
    </>
  );
};

export default OtherOption;
