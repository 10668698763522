import React from 'react';

import 'app/components/customer/steps/Payment/css/ProductSelection.scss';
import Divider from '@setproduct-ui/core/Divider';
import { P } from 'app/components/common/Typography';
import Radio from 'app/components/common/Radio';
import { AvailableProducts, KingV2SubProducts, MagicianSubProducts } from 'app/constants/Products';
import ProductHeader from 'app/components/customer/steps/Payment/king/ProductHeader';
import nextBillingCycle from 'app/helpers/nextBillingCycle';
import PriceFormatter from '../Generic/PriceFormatter';
import subProductNames from 'app/utils/subProductNames';

type Props = {
  productName: AvailableProducts;
  selectedSubProduct: KingV2SubProducts | MagicianSubProducts;
  subProductsConfig: Array<any>;
  firstTimeChoice?: boolean;
  handleChange: (setFieldValue: any) => void;
  setOpenModalNotInState?: (openModalNotInState: boolean) => void;
};

const GenericProductSelectionForm = ({
  productName,
  selectedSubProduct,
  subProductsConfig,
  handleChange,
  setOpenModalNotInState,
  firstTimeChoice = false,
}: Props) => {
  const nextBillingCycleStr = nextBillingCycle(productName);

  const notAvailableInStateText = (notAvailableInState) =>
    setOpenModalNotInState && notAvailableInState ? (
      <P className="mt12">
        <b className="bold">Not currently available in your state.</b>
        <br />
        <a onClick={() => setOpenModalNotInState(true)}>Learn more</a>
      </P>
    ) : (
      <></>
    );

  const renderAlert = () => (
    <div className="product-selection-alert">
      <P className="mb12">
        <b>NOTE:</b> Any changes you make to your product will take effect after your next billing cycle on{' '}
        <b>{nextBillingCycleStr}</b>.
      </P>
      <P>
        If you would like it sooner,{' '}
        <a target="_blank" className="underlined" href="mailto:support@maximustribe.com" rel="noreferrer">
          contact a Maximus concierge team member
        </a>{' '}
        to make a change to your current subscription.
      </P>
    </div>
  );

  const renderRadio = (subProduct, showDivider) => {
    return (
      <>
        <Radio
          className="radio no-borders"
          onChange={() => handleChange(subProduct.name)}
          checked={selectedSubProduct === subProduct.name}
          disabled={subProduct.notAvailableInState}
          testId={`drug-variant-${subProduct.name}`}
          label={
            <>
              <div className="price_row ">
                <div className="radio-label mb8">{subProductNames[productName][subProduct.name]}</div>
                <PriceFormatter period="mo" from price={subProduct.price} />
              </div>
              <P>{subProduct.description}</P>
              {notAvailableInStateText}
            </>
          }
        />
        {showDivider && <Divider />}
      </>
    );
  };

  return (
    <>
      {!firstTimeChoice && renderAlert()}
      <div className="payment_card magician_treatment_plan__options mt16">
        <ProductHeader productName={productName} subProductName={selectedSubProduct} />
        {subProductsConfig.map((subProduct, index) => renderRadio(subProduct, index !== subProductsConfig.length - 1))}
      </div>
    </>
  );
};

export default GenericProductSelectionForm;
