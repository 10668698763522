import React from 'react';
import { H6 } from 'app/components/common/Typography';
import Input, { InputMask } from 'app/components/common/formik/Input';
import { ShippingFields } from 'app/components/customer/steps/Shipping';
import { PHONE_MASK, phoneDisplayFormatter } from 'app/helpers/formatters';

const PharmacyAddress = ({ choice }) => {
  if (choice !== 'other') {
    return null;
  }

  return (
    <>
      <H6 colorStep={90} className="mt12 mb12">
        Pharmacy Info
      </H6>
      <div>
        <Input className="pharmacy-input" id="pharmacy_name" name="pharmacy_name" placeholder="Pharmacy Name" />
      </div>
      <ShippingFields labels={false} className="pharmacy-input" />
      <div className="mt12 mb16">
        <InputMask
          id="phone_number"
          name="phone_number"
          className="pharmacy-input"
          displayFormatter={phoneDisplayFormatter}
          mask={PHONE_MASK}
        />
      </div>
    </>
  );
};

export default PharmacyAddress;
