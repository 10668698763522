import React from 'react';
import { RaisedButton } from 'app/components/common/Button';
import { H5, P } from 'app/components/common/Typography';
import * as actions from 'app/actions/customer';
import { push } from 'connected-react-router/immutable';
import * as selectors from 'app/selectors/customer';
import { connect } from 'react-redux';
import './css/LabStudy.scss';

const mapStateToProps = (state) => {
  const onSuccessAction = push(selectors.selectNextStepPath(state));

  return {
    onSuccessAction,
  };
};
const LabStudyStep = ({ dispatch, onSuccessAction }) => {
  const optInClickFactory = (answer) => () => {
    const cmdType = 'answer_questions';
    const params = { lab_study_opt_in: answer };
    const action = actions.apiRequestUserCommand({
      cmdType,
      params,
      context: { onSuccessAction },
    });
    dispatch(action);
  };

  return (
    <div className="mh16 mt24">
      <H5 colorStep={60}>
        Early Access <span className="gold">Member Offer!</span>
      </H5>
      <P colorStep={60} className="mt16">
        As part of our Alpha program, we are offering all members a 10% discount off our standard $199.99 subscription
        for the first 6 months ($179.99/month for the first 6 months for the Silver Protocol, $199.99/month afterwards).
      </P>
      <P colorStep={60} className="mt16">
        If you are interested in helping us with product research, we are offering an additional 10% off (20% total) for
        our Gold members ($159.99/month for the first 6 months for the Gold Protocol, $199.99/month afterwards). To
        receive this additional discount, you will need to make two visits to your local lab for in-person blood draws
        and agree to share test results with Maximus to help improve our product offering. If you agree, we will contact
        you with additional information.
      </P>
      <RaisedButton
        text="YES, I'LL HELP! SIGN ME UP FOR GOLD (20% OFF)"
        data-testid="opt-in-yes"
        className="mt16 gold-plan"
        onClick={optInClickFactory('yes')}
      />
      <RaisedButton
        id="signup-link"
        text="NO THANKS, I'LL STAY SILVER (10% OFF)"
        className="mt8 mb24 silver-plan"
        onClick={optInClickFactory('no')}
      />
    </div>
  );
};

export default connect(mapStateToProps)(LabStudyStep);
