/* eslint-disable no-case-declarations */
import { MagicianSubProducts } from 'app/constants/Products';
import BasePriceCalculator from 'app/helpers/productSpecificPriceCalculators/basePriceCalculator';

// Key is a number of months
export const MAGICIAN_SUBSCRIPTION_MONTHLY_COST = {
  [MagicianSubProducts.Semaglutide]: {
    1: 329_99,
    3: 299_99,
    6: 269_99,
    12: 249_99,
  },
  [MagicianSubProducts.Tirzepatide]: {
    1: 449_99,
    3: 429_99,
    6: 409_99,
    12: 399_99,
  },
};

class MagicianPriceCalculator extends BasePriceCalculator {
  drugVariant: MagicianSubProducts;
  isOnboarding: boolean;
  crlFeatureEnabledEncloPregWeightLoss: boolean;

  MAGICIAN_LAB_COST = 69_99;
  LEGACY_MAGICIAN_LAB_COST = 65_00;

  constructor(
    includeBB: boolean,
    includeBBonAnotherProtocol: boolean,
    intakeName: string,
    useOwnLab: boolean,
    multiMonthPlan: 1 | 3 | 6 | 12,
    drugVariant: MagicianSubProducts,
    isOnboarding = false,
    crlFeatureEnabledEncloPregWeightLoss = false,
    subscriptionPaid = false,
    labPaid = false,
  ) {
    super(includeBB, includeBBonAnotherProtocol, intakeName, multiMonthPlan, useOwnLab, subscriptionPaid, labPaid);

    this.drugVariant = drugVariant;
    this.isOnboarding = isOnboarding;
    this.crlFeatureEnabledEncloPregWeightLoss = crlFeatureEnabledEncloPregWeightLoss;
  }

  monthlyCost = () => MAGICIAN_SUBSCRIPTION_MONTHLY_COST[this.drugVariant][this.multiMonthPlan];

  subscriptionCost() {
    if (this.subscriptionPaid) return 0;

    return (
      (MAGICIAN_SUBSCRIPTION_MONTHLY_COST[this.drugVariant][this.multiMonthPlan] +
        (this.includeBB ? this.BB_PRICE : 0)) *
      this.multiMonthPlan
    );
  }

  atHomeLabPrice() {
    return this.crlFeatureEnabledEncloPregWeightLoss ? this.MAGICIAN_LAB_COST : this.LEGACY_MAGICIAN_LAB_COST;
  }

  labCost = () => (this.isOnboarding && !this.labPaid && !this.useOwnLab ? this.atHomeLabPrice() : 0);

  totalDueTodayForCurrentProduct = () => this.subscriptionCost() + this.labCost();
}

export default MagicianPriceCalculator;
