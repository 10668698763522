import React from 'react';
import { P, H4 } from 'app/components/common/Typography';
import { connect } from 'react-redux';
import { push } from 'connected-react-router/immutable';
import * as actions from 'app/actions/customer';
import * as selectors from 'app/selectors/customer';
import { PrimaryButton } from 'app/components/common/Button';
import DumbServicesList from 'app/components/customer/DumbServicesList';
import SocialMedia from 'app/components/customer/SocialMedia';

const welcomeMessage = 'Welcome back,';
const Welcome = ({ name }) => (
  <H4>
    {welcomeMessage}{' '}
    <P color="primary" tagName="span" className="font-size-inherit font-weight-inherit">
      {name}.
    </P>
  </H4>
);

const mapStateToProps = (state) => ({
  onSuccessAction: push(selectors.selectNextStepPath(state)),
  intake: selectors.selectCurrentIntake(state),
  firstName: selectors.selectCustomerFirstName(state),
});

const SustainAcknowledgement = ({ firstName, onSuccessAction, step, intake, dispatch }) => {
  const onClick = () => {
    const action = actions.apiRequestUserCommand({
      cmdType: 'answer_questions',
      params: { [step]: true, intake: intake.get('name') },
      context: { onSuccessAction },
    });
    dispatch(action);
  };

  return (
    <div className="p16">
      <Welcome name={firstName} />
      <DumbServicesList noLab />
      <PrimaryButton className="mt16 mb24" data-testid="next-step" text="Get Started" onClick={onClick} />
      <div className="flex1" />
      <SocialMedia />
    </div>
  );
};

export default connect(mapStateToProps)(SustainAcknowledgement);
