import PriceFormatter from 'app/components/customer/steps/Payment/Generic/PriceFormatter';
import React from 'react';

type Props = {
  price: number;
};
const GenericOldPrice = ({ price }: Props) => (
  <div style={{ opacity: price > 0 ? 1 : 0 }} className="price_row">
    <div>&nbsp;</div>
    <div>
      <PriceFormatter price={price} className="payment_method__old_price" />
    </div>
  </div>
);

export default GenericOldPrice;
