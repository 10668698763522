import React, { useState, useEffect } from 'react';
import SetProductDialog from '@setproduct-ui/core/Dialog';
import { useLocation } from 'react-router-dom';
import { useAppDispatch } from 'app/helpers/hooks';
import { push } from 'connected-react-router/immutable';
import * as Routes from 'app/constants/Routes';
import '../css/ReferralSourceModal.scss';
import '../css/BillingIssueModal.scss';

const ModalTitle = () => <div className="referral-source-modal__title">Houston, we have a problem 🚀</div>;

const ModalBody = ({ message, setDialogOpen, dispatch }) => (
  <div className="referral-source-modal__body">
    <div className="referral-source-modal__content">
      <div className="referral-source-modal__options">
        <p>There is an unresolved billing issue on your account, but don’t sweat it: we’re here to help.</p>
        <p>
          Billing issue reason: <span className="billing-issue-message">"{message}"</span>
        </p>
        <p>To resolve the billing issue:</p>
        <ul>
          <li>
            Go to the{' '}
            <span
              className="billing-issue-link"
              onClick={() => {
                setDialogOpen(false);
                dispatch(push(Routes.BillingSettings));
              }}
            >
              shipping/billing preferences
            </span>{' '}
            page (located in Settings).
          </li>
          <li>Enter your updated card info and billing address (if different than shipping), and submit.</li>
          <li>Voila! You will receive immediate confirmation whether the new billing info was accepted.</li>
        </ul>
        <p>
          Please note: some billing issues may need to be resolved through your bank. For instance, you may need to
          contact your bank to ensure Maximus is recognized as an accepted entity to make charges.
        </p>
        <p>
          If you continue to have issues, let us know and one of our concierges will help you resolve the issue:{' '}
          <a href="mailto:support@maximustribe.com">support@maximustribe.com</a>
        </p>
      </div>
    </div>
  </div>
);

const BillingIssueModal = ({ message }) => {
  const [dialogOpen, setDialogOpen] = useState(true);
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname === Routes.BillingSettings) {
      setDialogOpen(false);
    }
  }, [pathname]);

  return (
    <SetProductDialog
      isOpen={dialogOpen}
      onClose={() => setDialogOpen(false)}
      title={<ModalTitle />}
      text={<ModalBody message={message} setDialogOpen={setDialogOpen} dispatch={dispatch} />}
      className="referral-source-modal billing-issue"
    />
  );
};

export default BillingIssueModal;
