import Alert from 'app/components/common/Alert';
import React, { FC } from 'react';

const ProductNotAvailableAlert: FC = () => (
  <Alert type="error" className="mb24 no-uppercase" noClose>
    <p className="herman_header">Product is not available in your state. Please choose a different product.</p>
  </Alert>
);

export default ProductNotAvailableAlert;
