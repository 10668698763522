import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';

import { PrimaryButton } from 'app/components/common/Button';
import { P } from 'app/components/common/Typography';
import { Login } from 'app/constants/Routes';

const BeenHereBefore: FC<{
  setShowLoginModal: (show: boolean) => void;
}> = ({ setShowLoginModal }) => {
  const history = useHistory();

  return (
    <div>
      <PrimaryButton text="LOGIN" onClick={() => history.push(Login)} type="button" />
      <a onClick={() => setShowLoginModal(false)}>
        <P color="primary" tagName="span" className="hover-underlined cookie-email-modal__create_account" large={false}>
          CREATE AN ACCOUNT
        </P>
      </a>
    </div>
  );
};

export default BeenHereBefore;
