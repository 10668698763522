import React, { useEffect } from 'react';
import { Routes } from 'app/routes';
import { useAppSelector, useAppDispatch } from 'app/helpers/hooks';
import { selectAuthentication, selectNotifyUser } from 'app/selectors/customer';
import { useSnackbar } from 'app/components/common/Snackbar';
import { notifyUser } from 'app/helpers/commandHelpers';
import { CustomerProps } from 'app/types';
import { initializeAmplitudeSessionReplay } from 'app/utils/initializeAmplitudeSessionReplay';

export const Fetch = ({ userProps }: { userProps: CustomerProps }) => {
  const dispatch = useAppDispatch();
  const authentication = useAppSelector(selectAuthentication);
  const notifyUserMessage = useAppSelector(selectNotifyUser);
  const { openSnackbar } = useSnackbar();

  useEffect(() => {
    if (userProps) {
      if (userProps.entity_type === 'customer') {
        const initialAction = {
          type: 'INIT_CUSTOMER',
          customer: userProps,
        };
        dispatch(initialAction);
      } else if (userProps.entity_type === 'apologize') {
        dispatch.apologize.init(userProps);
        dispatch({ type: 'INIT_UNAUTHENTICATED' });
      } else {
        const initialAction = {
          type: '@common/INIT_USERS',
          payload: userProps,
        };
        dispatch(initialAction);
      }
    } else {
      dispatch({ type: 'INIT_UNAUTHENTICATED' });
    }
  }, []);

  useEffect(() => {
    if (notifyUserMessage) {
      openSnackbar(notifyUserMessage);
      dispatch(notifyUser(null));
    }
  }, [notifyUserMessage]);

  useEffect(() => {
    initializeAmplitudeSessionReplay();
  }, []);

  if (!authentication) {
    return null;
  }

  return <Routes />;
};
