import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router';

const mapStateToProps = (state) => ({
  location: state.router.get('location').toJS(),
});

function PrivateRoute({ component: Component, allowed, redirectTo, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        allowed === true ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: redirectTo, state: { from: props.location } }} />
        )
      }
    />
  );
}

export default connect(mapStateToProps)(PrivateRoute);
