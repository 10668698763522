import { Cmd } from 'redux-loop';
import { pollRequest, apiRequest } from 'app/api';
import { apiRequestSuccess, apiRequestFailure } from 'app/helpers/commandHelpers';
import * as selectors from 'app/selectors/customerReducer';

export const pollCustomer = (state, { delay, nudge }) => {
  const customerId = selectors.selectCustomerId(state);
  const reqId = selectors.selectGReqId(state);

  return Cmd.run(pollRequest, {
    successActionCreator: apiRequestSuccess,
    failActionCreator: apiRequestFailure,
    args: [reqId, 'GET', `/api/customers/${customerId}/${nudge ? 'nudge' : ''}`, null, delay],
  });
};

export const apiRequestLoginWithEmailMaker = ({ params, context }) =>
  apiRequestGeneral({
    httpMethod: 'POST',
    path: '/api/session',
    params,
    context,
  });

export const apiRequestLoginWithPasswordMaker = ({ params, context }) =>
  apiRequestGeneral({
    httpMethod: 'POST',
    path: '/login',
    params,
    context,
  });

export const apiRequestLogoutMaker = (context) =>
  apiRequestGeneral({
    httpMethod: 'DELETE',
    path: '/api/session',
    context,
  });

export const apiRequestUserCommandMaker = (state, { type, params, context } = {}) => {
  const parentParams = {
    type,
    user_id: selectors.selectCustomerId(state),
    params,
  };

  return apiRequestCommandMaker({ params: parentParams, context });
};

export const apiRequestCommandMaker = ({ params, context }) =>
  apiRequestGeneral({
    httpMethod: 'POST',
    path: '/api/commands',
    params,
    context,
  });

export const apiRequestCustomersMaker = ({ params, context }) =>
  apiRequestGeneral({
    httpMethod: 'POST',
    path: '/api/customers',
    params,
    context,
  });

export const apiRequestPasswordMaker = ({ params, context }) =>
  apiRequestGeneral({
    httpMethod: 'POST',
    path: '/api/passwords',
    params,
    context,
  });

export const apiRequestPasswordResetMaker = ({ params, context }) =>
  apiRequestGeneral({
    httpMethod: 'POST',
    path: '/api/passwords/reset',
    params,
    context,
  });

export const apiRequestGuestMaker = ({ params, context }) =>
  apiRequestGeneral({
    httpMethod: 'POST',
    path: '/api/guests',
    params,
    context,
  });

export const apiRequestGuestEmailUniquenessValidator = ({ params, context }) =>
  apiRequestGeneral({
    httpMethod: 'POST',
    path: '/api/guests/email_uniqueness_validator',
    params,
    context,
  });

const apiRequestGeneral = ({ httpMethod, params, context, path }) =>
  Cmd.run(apiRequest, {
    successActionCreator: apiRequestSuccess,
    failActionCreator: apiRequestFailure,
    args: [42, httpMethod, path, params, context],
  });
