import React, { FC, ReactNode, useCallback, isValidElement } from 'react';

import cx from 'classnames';

import './css/HeadingWithIcon.scss';

const HeadingWithIcon: FC<{
  className?: string;
  icon: string | ReactNode;
  text?: ReactNode;
  iconUp?: string;
  iconRight?: string;
  tag?: any;
  textMargin?: string;
}> = ({ children, icon, text, iconUp, iconRight, tag: Tag, className, textMargin }) => {
  const textStyle =
    (textMargin && {
      marginLeft: textMargin,
      marginRight: textMargin,
    }) ||
    undefined;
  const mainClassNames = {
    'heading_with_icon': !iconUp,
    'heading_with_icon--up': iconUp,
  };

  const renderIcon = useCallback(() => {
    if (isValidElement(icon)) {
      return icon;
    }

    return <img src={icon as string} />;
  }, [icon]);

  return (
    <div className={cx(mainClassNames, className)}>
      {!iconRight && renderIcon()}
      <div className="heading_with_icon__text" style={textStyle}>
        {children || <Tag>{text}</Tag>}
      </div>
      {iconRight && renderIcon()}
    </div>
  );
};
export default HeadingWithIcon;
