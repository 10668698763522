import React, { useState } from 'react';
import Checkbox from 'app/components/common/Checkbox';
import { P } from 'app/components/common/Typography';
import { useFormikContext } from 'formik';
import SetProductDialog from '@setproduct-ui/core/Dialog/Dialog';
import GenericRefundPolicy from './GenericRefundPolicy';

const GenericSelfPay = () => {
  const [openRefundModal, setOpenRefundModal] = useState(false);
  const { values, setFieldValue } = useFormikContext<{
    selfPay: boolean;
  }>();
  const { selfPay } = values;

  const handleCheckboxChange = (e) => {
    setFieldValue('selfPay', e.target.checked);
  };

  return (
    <div className="flex mv32 flexAlignBaseLine relative">
      <Checkbox
        topAligned
        onChange={handleCheckboxChange}
        checked={selfPay}
        id="selfPay"
        testId="self-pay"
        name="selfPay"
      />
      <div>
        <P>
          I acknowledge that I have read, understood, and accept the{' '}
          <a onClick={() => setOpenRefundModal(true)}>Refund Policy</a> as well as{' '}
          <a rel="noreferrer" href="/self-pay-agreement" target="_blank">
            Patient Self-Pay Agreement
          </a>{' '}
          stating that the medical group does not participate in any insurance plans, including Medicare / Medicaid.
        </P>
      </div>

      <SetProductDialog
        isOpen={openRefundModal}
        title="Refund Policy"
        text={<GenericRefundPolicy onClose={() => setOpenRefundModal(false)} />}
        onClose={() => setOpenRefundModal(false)}
        className="manage_subscription__cancel_modal align-left"
      />
    </div>
  );
};

export default GenericSelfPay;
