import React, { FC, ReactNode, useState } from 'react';

import Question from 'app/constants/Question';
import { useField, useFormikContext } from 'formik';
import Radio from 'app/components/common/Radio';
import './css/SwitchableRadio.scss';
import { P } from 'app/components/common/Typography';

export const SingleRadioField: FC<{
  testId?: string;
  label: ReactNode;
  radioValue: string;
  className?: string;
  name: string;
}> = ({ testId, label, radioValue, className, name }) => {
  const [{ value }] = useField({ name });
  const { setFieldValue } = useFormikContext();

  return (
    <Radio
      checked={value === radioValue}
      label={label}
      testId={testId}
      className={className}
      onChange={() => {
        setFieldValue(name, radioValue);
      }}
    />
  );
};

const SwitchableRadioQuestion = ({ name, submit = true, ...rest }) => {
  const question = rest.question || new Question(name);

  const optionsByUnit = (u) => {
    return question.options.filter((o) => {
      return o.unit == u;
    });
  };

  const [field, , t] = useField({ id: question.name, name: question.name });
  const { value } = field;
  const units = question.data.switch_keys;
  const [unitIndex, setUnitIndex] = useState(optionsByUnit(units[1]).includes(value) ? 1 : 0);
  const unit = units[unitIndex];
  const { setFieldValue, submitForm } = useFormikContext();
  const revertedUnitIndex = unitIndex === 0 ? 1 : 0;

  const switchUnit = () => {
    setUnitIndex(revertedUnitIndex);
  };

  const handleChange = async (opt) => {
    await setFieldValue(question.name, opt.value);
    await t.setValue(opt.value);
    if (submit) {
      setTimeout(submitForm, 300);
    }
  };

  return (
    <div>
      <P className="mh16">
        Amounts are shown in {unit}{' '}
        <a className="switch_unit" onClick={switchUnit}>
          Use {units[revertedUnitIndex]} instead
        </a>
      </P>
      {optionsByUnit(unit)?.map((opt) => (
        <div key={opt.value as string} className="mh16 pointer" onClick={() => handleChange(opt)}>
          <Radio
            checked={opt.value === value}
            label={opt.label}
            testId={`question_${question.name}_option_${opt.value}`}
          />
        </div>
      ))}
    </div>
  );
};

export default SwitchableRadioQuestion;
