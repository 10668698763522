import React from 'react';
import { useAppDispatch, useAppSelector } from 'app/helpers/hooks';

import DashboardIcon from 'images/profile-menu/dashboard-icon.svg';
import SettingsIcon from 'images/profile-menu/settings-icon.svg';
import ContactUsIcon from 'images/profile-menu/contact-us-icon.svg';
import '../common/css/ProfileMenu.scss';
import { Dashboard, Settings, ContactUs } from 'app/constants/Routes';
import { logout } from 'app/actions/customer';
import { IPopupMenuEntry } from 'app/components/Header';
import ProfileMenu from 'app/components/common/ProfileMenu';
import { selectCustomerFullName, selectLatestPaymentMethodErrorMessage } from 'app/selectors/customer';

const CustomerMenu = () => {
  const customerName = useAppSelector(selectCustomerFullName);
  const paymentErrorMessage = useAppSelector(selectLatestPaymentMethodErrorMessage);
  const pages: IPopupMenuEntry[] = [
    {
      icon: DashboardIcon,
      title: 'Dashboard',
      url: Dashboard,
    },
    {
      icon: SettingsIcon,
      title: 'Settings',
      url: Settings,
      notification: !!paymentErrorMessage,
    },
    {
      icon: ContactUsIcon,
      title: 'Contact Us',
      url: ContactUs,
    },
  ];

  const dispatch = useAppDispatch();
  const logOut = () => dispatch(logout());

  return <ProfileMenu fullName={customerName} pages={pages} logOut={logOut} />;
};

export default CustomerMenu;
