export const getNumeric = (value) => value.replace(/\D/g, '');

export const zipCodeDisplayFormatter = (value) => {
  const numericString = getNumeric(value);
  if (!numericString) {
    return '';
  }

  return numericString.substr(0, 5);
};

export const phoneDisplayFormatter = (value) => {
  const numericString = getNumeric(value);
  if (!numericString) {
    return '';
  }
  const firstThree = numericString.substr(0, 3);
  const areaCode = `(${firstThree})`;
  const secondThree = numericString.substr(3, 3);
  if (!secondThree) {
    return areaCode;
  }
  const lastFour = numericString.substr(6, 4);
  if (!lastFour) {
    return `${areaCode}-${secondThree}`;
  }

  return `${areaCode}-${secondThree}-${lastFour}`;
};

export const ZIP_CODE_MASK = 'XXXXX';
export const PHONE_MASK = '(XXX)-XXX-XXXX';
