import { P } from 'app/components/common/Typography';
import WhiteButton from 'app/components/common/WhiteButton';
import React from 'react';

const ModalBodySubscription = ({ setOpenModalSubscription, subscriptionPeriodDays = 30 }) => (
  <>
    <P className="mb12">
      Your subscription is billed every <span className="bold">{subscriptionPeriodDays} days</span> and covers the cost
      of licensed doctor consultations, licensed psychologist-led coaching, and medications (if qualified).
    </P>

    <P className="mb12">
      Your subscription will automatically renew every <span className="bold">{subscriptionPeriodDays} days</span> until
      you a cancel. You may cancel your subscription at any time by visiting the Settings page.
    </P>

    <P className="mb12">
      Maximus may change the subscription price from time to time, which will take effect at the start of your next
      subscription period. You have the right to reject any price change by canceling your subscription prior to the
      change. Your cancelation will go into effect at the next billing cycle as long as you have paid for all medication
      received.
    </P>

    <P className="mb24">
      For more information, read our{' '}
      <a href="/terms-of-use" target="_blank">
        Terms of Use
      </a>
      .
    </P>

    <WhiteButton text="Close" onClick={() => setOpenModalSubscription(false)} />
  </>
);

export default ModalBodySubscription;
