import { getTracking as getTrackingOriginal, amazon, dhl, ontrac, s10, ups, usps, fedex } from 'ts-tracking-number';

// NOTE: Reorder couriers, because some of USPS detected as FedEx
const couriers = [amazon, dhl, ontrac, s10, ups, usps, fedex];

export const getTracking = (number: string | undefined, courier: string | undefined) => {
  const normalizedCourier = courier?.toLowerCase();
  let courierArray = couriers;

  // eslint-disable-next-line default-case
  switch (normalizedCourier) {
    case 'ups':
      courierArray = [ups];
      break;
    case 'amazon':
      courierArray = [amazon];
      break;
    case 'dhl':
      courierArray = [dhl];
      break;
    case 'ontrac':
      courierArray = [ontrac];
      break;
    case 'usps':
      courierArray = [usps];
      break;
    case 'fedex':
      courierArray = [fedex];
      break;
  }

  return getTrackingOriginal(number?.replace(/ /g, '') as string, courierArray);
};
