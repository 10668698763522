import React from 'react';

export const CustomDialogCloseIcon = ({ className = '', onClose }) => (
  <i className={`modal-close-icon ${className}`} onClick={onClose}>
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2 12.5C2 18.0228 6.47715 22.5 12 22.5C17.5228 22.5 22 18.0228 22 12.5C22 6.97715 17.5228 2.5 12 2.5C6.47715 2.5 2 6.97715 2 12.5Z"
        fill="#EEF0FF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.9393 16.607L15.2929 16.9605L16 16.2534L15.6464 15.8998L12.4769 12.7303L15.6464 9.56066L16 9.20711L15.2929 8.5L14.9393 8.85355L11.7698 12.0231L8.60017 8.85355L8.24661 8.5L7.53951 9.20711L7.89306 9.56066L11.0626 12.7303L7.89306 15.8998L7.53951 16.2534L8.24661 16.9605L8.60017 16.607L11.7698 13.4374L14.9393 16.607Z"
        fill="#0C1442"
      />
    </svg>
  </i>
);
