import { Link } from 'react-router-dom';
import Logo from 'images/Crow.svg';
import MaximusImage from 'images/maximus.svg';
import LoginMenu from 'images/Login-Menu.svg';
import React from 'react';
import * as Routes from 'app/constants/Routes';
import SubHeader from './SubHeader';

const OpaqueHeader = ({ onMenuClick, loggedIn, redirect, logout, children }) => (
  <header>
    <div className="header">
      <div className="header__content">
        <Link to={loggedIn ? Routes.Dashboard : Routes.Login}>
          <div className="header__logo">
            <img className="header__logo__crown" src={Logo} />
            <img src={MaximusImage} />
          </div>
        </Link>
        <div className="header__menu-items">
          {!loggedIn && (
            <>
              <div className="white">Already have an account?</div>
              <div>
                <a className="header__logout_button" onClick={() => redirect(Routes.Login)}>
                  SIGN IN
                </a>
              </div>
            </>
          )}
          {loggedIn && !children && (
            <>
              <a onClick={() => redirect(Routes.Dashboard)}>DASHBOARD</a>
              <a onClick={() => redirect(Routes.Settings)}>SETTINGS</a>
              <a className="header__logout_button" onClick={logout}>
                SIGN OUT
              </a>
            </>
          )}
          {loggedIn && children}
        </div>
      </div>
      <div className="clickable h56 ph24" onClick={onMenuClick}>
        <div className="clickable__menu">
          <img src={LoginMenu} />
        </div>
        <div className="clickable__logo">
          <img src={Logo} />
        </div>
      </div>
    </div>
    {loggedIn && <SubHeader />}
  </header>
);

export default OpaqueHeader;
