import React, { useState } from 'react';
import Radio from 'app/components/common/Radio';
import { P } from 'app/components/common/Typography';
import { useFormikContext, Field } from 'formik';
import Select from 'react-select';
import cx from 'classnames';
import { AvailableProducts } from 'app/constants/Products';
import ProductImage from 'app/components/customer/steps/Payment/king/ProductImage';
import { useAppDispatch, useAppSelector } from 'app/helpers/hooks';
import { selectCurrentIntake } from 'app/selectors/customer';
import { apiRequestUserCommand } from 'app/actions/customer';

const options = [
  {
    label: '4 doses',
    value: 4,
  },
  {
    label: '8 doses',
    value: 8,
  },
  {
    label: '12 doses',
    value: 12,
  },
];

const daily_strength_mapper = {
  '2_5_mg': '2.5 mg',
  '5_mg': '5.0 mg',
};

type Props = {
  changeable?: boolean;
  saveDataOnChange?: boolean;
  changeProductPosition?: string;
  className?: string;
};

const ProductHeader = ({
  saveDataOnChange = false,
  changeable = true,
  changeProductPosition = 'bottom',
  className = '',
}: Props) => {
  const dispatch = useAppDispatch();

  const [showDoseChange, setShowDoseChange] = useState(false);
  const { setFieldValue, values } = useFormikContext<any>();

  const handleDailyStrengthChange = (value) => {
    const params = { number_of_doses: null, daily_strength: value };

    saveDataOnChange && onChange(params);
    setFieldValue('number_of_doses', params.number_of_doses);
    setFieldValue('daily_strength', params.daily_strength);
  };
  const handleNumberOfDosesChange = (value) => {
    const params = { number_of_doses: value, daily_strength: null };

    saveDataOnChange && onChange(params);
    setFieldValue('number_of_doses', params.number_of_doses);
    setFieldValue('daily_strength', params.daily_strength);
  };

  const intake = useAppSelector(selectCurrentIntake);

  const onChange = (params: any) => {
    const action = apiRequestUserCommand({
      cmdType: 'select_lover_product',
      params: { ...params, intake: intake.get('name') },
    });
    dispatch(action);
  };

  const does_dropdown = (
    <div>
      <P className="paragraph">Doses per 30 days</P>
      <Field name="number_of_doses">
        {({ meta }) => (
          <Select
            className={cx('select', {
              '-red': meta.error,
            })}
            classNamePrefix="select"
            value={options.find((_) => _.value === values.number_of_doses)}
            options={options}
            isSearchable={false}
            onChange={(v) => handleNumberOfDosesChange(v?.value)}
          />
        )}
      </Field>
    </div>
  );

  const productChangeButton = () => {
    return (
      <span
        data-testid="change-lab"
        onClick={() => setShowDoseChange(true)}
        className="generic_payment_page__change_subs"
      >
        Change Product
      </span>
    );
  };

  return (
    <div className={className}>
      {changeProductPosition === 'top' && changeable && <div className="mb12">{productChangeButton()}</div>}
      <ProductImage productName={AvailableProducts.Lover} subProductName={''} className="mb36" />
      <div>
        <h4 className="subtitle mt16 mb16">Vardenafil + Tadalafil</h4>
        {!showDoseChange && (
          <div>
            <div className="flex">
              <div>
                {values.daily_strength && (
                  <h6 className="subtitle">Daily use - {daily_strength_mapper[values.daily_strength]}</h6>
                )}
                {values.number_of_doses && <h6 className="subtitle">As Needed - 10mg</h6>}
              </div>
              {changeProductPosition === 'bottom' && changeable && productChangeButton()}
            </div>
            {values.number_of_doses && does_dropdown}
          </div>
        )}
      </div>

      {showDoseChange && (
        <div>
          <div className="lover_treatment_plan__option">
            <Radio
              className="no-borders top-aligned"
              onChange={() => handleDailyStrengthChange('2_5_mg')}
              checked={'2_5_mg' === values.daily_strength}
              label="Daily Use - 2.5mg"
            />
          </div>
          <div className="lover_treatment_plan__option">
            <Radio
              className="no-borders top-aligned"
              onChange={() => handleDailyStrengthChange('5_mg')}
              checked={'5_mg' === values.daily_strength}
              label="Daily Use - 5.0mg"
            />
          </div>
          <div className="lover_treatment_plan__option">
            <Radio
              checked={!!values.number_of_doses}
              onChange={() => handleNumberOfDosesChange(4)}
              className="no-borders top-aligned"
              label="As Needed - 10mg"
            />
            {!!values.number_of_doses && <div className="ml36 mt4">{does_dropdown}</div>}
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductHeader;
