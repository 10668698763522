import { createElement, ElementType, HTMLAttributes } from 'react';
import DOMPurify from 'dompurify';

export { marketingTrack } from './marketingTracking';

export * from './isEmbededBrowser';

export async function hotjarIdentity(userId: string) {
  const { hj } = window as any;
  if (hj) {
    hj('identify', userId, {});
  }
}

type SanitizeOptions = {
  html: string;
  config?: DOMPurify.Config;
  as?: ElementType;
};

const sanitize = ({ html, config }: Pick<SanitizeOptions, 'html' | 'config'>) => ({
  __html: DOMPurify.sanitize(html, {
    ALLOWED_TAGS: ['b', 'i', 'em', 'strong', 'a', 'br'],
    ALLOWED_ATTR: ['href'],
    ...config,
  }),
});

export const SanitizeHTML = ({ html, config, as = 'div', ...props }: SanitizeOptions & HTMLAttributes<HTMLElement>) =>
  createElement(as, { dangerouslySetInnerHTML: sanitize({ html, config }), ...props });

// analytics.alias(userId, [previousId], [options], [callback]);
export async function segmentAlias(userId: string) {
  const { analytics } = window as any;
  if (analytics) {
    analytics.alias(userId);
  }
}
