import React, { useCallback, useContext, useMemo, useState, useEffect } from 'react';
import { useAppSelector } from 'app/helpers/hooks';
import { push } from 'connected-react-router/immutable';
import * as Routes from 'app/constants/Routes';
import cx from 'classnames';
import qs from 'qs';
import {
  selectCustomer,
  selectCustomerId,
  selectCustomerProductNames,
  selectCustomerProducts,
} from 'app/selectors/customer';
import { apiRequestPromiseViaDispatch } from 'app/api';
import { ProductContext } from '../Product/context';

import './css/ProductSelector.scss';
import { isProductHasPendingActions } from '../Product/provider';
import protocolNames from 'app/utils/protocolNames';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { selectUser } from 'app/selectors/admin';

const ProductHeader = () => {
  const location = useLocation();
  const { product: queryProduct } = qs.parse(location.search.replace(/^\?/, ''));
  const productCtx = useContext(ProductContext)!;

  useEffect(() => {
    if (queryProduct) {
      productCtx.change(queryProduct as any);
    }
  }, [queryProduct, productCtx]);

  const userProducts = useAppSelector(selectCustomerProductNames);
  const customer = useAppSelector(selectCustomer);
  const customerProducts = useAppSelector(selectCustomerProducts);
  const customerId = useAppSelector(selectCustomerId);
  // TODO: Remove once PRESCRIPTIONS_V2_ENABLED feature flag has been removed
  const userImm = useAppSelector(selectUser);
  const prescriptions_v2_enabled = !!userImm?.get('prescriptions_v2_enabled');

  const dispatch = useDispatch();
  const [isPending, setIsPending] = useState(false);
  const flags = customer.get('flags');

  const isCreateKingAvailable = flags.includes('create_king_product_available');
  const isCreateLoverAvailable = flags.includes('create_lover_product_available');
  const isCreateWarriorAvailable = flags.includes('create_warrior_product_available');
  const isCreateMagicianAvailable = flags.includes('create_magician_product_available');
  const productsAvailableForCreate = useMemo(
    () =>
      [
        isCreateKingAvailable && 'king',
        isCreateLoverAvailable && 'lover',
        isCreateWarriorAvailable && 'warrior',
        isCreateMagicianAvailable && 'magician',
      ].filter((item) => typeof item === 'string') as string[],
    [isCreateKingAvailable, isCreateLoverAvailable, isCreateWarriorAvailable, isCreateMagicianAvailable],
  );

  const createNewProduct = useCallback(
    async (productName) => {
      setIsPending(true);

      return apiRequestPromiseViaDispatch({
        dispatchFn: dispatch,
        path: '/api/commands',
        body: {
          type: 'create_product',
          user_id: customerId,
          params: {
            product_name: productName,
          },
        },
        onSuccessFn: () => {
          productCtx.change(productName);
          setIsPending(false);
          dispatch(push(Routes.ContinueOnboarding));
        },
      });
    },
    [customerId, productCtx, dispatch],
  );

  if (userProducts?.length < 2 && !productsAvailableForCreate.length) {
    return null;
  }

  return (
    <div className="subheader subheader_products">
      <div className="subheader__content">
        <div className="product-selector">
          {userProducts.map((product) => (
            <a
              key={product}
              className={cx('product-selector__product', `product-${product}`, {
                'active': product === productCtx.selectedProduct,
                'pending-actions': isProductHasPendingActions(
                  customer,
                  customerProducts.get(product) as any,
                  product,
                  prescriptions_v2_enabled,
                ),
              })}
              href={`?product=${product}`}
            >
              {protocolNames[product]}
            </a>
          ))}
          {productsAvailableForCreate.map((product) => (
            <button
              key={`new-${product}`}
              type="button"
              className={`product-selector__product new product-${product}`}
              onClick={() => createNewProduct(product)}
              disabled={isPending}
            >
              {protocolNames[product]}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProductHeader;
