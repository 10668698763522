import React, { FC, ReactNode } from 'react';
import { isString } from 'lodash';
import './css/TitleWithIcon.scss';

const TitleWithIcon: FC<{
  icon: string | ReactNode;
  badge?: number;
  className?: string;
}> = ({ icon, badge, children, className }) => (
  <div className={`title_with_icon ${className}`}>
    {isString(icon) ? <img src={icon} /> : icon}
    {!!badge && badge > 0 && <span className="icon-badge">{badge}</span>}
    <div className="title-contents">{children}</div>
  </div>
);

export default TitleWithIcon;
