import React, { useState } from 'react';

import Card from '@setproduct-ui/core/Card';
import Divider from '@setproduct-ui/core/Divider';
import Typography from '@setproduct-ui/core/Typography';

import { H6, P } from 'app/components/common/Typography';
import HeadingWithIcon from 'app/components/common/HeadingWithIcon';
import './css/ShippingAddress.scss';
import PageHeader from 'app/components/common/PageHeader';

import { connect } from 'react-redux';

import { push } from 'connected-react-router/immutable';
import * as selectors from 'app/selectors/customer';
import { apiRequestUserCommand } from 'app/actions/customer';
import { Form, Formik } from 'formik';
import { ShippingFields } from 'app/components/customer/steps/Shipping';
import { PrimaryButton, SecondaryButton } from 'app/components/common/Button';
import { ShippingSchemaWithoutFullName } from 'app/helpers/validators';

import ShippingIcon from 'images/Icon-Shipping.svg';
import IconArrowDown from 'images/Icon-Arrow-Down.svg';
import IconArrowRight from 'images/Icon-Arrow-Right.svg';
import BackNextFooter from './BackNextFooter';

const mapStateToProps = (reduxState) => {
  const onSuccessAction = push(selectors.selectNextStepPath(reduxState));
  const previousStepAction = push(selectors.selectPreviousStepPath(reduxState));
  const shippingAddress = selectors.selectCustomerShippingAddress(reduxState);
  const phone_number = selectors.selectCustomer(reduxState).get('phone_number') || '';
  const { city = '', address_line_1 = '', address_line_2 = '', state = '', postal_code = '' } = shippingAddress.toJS();
  const initialValues = shippingAddress.merge({
    address_line_1,
    address_line_2,
    city,
    state,
    postal_code,
  });

  return {
    onSuccessAction,
    shippingAddress,
    previousStepAction,
    phone_number,
    initialValues,
  };
};

const ShippingCard = ({
  dispatch,
  previousStepAction,
  initialValues,
  onSuccessAction,
  shippingAddress,
  phone_number,
}) => {
  const [expandedForm, setExpandedForm] = useState(false);
  const { address_line_1, address_line_2, city, state: shippingState, postal_code } = shippingAddress.toJS();
  const line1 = `${address_line_1}.`;
  const line2 = `${address_line_2}.`;
  const line3 = `${city}, ${shippingState} ${postal_code}`;
  const icon = expandedForm ? IconArrowDown : IconArrowRight;
  const redirectPrevious = () => dispatch(previousStepAction);
  const onClick = () => {
    dispatch(
      apiRequestUserCommand({
        cmdType: 'answer_questions',
        params: {
          intake: 'follow_up',
          follow_up_shipping_confirmation: true,
        },
        context: { onSuccessAction },
      }),
    );
  };
  const line2Component = (
    <P type="body_bold" className="mh16" data-testid="line2">
      {line2}
    </P>
  );

  const onSubmit = (values) => {
    const params = {
      ...values,
      phone_number,
    };
    const action = apiRequestUserCommand({
      params,
      context: {},
      cmdType: 'update_shipping_address',
    });
    dispatch(action);
    setExpandedForm(false);
  };

  return (
    <>
      <div className="flex1">
        <div className="flow-root">
          <PageHeader className="ph16 pv12">
            <HeadingWithIcon icon={ShippingIcon}>
              <H6>Shipping Address</H6>
            </HeadingWithIcon>
          </PageHeader>
          <Card className="mh16 mt16 mb8" view="raised">
            <P className="mh16 mt12">
              Please confirm the address we have on file. We will use it to ship any medications and tests indicated by
              your clinician.
            </P>
            {!expandedForm && (
              <>
                <P type="body_bold" className="mh16 mt12">
                  {line1}
                </P>
                {address_line_2 && line2Component}
                <P type="body_bold" className="mh16">
                  {line3}
                </P>
                <Divider className="mt12" />
              </>
            )}

            <div className="clickable mb12 mh16 mt12" onClick={() => setExpandedForm(!expandedForm)}>
              <HeadingWithIcon icon={icon}>
                <Typography type="button_text" color="primary">
                  Update Shipping Address
                </Typography>
              </HeadingWithIcon>
            </div>
            {expandedForm && (
              <Formik
                initialValues={initialValues.toJS()}
                validationSchema={ShippingSchemaWithoutFullName}
                onSubmit={onSubmit}
              >
                {({ isSubmitting, handleSubmit }) => (
                  <Form>
                    <ShippingFields />
                    <div className="flex mh16">
                      <SecondaryButton
                        text="Cancel"
                        onClick={() => setExpandedForm(!expandedForm)}
                        className="mv24 mr16"
                        disabled={isSubmitting}
                      />
                      <PrimaryButton
                        type="button"
                        text="Submit"
                        onClick={handleSubmit}
                        className="mv24"
                        disabled={isSubmitting}
                      />
                    </div>
                  </Form>
                )}
              </Formik>
            )}
          </Card>
        </div>
      </div>
      <BackNextFooter onNext={onClick} onBack={redirectPrevious} nextDisabled={expandedForm} />
    </>
  );
};

export default connect(mapStateToProps)(ShippingCard);
