import React, { FC } from 'react';
import cx from 'classnames';

import { SecondaryButton, PrimaryButton } from 'app/components/common/Button';
import './css/BackNextFooter.scss';

interface Props {
  className?: string;
  buttonClassName?: string;
  backDisabled?: boolean;
  onBack?: () => void;
  nextDisabled?: boolean;
  onNext?: () => void;
  noNext?: boolean;
  noBack?: boolean;
  horizontalPadding?: boolean;
  nextText?: string;
  testId?: string;
}

const BackNextFooter: FC<Props> = ({
  className,
  buttonClassName,
  backDisabled,
  nextDisabled,
  onNext,
  onBack,
  noNext,
  noBack = false,
  nextText = 'Next',
  testId = 'next-step',
  horizontalPadding = true,
}) => {
  const nextButton = (
    <PrimaryButton
      className={buttonClassName}
      text={nextText}
      type="button"
      onClick={onNext}
      disabled={nextDisabled}
      data-testid={testId}
    />
  );

  return (
    <div
      className={cx('back-next mt24', className, {
        mh16: horizontalPadding,
      })}
    >
      {!noNext && nextButton}
      {!noBack && (
        <SecondaryButton
          className={cx(buttonClassName, 'mt8 mb16')}
          text="Back"
          onClick={onBack ?? (() => {})}
          disabled={backDisabled || !onBack}
          data-testid="back-to-previous-step"
        />
      )}
    </div>
  );
};

export default BackNextFooter;
