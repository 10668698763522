import React from 'react';

type Props = {
  price: number | string | undefined;
  className?: string;
  prefix?: string;
  smallDollarSign?: boolean;
};
const PriceFormatter = ({ price, className = '', prefix = '', smallDollarSign = false }: Props) => (
  <div className={className}>
    {prefix}
    {smallDollarSign && <sup>$</sup>}
    {!smallDollarSign && '$'}
    {price?.toString().split('.')[0]}
    <sup>
      {
        parseFloat(price as string)
          .toFixed(2)
          ?.split('.')[1]
      }
    </sup>
  </div>
);

export default PriceFormatter;
