import { FormikBag } from 'formik';
import { Action } from 'redux';
import { apiRequestFailure, apiRequestSuccess, notifyUser } from 'app/helpers/commandHelpers';
import { SnackbarTypes } from 'app/components/common/Snackbar';
import { ApiRequestStatus, ApiRequestMessage } from 'app/constants/apiRequests';
import { NOOP } from './actions/customer';

/* global navigator */
export function pollRequest<C>(
  reqId: number,
  method: RequestInit['method'],
  path: string,
  body: RequestInit['body'],
  delay: number,
  context: C,
) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(apiRequest(reqId, method, path, body, context));
    }, delay);
  });
}

export const getLocation = <T>(context?: T) =>
  new Promise<{ location: GeolocationPosition; context?: T }>((resolve, reject) => {
    if (!navigator?.geolocation?.getCurrentPosition) {
      reject({
        error: {
          code: 1,
          message: 'Geolocation is not supported',
        },
        context,
      });
    }
    navigator.geolocation.getCurrentPosition(
      (location) => resolve({ location, context }),
      (error) => reject({ error, context }),
      { maximumAge: 1000 * 60 * 10, timeout: 3000, enableHighAccuracy: false },
    );
  });

export function fileUpload<C>(reqId: number, path: string, body: RequestInit['body'], context?: C) {
  const request = {
    method: 'POST',
    data: body,
    path,
    context,
  };

  return fetch(path, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'X-CSRF-Token': getCSRF(),
    },
    body,
  })
    .then(async (resp) => {
      const { status } = resp;
      let json = undefined;

      try {
        json = await resp.json();
      } catch (e) {
        // ignore
      }

      return {
        reqId,
        status,
        context,
        json,
        request,
      };
    })
    .catch((err) => ({
      reqId,
      err,
      request,
    }));
}

export async function apiRequest<C>(
  reqId: number | string,
  method: RequestInit['method'],
  path: string,
  body?: RequestInit['body'] | any,
  context?: C,
  signal?: RequestInit['signal'],
) {
  if (typeof fetch === 'undefined') {
    return { reqId, err: 'NO FETCH IN TEST' };
  }
  const request = {
    method,
    path,
    body,
    context,
  };
  const resp = await fetch(path, {
    method,
    redirect: 'follow',
    headers: {
      'Accept': 'application/json',
      'Content-type': 'application/json',
      'X-CSRF-Token': getCSRF(),
    },
    body: body && JSON.stringify(body),
    signal,
  }).catch(({ message }) => ({
    reqId,
    message,
    request,
  }));

  if ('message' in resp) {
    throw resp;
  }
  const { status } = resp;

  try {
    const json = await resp.json();

    return {
      reqId,
      status,
      json,
      context,
      request,
    };
  } catch {
    return {
      reqId,
      status,
      context,
      request,
    };
  }
}

interface ApiRequestResult extends Response {
  parsedJson?: any;
  nonSuccess?: ApiRequestStatus;
}

export async function apiRequestPromise<Context>(
  method: RequestInit['method'],
  path: string,
  body?: RequestInit['body'] | any,
  signal?: RequestInit['signal'],
  context?: Context,
) {
  const resp = (await fetch(path, {
    method,
    redirect: 'follow',
    headers: {
      'Accept': 'application/json',
      'Content-type': 'application/json',
      'X-CSRF-Token': getCSRF(),
    },
    body: body && JSON.stringify(body),
    signal,
  }).catch(() => ({
    nonSuccess: ApiRequestStatus.Failure,
  }))) as ApiRequestResult;

  if (resp.status === 400) {
    resp.nonSuccess = ApiRequestStatus.Problem;
  } else if (resp.status > 400 && resp.status < 500) {
    resp.nonSuccess = ApiRequestStatus.ClientError;
  } else if (resp.status >= 500) {
    resp.nonSuccess = ApiRequestStatus.ServerError;
  }

  let json;
  try {
    json = await resp.json();
  } catch (e) {
    resp.nonSuccess = resp.nonSuccess || ApiRequestStatus.ClientError;
  }

  if (resp.nonSuccess) {
    resp.parsedJson = json;
    throw resp;
  }

  return {
    status: resp.status,
    json,
    context,
    request: {
      method,
      path,
      body,
      context,
    },
  };
}

export function apiRequestPromiseViaDispatch({
  dispatchFn,
  method = 'POST',
  path,
  body = null,
  onSuccessAction,
  onSuccessFn,
  onErrorAction,
  onErrorFn,
  form,
}: {
  method?: Parameters<typeof apiRequestPromise>[0];
  path: Parameters<typeof apiRequestPromise>[1];
  body?: Parameters<typeof apiRequestPromise>[2];
  dispatchFn: (action: Action) => void;
  onSuccessAction?: Action;
  onSuccessFn?: (resp: { reqId?: number | string; status: number; json: any; context: unknown; request: any }) => void;
  onErrorAction?: Action;
  onErrorFn?: (resp: ApiRequestResult) => void;
  form?: FormikBag<object, object>;
}) {
  form?.setErrors({});

  return apiRequestPromise(method, path, body)
    .then((resp) => {
      dispatchFn(apiRequestSuccess(resp));
      if (onSuccessAction) dispatchFn(onSuccessAction);
      if (onSuccessFn) onSuccessFn(resp);
    })
    .catch((resp) => {
      dispatchFn(apiRequestFailure(resp));

      let message;
      const nonClientError = [ApiRequestStatus.Failure, ApiRequestStatus.Problem, ApiRequestStatus.ServerError].find(
        (s) => s === resp?.nonSuccess,
      );
      if (nonClientError) {
        // no internet, 400, 500+
        message = ApiRequestMessage[nonClientError];
      } else if (resp.nonSuccess === ApiRequestStatus.ClientError) {
        // 401-499
        const errors = resp.parsedJson?.errors || { api: resp.parsedJson?.message || ApiRequestMessage.clientError };
        if (form) {
          form.setErrors(errors);
        } else {
          message = errors.api;
        }
      }

      if (message) dispatchFn(notifyUser({ message, type: SnackbarTypes.Error }));
      if (onErrorAction) dispatchFn(onErrorAction);
      if (onErrorFn) onErrorFn(resp);

      return { type: NOOP };
    });
}

export function getCSRF() {
  const element = document.querySelector("meta[name='csrf-token']");

  return element?.getAttribute('content') || '';
}
