import { backendLog } from 'app/utils/LogFrontendEvents';
import { NOTIFY_USER } from 'app/actions/customer';
import { SnackbarProps } from 'app/components/common/Snackbar';
type RequestResponseContext = { logger_prefix?: string };

export function apiRequestSuccess(
  this: RequestResponseContext | void,
  {
    json,
    reqId,
    context,
    status,
    request,
  }: {
    reqId?: number | string;
    json: any;
    context?: any;
    status: number;
    request: any;
  },
) {
  if (status === 200) {
    return { type: 'API_REQUEST_SUCCESS', json, reqId, ...context };
  }
  if (status === 400) {
    return { type: 'API_REQUEST_PROBLEMS', json, reqId, context };
  }
  const logger_prefix = (this as RequestResponseContext)?.logger_prefix
    ? `${(this as RequestResponseContext).logger_prefix}/`
    : '';
  if (status !== 403) {
    // 403 is not an error, it's a normal response for unauthorized requests
    backendLog(`${logger_prefix}api_request_errors`, { reqId, context, status, json, request });
  }

  return { type: 'API_REQUEST_ERRORS', reqId, context, status };
}

export function apiRequestFailure(this: RequestResponseContext | void, { message, reqId, request }) {
  const logger_prefix = (this as RequestResponseContext)?.logger_prefix
    ? `${(this as RequestResponseContext).logger_prefix}/`
    : '';
  backendLog(`${logger_prefix}api_request_failure`, { reqId, message, request });

  return {
    type: 'API_REQUEST_FAILED',
    message,
    reqId,
  };
}

export function notifyUser(payload: SnackbarProps | null) {
  return {
    type: NOTIFY_USER,
    payload,
  };
}
