import React from 'react';

import './css/ContactUs.scss';

const ContactUs = () => {
  const openLiveChat = () => {
    try {
      window.Intercom('showSpace', 'messages');
    } catch {}
  };

  return (
    <div className="contact-us">
      <div className="top">
        <div className="wrapper">
          <h1>Contact Us</h1>
          <p>We’re here to help.</p>
        </div>
      </div>
      <div className="wrapper">
        <div className="options">
          <div className="options__item">
            <div className="options__top">
              <h2>Help Center</h2>
              <p>Your question may have already been answered.</p>
              <p>
                Go to support.maximustribe.com to search our help center for <u>quick answers</u>.
              </p>
            </div>
            <div className="options__info">
              <a target="_blank" href="https://support.maximustribe.com" rel="noreferrer">
                Help Center
              </a>
            </div>
          </div>
          <div className="options__item">
            <div className="options__top">
              <h2>Medical Questions</h2>
              <p>
                If your question is medical in nature (related to your health, your response to Maximus protocols,
                etc.), please log in to Elation Patient Passport to message your Maximus doctor.
              </p>
            </div>
            <div className="options__info">
              <a target="_blank" href="https://maximustribe.com/passport" rel="noreferrer">
                Passport
              </a>
              <p>
                if you are experiencing an emergency, seek emergency care. <b>Do not use Patient Passport.</b>
              </p>
            </div>
          </div>
          <div className="options__item">
            <div className="options__top">
              <h2>Concierge Team</h2>
              <p>
                If you would like to contact a member of the Maximus concierge team, please contact us using one of the
                following options:
              </p>
              <p>
                Live Chat (fastest): <a onClick={openLiveChat}>Click here</a>
                <br />
                Email:{' '}
                <a target="_blank" href="mailto:support@maximustribe.com" rel="noreferrer">
                  support@maximustribe.com
                </a>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
              </p>
            </div>
          </div>
        </div>
        <div className="discord">
          <h2>Join the Maximus Tribe on Discord</h2>
          <p>
            Get the most out of your Maximus Protocols by joining the{' '}
            <a target="_blank" href="https://maximustribe.com/discord" rel="noreferrer">
              Maximus Discord community (click here to join)
            </a>
            , where you and other members can share experiences and hold one another accountable as you strive to
            achieve your goals.
          </p>
          <p>
            Make sure to join the #⚔health-coaching channel and post at least one health behavior you would like to
            work on. Led by a trained clinical psychologist, the community will lift you up to become the man you were
            meant to be.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
