import Alert from 'app/components/common/Alert';
import React, { FC } from 'react';

const OutOfServiceAlert: FC<{ validStates: string[] }> = ({ validStates }) => (
  <Alert type="error" className="mb24 no-uppercase" noClose>
    <h5 className="bold">OUT OF SERVICE AREA</h5>
    <p className="mt16">Unfortunately your shipping address is in a state Maximus is not yet licensed to service.</p>
    <p className="mt16">
      Maximus is currently licensed in: <b className="bold">{validStates.join(', ') || 'No states'}</b>
    </p>
    <p className="mt16">
      Once within our licensed service area you may resume onboarding. If you have questions contact us at:{' '}
      <a className="white underlined" href="mailto:support@maximustribe.com">
        support@maximustribe.com
      </a>
    </p>
  </Alert>
);

export default OutOfServiceAlert;
