/* eslint-disable no-case-declarations */
import BasePriceCalculator from 'app/helpers/productSpecificPriceCalculators/basePriceCalculator';

class WarriorPriceCalculator extends BasePriceCalculator {
  constructor(includeBB: boolean, includeBBonAnotherProtocol: boolean, intakeName: string) {
    super(includeBB, includeBBonAnotherProtocol, intakeName);
  }

  monthlyCost() {
    return 199_99;
  }

  subscriptionCost() {
    if (this.subscriptionPaid) return 0;

    if (this.includeBB) {
      return 249_98;
    }
    return 199_99;
  }

  totalDueTodayForCurrentProduct() {
    if (!this.intakeName.includes('onboarding')) {
      return 0;
    }

    return this.subscriptionCost();
  }
}

export default WarriorPriceCalculator;
