import React from 'react';
import TriangleAlert from 'images/Icon-Triangle-Alert.svg';
import Card from '@setproduct-ui/core/Card';
import HeadingWithIcon from 'app/components/common/HeadingWithIcon';
import { P } from 'app/components/common/Typography';

const ErrorCard = ({ errorTitle, errorText, textColor = 'danger', className = 'danger-card mh16 mt16' }) => (
  <Card className={className} view="raised">
    <HeadingWithIcon textMargin="16px" icon={TriangleAlert} iconUp className="m16">
      <P color={textColor} colorStep={60}>
        {!errorTitle ? null : <span className="bold">{errorTitle}</span>}
        {errorText}
      </P>
    </HeadingWithIcon>
  </Card>
);
export default ErrorCard;
