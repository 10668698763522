import React from 'react';
import DrugsBottle from 'images/sub-products/lover/lover-drugs-smaller.png';
import { useFormikContext } from 'formik';
import {
  LOVER_SUBSCRIPTION_MONTHLY_COST,
  LOVER_SUBSCRIPTION_MONTHLY_COST_VARIANT_2,
} from 'app/helpers/productSpecificPriceCalculators/loverPriceCalculator';
import { AvailableProducts } from 'app/constants/Products';
import GenericMultimonthPlanSelectionForm from 'app/components/customer/steps/Payment/Generic/GenericMultimonthPlanSelectionForm';
import ProductHeader from 'app/components/customer/steps/Payment/lover/ProductHeader';
import { useExperiment } from 'app/utils/useExperiment';

const LoverMultimonthPlanSelectionForm = ({ handleChangeProduct, saveDataOnChange }) => {
  const { values } = useFormikContext<{
    number_of_doses: number;
    daily_strength: string;
  }>();

  const bloodflow_variant_2 =
    useExperiment('bloodflow_multimonth', 'bloodflow_multimonth_variation_0') === 'bloodflow_multimonth_variation_2';

  const priceConfig = bloodflow_variant_2 ? LOVER_SUBSCRIPTION_MONTHLY_COST_VARIANT_2 : LOVER_SUBSCRIPTION_MONTHLY_COST;

  const selectedSubProduct = values.daily_strength || values.number_of_doses?.toString();

  const multimonthConfig = [
    {
      value: 12,
      specialLabel: 'Best Value',
      subheader: 'Bills every 12 months',
      title: 'Yearly Plan',
    },
    {
      value: 3,
      specialLabel: 'Most Popular',
      subheader: 'Bills every 3 months',
      title: 'Quarterly Plan',
    },
    {
      value: 1,
      subheader: 'Bills every month',
      title: 'Monthly Plan',
    },
  ];

  return (
    <GenericMultimonthPlanSelectionForm
      productName={AvailableProducts.Lover}
      selectedSubProduct={selectedSubProduct}
      multimonthConfig={multimonthConfig}
      handleChangeProduct={handleChangeProduct}
      showBreadcrumbs={true}
      drugsImage={DrugsBottle}
      priceConfig={priceConfig}
      productHeader={<ProductHeader className={'payment_card'} saveDataOnChange={saveDataOnChange} />}
    />
  );
};

export default LoverMultimonthPlanSelectionForm;
