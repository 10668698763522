import React, {
  CSSProperties,
  ComponentProps,
  ComponentPropsWithRef,
  ComponentType,
  FC,
  ReactNode,
  StyleHTMLAttributes,
} from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Type from './_type.module.css';
import View from './_view.module.css';
import Color from '../../styles/color.module.css';

import { ThemeContext } from '../ThemeContext';

interface TypographyProps {
  type?: string;
  font?: string;
  large?: boolean;
  small?: boolean;
  className?: string;
  colorStep?: '' | 0 | 5 | 10 | 20 | 30 | 40 | 50 | 60 | 70 | 80 | 90 | 100;
  color?:
    | 'default'
    | 'primary'
    | 'warning'
    | 'danger'
    | 'success'
    | 'primary_alt'
    | 'warning_alt'
    | 'danger_alt'
    | 'success_alt';
  dense?: boolean;
  theme?: 'black' | 'blue';
  tagName?: keyof JSX.IntrinsicElements;
  children?: ReactNode;
  style?: CSSProperties;
  onClick?: (e: React.MouseEvent) => void;
}

const Typography = React.forwardRef(function Typography(props: TypographyProps, ref) {
  const {
    type = 'body',
    color = 'default',
    tagName = 'div',
    font = 'inter',
    colorStep = '100',
    large = false,
    small = false,
    className,
    ...restProps
  } = props;

  const Tag = tagName as any;

  return (
    <ThemeContext.Consumer>
      {({ isDark }) => (
        <Tag
          {...restProps}
          ref={ref as any}
          className={cx(
            Type[type],
            Type[font],
            large && Type['large'],
            small && Type['small'],
            isDark ? View['color' + colorStep + '-dark'] : View['color' + colorStep],
            Color[color],
            className,
          )}
        />
      )}
    </ThemeContext.Consumer>
  );
});

export const VALID_TYPES = [
  'body',
  'body_bold',
  'button_text',
  'overline',
  'caption',
  'inherit',
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
];

Typography.propTypes = {
  /**
   `The type of the component text.
   * Variants: `body` `button_text` `overline` `caption` `h1` `h2` `h3` `h4` `h5` `h6`
   * Default value (if undefined): `body` `
   */
  type: PropTypes.oneOf(VALID_TYPES),
  /**
   ` The step of the component color from "0" to "100".
   * Default value (if undefined): `100` `
   */
  colorStep: PropTypes.oneOf(['', 0, 5, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]),
  /**
  ` The color of the component.
   * Variants: `primary` `warning` `danger` `success` `primaryAlt` `warningAlt` `dangerAlt` `successAlt`
   * Default value (if undefined): `default` `
   */
  color: PropTypes.oneOf([
    'default',
    'primary',
    'warning',
    'danger',
    'success',
    'primary_alt',
    'warning_alt',
    'danger_alt',
    'success_alt',
  ]),
  /**
   * Dense size
   */
  dense: PropTypes.bool,
  /**
  ` The name of the theme.
   * Variants: `black` `blue`
   * Default value (if undefined): `black` `
   */
  theme: PropTypes.oneOf(['black', 'blue']),
};

export default Typography as FC<TypographyProps>;
