import React, { FC, ReactNode } from 'react';
import cx from 'classnames';
import { H6 } from 'app/components/common/Typography';
import './css/PageHeader.scss';

const PageHeader: FC<{
  title: ReactNode;
  className?: string;
}> = ({ title, className, children }) => (
  <div className={cx('page-header', className)}>
    {!title && children}
    {title && <H6>{title}</H6>}
  </div>
);

export default PageHeader;
