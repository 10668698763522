import { Link } from 'react-router-dom';
import Logo from 'images/Crow.svg';
import MaximusImage from 'images/maximus.svg';
import LoginMenu from 'images/Login-Menu.svg';
import React from 'react';
import * as Routes from 'app/constants/Routes';
import { isStagingOrLower } from 'app/helpers/env';

const TransparentHeader = ({ onMenuClick }) => (
  <header className="header">
    <div className="header__content">
      <Link to={Routes.Login}>
        <div className="header__logo">
          <img className="header__logo__crown" src={Logo} />
          <img src={MaximusImage} />
        </div>
      </Link>
      <div className="header__menu-items">
        <div className="white">New Customer?</div>
        <div>
          <a
            href={isStagingOrLower() ? Routes.Registration : Routes.ProductSelection}
            className="header__logout_button"
          >
            GET STARTED
          </a>
        </div>
      </div>
    </div>
    <div className="clickable h56 ph24" onClick={onMenuClick}>
      <div className="clickable__menu">
        <img src={LoginMenu} />
      </div>
      <div className="clickable__logo">
        <img src={Logo} />
      </div>
    </div>
  </header>
);

export default TransparentHeader;
