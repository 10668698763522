import React, { useEffect, useState } from 'react';
import { TransitionChildren } from 'react-transition-group/Transition';

import { selectCurrentIntakeStepIndex } from 'app/selectors/customer';

import { Location } from 'app/types/index.d';
import { BaseAnimate } from './BaseAnimate';
import { useAppSelector } from 'app/helpers/hooks';

export enum AnimationTypes {
  Fade = 'fade',
  Slide = 'slide',
}

export enum AnimationSides {
  Static = '',
  Down = 'Down',
  DownBig = 'DownBig',
  Left = 'Left',
  LeftBig = 'LeftBig',
  Right = 'Right',
  RightBig = 'RightBig',
  Up = 'Up',
  UpBig = 'UpBig',
}

export enum AnimationDirections {
  Static = 'static',
  Horizontal = 'horizontal',
  HorizontalBig = 'horizontalBig',
  Vertical = 'vertical',
  VerticalBig = 'verticalBig',
}

type AnimateProps = {
  children: TransitionChildren;
  location: Location;
  className?: string;
};
export const Animate = ({ location, className, children }: AnimateProps) => {
  const currentStep = useAppSelector(selectCurrentIntakeStepIndex) as number;
  const [lastStep, setLastStep] = useState(currentStep);
  const [direction, setDirection] = useState(true);

  useEffect(() => {
    setDirection(currentStep > lastStep);
    setLastStep(currentStep);
  }, [currentStep]);

  return (
    <BaseAnimate forward={direction} frameKey={location.key} className={className}>
      {children}
    </BaseAnimate>
  );
};
