import { Event, EventHint } from '@sentry/types/types';
import type { ErrorEvent } from '@sentry/types/types/event';

function isFBNavigationError(event: Event): boolean {
  return /FBNav\w+/i.test(event.message || '');
}

function isWebSocketCloseUnclean(event: Event): boolean {
  if (!event.message) {
    return false;
  } else {
    return event.message.includes('WebSocket close was unclean');
  }
}

function isUnlabeledEvent(eventHint: EventHint): boolean {
  const exception: Record<string, any> = eventHint?.originalException as Record<string, any>;
  const errorMessage = exception ? (typeof exception === 'string' ? exception : exception?.message) : '';
  return !!errorMessage && /^unlabeled event$/.test(errorMessage);
}

export default function suppressInfoMessages(event: ErrorEvent, eventHint: EventHint): boolean {
  if (event.level !== 'info') return false;

  return isFBNavigationError(event) || isWebSocketCloseUnclean(event) || isUnlabeledEvent(eventHint);
}
