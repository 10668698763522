import React from 'react';
import DraggableComponent from 'app/utils/redesign/DraggableComponent';
import { useRedesign } from 'app/utils/redesign/RedesignProvider';

export const RedesignControl = ({ onChange }) => {
  const value = useRedesign();

  return (
    <DraggableComponent>
      <label className="redesign-switch">
        <input type="checkbox" onChange={(e) => onChange(e.target.checked)} checked={value} />
        <i></i>
        New Design
      </label>
      <style>
        {`
        .redesign-switch {
          display: inline-block;
          cursor: pointer;
          -webkit-tap-highlight-color: transparent;
        }
          .redesign-switch i {
          position: relative;
          display: inline-block;
          margin-right: .5rem;
          width: 23px;
          height: 13px;
          background-color: #e6e6e6;
          border-radius: 23px;
          vertical-align: text-bottom;
          transition: all 0.3s linear;
        }
          .redesign-switch i::before {
          content: "";
          position: absolute;
          left: 0;
          width: 21px;
          height: 11px;
          background-color: #fff;
          border-radius: 11px;
          transform: translate3d(2px, 2px, 0) scale3d(1, 1, 1);
          transition: all 0.25s linear;
        }
          .redesign-switch i::after {
          content: "";
          position: absolute;
          left: 0;
          width: 11px;
          height: 11px;
          background-color: #fff;
          border-radius: 6px;
          box-shadow: 0 2px 2px rgba(0, 0, 0, 0.24);
          transform: translate3d(2px, 2px, 0);
          transition: all 0.2s ease-in-out;
        }
          .redesign-switch:active i::after {
          width: 14px;
          transform: translate3d(2px, 2px, 0);
        }
        .redesign-switch:active input:checked + i::after { transform: translate3d(8px, 1px, 0); }
        .redesign-switch input { display: none; }
        .redesign-switch input:checked + i { background-color: #4BD763; }
        .redesign-switch input:checked + i::before { transform: translate3d(9px, 1px, 0) scale3d(0, 0, 0); }
        .redesign-switch input:checked + i::after { transform: translate3d(11px, 1px, 0); }
      `}
      </style>
    </DraggableComponent>
  );
};
