import { useAppDispatch, useAppSelector } from 'app/helpers/hooks';
import * as selectors from 'app/selectors/customer';
import {
  selectCurrentIntake,
  selectCustomer,
  selectCustomerBillingAddress,
  selectCustomerFlags,
  selectCustomerShippingAddress,
  selectNextStepPath,
} from 'app/selectors/customer';
import React, { useContext, useState } from 'react';
import { Formik } from 'formik';
import { BillingSchema } from 'app/helpers/validators';
import Spinner from 'app/components/customer/Spinner';
import { Redirect } from 'react-router';
import GenericPaymentForm from 'app/components/customer/steps/Payment/Generic/GenericPaymentForm';
import PaymentContextProvider from 'app/components/customer/steps/Payment/PaymentContext/provider';
import { PaymentContext } from 'app/components/customer/steps/Payment/PaymentContext/context';
import * as actions from 'app/actions/customer';
import { reportBrowserProblem } from 'app/actions/customer';
import { AvailableProducts, KingV2SubProducts, MagicianSubProducts } from 'app/constants/Products';

const shippingAndBillingDiffer = (shippingAddress, billingAddress) => {
  if (!billingAddress || !shippingAddress) {
    return false;
  }

  const shippingKeys = Object.keys(shippingAddress.toJS());
  const billingsKeys = Object.keys(billingAddress.toJS());
  if (shippingKeys.length !== billingsKeys.length) {
    return true;
  }
  for (const key of shippingKeys) {
    if (!billingAddress.get(key)) {
      return true;
    }

    if (shippingAddress.get(key) !== billingAddress.get(key)) {
      return true;
    }
  }
  return false;
};

const GenericPaymentStep = () => {
  const dispatch = useAppDispatch();
  const paymentCtx = useContext(PaymentContext)!;

  const customer = useAppSelector(selectCustomer);
  const nextStepPath = useAppSelector(selectNextStepPath);
  const shippingAddress = useAppSelector(selectCustomerShippingAddress);
  const flags = useAppSelector(selectCustomerFlags);
  const intake = useAppSelector(selectCurrentIntake);
  const billingAddress = useAppSelector(selectCustomerBillingAddress);
  const product = useAppSelector((state) =>
    selectors.selectCustomerProduct(state, selectors.selectCurrentIntakeProduct(state)),
  );
  const subProductChoice = product.get('opt_in_choice')?.get('sub_product_choice');
  const paymentSubmitted = product.get('payment_successful');
  const productName = intake.get('product_name');
  const warrior_strength =
    productName === AvailableProducts.Warrior ? intake.get('warrior_strength') || 'extra_strength_rx' : null;

  const labPrefix = productName === AvailableProducts.King ? '' : 'magician_';
  const use_own_lab = [AvailableProducts.King, AvailableProducts.Magician].includes(productName)
    ? customer.get(`${labPrefix}use_own_lab`)
    : false;

  const own_lab_file_uploaded = customer.get(`${labPrefix}own_lab_file_uploaded`);
  const [errorMessage, setErrorMessage] = useState('');
  const [optInParams, setOptInParams] = useState({ intake_name: intake.get('name') });
  const pending = flags.includes('pending_payment');

  const isOnboarding = intake.get('name').includes('onboarding');
  const currentPaymentMethod = customer.get('payment_method');
  const intakePaymentSuccessful = intake.get('billing_step_completed');
  const redirectCriteria = isOnboarding ? paymentSubmitted : intakePaymentSuccessful;

  const onSubmit = async (values, formHelper) => {
    try {
      // Update when apply to the rest of the protocols
      const {
        address_line_1,
        address_line_2,
        city,
        state,
        postal_code,
        include_supplement,
        use_own_lab,
        lab_kit_type,
        drug_variant,
        strength_variant,
        daily_strength,
        number_of_doses,
      } = values;
      let params = {
        address_line_1,
        address_line_2,
        city,
        postal_code,
        strength_variant,
        drug_variant,
        state,
        warrior_strength,
        number_of_doses,
        daily_strength,
        lab_kit_type,
        intake_name: intake.get('name'),
        include_supplement: !!include_supplement,
        async: true,
        use_own_lab: !!use_own_lab,
      };
      if (!currentPaymentMethod?.get('card_last4')) {
        let result;
        if (!values.applePayPaymentMethod) {
          const full_name = `${customer.get('first_name')} ${customer.get('last_name')}`;
          result = await paymentCtx.createPaymentMethod({ ...values, full_name });
        } else {
          result = values.applePayPaymentMethod;
        }

        const onboardingOnly = {
          card_brand: result.cardBrand,
          card_last4: result.cardLastFour,
          payment_method_id: result.paymentMethodId,
          payment_method_source: result.paymentMethodSource || paymentCtx.paymentProvider,
          ...optInParams,
        };
        params = { ...params, ...onboardingOnly };
      }
      const action = actions.apiRequestUserCommand({ cmdType: 'opt_in_to_intake_payment', params });
      dispatch(action);
    } catch (e) {
      dispatch(
        reportBrowserProblem({
          error: { code: 1 },
          problemType: `${paymentCtx.paymentProvider}_failed`,
          details: e,
        }),
      );
      setErrorMessage(e instanceof Error ? e.message : 'Error create payment method');

      return;
    } finally {
      formHelper.setSubmitting(false);
    }
  };

  let initialLoverDose: string | null = null;
  if (intake.get('product_name') === AvailableProducts.Lover && !product.get('opt_in_choice')) {
    initialLoverDose = '5_mg';
  }

  const initialShippingAddress = {
    address_line_1: '',
    address_line_2: '',
    city: '',
    state: '',
    full_name: '',
    postal_code: '',
    ...shippingAddress?.toJS(),
  };

  const initialValues = {
    ...initialShippingAddress,
    selfPay: false,
    refundPolicy: false,
    drug_variant: product.get('opt_in_choice')?.get('drug_variant') || MagicianSubProducts.Semaglutide,
    strength_variant: product.get('opt_in_choice')?.get('strength_variant'),
    daily_strength: subProductChoice?.get('daily_strength') ?? initialLoverDose,
    updateAddress: shippingAndBillingDiffer(shippingAddress, billingAddress),
    number_of_doses: subProductChoice?.get('number_of_doses') ?? null,
    include_supplement: intake.get('suggested_supplement_choice'),
    multimonth_plan: product.get('opt_in_choice')?.get('multimonth_plan') || 1,
    selected_king_v2_product:
      product.get('opt_in_choice')?.get('selected_king_v2_product') || KingV2SubProducts.EncloPregnolone,
    use_own_lab: use_own_lab,
    lab_kit_type: product.get('lab_kit_type'),
    own_lab_file_uploaded: own_lab_file_uploaded,
    applePayPaymentMethod: null,
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={BillingSchema}
      enableReinitialize={false}
      validateOnMount
    >
      {pending ? (
        <Spinner isCenter />
      ) : redirectCriteria ? (
        <Redirect to={nextStepPath} />
      ) : (
        <div className="mb24">
          <GenericPaymentForm errorMessage={errorMessage} setOptInParams={setOptInParams} isOnboarding={isOnboarding} />
        </div>
      )}
    </Formik>
  );
};

export default (props) => (
  <PaymentContextProvider>
    <GenericPaymentStep {...props} />
  </PaymentContextProvider>
);
