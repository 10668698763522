import { AvailableProducts } from 'app/constants/Products';
import React from 'react';
import KingLabWidget from 'app/components/customer/steps/Payment/king/KingLabWidget';
import MagicianLabWidget from 'app/components/customer/steps/Payment/magician/MagicianLabWidget';

const LabOnboardingWidget = ({
  product_name,
  setOpenModalAtHomeLab,
  use_own_lab,
  lab_kit_type,
  setFieldValue,
  own_lab_file_uploaded,
  inputFile,
  setOwnLabFile,
  setOpenModalOwnLab,
  priceCalculator,
  byolFormSubmitted,
  kingV2OralTrt,
  labSelectionStep,
}) => {
  const renderKingLabWidget = () => (
    <KingLabWidget
      setFieldValue={setFieldValue}
      setOpenModalAtHomeLab={setOpenModalAtHomeLab}
      kingV2OralTrt={kingV2OralTrt}
      setOpenModalOwnLab={setOpenModalOwnLab}
      inputFile={inputFile}
      setOwnLabFile={setOwnLabFile}
      labSelectionStep={labSelectionStep}
    />
  );

  const renderMagicianLabWidget = () => (
    <MagicianLabWidget
      setFieldValue={setFieldValue}
      setOwnLabFile={setOwnLabFile}
      priceCalculator={priceCalculator}
      use_own_lab={use_own_lab}
      lab_kit_type={lab_kit_type}
      setOpenModalAtHomeLab={setOpenModalAtHomeLab}
      setOpenModalOwnLab={setOpenModalOwnLab}
      own_lab_file_uploaded={own_lab_file_uploaded}
      byolFormSubmitted={byolFormSubmitted}
      inputFile={inputFile}
    />
  );

  // TODO: Refactor this to avoid usind products conditionals,
  // better to do it when magician prodocol will follow same principles as king
  return product_name === AvailableProducts.King ? renderKingLabWidget() : renderMagicianLabWidget();
};

export default LabOnboardingWidget;
