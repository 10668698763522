import React from 'react';
import DrugsBottle from 'images/sub-products/magician/magician-drugs.png';
import { useFormikContext } from 'formik';
import { MAGICIAN_SUBSCRIPTION_MONTHLY_COST } from 'app/helpers/productSpecificPriceCalculators/magicianPriceCalculator';
import { AvailableProducts, MagicianSubProducts } from 'app/constants/Products';
import GenericMultimonthPlanSelectionForm from 'app/components/customer/steps/Payment/Generic/GenericMultimonthPlanSelectionForm';
import { useAppSelector } from 'app/helpers/hooks';
import { selectCustomer } from 'app/selectors/customer';

const MagicianMultimonthPlanSelectionForm = ({ handleChangeProduct }) => {
  const { values } = useFormikContext<{
    multimonth_plan: number;
    drug_variant: string;
  }>();
  const selectedSubProduct = values.drug_variant || MagicianSubProducts.Semaglutide;
  const customer = useAppSelector(selectCustomer);
  const tirzAvailable = customer.get('tirzepatide_available_for_customers');

  const savingsConfig = {
    [MagicianSubProducts.Semaglutide]: {
      3: '360.00',
      6: '720.00',
      12: '960.00',
    },
    [MagicianSubProducts.Tirzepatide]: {
      3: '240.00',
      6: '480.00',
      12: '600.00',
    },
  };

  const multimonthConfig = [
    {
      value: 12,
      specialLabel: 'Best Value',
      savings: `$${savingsConfig[selectedSubProduct][12]} yearly savings`,
      subheader: 'Bills every 12 months',
      title: 'Yearly Plan',
    },
    {
      value: 6,
      specialLabel: 'Most Popular',
      savings: `$${savingsConfig[selectedSubProduct][6]} yearly savings`,
      subheader: 'Bills every 6 months',
      title: 'Semiannual Plan',
    },
    {
      value: 3,
      savings: `$${savingsConfig[selectedSubProduct][3]} yearly savings`,
      subheader: 'Bills every 3 months',
      title: 'Quarterly Plan',
    },
    {
      value: 1,
      subheader: 'Bills every month',
      title: 'Monthly Plan',
    },
  ];

  return (
    <GenericMultimonthPlanSelectionForm
      productName={AvailableProducts.Magician}
      selectedSubProduct={selectedSubProduct}
      multimonthConfig={multimonthConfig}
      handleChangeProduct={handleChangeProduct}
      showBreadcrumbs={tirzAvailable}
      drugsImage={DrugsBottle}
      priceConfig={MAGICIAN_SUBSCRIPTION_MONTHLY_COST}
    />
  );
};

export default MagicianMultimonthPlanSelectionForm;
