import React from 'react';
import { useField, useFormikContext } from 'formik';
import CommonTextArea from '../TextArea';

const TextArea = ({ id, name, ...restProps }) => {
  const [{ value }] = useField({ id, name });
  const { setFieldValue } = useFormikContext();
  const onChange = ({ target: { value } }) => setFieldValue(name, value);

  return <CommonTextArea {...restProps} onChange={onChange} value={value} testId={name} />;
};

export default TextArea;
