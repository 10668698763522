import React, { FC } from 'react';
import cx from 'classnames';
import LogOutIcon from 'images/profile-menu/log-out-icon.svg';
import { Menu, MenuItem } from '@blueprintjs/core';
import { Popover2 } from '@blueprintjs/popover2';
import { useHistory } from 'react-router-dom';
import { IMenuAddon, IPopupMenuEntry } from 'app/components/Header';
import AvatarLion from '../common/AvatarLionIcon';

import './css/ProfileMenu.scss';

const ProfileMenu: FC<{
  fullName: string;
  pages: IPopupMenuEntry[];
  logOut: () => void;
  role?: string;
  addons?: IMenuAddon[];
}> = ({ role, fullName, logOut, pages, addons }) => {
  const history = useHistory();

  return (
    <div className="profile-menu">
      {addons?.map((a, i) => <React.Fragment key={i}>{a.component}</React.Fragment>)}
      <Popover2
        content={
          <Menu className="profile-menu_popup-menu">
            {pages.map((p) => (
              <MenuItem
                key={p.title}
                href={p.url}
                icon={<img src={p.icon} alt={p.title} />}
                text={p.title}
                onClick={
                  p.raw
                    ? undefined
                    : (e) => {
                        e.preventDefault();
                        history.push(p.url);
                      }
                }
                className={cx({ 'profile-menu__notification': p.notification })}
              />
            ))}
            <MenuItem icon={<img src={LogOutIcon} alt="Sign Out" />} text="Sign Out" onClick={logOut} />
          </Menu>
        }
        position="bottom-right"
      >
        <div className="profile-menu-user profile-menu_item">
          <div className="profile-menu-user_info">
            <div className="profile-menu-user_name">{fullName}</div>
            {role && <div className="profile-menu-user_role">{role}</div>}
          </div>
          <div
            className={cx('profile-menu-user_avatar', {
              'profile-menu-user_avatar-notification': pages.some((page) => page.notification),
            })}
          >
            <AvatarLion />
          </div>
        </div>
      </Popover2>
    </div>
  );
};

export default ProfileMenu;
