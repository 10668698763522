import { List, Map, Record as ImmutableRecord } from 'immutable';
import { indexBy } from 'lodash/fp';
import { createSelector } from 'reselect';
import { RootState, Selector } from 'app/configureStore';
import { BaseIntake, CustomerUser, CustomerUserImm } from 'app/types/admin/customerUser';
import { ImmutableMap, ImmutableMapping } from 'app/types/admin';
import { AvailableProducts } from 'app/constants/Products';

export const ONBOARDING_TYPES = ['onboarding', 'lover_onboarding', 'warrior_onboarding', 'magician_onboarding'];
export const FOLLOW_UP_TYPES = ['follow_up'];
export const SUSTAIN_TYPES = ['king_sustain'];

export const defProduct = (_state: RootState, product: string | AvailableProducts | null) => product;
export const selectAuthentication = (state: RootState) => state.common.get('authentication');
export const selectNotifyUser = (state: RootState) => state.common.get('notifyUser');
export const selectCustomer: Selector<CustomerUserImm> = (state) => state.customer.get('customer');
export const selectCustomerId: Selector<CustomerUser['id']> = (state) => state.customer.getIn(['customer', 'id']);
export const selectCustomerIsProspect: Selector<CustomerUser['is_prospect']> = (state) =>
  state.customer.getIn(['customer', 'is_prospect']);
export const selectCustomerEmail: Selector<CustomerUser['email']> = (state) =>
  state.customer.getIn(['customer', 'email']);
export const selectCustomerFirstName: Selector<CustomerUser['first_name']> = (state) =>
  state.customer.getIn(['customer', 'first_name']);
export const selectCustomerLastName: Selector<CustomerUser['last_name']> = (state) =>
  state.customer.getIn(['customer', 'last_name']);
export const selectCustomerFlags: Selector<CustomerUser['flags']> = (state) =>
  state.customer.getIn(['customer', 'flags'], List());
export const selectCustomerQuestionnaire: Selector<ImmutableRecord<CustomerUser['questionnaire']>> = (state) =>
  state.customer.getIn(['customer', 'questionnaire']);
export const selectUploadFileFormError = (state) => state.getIn(['form', 'upload_file_form', 'asyncErrors', 'error']);

export const selectCustomerReferralSources: Selector<CustomerUser['questionnaire']['referral_sources']> = (state) =>
  state.customer.getIn(['customer', 'questionnaire', 'referral_sources']);
export const selectCustomerReferralSourcesOptions: Selector<List<CustomerUser['referral_sources_options']>> = (state) =>
  state.customer.getIn(['customer', 'referral_sources_options']);

export const selectCustomerShippingAddress: Selector<ImmutableMap<CustomerUser['shipping_address']>> = (state) =>
  state.customer.getIn(['customer', 'shipping_address'], Map());
export const selectCustomerBillingAddress: Selector<CustomerUser['shipping_address']> = (state) =>
  state.customer.getIn(['customer', 'billing_address'], Map());
export const selectCustomerPhoneNumber: Selector<CustomerUser['phone_number']> = (state) =>
  state.customer.getIn(['customer', 'phone_number'], '');
export const selectGeolocationDenied: Selector<CustomerUser['geolocationDenied']> = (state) =>
  state.customer.getIn(['geolocationDenied']);

export const selectCustomerQAdam: Selector<CustomerUser['qadam']> = (state) =>
  state.customer.getIn(['customer', 'qadam'], Map());
export const selectCustomerLabOrders: Selector<ImmutableMapping<CustomerUser['lab_orders']>> = (state) =>
  state.customer?.getIn(['customer', 'lab_orders']);

export const selectRegistrationLocation: Selector<CustomerUser['registration_location']> = (state) =>
  state.customer.getIn(['customer', 'registration_location']);
export const selectSelfReportedPosition = (state) =>
  state.customer.getIn(['customer', 'answers', 'self_reported_position']);

export const selectCustomerProducts: Selector<ImmutableMapping<CustomerUser['maximus_products']>> = (state) =>
  state.customer.getIn(['customer', 'maximus_products']);
export const selectCustomerProductNames = createSelector(
  selectCustomerProducts,
  (products) => products.filter(Boolean).keySeq().toArray() as AvailableProducts[],
);
export const selectCustomerProduct = createSelector([selectCustomer, defProduct], (customer, product) =>
  customer.getIn(['maximus_products', product]),
);
export const selectProductFlags = createSelector(
  [selectCustomerProduct, defProduct],
  (product) => product?.get('flags') || [],
);

export const selectCustomerProductIntakes = createSelector(
  selectCustomerProduct,
  (product) => product?.get('intakes') || (List() as List<BaseIntake>),
);

export const selectCustomerKingProduct = (s) => selectCustomerProduct(s, 'king');

export const selectCustomerAllIntakes: Selector<List<ImmutableMap<BaseIntake>>> = createSelector(
  selectCustomer,
  (customer) =>
    customer
      .get('maximus_products')
      .valueSeq()
      .map((_) => _ && ((_ as any).get('intakes') as List<ImmutableMap<BaseIntake>>))
      .flatten(1)
      .filter((_) => !!_)
      .toList() as any,
);

export const selectCustomerProductOnboarding = createSelector(selectCustomerProductIntakes, (intakes) =>
  intakes.find((i) => ONBOARDING_TYPES.includes(i.get('type'))),
);

export const selectCustomerProductOnboardingSteps = createSelector(
  selectCustomerProductOnboarding,
  (onboarding) => onboarding?.get('steps') || List(),
);
export const selectCustomerProductOnboardingQuestionnaire = createSelector(
  selectCustomerProductOnboarding,
  (onboarding) => onboarding?.get('questionnaire') || Map(),
);

export const selectCustomerAllIntakesByName: (state: RootState) => Record<string, ImmutableMap<BaseIntake>> =
  createSelector(selectCustomerAllIntakes, (intakes) => indexBy((i) => i.get('name') as string, intakes.toArray()));
export const selectCustomerKingIntakes = createSelector(
  selectCustomerKingProduct,
  (product) => product?.get('intakes') || List(),
);

export const selectCustomerKingOnboardingSteps = (state) =>
  selectCustomerProductOnboardingSteps(state, AvailableProducts.King);

export const selectCustomerKingOnboardingQuestionnaire = (state) =>
  selectCustomerProductOnboardingQuestionnaire(state, AvailableProducts.King);

export const selectKingFollowUpIntake = createSelector(
  selectCustomerAllIntakesByName,
  (intakesByName) => intakesByName.follow_up,
);

export const selectKingActiveSustainIntake = createSelector(selectCustomerKingIntakes, (intakes) =>
  intakes.find((intake) => intake.get('type') === 'king_sustain' && intake.get('enabled')),
);

export const selectProductLabOrderResults = createSelector(
  selectCustomerProduct,
  (product) => product?.get('lab_order_results') || List(),
);

export const selectHasPassword = (state) => state.customer.getIn(['customer', 'has_password']);

export const selectCustomerDismissedNotifications = (state) =>
  selectCustomer(state).get('dismissed_notifications', List());
