import React, { FC } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Map } from 'immutable';
import { Formik, FormikProps, Form } from 'formik';
import cx from 'classnames';
import { useAppSelector } from 'app/helpers/hooks';
import { ImmutableMap } from 'app/types/admin';
import { RootState } from 'app/configureStore';
import { CustomerShippingAddress } from 'app/types';
import Input, { InputMask } from 'app/components/common/formik/Input';
import Spinner from 'app/components/customer/Spinner';
import { Dropdown as BlackDropdown } from 'app/components/common/formik/Dropdown';
import * as selectors from 'app/selectors/customer';
import { clearAddressFormErrors } from 'app/actions/customer';
import { zipCodeDisplayFormatter, ZIP_CODE_MASK, PHONE_MASK, phoneDisplayFormatter } from 'app/helpers/formatters';
import { ShippingSchema } from 'app/helpers/validators';
import PaymentCard from 'app/components/customer/steps/Payment/PaymentCard';
import OutOfServiceAlert from 'app/components/customer/steps/Payment/OutOfServiceAlert';
import '../../css/ShippingAddress.scss';
import { Checkbox } from 'app/components/common/formik/Checkbox';
import { P } from 'app/components/common/Typography';
import { apiRequestPromiseViaDispatch } from 'app/api';
import axios from 'axios';
import ProductNotAvailableAlert from 'app/components/customer/steps/Payment/ProductNotAvailableAlert';
import { selectCurrentIntake } from 'app/selectors/customer';
import { BaseIntake, MaximusProduct } from 'app/types/admin/customerUser';

export interface ShippingValues extends CustomerShippingAddress {
  phone_number: string;
  receive_sms: boolean;
}

const mapStateToProps = (reduxState: RootState) => {
  const shippingAddress = selectors.selectCustomerShippingAddress(reduxState) || Map();
  const customer = selectors.selectCustomer(reduxState);
  const phone_number = customer.get('phone_number') || '';
  const receive_sms = !!customer.get('receive_sms');
  const { city = '', address_line_1 = '', address_line_2 = '', state = '', postal_code = '' } = shippingAddress.toJS();
  const initialFullName = selectors.selectCustomerFullName(reduxState);
  const initialValues = shippingAddress.merge({
    full_name: initialFullName,
    address_line_1,
    address_line_2,
    city,
    receive_sms,
    state,
    postal_code,
    phone_number: phoneDisplayFormatter(phone_number),
  });
  const initialErrors = reduxState.customer.getIn(['forms', 'update_address_form', 'errors'], {});

  return {
    initialErrors,
    initialValues,
  };
};
export const ShippingFields: FC<{
  disabled?: boolean;
  labels?: boolean;
  className?: string;
  inputDefaultClassName?: string;
  handleStateSelection: (option: string) => void;
}> = ({ disabled, className, labels = true, inputDefaultClassName = 'mt12', handleStateSelection }) => {
  return (
    <>
      <div className={inputDefaultClassName}>
        <Input
          id="address_line_1"
          name="address_line_1"
          label={labels ? 'Street Line 1' : null}
          placeholder="Street 1"
          disabled={disabled}
          className={className}
        />
      </div>
      <div className={inputDefaultClassName}>
        <Input
          id="address_line_2"
          name="address_line_2"
          label={labels ? 'Street Line 2' : null}
          placeholder="Street 2"
          disabled={disabled}
          className={className}
        />
      </div>
      <div className={cx(inputDefaultClassName, 'flex')}>
        <div className="flex1">
          <Input
            id="city"
            name="city"
            label={labels ? 'City' : null}
            placeholder="City"
            className={className}
            disabled={disabled}
          />
        </div>
      </div>
      <div className={inputDefaultClassName} />
      <div className={cx(inputDefaultClassName, 'flex')}>
        <div className="flex1">
          <BlackDropdown
            id="state"
            name="state"
            disabled={disabled}
            className={className}
            showLabel={labels}
            onStateChange={handleStateSelection}
          />
        </div>
        <div className="flex1">
          <InputMask
            id="postal_code"
            name="postal_code"
            label={labels ? 'ZIP' : null}
            displayFormatter={zipCodeDisplayFormatter}
            mask={ZIP_CODE_MASK}
            inputMode="numeric"
            className={className}
            disabled={disabled}
          />
        </div>
      </div>
    </>
  );
};
export interface IShippingStep {
  initialValues: ImmutableMap<ShippingValues>;
  initialErrors: Record<keyof ShippingValues, string>;
  shippingFormRef: any;
  productPharmacyValid: boolean;
  titleClasses?: string;
  setProductPharmacyValid: (submitted: boolean) => void;
  setShippingFormValid: (submitted: boolean) => void;
  productFromParam?: ImmutableMap<MaximusProduct>;
  intakeFromParam?: ImmutableMap<BaseIntake>;
}
const GenericShippingForm = ({
  initialValues,
  initialErrors,
  shippingFormRef,
  productPharmacyValid,
  productFromParam,
  intakeFromParam,
  setProductPharmacyValid,
  setShippingFormValid,
  titleClasses = 'title_with_line xl mt48 mv16',
}: IShippingStep) => {
  const product =
    productFromParam ||
    useAppSelector((state) => selectors.selectCustomerProduct(state, selectors.selectCurrentIntakeProduct(state)));
  const pharmacyProductName = product?.get('pharmacy_product_name');
  const validStates = useAppSelector(selectors.selectValidStates);
  const customerId = useAppSelector(selectors.selectCustomerId);
  const intake = intakeFromParam || useAppSelector(selectCurrentIntake);
  const intakeName = intake?.get('name');
  const dispatch = useDispatch();

  const onSubmit = async (values: ShippingValues, form) =>
    apiRequestPromiseViaDispatch({
      dispatchFn: dispatch,
      path: '/api/commands',
      body: {
        type: 'update_shipping_address',
        user_id: customerId as string,
        params: {
          ...values,
          phone_number: values.phone_number.replace(/\D/g, ''),
          intake: intakeName,
        },
      },
      onErrorFn: (errors) => {
        Object.entries(errors?.parsedJson?.errors).forEach((entry) => {
          const [key, value] = entry;
          form.setFieldError(key, value as string);
        });
      },
      form,
    });

  const handleStateSelection = async (option: string) => {
    const response = await axios.get('/api/product_pharmacy/product_pharmacy_availability', {
      params: {
        pharmacy_product_name: pharmacyProductName,
        state: option,
      },
    });
    const { product_pharmacy_available } = response.data;
    setProductPharmacyValid(!!product_pharmacy_available && validStates.includes(option));
  };

  return (
    <>
      <h2 className={`${titleClasses} animated-deep-hidden`}>Shipping Address</h2>
      <Formik
        initialValues={initialValues.toJS()}
        validationSchema={ShippingSchema}
        onSubmit={onSubmit}
        innerRef={shippingFormRef}
        validateOnMount={true}
      >
        {({ isSubmitting, values, isValid, setFieldValue }: FormikProps<ShippingValues>) => {
          setShippingFormValid(isValid);
          return (
            <Form>
              {values?.state && !validStates.includes(values.state) && <OutOfServiceAlert validStates={validStates} />}
              {values?.state && !productPharmacyValid && <ProductNotAvailableAlert />}
              <PaymentCard className="animated-deep">
                {isSubmitting ? (
                  <Spinner isCenter />
                ) : (
                  <>
                    <ShippingFields handleStateSelection={handleStateSelection} />
                    <div className="mt12 mt16 mb24">
                      <InputMask
                        id="phone_number"
                        name="phone_number"
                        onKeyUp={() => dispatch(clearAddressFormErrors())}
                        label="Phone"
                        displayFormatter={phoneDisplayFormatter}
                        mask={PHONE_MASK}
                        inputMode="tel"
                        initialError={initialErrors.phone_number}
                      />
                    </div>
                    <Checkbox
                      topAligned
                      label={
                        <>
                          <P style={{ fontSize: '10px' }}>
                            I consent to receive text/SMS messages from Maximus, including important updates related to
                            my active subscriptions. I understand I can opt-out at any time by replying STOP to these
                            messages. I understand message and data rates may apply.
                          </P>
                          <P style={{ fontSize: '10px' }}>
                            Please refer to our{' '}
                            <a href="/terms-of-use" target="_blank" style={{ fontSize: '10px' }}>
                              Terms and Privacy Policy
                            </a>
                          </P>
                        </>
                      }
                      testId="receive_sms"
                      name="receive_sms"
                      onChange={(evt) => setFieldValue('receive_sms', evt.target.checked)}
                      checked={values.receive_sms}
                    />
                  </>
                )}
              </PaymentCard>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default connect(mapStateToProps)(GenericShippingForm);
