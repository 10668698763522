/* eslint-disable no-case-declarations */
import BasePriceCalculator from 'app/helpers/productSpecificPriceCalculators/basePriceCalculator';

// Key is a number of months
export const LOVER_SUBSCRIPTION_MONTHLY_COST = {
  '2_5_mg': {
    1: 99_99,
    3: 84_99,
    12: 69_99,
  },
  '5_mg': {
    1: 149_99,
    3: 129_99,
    12: 109_99,
  },
  '4': {
    1: 59_99,
    3: 49_99,
    12: 39_99,
  },
  '8': {
    1: 109_99,
    3: 89_99,
    12: 69_99,
  },
  '12': {
    1: 159_99,
    3: 134_99,
    12: 119_99,
  },
};

export const LOVER_SUBSCRIPTION_MONTHLY_COST_VARIANT_2 = {
  '2_5_mg': {
    1: 79_99,
    3: 69_99,
    12: 59_99,
  },
  '5_mg': {
    1: 109_99,
    3: 89_99,
    12: 69_99,
  },
  '4': {
    1: 39_99,
    3: 34_99,
    12: 29_99,
  },
  '8': {
    1: 79_99,
    3: 69_99,
    12: 59_99,
  },
  '12': {
    1: 119_99,
    3: 104_99,
    12: 89_99,
  },
};

class LoverPriceCalculator extends BasePriceCalculator {
  dailyStrength: string | null;
  numberOfDoses: 4 | 8 | 12 | null;
  otherActiveSubscription: boolean;
  expVariant: string | null;

  SHIPPING_PRICE = 9_99;

  constructor(
    includeBB: boolean,
    includeBBonAnotherProtocol: boolean,
    intakeName: string,
    dailyStrength: string | null = null,
    numberOfDoses: null | 4 | 8 | 12 = null,
    multiMonthPlan: 1 | 3 | 12,
    otherActiveSubscription: boolean,
    expVariant: string | null = null,
  ) {
    super(includeBB, includeBBonAnotherProtocol, intakeName, multiMonthPlan);

    this.dailyStrength = dailyStrength;
    this.numberOfDoses = numberOfDoses;
    this.otherActiveSubscription = otherActiveSubscription;
    this.expVariant = expVariant;
  }

  monthlyCost() {
    if (!this.numberOfDoses && !this.dailyStrength) return 0;

    const subProduct = (
      this.numberOfDoses ? this.numberOfDoses : !!this.dailyStrength && this.dailyStrength
    ).toString();

    const bloodflow_variant_2 = this.expVariant === 'bloodflow_multimonth_variation_2';

    const priceConfig = bloodflow_variant_2
      ? LOVER_SUBSCRIPTION_MONTHLY_COST_VARIANT_2
      : LOVER_SUBSCRIPTION_MONTHLY_COST;

    return priceConfig[subProduct][this.multiMonthPlan];
  }

  subscriptionCost() {
    if (this.subscriptionPaid) return 0;

    return (this.monthlyCost() + (this.includeBB ? this.BB_PRICE : 0)) * this.multiMonthPlan;
  }

  totalDueTodayForCurrentProduct() {
    if (!this.intakeName.includes('onboarding')) {
      return 0;
    }

    return this.subscriptionCost() + this.shippingPrice();
  }

  freeShipping() {
    return !(!this.otherActiveSubscription && this.subscriptionCost() <= 10000);
  }

  shippingPrice() {
    return this.freeShipping() ? 0 : this.SHIPPING_PRICE;
  }
}

export default LoverPriceCalculator;
