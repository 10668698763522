const supportEmail = `<a href="mailto:support@maximustribe.com">support@maximustribe.com</a>`;

export enum ApiRequestStatus {
  Failure = 'failure',
  Problem = 'problem',
  ServerError = 'serverError',
  ClientError = 'clientError',
}

export const ApiRequestMessage: { [key in ApiRequestStatus]: string } = {
  [ApiRequestStatus.Failure]: `We’re having trouble connecting. Please check your Internet connection and try again.<br>If that doesn’t work, contact our support team for help: ${supportEmail}`,
  [ApiRequestStatus.Problem]: `Something went wrong, and we’re humbled to say we don’t know why. <br>No need to wait, just contact our support team for help: ${supportEmail}`,
  [ApiRequestStatus.ServerError]: `We ran into an unexpected error on our end, and can’t perform this action right now. Our engineering team has been notified, and we’re working on it.<br>Try again later or contact our support team for urgent matters: ${supportEmail}`,
  [ApiRequestStatus.ClientError]: `Something went wrong. Please try again.<br>If the problem persists, please contact our support team for help: ${supportEmail}`,
};
