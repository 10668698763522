import React, { useState } from 'react';
import { connect } from 'react-redux';

import { push } from 'connected-react-router/immutable';
import * as selectors from 'app/selectors/customer';
import 'app/components/customer/steps/css/LocationStep.scss';
import Spinner from 'app/components/customer/Spinner';

import Confirm from './Confirm';
import Denied from './Denied';
import Allow from './Allow';

const mapStateToProps = (state) => {
  const flags = selectors.selectCustomerFlags(state);
  const nextStepPath = selectors.selectNextStepPath(state);
  const nextStepAction = push(nextStepPath);
  const prevStep = selectors.selectPreviousStep(state)?.toJS();
  const hideBackButton = !prevStep?.revisitable;
  const prev = selectors.selectPreviousStepPath(state);
  const previousStepAction = push(prev);

  return {
    nextStepAction,
    previousStepAction,
    hideBackButton,
    denied: selectors.selectGeolocationDenied(state),
    browserLocation: selectors.selectRegistrationLocation(state),
    selfReportedLocation: selectors.selectSelfReportedPosition(state),
    isSpinning: flags.includes('geolocating'),
    geolocated: flags.includes('geolocated'),
  };
};

const LocationStep = ({
  isSpinning,
  dispatch,
  browserLocation,
  denied,
  nextStepAction,
  hideBackButton,
  geolocated,
  previousStepAction,
}) => {
  const [locationFailed, setLocationFailed] = useState<boolean>(false);
  const redirectPrevious = () => dispatch(previousStepAction);
  if (isSpinning) {
    return <Spinner isCenter />;
  }
  if (geolocated) {
    setTimeout(() => dispatch(nextStepAction), 0);
  }
  if (denied) {
    return <Denied />;
  }
  if (browserLocation || locationFailed) {
    return <Confirm noBack={hideBackButton} browserLocation={browserLocation} redirectPrevious={redirectPrevious} />;
  }

  return (
    <Allow
      hideBackButton={hideBackButton}
      redirectPrevious={redirectPrevious}
      onFailed={() => setLocationFailed(true)}
    />
  );
};

export default connect(mapStateToProps)(LocationStep);
