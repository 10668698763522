import { useAppSelector } from 'app/helpers/hooks';
import * as selectors from 'app/selectors/customer';
import { selectCustomerStripeInfo } from 'app/selectors/admin';
import dayjs from 'dayjs';
import { selectCustomer } from 'app/selectors/customer';

const nextBillingCycle = (currentProductName) => {
  const customer = useAppSelector(selectCustomer);
  const productsImm = useAppSelector(selectors.selectCustomerProducts);

  const stripeInfo = useAppSelector((state) => selectCustomerStripeInfo(state, customer.get('id')));
  const nextStripeBillingCycle = (stripeProductId: string) => {
    const subscription = (stripeInfo?.get('subscriptions')?.toJS() || []).find((s) => s.id === stripeProductId);
    if (!subscription) {
      return '';
    }

    return dayjs(subscription.current_period_end * 1000).format('MMMM D, YYYY');
  };

  return customer.get('spreedly_enabled')
    ? productsImm.get(currentProductName).get('current_subscription')?.get('next_billing_date_str')
    : nextStripeBillingCycle(
        productsImm.get(currentProductName).get('current_subscription')?.get('stripe_internal_id'),
      );
};

export default nextBillingCycle;
