import React, { ComponentProps, FC, forwardRef } from 'react';
import { useField } from 'formik';
import CommonInput, { InputMask as CommonInputMask } from '../Input';
import '../css/InputMask.scss';

interface InputMaskProps {
  initialError?: string;
  id?: string;
  name: string;
}
export const InputMask: FC<InputMaskProps & ComponentProps<typeof CommonInputMask>> = forwardRef(
  ({ id, name, initialError, ...restProps }, ref) => {
    const [field, { touched, error }, { setValue }] = useField({ id, name });

    return (
      <CommonInputMask
        ref={ref}
        success={touched && !error}
        error={touched && (Boolean(error) || Boolean(initialError))}
        errorText={(touched && (error || initialError)) || undefined}
        testId={id}
        {...field}
        {...restProps}
        onChange={setValue}
      />
    );
  },
);

const Input: FC<
  {
    id?: string;
    name: string;
  } & ComponentProps<typeof CommonInput>
> = forwardRef(({ id, name, ...restProps }, ref) => {
  const [field, { touched, error }] = useField({ id, name });

  return (
    <CommonInput
      ref={ref}
      success={touched && !error}
      error={touched && Boolean(error)}
      errorText={(touched && error) || undefined}
      testId={id}
      {...field}
      {...restProps}
    />
  );
});

export default Input;
