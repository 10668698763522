import React, { useState, useRef } from 'react';
import { FormikProps } from 'formik';
import { useAppSelector } from 'app/helpers/hooks';
import { PrimaryButton } from 'app/components/common/Button';
import SetProductDialog from '@setproduct-ui/core/Dialog';
import GenericShippingForm from '../Generic/GenericShippingForm';
import { selectCustomerShippingAddress } from 'app/selectors/customer';
import { BaseIntake, MaximusProduct } from 'app/types/admin/customerUser';
import { ImmutableMap } from 'app/types/admin';

type Props = {
  productPharmacyValid: boolean;
  shippingFormValid: boolean;
  setProductPharmacyValid: (e: any) => void;
  setShippingFormValid: (e: any) => void;
  product?: ImmutableMap<MaximusProduct>;
  intake?: ImmutableMap<BaseIntake>;
};

const ShippingAddressWidget = ({
  productPharmacyValid,
  shippingFormValid,
  product,
  intake,
  setProductPharmacyValid,
  setShippingFormValid,
}: Props) => {
  const shippingFormRef = useRef<FormikProps<any>>(null);
  const shippingAddress = useAppSelector(selectCustomerShippingAddress);

  const [shippingModalOpen, setShippingModalOpen] = useState(false);

  const submitForm = () => {
    if (!shippingAddress && !shippingFormValid) return;

    if (shippingFormRef.current?.dirty) {
      shippingFormRef.current.submitForm();
    }
    setShippingModalOpen(false);
  };

  const {
    city = '',
    address_line_1 = '',
    address_line_2 = '',
    state = '',
    postal_code = '',
  } = shippingAddress?.toJS() || {};

  return (
    <>
      <div className="payment_card mt24">
        <h3 className="subtitle mb16">Shipping Address</h3>
        {shippingAddress ? (
          <div className="flex flexAlignEnd">
            <p>
              {address_line_1} {address_line_2} <br />
              {city} {state} {postal_code}
            </p>
            <span onClick={() => setShippingModalOpen(true)} className="generic_payment_page__change_subs">
              Change
            </span>
          </div>
        ) : (
          <PrimaryButton type="button" text="ADD SHIPPING ADDRESS" onClick={() => setShippingModalOpen(true)} />
        )}
      </div>

      <SetProductDialog
        isOpen={shippingModalOpen}
        title={<h2 className="title_without_line xl">Shipping Address</h2>}
        text={
          <>
            <GenericShippingForm
              productFromParam={product}
              intakeFromParam={intake}
              titleClasses="display-none"
              shippingFormRef={shippingFormRef}
              productPharmacyValid={productPharmacyValid}
              setProductPharmacyValid={setProductPharmacyValid}
              setShippingFormValid={setShippingFormValid}
            />
            <PrimaryButton className="mt40" type="button" text="SUBMIT" onClick={submitForm} />
          </>
        }
        onClose={() => setShippingModalOpen(false)}
        className="manage_subscription__cancel_modal generic_payment_page__modal align-left pb32"
      />
    </>
  );
};

export default ShippingAddressWidget;
