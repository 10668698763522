import React from 'react';
import { useAppSelector } from 'app/helpers/hooks';
import { useExperiment } from 'app/utils/useExperiment';
import { selectCurrentIntake, selectCurrentIntakeProduct } from 'app/selectors/customer';
import './css/DiscountBanner.scss';

const DiscountBanner = () => {
  const isVariant =
    useExperiment('testosterone_discount', 'testosterone_discount_variation_0') === 'testosterone_discount_variation_1';

  const isLabsFree =
    useExperiment('testosterone_discount_labs_free', 'testosterone_discount_labs_free_variation_0') ===
    'testosterone_discount_labs_free_variation_1';

  const labsFreeBlackFridayExperiment =
    useExperiment('testosterone_black_friday_labs_free', 'off') === 'experiment_without_variations';

  if (!isVariant && !isLabsFree && !labsFreeBlackFridayExperiment) return null;

  const currentIntake = useAppSelector(selectCurrentIntake);
  const currentProductName = useAppSelector(selectCurrentIntakeProduct);

  const isOnboarding = currentIntake.get('type') === 'onboarding';
  const isKingProtocol = currentProductName === 'king';

  if (!(isOnboarding && isKingProtocol)) return null;

  return (
    <div className="discount-banner-exp">
      {labsFreeBlackFridayExperiment ? (
        <p>
          <span>Black Friday Deal:</span> Buy any testosterone protocol and get your first lab test free.{' '}
          <span>Savings $99+</span>
        </p>
      ) : (
        (isVariant && (
          <p>
            Promo Code <span>FIRST100</span> has been applied to give you $100 Off today&apos;s purchase
          </p>
        )) ||
        (isLabsFree && (
          <p>
            Limited time offer: Take <span>$100 off</span> your first purchase and get a <span>free lab test</span>{' '}
            ($99+ value).
          </p>
        ))
      )}
    </div>
  );
};

export default DiscountBanner;
