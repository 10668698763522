import { P } from 'app/components/common/Typography';
import React from 'react';
import PriceFormatter from '../Generic/PriceFormatter';
import protocolNames from 'app/utils/protocolNames';
import { AvailableProducts } from 'app/constants/Products';

const WarriorPaymentHeader = ({ priceCalculator }) => {
  return (
    <>
      <div className="price_row">
        <div>{protocolNames[AvailableProducts.Warrior]} Protocol (0.1%)</div>
        <PriceFormatter price={priceCalculator.monthlyCost()} />
      </div>
      <P className="mt12 mb12">
        Covers prescription medications (if qualified), physician consultations, and psychologist health coaching.
      </P>
    </>
  );
};

export default WarriorPaymentHeader;
