import React from 'react';
import { connect } from 'react-redux';
import { P } from 'app/components/common/Typography';
import { push } from 'connected-react-router/immutable';
import * as actions from 'app/actions/customer';
import * as selectors from 'app/selectors/customer';
import { PrimaryButton } from 'app/components/common/Button';
import HeadingWithIcon from 'app/components/common/HeadingWithIcon';
import BulletCheckmark from 'images/Icon-Bullet-Checkmark.svg';
import './css/WarriorIntro.scss';
import * as Routes from 'app/constants/Routes';
import protocolNames from '../../../utils/protocolNames';

const benefits = [
  'Thicker hair, reduced shedding',
  'Increased beard growth',
  'Hormone safe',
  'Start seeing results in 2-3 months',
];
const access = [
  'Prescription topical gel (if qualified) ',
  'Licensed doctor consultations',
  'Free 2 day priority shipping for prescriptions',
];

const mapStateToProps = (state) => ({
  intake: selectors.selectCurrentIntake(state),
});

const WarriorOnboardingAcknowledgement = ({ step, intake, dispatch }) => {
  const action = actions.apiRequestUserCommand({
    cmdType: 'answer_questions',
    params: { [step]: true, intake: intake.get('name') },
    context: {
      onSuccessActionCreator: ({ json: { entity } }) =>
        push(
          Routes.createIntake(
            intake.get('name'),
            entity.maximus_products.warrior.intakes[0].steps.filter((step) => !step.completed)[0].url_friendly_name,
          ),
        ),
    },
  });

  const onClick = () => {
    dispatch(action);
  };

  return (
    <div className="p16 warrior-intro">
      <h1>The {protocolNames['warrior']} Protocol</h1>
      <P className="mb20">
        A one-of-a-kind combination of prescription medications to safely and effectively reduce hair loss and promote
        hair and beard growth.
      </P>

      <P className="bold">Benefits include:</P>
      <ul>
        {benefits.map((benefit) => (
          <li key={benefit}>
            <HeadingWithIcon icon={BulletCheckmark} text={benefit} tag={P} className="mv8" />
          </li>
        ))}
      </ul>
      <P className="bold mt36">
        Starting at $149
        <sup>99</sup> per month you have access to:
      </P>
      <ul>
        {access.map((a) => (
          <li key={a}>
            <HeadingWithIcon icon={BulletCheckmark} text={a} tag={P} className="mv8" />
          </li>
        ))}
      </ul>
      <PrimaryButton className="mt16 mb24" data-testid="next-step" text="Start my journey" onClick={onClick} />
      <div className="mb16 center-align">
        To learn more about the {protocolNames['warrior']} Protocol visit:{' '}
        <a target="_blank" href="https://maximustribe.com/warrior" rel="noreferrer">
          maximustribe.com/warrior
        </a>
      </div>
    </div>
  );
};

export default connect(mapStateToProps)(WarriorOnboardingAcknowledgement);
