import React, { useRef } from 'react';

const DraggableComponent: React.FC = ({ children }) => {
  const elementRef = useRef<HTMLDivElement | null>(null);
  let initX = 0;
  let initY = 0;
  let firstX = 0;
  let firstY = 0;

  const onMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (elementRef.current) {
      initX = elementRef.current.offsetLeft;
      initY = elementRef.current.offsetTop;
      firstX = e.pageX;
      firstY = e.pageY;

      document.addEventListener('mousemove', onMouseMove);
      document.addEventListener('mouseup', onMouseUp);
    }
  };

  const onMouseMove = (e: MouseEvent) => {
    if (elementRef.current) {
      elementRef.current.style.left = `${initX + e.pageX - firstX}px`;
      elementRef.current.style.top = `${initY + e.pageY - firstY}px`;
    }
  };

  const onMouseUp = () => {
    document.removeEventListener('mousemove', onMouseMove);
    document.removeEventListener('mouseup', onMouseUp);
  };

  const onTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (elementRef.current) {
      initX = elementRef.current.offsetLeft;
      initY = elementRef.current.offsetTop;
      firstX = e.touches[0].pageX;
      firstY = e.touches[0].pageY;

      document.addEventListener('touchmove', onTouchMove);
      document.addEventListener('touchend', onTouchEnd);
    }
  };

  const onTouchMove = (e: TouchEvent) => {
    if (elementRef.current) {
      const contact = e.touches[0];
      elementRef.current.style.left = `${initX + contact.pageX - firstX}px`;
      elementRef.current.style.top = `${initY + contact.pageY - firstY}px`;
    }
  };

  const onTouchEnd = () => {
    document.removeEventListener('touchmove', onTouchMove);
    document.removeEventListener('touchend', onTouchEnd);
  };

  return (
    <div
      ref={elementRef}
      onMouseDown={onMouseDown}
      onTouchStart={onTouchStart}
      className={'redesign-component-wrapper'}
    >
      <style>
        {`
          .redesign-component-wrapper {
            position: fixed;
            width: 130px;
            height: 30px;
            padding: 8px;
            border-radius: 10px;
            right: 100px;
            bottom: 20px;
            background-color: #0050eb;
            color: white;
            z-index: 1000;
            opacity: 0.1;
            transition: opacity 0.5s ease-in-out;
            font-size: 12px;
          }
          .redesign-component-wrapper:hover {
              opacity: 1;
          }
        `}
      </style>
      {children}
    </div>
  );
};

export default DraggableComponent;
