import React from 'react';
import ScheduleAppointmentForm from 'app/components/customer/steps/ScheduleAppointmentForm';
import { Redirect } from 'react-router';
import { Root } from 'app/constants/Routes';
import { useAppSelector } from 'app/helpers/hooks';
import { selectCustomer } from 'app/selectors/customer';

const AppointmentPage = () => {
  const customer = useAppSelector(selectCustomer);
  const pendingApointmentSchedule = customer.get('pending_appointment_schedule');

  return (
    <>
      {!pendingApointmentSchedule && <Redirect to={Root} />}
      <ScheduleAppointmentForm />
    </>
  );
};

export default AppointmentPage;
