import React from 'react';

import './css/Footer.scss';

import CrownLogo from '../../../images/Crown-Logo-Black.svg';

const Footer = () => (
  <footer className="footer">
    <div className="footer__content">
      <div className="footer__content__logo">
        <img src={CrownLogo} />
        <div>&copy; {new Date().getFullYear()} MAXIMUS</div>
      </div>
      <div className="footer__content__links">
        <a href="/accessibility-statement" target="_blank">
          ACCESSIBILITY
        </a>
        <a href="/terms-of-use" target="_blank">
          TERMS
        </a>
        <a rel="noreferrer" href="/privacy-policy" target="_blank">
          POLICY
        </a>
      </div>
    </div>
  </footer>
);

export default Footer;
