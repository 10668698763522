import React from 'react';
import { Formik, Form } from 'formik';
import Button from 'app/components/common/formik/Button';
import FormField from 'app/components/common/NewDesignSystem/FormField';
import Input from 'app/components/common/formik/Input';
import Divider from '@setproduct-ui/core/Divider';
import { RegistrationSchemaLabAccountDetails } from 'app/helpers/validators';
import Select from 'react-select';

const SEX_OPTIONS = [
  { value: 'f', label: 'Female' },
  { value: 'm', label: 'Male' },
];

const AccountDataFillStep = ({ initialValues, onSubmit }) => (
  <Formik
    initialValues={{
      ...initialValues,
    }}
    onSubmit={onSubmit}
    validationSchema={RegistrationSchemaLabAccountDetails}
  >
    {({ isSubmitting, errors, values, setFieldValue, isValid }) => (
      <Form>
        <h3 className="title">Create Account</h3>
        <p className="description mb8">
          To keep your health data secure, we’ll need you to create an account. You’ll be able to access your analysis
          with this information.
        </p>

        <FormField label={'First Name'}>
          <Input id="first_name" name="first_name" placeholder="Enter First Name" className="text-field" />
        </FormField>
        <FormField label={'Last Name'}>
          <Input id="last_name" name="last_name" placeholder="Enter Last Name" className="text-field" />
        </FormField>

        <div className="row">
          <FormField label={'Date of Birth'} className="col-12 col-sm-6">
            <Input name="date_of_birth" type="date" />
          </FormField>
          <FormField label={'Sex at Birth'} className="col-12 col-sm-6">
            <Select
              classNamePrefix="select"
              options={SEX_OPTIONS}
              value={SEX_OPTIONS.find((p) => p.value === values.sex)}
              isSearchable={false}
              onChange={(v) => setFieldValue('sex', v?.value)}
            />
          </FormField>
        </div>

        <Divider className="mb24" />

        <FormField label={'Password'}>
          <Input id="password" name="password" placeholder="Enter a Password" type="password" className="text-field" />
        </FormField>
        <FormField label={'Confirm Password'}>
          <Input
            id="password_confirmation"
            name="password_confirmation"
            type="password"
            placeholder="Re-enter Password"
            className="text-field"
          />
        </FormField>

        {errors && errors.api && <div className="errors">{errors.api}</div>}

        <Button
          disabled={isSubmitting || !isValid}
          style="primary"
          type="submit"
          data-testid="registration-step-1-submit"
          text="Next"
          className="button-next"
        />
      </Form>
    )}
  </Formik>
);

export default AccountDataFillStep;
