import updateSubscriptionSteps from 'app/utils/updateSubscriptionSteps';
import { CustomerUserImm, ProductIntakeImm } from 'app/types/admin/customerUser';
import * as Routes from 'app/constants/Routes';
import PriceCalculator from 'app/helpers/priceCalculator';
import GenericSubscriptionSummary from 'app/components/customer/steps/Payment/Generic/GenericSubscriptionSummary';
import React from 'react';
import PageHeader from 'app/components/common/PageHeader';

class BaseCheckoutHelper {
  customer: CustomerUserImm;
  intake: ProductIntakeImm;
  isOnboarding: boolean;

  constructor(customer: CustomerUserImm, intake: ProductIntakeImm, isOnboarding: boolean = false) {
    this.customer = customer;
    this.intake = intake;
    this.isOnboarding = isOnboarding;
  }

  currentProductName() {
    throw new Error('Not implemented');
  }

  firstStep = () => updateSubscriptionSteps.paymentStep;

  productSpecificInitialValues() {
    throw new Error('Not implemented');
  }

  multimonthEnabled = () => false;

  multimonthPlansOptions: { value: number; label: string }[] = [];

  updateSubProductCommandName = 'select_magician_drug_variant';

  selectProductStepPath() {
    return 'apologize';
  }

  redirectTo = (_params: any) => Routes.ManageSubscription;

  renderProductStep(_firstTimeChoice: boolean = false, _handleSubmit: any = null) {
    throw new Error('Not implemented');
  }

  renderProductStepHeader = (_values) => (
    <PageHeader title="Choose Your Protocol" className="treatment_plan__header mb24 -edged" />
  );

  renderMultimonthStep(_handleChangeProduct) {
    throw new Error('Not implemented');
  }

  renderPlanSelection(_onMultimonthUpdate, _subProductName, _priceCalculator) {
    throw new Error('Not implemented');
  }

  showLabAndDueTodaySection = (_params) => false;

  priceCalculator(_values: any, _subscription_paid: boolean = false, _lab_paid: boolean = false): PriceCalculator {
    throw new Error('Not implemented');
  }

  specialDisabledConditions(_values: any, _params): boolean {
    return false;
  }

  productChanged(_values: any): boolean {
    return false;
  }

  subscriptionSummaryComponent(): React.FC<any> {
    return GenericSubscriptionSummary;
  }

  kingV2Offered(): boolean {
    return false;
  }

  isKingV2OralTrt(_subProductName: any): boolean {
    return false;
  }

  activeProductWithSupplementName(): string | undefined {
    return this.customer.get('active_product_with_supplement_name');
  }

  supplementsOnAnotherIntake = () => {
    return !!(
      this.activeProductWithSupplementName() && this.activeProductWithSupplementName() != this.currentProductName()
    );
  };

  crlFeatureEnabledEncloPregWeightLoss = (): boolean =>
    !!this.customer.get('crl_feature_enabled_enclo_preg_weight_loss');

  subProductName = (_values) => undefined;

  bbOutOfStock = () => this.intake.get('should_get_supplement_preorder');

  showLabKitOnboarding = () => false;

  subProductNameForIntakeCheckout(_product) {
    return undefined;
  }

  showChangeProductButton = () => false;

  showLabSelectionButton = () =>
    this.isOnboarding && this.intake.get('steps').some((item) => item.get('name') === 'lab_selection');

  showOwnLabSection = () => false;

  useOwnLab = () => false;

  showProductSelectionStepNextButton = () => true;
}

export default BaseCheckoutHelper;
