import * as React from 'react';
import cx from 'classnames';
import './css/TextInput.scss';
import { v4 as uuidv4 } from 'uuid';
import Success from 'images/text-input/Success.svg';
import Failure from 'images/text-input/Failure.svg';
import { forwardRef } from 'react';

type Props = {
  type?: 'text' | 'password' | 'number';
  id?: string;
  label?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  readOnly?: boolean;
  className?: string;
  errorText?: string;
  size?: 'small' | 'medium' | 'large';
  valid?: boolean;
  error?: boolean;
  value?: string | number;
  disabled?: boolean;
  [x: string]: any;
};

const TextInput = forwardRef(
  (
    {
      type = 'text',
      id = uuidv4(),
      size = 'medium',
      label,
      error,
      errorText,
      disabled,
      className,
      onChange,
      valid,
      insideElement,
      active: _, // remove from rest, attribute active not allowed on element input
      ...rest
    }: Props,
    ref: React.ForwardedRef<HTMLInputElement>,
  ) => (
    <>
      {label && (
        <label htmlFor={id} className={cx('hs2-text-input__label', { disabled }, size)}>
          {label}
        </label>
      )}
      <div className="hs2-text-input__container">
        {insideElement}
        <input
          ref={ref}
          id={id}
          type={type}
          onChange={onChange}
          {...rest}
          disabled={disabled}
          className={cx(`hs2-text-input__input`, className, size, { valid }, { failed: !!error })}
        />
        {valid && !error && <img className="hs2-text-input__state" src={Success} />}
        {error && <img className="hs2-text-input__state" src={Failure} />}
      </div>
      {error && <div className={cx('hs2-text-input__error', size)}>{errorText}</div>}
    </>
  ),
);

export default TextInput;
