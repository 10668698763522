import React from 'react';
import cx from 'classnames';

import { H6, P } from 'app/components/common/Typography';

import './css/ProgressBar.scss';
import Bar from '@setproduct-ui/core/ProgressBar';

type Props = {
  position?: number;
  total?: number;
  title?: string;
  refill?: boolean;
  className?: string;
};

const ProgressBar = ({ position, total, title = 'Clinical Intake', refill = false, className = '' }: Props) => {
  let percentage = 0;
  if (position && total) {
    percentage = ((position - 1) / total) * 0.99; // We don't need to show 100% (#183024343)
  }

  return (
    <div className={cx('progress-bar', className)}>
      <div className="flex">
        <H6>
          <div className="line-bar" />
          {title}
        </H6>
        {!!percentage && (
          <div className="bar">
            <Bar animate={false} stripes={false} value={percentage} />
            <span>{(percentage * 100).toFixed(0)}%</span>
          </div>
        )}
      </div>
      {refill && (
        <div className="mh16 mt24">
          <P className="gold mb12">MEDICAL QUESTIONS</P>
          So our licensed clinicians are accurately tracking the progress of your treatment, please complete the
          following questions:
        </div>
      )}
    </div>
  );
};

export default ProgressBar;
