import React, { ComponentProps } from 'react';
import { P } from 'app/components/common/Typography';
import { SecondaryButton } from 'app/components/common/Button';

const Cancelled = ({ uncancel }: { uncancel: ComponentProps<typeof SecondaryButton>['onClick'] }) => (
  <div className="mt40 mh24 center-align">
    <P className="mt12 mh16">
      We are sorry to see you go. We have canceled your subscription. You will not be charged starting the next billing
      cycle and will lose access to the Maximus protocol.
    </P>
    <div className="m16">
      <SecondaryButton id="submit" text="Resume Account" onClick={uncancel} />
    </div>
  </div>
);

export default Cancelled;
