import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as selectors from 'app/selectors/customer';
import { push } from 'connected-react-router/immutable';
import { useParams } from 'react-router';
import UserPhotoComponent from './user-photo-component';
import '../css/UploadPhoto.scss';

const mapStateToProps = (state) => {
  const stepName = selectors.selectCurrentIntakeStep(state)?.get('name');
  const nextStepAction = push(selectors.selectNextStepPath(state));
  const intake = selectors.selectCurrentIntakeStep(state);

  return {
    stepName,
    intake,
    nextStepAction,
  };
};

const front_of_head = {
  title: 'Front of Head Photo',
  header: 'Upload a photo of the front of your head',
  boldText: 'front of your head',
  imgClass: 'front-of-head',
};

const top_of_head = {
  title: 'Top of Head Photo',
  header: 'Upload a photo of the top of your head',
  boldText: 'top of your head',
  imgClass: 'top-of-head',
};

const back_of_head = {
  title: 'Back of Head Photo',
  header: 'Upload a photo of the back of your head',
  boldText: 'back of your head',
  imgClass: 'back-of-head',
};

const description = {
  onboardingDescription: 'To track your progress and provide diagnosis, your doctor needs a photo of the ',
  sustainDescription: 'For diagnosis, the medical provider needs a photo of the ',
};

const textproperties = (stepName) => {
  switch (stepName) {
    case 'front_of_head':
      return front_of_head;
    case 'back_of_head':
      return back_of_head;
    case 'top_of_head':
      return top_of_head;
    default:
      return front_of_head;
  }
};

const intakeDescriptionProperties = (intake) => {
  switch (intake) {
    case 'warrior_onboarding':
      return description.onboardingDescription;
    case 'warrior_sustain':
      return description.sustainDescription;
    default:
      return description.sustainDescription;
  }
};

const UploadPhoto = ({ dispatch, stepName, intake, nextStepAction }) => {
  const { step } = useParams() as { step?: string };
  if (intake?.get('completed')) {
    setTimeout(() => dispatch(nextStepAction), 0);
  }
  const [properties, setProperties] = useState(textproperties(stepName));
  const textDescription = intakeDescriptionProperties(intake);

  useEffect(() => {
    if (step) {
      setProperties(textproperties(step));
    }
  }, [step]);

  return (
    <div>
      <UserPhotoComponent
        title={properties?.title}
        header={properties?.header}
        description={{ text: textDescription, boldText: properties?.boldText }}
        imgClass={properties?.imgClass}
        nonIntakeStep={step}
      />
    </div>
  );
};

export default connect(mapStateToProps)(UploadPhoto);
