import React, { FC } from 'react';

const FormField: FC<{ className?: string; label: string; children: React.ReactNode }> = ({
  className,
  label,
  children,
}) => (
  <div className={`form-field ${className || ''}`}>
    <p className="form-field-label">{label}</p>
    {children}
  </div>
);

export default FormField;
