import React from 'react';
import { Formik } from 'formik';
import { PrimaryButton } from 'app/components/common/Button';
import { push } from 'connected-react-router/immutable';
import * as selectors from 'app/selectors/customer';
import { connect } from 'react-redux';
import * as Routes from 'app/constants/Routes';
import { ShippingSchemaWithoutFullName } from 'app/helpers/validators';
import DumbShippingCard from 'app/components/customer/steps/Payment/DumbShippingCard';
import cx from 'classnames';
import DumbPaymentMethodNew from 'app/components/customer/steps/Payment/DumbPaymentMethodNew';
import PaymentContextProvider from '../steps/Payment/PaymentContext/provider';

const mapStateToProps = (reduxState) => {
  const customer = selectors.selectCustomer(reduxState);
  const shippingAddress = selectors.selectCustomerShippingAddress(reduxState);
  const currentPaymentMethod = customer.get('payment_method');

  const { city = '', address_line_1 = '', address_line_2 = '', state = '', postal_code = '' } = shippingAddress.toJS();
  const initialValues = shippingAddress
    .merge({
      address_line_1,
      address_line_2,
      city,
      state,
      postal_code,
    })
    .delete('full_name');

  return {
    initialValues,
    currentPaymentMethod,
    showBillingSettings: !!currentPaymentMethod,
  };
};

const BillingPreferences = ({ initialValues, dispatch, showBillingSettings }) => (
  <div className="centered flex-column payment pt52">
    <div
      className={cx('margin-centered', {
        mb48: !showBillingSettings,
      })}
      style={{ maxWidth: '456px', padding: '0 10px', width: '100%' }}
    >
      <Formik
        enableReinitialize
        initialValues={initialValues.toJS()}
        onSubmit={() => {}}
        validationSchema={ShippingSchemaWithoutFullName}
      >
        <DumbShippingCard />
      </Formik>
      {showBillingSettings && (
        <>
          <PaymentContextProvider>
            <DumbPaymentMethodNew />
          </PaymentContextProvider>

          <PrimaryButton
            text="Back to Settings"
            onClick={() => dispatch(push(Routes.Settings))}
            className="mt52 mb24"
          />
        </>
      )}
    </div>
  </div>
);

export default connect(mapStateToProps)(BillingPreferences);
