import React from 'react';
import TextArea from 'app/components/common/formik/TextArea';
import './css/TextArea.scss';

const TextAreaQuestion = ({ question }) => {
  const { placeholder, name } = question;

  return (
    <div className="text-area-question" key={name}>
      <TextArea id={name} name={name} placeholder={placeholder} />
    </div>
  );
};

export default TextAreaQuestion;
