import React, { createContext, useContext, useState } from 'react';
import { RedesignControl } from 'app/utils/redesign/RedesignControl';
import { isStagingOrLower } from 'app/helpers/env';
import { useAppSelector } from 'app/helpers/hooks';
import { selectAuthentication } from 'app/selectors/customer';

const RedesignContext = createContext(false);

export const RedesignProvider = ({ children }) => {
  const key = 'Maximus2024DesignVersion';
  const [isNewVersion, setIsNewVersion] = useState(() => {
    const savedFlag = localStorage.getItem(key);
    return savedFlag === 'true';
  });

  const toggleTheme = async (value) => {
    setIsNewVersion(value);
    localStorage.setItem(key, value.toString());
  };

  const authentication = useAppSelector(selectAuthentication);
  const isAdmin = authentication === 'admin';
  const showRedesignControl = !isAdmin && isStagingOrLower();

  return (
    <RedesignContext.Provider value={isNewVersion}>
      {showRedesignControl && <RedesignControl onChange={toggleTheme} />}
      {children}
    </RedesignContext.Provider>
  );
};

export const useRedesign = () => useContext(RedesignContext);
