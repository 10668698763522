import React from 'react';

import Slider from '@setproduct-ui/core/Slider';
import Card from '@setproduct-ui/core/Card';

import { useField, useFormikContext } from 'formik';
import { useAppSelector } from 'app/helpers/hooks';
import { selectCurrentIntake } from 'app/selectors/customer';
import MBodyfat8 from 'images/MImage-Body-8.png';
import MBodyfat12 from 'images/MImage-Body-12.png';
import MBodyfat15 from 'images/MImage-Body-15.png';
import MBodyfat20 from 'images/MImage-Body-20.png';
import MBodyfat25 from 'images/MImage-Body-25.png';
import MBodyfat30 from 'images/MImage-Body-30.png';
import MBodyfat35 from 'images/MImage-Body-35.png';

import FBodyfat15 from 'images/FImage-Body-15.png';
import FBodyfat20 from 'images/FImage-Body-20.png';
import FBodyfat25 from 'images/FImage-Body-25.png';
import FBodyfat30 from 'images/FImage-Body-30.png';
import FBodyfat35 from 'images/FImage-Body-35.png';
import FBodyfat40 from 'images/FImage-Body-40.png';
import FBodyfat45 from 'images/FImage-Body-45.png';

import './css/Bodyfat.scss';

const BodyfatQuestion = ({ question }) => {
  const { name } = question;
  const [{ value }] = useField({ id: name, name });
  const { setFieldValue } = useFormikContext();
  const intake = useAppSelector(selectCurrentIntake);
  const intake_name = intake.get('name');
  const sex = (intake_name === 'magician_onboarding' ? intake.get('questionnaire').get('sex') : 'm') || 'm';
  const imageMap = {
    m: {
      8: MBodyfat8,
      12: MBodyfat12,
      15: MBodyfat15,
      18: MBodyfat20,
      25: MBodyfat25,
      30: MBodyfat30,
      35: MBodyfat35,
    },
    f: {
      15: FBodyfat15,
      20: FBodyfat20,
      25: FBodyfat25,
      30: FBodyfat30,
      35: FBodyfat35,
      40: FBodyfat40,
      45: FBodyfat45,
    },
  };
  const numMap = {
    m: {
      8: '1',
      12: '2',
      15: '3',
      18: '4',
      25: '5',
      30: '6',
      35: '7',
    },
    f: {
      15: '1',
      20: '2',
      25: '3',
      30: '4',
      35: '5',
      40: '6',
      45: '7',
    },
  };
  const reverse = {
    m: {
      1: '8',
      2: '12',
      3: '15',
      4: '18',
      5: '25',
      6: '30',
      7: '35',
    },
    f: {
      1: '15',
      2: '20',
      3: '25',
      4: '30',
      5: '35',
      6: '40',
      7: '45',
    },
  };
  const Image = ({ num }) => <img data-value={num} src={imageMap[sex][num]} />;
  const mapped = numMap[sex][value];

  return (
    <div>
      <Card className="mt12 mh16">
        <div className="bodyfat-wrapper">
          <Image num={value} />
          <Slider
            color="default"
            vertical
            stepSize={1}
            min={1}
            max={7}
            initialValue={1}
            value={Number(mapped)}
            labelRenderer={(val) => <span data-testid={`label_${reverse[sex][val]}`}> -{reverse[sex][val]}% </span>}
            onChange={(val) => {
              setFieldValue(name, reverse[sex][val]);
            }}
          />
        </div>
      </Card>
    </div>
  );
};

export default BodyfatQuestion;
