import { createModel } from '@rematch/core';
import { RejectionType } from 'app/constants/enums';

export interface ApologizeState {
  flags: RejectionType[];
}
const apologize = createModel()({
  state: {
    flags: [],
  } as ApologizeState,
  reducers: {
    init: (_state, action) => action,
  },
});
export default apologize;
