import React, { useMemo } from 'react';

import { P } from 'app/components/common/Typography';
import { push } from 'connected-react-router/immutable';

import './css/RefillComplete.scss';
import { connect } from 'react-redux';

import { Formik } from 'formik';
import * as Routes from 'app/constants/Routes';
import { PrimaryButton, SecondaryButton } from 'app/components/common/Button';
import TextArea from 'app/components/common/TextArea';
import { apiRequestUserCommand } from 'app/actions/customer';
import * as selectors from 'app/selectors/customer';
import { TreatmentGoingSchema } from 'app/helpers/validators';

// import IconSuccess from 'images/Icon-Success.svg'

const RefillCheckpoint = ({ dispatch, intake, onSuccessAction }) => {
  const onSubmit = (params) => {
    const name = 'treatment_going';
    if (intake) {
      params.intake = intake.get('name');
    }
    const action = apiRequestUserCommand({
      cmdType: 'answer_questions',
      params,
      context: { onSuccessAction, form: name },
    });
    dispatch(action);
  };
  const initialValues = useMemo(() => ({ treatment_going: '' }), []);

  return (
    <div className="refill_complete centered">
      {/* <div className="refill_complete__success">
        <div className="flex">
          <img src={IconSuccess} />
          <H6>Refill Intake Complete!</H6>
        </div>
        <P className="refill_complete__info">We will send a notification once your medication has been shipped!</P>
        <PrimaryButton onClick={() => dispatch(push(Routes.Dashboard))} text="return to dashboard" />
      </div> */}

      <div className="mh16 mv24">
        <P className="gold mb12 refill_complete__heading">OPTIONAL QUESTION OR COMMENT</P>

        <P className="refill_complete__question mb24">
          Please describe to your clinician how the treatment has been going so far. Have you gotten closer to your
          goals?
        </P>
        <P className="refill_complete__question mb24">
          Have you experienced any side effects or health issues you think are related? Let us know if you have any
          additional comments/questions so your clinician can get a full picture.
        </P>
      </div>
      <Formik validationSchema={TreatmentGoingSchema} initialValues={initialValues} onSubmit={onSubmit}>
        {({ handleSubmit, setFieldValue, isValid, dirty }) => (
          <>
            <TextArea
              testId="freeform_on_follow_up"
              placeholder="Add your note to the doctor here..."
              onChange={({ currentTarget: { value: content } }) => {
                setFieldValue('treatment_going', content);
              }}
            />
            <div className="mh16 mt24">
              <PrimaryButton
                text="submit"
                data-testid="next-step"
                onClick={() => handleSubmit()}
                disabled={!isValid || !dirty}
              />
              <SecondaryButton
                type="button"
                data-testid="cancel"
                text="Skip"
                onClick={() => onSubmit(initialValues)}
                className="mt16"
              />
            </div>
          </>
        )}
      </Formik>
    </div>
  );
};

const mapStateToProps = (state) => {
  const productName = selectors.selectCurrentIntakeProduct(state);
  const onSuccessAction = push(Routes.ProductDashboard(productName));
  const previousStepAction = push(selectors.selectPreviousStepPath(state));

  return {
    intake: selectors.selectCurrentIntake(state),
    onSuccessAction,
    previousStepAction,
  };
};

export default connect(mapStateToProps)(RefillCheckpoint);
