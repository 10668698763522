import { backendLog } from 'app/utils/LogFrontendEvents';

export function apiRequestSuccess(
  this: { logger_prefix?: string },
  {
    json,
    reqId,
    context,
    status,
    request,
  }: {
    json?: any; // ApiRequest can fail and still 'succeed' (not throw)
    reqId: string | number;
    context?: any; // fileUpload may not include this
    status?: any; // fileUpload may not include this
    request?: any;
  },
) {
  if (status === 200) {
    return {
      type: 'admin/API_REQUEST_SUCCESS',
      payload: { json, reqId, ...context },
    };
  }
  if (status === 400) {
    return {
      type: 'admin/API_REQUEST_PROBLEMS',
      payload: { json, reqId, context },
    };
  }
  const logger_prefix = this?.logger_prefix || 'admin';
  backendLog(`${logger_prefix}/api_request_errors`, { reqId, context, status, json, request });

  return {
    type: 'admin/API_REQUEST_ERRORS',
    payload: { reqId, context, status, json },
  };
}

export function apiRequestFailure(this: { logger_prefix?: string }, { message, reqId, request }) {
  const logger_prefix = this?.logger_prefix || 'admin';
  backendLog(`${logger_prefix}/api_request_failure`, { reqId, message, request });

  return {
    type: 'admin/API_REQUEST_FAILED',
    payload: { message, reqId },
  };
}
