import cx from 'classnames';
import React, { FC } from 'react';
import './css/Alert.scss';
import CrossIcon from 'images/cross.svg';

const Alert: FC<{
  noClose?: boolean;
  className?: string;
  type?: 'success' | 'error';
  onClose?: () => void;
}> = ({ children, noClose = false, className, onClose, type = 'success' }) => (
  <div className={cx('alert', type, className)}>
    <div className="flex">
      <div>{children}</div>
      {!noClose && (
        <div>
          <img onClick={onClose} src={CrossIcon} />
        </div>
      )}
    </div>
  </div>
);

export default Alert;
