/* eslint-disable no-case-declarations */

class BasePriceCalculator {
  includeBB: boolean;
  includeBBonAnotherProtocol: boolean;
  useOwnLab: boolean;
  multiMonthPlan: 1 | 3 | 6 | 12;
  intakeName: string;
  subscriptionPaid: boolean;
  labPaid: boolean;

  BB_PRICE = 49_99;

  constructor(
    includeBB: boolean,
    includeBBonAnotherProtocol: boolean,
    intakeName: string,
    multiMonthPlan: 1 | 3 | 6 | 12 = 1,
    useOwnLab: boolean = false,
    subscriptionPaid = false,
    labPaid = false,
  ) {
    this.includeBB = includeBB;
    this.includeBBonAnotherProtocol = includeBBonAnotherProtocol;
    this.multiMonthPlan = multiMonthPlan;
    this.useOwnLab = useOwnLab;
    this.intakeName = intakeName;
    this.subscriptionPaid = subscriptionPaid;
    this.labPaid = labPaid;
  }

  monthlyCost() {
    throw new Error('not implemented');
  }

  fullMonthlyCost() {
    return this.monthlyCost()! + (this.includeBB ? this.BB_PRICE : 0);
  }

  subscriptionCost() {
    throw new Error('not implemented');
  }

  appointmentCost() {
    return 0;
  }

  bbDiscount() {
    throw new Error('not implemented');
  }
  bbPotentialDiscount() {
    return 0;
  }

  atHomeLabPrice() {
    throw new Error('not implemented');
  }

  labCost() {
    return 0;
  }

  totalDueTodayForCurrentProduct() {
    throw new Error('not implemented');
  }

  freeShipping() {
    return true;
  }

  shippingPrice() {
    return 0;
  }

  nonDiscountedPrice() {
    return 0;
  }

  bbPrice() {
    return this.BB_PRICE;
  }

  montlyPayment() {
    throw new Error('not implemented');
  }

  quarterlyPayment(): number {
    throw new Error('not implemented');
  }

  yearlyPayment(): number {
    throw new Error('not implemented');
  }
}

export default BasePriceCalculator;
