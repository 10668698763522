import React, { useState } from 'react';
import PageHeader from 'app/components/common/PageHeader';
import { push } from 'connected-react-router/immutable';
import { Formik } from 'formik';
import { apiRequestUserCommand } from 'app/actions/customer';
import { connect } from 'react-redux';
import * as selectors from 'app/selectors/customer';
import { P } from 'app/components/common/Typography';
import BackNextFooter from 'app/components/customer/steps/BackNextFooter';
import Divider from '@setproduct-ui/core/Divider';
import { PharmacySchema } from 'app/helpers/validators';
import HospitalIcon from 'images/Icon-Doctor.svg';
import ApcImg from 'images/pharmacy/APC-Logo 1.png';
import PcaaImg from 'images/pharmacy/PcaaImg.png';
import LegitScriptImg from 'images/pharmacy/LegitScriptImg.png';
import { ReactSVG } from 'react-svg';
import PharmacyAddress from './Address';
import OtherOption from './OtherOption';
import '../../css/Pharmacy.scss';

const mapStateToProps = (state) => {
  const prev = selectors.selectPreviousStepPath(state);
  const previousStepAction = push(prev);
  const onSuccessAction = push(selectors.selectNextStepPath(state));
  const productName = selectors.selectCurrentIntakeProduct(state);
  const product = selectors.selectCustomerProduct(state, productName)?.toJS();
  const pharmacyChoice = product.pharmacy_choice || 'maximus_preferred_pharmacy';
  const pharmacyInfo = product.pharmacy_info || {
    pharmacy_name: '',
    address_line_1: '',
    address_line_2: '',
    city: '',
    state: '',
    postal_code: '',
    phone_number: '',
  };
  const { name = '', zip = '', address_1 = '', address_2 = '', ...rest } = pharmacyInfo;

  const initialValues = {
    ...rest,
    choice: pharmacyChoice,
    pharmacy_name: name,
    address_line_1: address_1,
    address_line_2: address_2,
    postal_code: zip,
  };

  return {
    previousStepAction,
    onSuccessAction,
    pharmacyChoice,
    initialValues,
    productName,
  };
};

export const PharmacyStep = ({
  dispatch,
  pharmacyChoice,
  previousStepAction,
  onSuccessAction,
  initialValues,
  productName,
}) => {
  const [expanded, setExpanded] = useState(pharmacyChoice === 'other');
  const [checkboxValue, toggleCheckbox] = useState(false);
  const redirectPrevious = () => dispatch(previousStepAction);
  const pharmacy = 'maximus_preferred_pharmacy';

  const onSubmit = (values) => {
    const cmdType = 'select_pharmacy';
    const action = apiRequestUserCommand({
      cmdType,
      params: {
        ...values,
        product_name: productName,
      },
      context: { onSuccessAction },
    });
    dispatch(action);
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={PharmacySchema} validateOnMount>
      {({ handleSubmit, values: { choice }, isValid, setFieldValue }) => (
        <>
          <div className="pharmacy-step">
            <PageHeader
              title={
                <>
                  <span className="icon -rounded">
                    <ReactSVG src={HospitalIcon} />
                  </span>
                  <span className="title">Pharmacy Confirmation</span>
                </>
              }
              className="mb16"
            />

            <div className="flex1 overflow-scroll">
              <P type="body" large className="mt16 font-medium font-poppins">
                Our clinical protocols use custom doses of specific, speciality compounded drugs which are only
                available at a limited set of compounding pharmacies in the US. If deemed clinically appropriate by your
                attending physician, your prescription will be mailed from an accredited pharmacy:
              </P>
              <div className="mt24 mb24 font-medium pointer relative">
                <p className="pharmacy-label">Maximus Recommended Pharmacies</p>

                <div className="pharmacy-badges">
                  <div>
                    <img src={ApcImg} />
                  </div>
                  <div>
                    <img src={PcaaImg} />
                  </div>
                  <div>
                    <img src={LegitScriptImg} />
                  </div>
                </div>
              </div>

              <P className="mt16 collaborates font-poppins">
                Maximus collaborates with and recommends pharmacies that are accustomed to stocking and fulfilling
                specific lifestyle medications, if deemed clinically appropriate and prescribed by your attending
                physician at the time of consult. These pharmacies include:{' '}
                <>
                  <a href="https://www.belmarpharmasolutions.com/" target="_blank" rel="noreferrer">
                    Belmar Pharma Solutions
                  </a>
                  {', '}
                </>
                <a href="https://precisioncompoundingpharmacy.net/" target="_blank" rel="noreferrer">
                  Precision Compounding Pharmacy
                </a>
                {', '}
                <a href="https://tailormadecompounding.com" target="_blank" rel="noreferrer">
                  Tailor Made Compounding
                </a>
                .
              </P>
              <Divider className="mv16" />
              <P className="font-poppins">
                We honor our patient&apos;s freedom to choose what pharmacy they would like their prescription sent to.{' '}
                <a
                  href="#"
                  color="gray"
                  className="underlined pointer different-pharm"
                  onClick={async () => {
                    if (expanded) {
                      await setFieldValue('choice', pharmacy);
                    }

                    setExpanded((prevValue) => !prevValue);
                  }}
                >
                  Choose a different pharmacy.
                </a>
              </P>
              <OtherOption
                className="danger-card mt24"
                setFieldValue={setFieldValue}
                expanded={expanded}
                checkboxValue={checkboxValue}
                toggleCheckbox={toggleCheckbox}
              />
              <Divider className="mv24" />
              <PharmacyAddress choice={choice} />
            </div>
          </div>

          <BackNextFooter
            nextText="Confirm"
            onNext={handleSubmit}
            nextDisabled={!isValid}
            onBack={redirectPrevious}
            horizontalPadding={false}
          />
        </>
      )}
    </Formik>
  );
};

export default connect(mapStateToProps)(PharmacyStep);
