import React from 'react';
import { useFormikContext } from 'formik';
import { PrimaryButton, SecondaryButton } from 'app/components/common/Button';

const getButtonType = (style) => {
  switch (style) {
    case 'primary':
      return PrimaryButton;
    case 'secondary':
      return SecondaryButton;
    default:
      return PrimaryButton;
  }
};

const Button = ({ style, ...props }) => {
  const formik = useFormikContext();
  const enabled = formik.isValid;
  const Button = getButtonType(style);

  return <Button disabled={!enabled} {...props} />;
};

export default Button;
