import TitleWithLine from 'app/components/common/Typography/TitleWithLine';
import PaymentCard from 'app/components/customer/steps/Payment/PaymentCard';
import React, { useMemo, useState } from 'react';
import ShippingCardIcon from 'images/ShippingCard.svg';
import { P } from 'app/components/common/Typography';
import { PrimaryButton } from 'app/components/common/Button';
import { ShippingFields } from 'app/components/customer/steps/Shipping';
import { connect } from 'react-redux';
import WhiteButton from 'app/components/common/WhiteButton';
import { Formik, useFormikContext } from 'formik';
import Alert from 'app/components/common/Alert';
import { useAppDispatch, useAppSelector } from 'app/helpers/hooks';
import { ShippingSchemaWithoutFullName } from 'app/helpers/validators';
import { selectCustomerId, selectCustomerShippingAddress, selectValidStates } from 'app/selectors/customer';
import { apiRequestPromise } from 'app/api';
import { apiRequestFailure, apiRequestSuccess } from 'app/helpers/commandHelpers';
import OutOfServiceAlert from './OutOfServiceAlert';

const mapStateToProps = (stateRedux) => {
  const shippingAddress = selectCustomerShippingAddress(stateRedux);

  const user_id = selectCustomerId(stateRedux);

  return { shippingAddress, user_id };
};

const DumbShippingCard = ({ shippingAddress, user_id }) => {
  const validStates = useAppSelector(selectValidStates);
  const [expanded, setExpanded] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const dispatch = useAppDispatch();
  const { setFieldValue } = useFormikContext();
  const shippingAddressValues = useMemo(() => shippingAddress.toJS(), [shippingAddress]);
  const { address_line_1, address_line_2, city, state: shippingState, postal_code } = shippingAddressValues;
  const line1 = `${address_line_1}.`;
  const line2 = `${address_line_2}.`;
  const line3 = `${city}, ${shippingState} ${postal_code}`;
  const line2Component = (
    <P type="body_bold" className="mh16" data-testid="line2">
      {line2}
    </P>
  );

  const onFormSubmitted = async (values) => {
    const { address_line_1, address_line_2, city, state, postal_code } = values;
    const params = {
      address_line_1,
      address_line_2,
      city,
      state,
      postal_code,
    };

    try {
      const resp = await apiRequestPromise('POST', '/api/commands', {
        type: 'update_shipping_address',
        user_id,
        params: { ...params },
      });
      setExpanded(false);
      setShowAlert(true);
      dispatch(apiRequestSuccess(resp));
    } catch (error) {
      const message = error instanceof Error ? error.message : 'An error occurred (DumbShippingCard#onFormSubmitted)';
      dispatch(apiRequestFailure({ message, reqId: 42, request: {} }));
    }

    for (const [k, v] of Object.entries(params)) {
      setFieldValue(k, v);
    }
  };
  const outOfService = !validStates.includes(shippingState);

  return (
    <>
      <TitleWithLine className="mb16 mt32" size="xl">
        Shipping Address
      </TitleWithLine>
      {showAlert && (
        <Alert onClose={() => setShowAlert(false)} className="mb24">
          Shipping address updated successfully
        </Alert>
      )}
      {outOfService && <OutOfServiceAlert validStates={validStates} />}
      <PaymentCard>
        <div className="payment_method_info">
          <div className="flex">
            <div>
              <img src={ShippingCardIcon} />
            </div>
            <div className="flex1">
              <P className="mt12">Delivering To:</P>
              <P type="body_bold" className="mt12">
                {line1}
              </P>
              {address_line_2 && line2Component}
              <P type="body_bold">{line3}</P>
              {!expanded && (
                <WhiteButton
                  onClick={() => setExpanded(true)}
                  className="payment_button mt24"
                  text="update shipping address"
                />
              )}
            </div>
          </div>
          {expanded && (
            <Formik
              initialValues={shippingAddressValues}
              validationSchema={ShippingSchemaWithoutFullName}
              onSubmit={onFormSubmitted}
              enableReinitialize
            >
              {({ handleSubmit, isSubmitting }) => (
                <>
                  <hr />
                  <ShippingFields />
                  <hr />
                  <div className="flex">
                    <WhiteButton onClick={() => setExpanded(false)} text="Cancel" />
                    <PrimaryButton onClick={() => handleSubmit()} text="Update" disabled={isSubmitting} />
                  </div>
                </>
              )}
            </Formik>
          )}
        </div>
      </PaymentCard>
    </>
  );
};

export default connect(mapStateToProps)(DumbShippingCard);
