import { AvailableProducts } from 'app/constants/Products';

export const createIntake = (name, step) => `/dashboard/intake/${name}/${step}`;

export const createIntakeClinicalQuestions = (name) => `/dashboard/intake/${name}/clinical_question`;
export const Admin = '/admin';
export const AdminLabs = '/admin/labs';
export const AdminSendgridConfig = '/admin/sendgrid/config';
export const AdminTassoApiTesting = '/admin/tasso-api-testing';
export const Dashboard = '/dashboard';
export const Onboarding = createIntake('onboarding', ':step');
export const ContinueOnboarding = '/dashboard/continue-onboarding';
export const ContinueIntake = '/dashboard/continue-intake';
export const Intake = createIntake(':intakeName', ':intakeStep');
export const ClinicalQuestion = createIntakeClinicalQuestions(':intakeName');
export const Settings = '/dashboard/account-preferences';
export const ContactUs = '/dashboard/contact-us';
export const ManageSubscription = '/dashboard/manage-subscriptions';
export const UpdateSubscription = `/dashboard/update-subscription`;
export const UpdateSubscriptionForProduct = (product: string) => `${UpdateSubscription}?product=${product}`;
export const UpdateSubscriptionForProductKingV2 = (product: string, oral_trt: boolean) =>
  `${UpdateSubscription}?product=${product}&oral_trt=${oral_trt}`;
export const BillingSettings = '/dashboard/billing-preferences';
export const WarriorRetakePhotos = '/dashboard/warrior-retake-photos/:step';
export const PasswordSettings = '/dashboard/password-preferences';
export const CongratsKing = '/dashboard/king/congrats';
export const CongratsMagician = '/dashboard/magician/congrats';
export const ScheduleAppointment = '/dashboard/schedule_appointment';
export const LabCheckout = '/dashboard/lab_checkout';
export const LabCheckoutSuccess = '/dashboard/lab_success';
export const UploadOwnLabs = '/dashboard/upload_own_lab';
export const AnnualLabCheckout = '/dashboard/annual_lab_checkout/:lab_type';
export const Login = '/login';
export const PasswordReset = '/password/reset';
export const Registration = '/registration';
export const ProductSelection = 'https://www.maximustribe.com/#view';
export const ComingSoon = '/coming-soon';
export const Goodbye = '/goodbye';
export const Root = '/';
export const Apologize = '/apologize';
export const AccessibilityStatement = '/accessibility-statement';
export const TermsOfUse = '/terms-of-use';
export const PrivacyPolicy = '/privacy-policy';
export const PharmacyConfiguration = '/admin/pharmacy_config';
export const ProductDashboard = (product: string | undefined) =>
  product && product !== AvailableProducts.King ? `${Dashboard}?product=${product}` : Dashboard;
export const UpdateQuestionnaire = '/dashboard/:product/update-questionnaire';
export const LabSelection = '/dashboard/intake/onboarding/lab_selection';
