import React from 'react';
import { useAppSelector } from 'app/helpers/hooks';
import { selectFurthestProductStepPath, selectCurrentIntake } from 'app/selectors/customer';
import { push } from 'connected-react-router/immutable';
import ScheduleAppointmentForm from './ScheduleAppointmentForm';

const ScheduleAppointmentStep = () => {
  const currentProductName = useAppSelector(selectCurrentIntake).get('product_name');

  const onSuccessActionCreator = (_, stateAfterSuccess) =>
    push(selectFurthestProductStepPath({ customer: stateAfterSuccess }, currentProductName));

  return <ScheduleAppointmentForm onSuccessActionCreator={onSuccessActionCreator} />;
};

export default ScheduleAppointmentStep;
