import React, { useEffect, useState } from 'react';
import { matchPath, useHistory, NavLink } from 'react-router-dom';

import '../customer/css/SubHeader.scss';

const chevronSvg = ({ color, size }) => (
  <svg width={size[0]} height={size[1]} viewBox="0 0 6 9" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.81361 4.04357L1.95646 0.186429C1.84075 0.0707143 1.68003 0 1.50003 0C1.14646 0 0.857178 0.289286 0.857178 0.642857C0.857178 0.822857 0.927892 0.983571 1.04361 1.09929L4.45075 4.5L1.05003 7.90071C0.927892 8.01643 0.857178 8.17714 0.857178 8.35714C0.857178 8.71071 1.14646 9 1.50003 9C1.68003 9 1.84075 8.92929 1.95646 8.81357L5.81361 4.95643C5.92932 4.84071 6.00003 4.68 6.00003 4.5C6.00003 4.32 5.92932 4.15929 5.81361 4.04357Z"
      fill={color}
    />
  </svg>
);

/**
 * Since we only need to show Subheader in a limited amount of routes and since the fact
 *  that 'where' and 'how' subheader is shown is to possibly be customized in the future
 *  we can get by with a simple location <-> subheader_data map, instead of
 *  filtering all of the routes and dynamically calculating subheader_data
 *  for breadcrumbs.
 */
const locationToSubheaderMap = {
  '/dashboard': {
    title: 'Dashboard',
    links: [{ to: '/dashboard', text: 'Dashboard' }],
  },
  '/dashboard/account-preferences': {
    title: 'Account Settings',
    links: [
      { to: '/dashboard', text: 'Dashboard' },
      { to: '/dashboard/account-preferences', text: 'Settings' },
    ],
  },
  '/dashboard/billing-preferences': {
    title: 'Billing & Shipping',
    links: [
      { to: '/dashboard', text: 'Dashboard' },
      { to: '/dashboard/account-preferences', text: 'Settings' },
      { to: '/dashboard/billing-preferences', text: 'Billing & Shipping' },
    ],
  },
  '/dashboard/password-preferences': {
    title: 'Update Password',
    links: [
      { to: '/dashboard', text: 'Dashboard' },
      { to: '/dashboard/account-preferences', text: 'Settings' },
      { to: '/dashboard/password-preferences', text: 'Update Password' },
    ],
  },
  '/dashboard/manage-subscriptions': {
    title: 'Manage subscriptions',
    links: [
      { to: '/dashboard', text: 'Dashboard' },
      { to: '/dashboard/account-preferences', text: 'Settings' },
      { to: '/dashboard/manage-subscriptions', text: 'Manage subscriptions' },
    ],
  },
};

const SubHeader = ({ locationMap = locationToSubheaderMap }) => {
  const currentLocation = window.location.pathname;
  const history = useHistory();

  const findMatchKey = (locationPathName: string): string => {
    const matchKey = Object.keys(locationToSubheaderMap).find((key) => {
      const match = matchPath(locationPathName, { path: key, exact: true });

      return !!match;
    });

    return matchKey || '';
  };

  const [locationMatchKey, setLocationMatchKey] = useState(findMatchKey(currentLocation));

  useEffect(() => {
    const stop = history.listen((location, _action) => {
      const matchKey = findMatchKey(location.pathname);
      setLocationMatchKey(matchKey || '');
    });

    return () => {
      stop?.();
    };
  }, []);

  if (
    currentLocation.startsWith('/admin') ||
    currentLocation.includes('/intro') ||
    locationMatchKey === '' ||
    currentLocation === '/dashboard'
  ) {
    return null;
  }

  return (
    <div className="subheader">
      <div className="subheader__content">
        {/* Page info */}
        <div className="subheader__page-info">
          <div className="title">{locationMap[locationMatchKey]?.title}</div>
        </div>

        {/* Breadcrumbs */}
        <ol className="subheader__breadcrumbs">
          {locationMap[locationMatchKey]?.links?.map((link, i) => (
            <li key={i}>
              {chevronSvg({ color: '#e1e1e1', size: [9, 9] })}
              <NavLink to={link.to} className="item" activeClassName="active" exact>
                {link.text}
              </NavLink>
            </li>
          ))}
        </ol>
      </div>
    </div>
  );
};

export default SubHeader;
