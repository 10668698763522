import React, { FC } from 'react';
import DiscordImage from 'images/discord.jpg';
import '../css/ResourceSection.scss';
import { ResourceSection } from './ResourceSection';

export const JoinOurDiscord: FC = () => (
  <ResourceSection
    image={DiscordImage}
    title="Discord Community"
    text="Discord is the social platform we use to offer coaching and peer discussions. Join the tribe today."
    urls={[
      [window.DISCORD_URL, 'Join Discord'],
      [window.DISCORD_DOWNLOAD_URL, 'Download APP'],
    ]}
  />
);
