import React, { useState } from 'react';
import { useField, useFormikContext } from 'formik';
import { NumericField } from './Numeric';

const INCHES_IN_FOOT = 12;
const HeightField = ({ id, name }) => {
  const [{ value }, { touched, error }] = useField({ id, name });
  const { setFieldValue } = useFormikContext();
  const hasError = touched && error;
  const initialFeet = Math.floor(value / INCHES_IN_FOOT);
  const initialInches = value % INCHES_IN_FOOT;
  const [feet, setFeet] = useState(initialFeet);
  const [inches, setInches] = useState(initialInches);
  const onFeetChange = (value) => {
    if (value.target) {
      value = value.target.value;
    }
    const newFeet = parseInt(value || 0, 10);
    setFeet(newFeet);
    const total = newFeet * INCHES_IN_FOOT + inches;
    setFieldValue(name, total);
  };
  const onInchesChange = (value) => {
    if (value.target) {
      value = value.target.value;
    }
    const newInches = parseInt(value || 0, 10);
    setInches(newInches);
    const total = feet * INCHES_IN_FOOT + newInches;
    setFieldValue(name, total);
  };

  return (
    <div>
      <NumericField value={feet} onChange={onFeetChange} label="FT" className="feet" />
      <NumericField value={inches} onChange={onInchesChange} label="IN" className="inches" />
      {hasError && <span>{error}</span>}
    </div>
  );
};

const HeightQuestion = ({ question }) => {
  const { name } = question;

  return (
    <div key={name}>
      <HeightField id={name} name={name} />
    </div>
  );
};

export default HeightQuestion;
