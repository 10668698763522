import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router/immutable';
import { Formik, Form } from 'formik';
import * as selectors from 'app/selectors/customer';
import * as actions from 'app/actions/customer';
import { PrimaryButton } from 'app/components/common/Button';
import { Dropdown } from 'app/components/common/formik/Dropdown';
import Input from 'app/components/common/formik/Input';
import { H6 } from 'app/components/common/Typography';
import { DriverLicenseSchema } from 'app/helpers/validators';

const DriverLicenseConfirmation = ({ onSuccessAction, step, dispatch, intake }) => {
  const initialValues = {
    intake: intake.get('name'),
  };

  return (
    <div className="driver-license-confirmation p16">
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          const action = actions.apiRequestUserCommand({
            cmdType: 'answer_questions',
            params: { [step]: true, ...values },
            context: { onSuccessAction },
          });
          dispatch(action);
        }}
        validationSchema={DriverLicenseSchema}
      >
        {({ errors, handleSubmit }) => (
          <Form>
            <div className="progress-bar">
              <div className="flex">
                <H6>
                  <div className="line-bar" />
                  Confirm Driver’s License
                </H6>
              </div>
            </div>
            <br />
            <p className="mb24">
              Please confirm your driver’s license state and license number. This is required to finish sign up for Oral
              TRT.
            </p>
            <br />

            <div className="inputs">
              <div className="inputs__item">
                <Dropdown
                  id="king_v2_id_state_confirmation"
                  name="king_v2_id_state_confirmation"
                  emptyValue="Select Your State"
                  showLabel={false}
                  position="bottom"
                  className="driver-license-confirmation__dropdown"
                />
              </div>
              {errors && errors.state && <div className="errors">{errors.state}</div>}
              <br />

              <div className="inputs__item">
                <Input
                  id="king_v2_id_number_confirmation"
                  name="king_v2_id_number_confirmation"
                  type="license"
                  placeholder="Enter Driver's License Number"
                  className="text-field"
                />
              </div>
            </div>

            <PrimaryButton
              id="driver_license_confirmation_submit"
              type="submit"
              text="Next"
              onClick={handleSubmit}
              className="mv24 mt36"
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

const mapStateToProps = (state, { step }) => {
  const onSuccessAction = push(selectors.selectNextStepPath(state));
  const intake = selectors.selectCurrentIntake(state);

  return {
    onSuccessAction,
    intake,
    step,
  };
};

export default connect(mapStateToProps)(DriverLicenseConfirmation);
