import React, { FC } from 'react';
import EbookImage from 'images/ebook.jpg';
import '../css/ResourceSection.scss';
import { ResourceSection } from './ResourceSection';

export const EBook: FC = () => (
  <ResourceSection
    image={EbookImage}
    title="The Maximus E-Book"
    text="To learn more about testosterone and the steps you can take to maximize your masculinity, check out the Maximus E-Book."
    urls={[[window.EBOOK_URL, 'View E-Book']]}
  />
);
