import './css/Payment.scss';
import React from 'react';

type Props = {
  children: React.ReactNode;
  className?: string;
};
const PaymentCard = ({ children, className = '' }: Props) => (
  <div className={`payment_card ${className}`}>{children}</div>
);

export default PaymentCard;
