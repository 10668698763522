import { createContext } from 'react';
export interface PaymentContextUserInfo {
  full_name: string;
  address_line_1: string;
  address_line_2?: string | null;
  city: string;
  state: string;
  postal_code: string;
}
export interface IPaymentContext {
  componentFactory: (props: any) => JSX.Element;
  paymentProvider: 'spreedly' | 'stripe' | 'klarna';
  createPaymentMethod: (
    userInfo: PaymentContextUserInfo,
  ) => Promise<{ cardBrand: string; cardLastFour: string; paymentMethodId: string }>;
  isValid: () => boolean;
  invalid?: boolean;
}

export const PaymentContext = createContext<IPaymentContext | undefined>(undefined);
