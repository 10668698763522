import React, { useMemo } from 'react';
import KlarnaIcon from 'app/components/customer/steps/Payment/Klarna/KlarnaIcon';
import { useAppSelector } from 'app/helpers/hooks';
import { selectCurrentIntakeProduct } from 'app/selectors/customer';
import protocolNames from 'app/utils/protocolNames';

type TOrderLine = {
  name: string;
  quantity: number;
  unit_price: number;
  total_amount: number;
  total_discount_amount: number;
  type?: string;
};

const KlarnaButton = ({ disabled, onSuccessfulPayment, priceCalculator, ...extraProps }) => {
  const currentProductName = useAppSelector(selectCurrentIntakeProduct);

  const buttonStyle = useMemo(
    () =>
      ({
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: disabled ? 'dimgray' : 'black',
      }) as React.CSSProperties,
    [disabled],
  );

  const iconStyle = useMemo(
    () => ({
      width: 141,
      height: 32,
    }),
    [],
  );

  const sessionPayload = useMemo(() => {
    const order_lines: TOrderLine[] = [];

    const subscriptionCost = priceCalculator.subscriptionCost();
    const labCost = priceCalculator.labCost();
    const appointmentCost = priceCalculator.appointmentCost();

    if (subscriptionCost > 0) {
      order_lines.push({
        name: `${protocolNames[currentProductName]} Subscription`,
        quantity: 1,
        unit_price: subscriptionCost,
        total_amount: subscriptionCost,
        total_discount_amount: 0,
      });
    }

    if (labCost > 0) {
      order_lines.push({
        name: `${protocolNames[currentProductName]} Lab`,
        quantity: 1,
        unit_price: labCost,
        total_amount: labCost,
        total_discount_amount: 0,
      });
    }

    if (appointmentCost > 0) {
      order_lines.push({
        name: `${protocolNames[currentProductName]} Appointment`,
        quantity: 1,
        unit_price: appointmentCost,
        total_amount: appointmentCost,
        total_discount_amount: 0,
      });
    }

    priceCalculator.discounts.forEach((discount) => {
      order_lines.push({
        name: `${discount.get('coupon').get('code')} Discount`,
        quantity: 1,
        unit_price: -priceCalculator.discountPrice(discount),
        total_amount: -priceCalculator.discountPrice(discount),
        total_discount_amount: 0,
        type: 'discount',
      });
    });

    return {
      purchase_country: 'US',
      purchase_currency: 'USD',
      locale: 'en-US',
      order_amount: priceCalculator.totalDueToday(),
      order_lines: order_lines,
      intent: 'buy_and_default_tokenize',
    };
  }, [currentProductName, priceCalculator]);

  const clickHandler = async () => {
    try {
      const response = await fetch('/api/klarna/create_session', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(sessionPayload),
      });
      const data = await response.json();
      // @ts-expect-error Use Klarna SDK to initialize payment
      window.Klarna.Payments.init({ client_token: data['client_token'] });
      // @ts-expect-error Use Klarna SDK to authorize payment
      window.Klarna.Payments.authorize({}, {}, function (res) {
        onSuccessfulPayment({
          paymentMethodId: res.authorization_token,
          paymentMethodSource: 'klarna',
          paymentMethodType: 'klarna_token',
        });
      });
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <button
      className={`default-button apple-pay-button apple-pay-button-dark`}
      onClick={clickHandler}
      style={buttonStyle}
      disabled={disabled}
      {...extraProps}
    >
      <KlarnaIcon style={iconStyle} disabled={disabled} />
    </button>
  );
};

export default KlarnaButton;
