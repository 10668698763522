import React, { useState } from 'react';
import { useAppDispatch } from 'app/helpers/hooks';
import SetProductDialog from '@setproduct-ui/core/Dialog';
import { PrimaryButton } from 'app/components/common/Button';
import * as actions from 'app/actions/customer';
import '../css/ReferralSourceModal.scss';
import '../css/AdkDiscontinuationModal.scss';
import protocolNames from 'app/utils/protocolNames';

const ModalTitle = () => (
  <div className="referral-source-modal__title">Dear {protocolNames['king']} Protocol Subscribers,</div>
);

const ModalBody = ({ skipModal }) => (
  <div className="referral-source-modal__body">
    <div className="referral-source-modal__content">
      <div className="referral-source-modal__options">
        <p>
          We previously sent an email mentioning that we are discontinuing the ADK Evail supplement, but it was missed
          by some, so I wanted to address this more directly. While ADK Evail was the best ‘off-the-shelf’ supplement we
          found to complement our {protocolNames['king']} Protocol, it is not personalized for Maximus members (i.e. the
          instructions on the label said to take one capsule, while we recommended two capsules for optimal dosing) and
          thus not an ideal solution.
        </p>
        <p>
          However, temporarily shipping it bought us time to manufacture a long-term solution: our own customized
          Maximus ‘Building Blocks’ supplement that is scientifically formulated for supporting hormone optimization
          (with Geranylgeraniol, Tocotrienols), and also saves you money by replacing your standard supplements with
          better versions (e.g. a multivitamin, bioavailable B-Complex, properly-dosed Vitamin D3/K2, etc.).
        </p>
        <p>
          Previously, we were purchasing ADK Evail and including it in the {protocolNames['king']} Protocol without
          profit, but having the cost come out of our margins. As a startup you often do such things that don’t scale,
          but have to institute better business practices as you bring on investors who expect healthy margins and a
          sustainable long-term business.
        </p>
        <p>
          The new Maximus Building Blocks supplement costs us more to manufacture (given it has 15+ ingredients vs. 5+
          in ADK Evail). In addition, we now offer multiple protocols ({protocolNames['king']}, {protocolNames['lover']}
          , etc.) and many of our members are on both, so it no longer makes sense to automatically bundle it with the{' '}
          {protocolNames['king']} Protocol, but to make it an optional add-on to any one of our protocols (coming soon
          to {protocolNames['lover']} Protocol subscribers). While this has to cost extra, we are offering more value in
          exchange and better results for you.
        </p>
        <p>
          A few customers have misinterpreted a month-to-month membership to mean a lifetime commitment on our part to
          provide them certain pharmaceuticals at a fixed price forever. This is a misunderstanding: a Maximus
          membership gives you concierge access to doctors who are on call and responsive to your questions/needs and
          personalize a protocol for you, based on best available evidence and availability.
        </p>
        <p>
          When I started the company, I made a very intentional decision to sell protocols, not just pills. This is to
          avoid becoming a pill mill in which consumers treat it like an à la carte menu (which turns the doctor into a
          prescription ‘rubber stamper’), and also not keeping up with the research literature and clinical best
          practices. Protocols, by definition, should be updated over time. There are zero commitments to our protocols
          right now, so if you’re ever unhappy about this or any changes, you can discontinue any month thereafter. But
          in order to best provide care, we should be continually updating things.
        </p>
        <p>
          Our long-term vision has always been to be a premium brand that spares no cost in providing the best clinical
          protocols to a mainstream audience. If you’re a price shopper, that’s great, but you are not our target
          customer. Good ingredients and quality care is not cheap, and that’s not the business we’re in or who we cater
          to.
        </p>
        <p>
          In the coming months, we will continue to upgrade the {protocolNames['king']} Protocol into v2, which will
          include multiple treatment options to increase testosterone, since everyone responds uniquely to different
          pharmaceuticals, and we’re continually doing R&D and lab testing to bring the cutting-edge from sports and
          performance medicine to ‘corporate athletes’ who are our target customer. Thanks for your support as we
          continually improve to be better together.
        </p>

        <div className="author">
          <div className="author__image" />
          <div className="author__text">
            In Health,
            <br />
            <b>Dr. Cam</b> and the <b>Maximus Team</b>
          </div>
        </div>

        <PrimaryButton onClick={skipModal} data-testid="adk-discontinuation-continue" text="Continue" />
      </div>
    </div>
  </div>
);

const AdkDiscontinuationModal = () => {
  const dispatch = useAppDispatch();
  const [dialogOpen, setDialogOpen] = useState(true);
  const skipModal = () => {
    setDialogOpen(false);
    const cmdType = 'answer_questions';
    const params = { notified_adk_discontinuation: true };
    const action = actions.apiRequestUserCommand({
      cmdType,
      params,
      context: {},
    });
    dispatch(action);
  };

  return (
    <SetProductDialog
      isOpen={dialogOpen}
      onClose={skipModal}
      title={<ModalTitle />}
      text={<ModalBody skipModal={skipModal} />}
      className="referral-source-modal adk-discontinuation"
    />
  );
};

export default AdkDiscontinuationModal;
