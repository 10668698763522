import { AvailableProducts, KingV2SubProducts, MagicianSubProducts } from 'app/constants/Products';

const subProductNames = {
  [AvailableProducts.King]: {
    [KingV2SubProducts.EncloPregnolone]: 'Enclomiphene + Pregnenolone',
    [KingV2SubProducts.Enclo]: 'Enclomiphene',
    [KingV2SubProducts.OralTrt]: 'Oral TRT',
    [KingV2SubProducts.EncloPregnoloneTrt]: 'Oral TRT+',
    [KingV2SubProducts.EncloTrt]: 'Oral TRT + Enclomiphene',
    [KingV2SubProducts.Topical]: 'Topical TRT',
    [KingV2SubProducts.EncloTopical]: 'Topical TRT + Enclomiphene',
    [KingV2SubProducts.Injectable]: 'Injectable TRT',
    [KingV2SubProducts.EncloInjectable]: 'Injectable TRT + Enclomiphene',
    [KingV2SubProducts.InjectableHCG]: 'Injectable TRT + hCG',
  },
  [AvailableProducts.Magician]: {
    [MagicianSubProducts.Semaglutide]: 'Semaglutide',
    [MagicianSubProducts.Tirzepatide]: 'Tirzepatide',
  },
};

export default subProductNames;
