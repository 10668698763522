import React from 'react';
import { P } from 'app/components/common/Typography';
import { connect } from 'react-redux';
import { push } from 'connected-react-router/immutable';
import * as actions from 'app/actions/customer';
import * as selectors from 'app/selectors/customer';
import { PrimaryButton } from 'app/components/common/Button';
import HeadingWithIcon from 'app/components/common/HeadingWithIcon';
import BulletCheckmark from 'images/Icon-Bullet-Checkmark.svg';
import './css/LoverIntro.scss';
import * as Routes from 'app/constants/Routes';
import protocolNames from '../../../utils/protocolNames';

const benefits = [
  'Stronger erections; longer endurance',
  'Greater sexual satisfaction. Go multiple times',
  'Better muscle pumps; less muscle fatigue',
  'Enhanced memory, attention, & processing speed',
];
const access = [
  'Prescription medication tablets (if qualified)',
  'Licensed doctor consultations',
  'Health coaching & community',
  'Priority shipping for meds',
];

const mapStateToProps = (state) => ({
  intake: selectors.selectCurrentIntake(state),
});

const LoverOnboardingAcknowledgement = ({ step, intake, dispatch }) => {
  const action = actions.apiRequestUserCommand({
    cmdType: 'answer_questions',
    params: { [step]: true, intake: intake.get('name') },
    context: {
      onSuccessActionCreator: ({ json: { entity } }) =>
        push(
          Routes.createIntake(
            intake.get('name'),
            entity.maximus_products.lover.intakes[0].steps.filter((step) => !step.completed)[0].url_friendly_name,
          ),
        ),
    },
  });

  const onClick = () => {
    dispatch(action);
  };

  return (
    <div className="p16 lover-intro">
      <h1>
        <span>{protocolNames['lover']}</span>
      </h1>
      <P className="mb20">
        {protocolNames['lover']} comes in a convenient, orally disintegrating tablet that stimulates your body's own
        natural blood flow. This combination is fast-acting and long-lasting so you perform your best in the bedroom,
        boardroom, and the gym.
      </P>

      <P className="bold">Blood flow benefits include:</P>
      <ul>
        {benefits.map((benefit) => (
          <li key={benefit}>
            <HeadingWithIcon icon={BulletCheckmark} text={benefit} tag={P} className="mv8" />
          </li>
        ))}
      </ul>
      <P className="bold mt36">With your subscription you have access to:</P>
      <ul>
        {access.map((a) => (
          <li key={a}>
            <HeadingWithIcon icon={BulletCheckmark} text={a} tag={P} className="mv8" />
          </li>
        ))}
      </ul>
      <PrimaryButton className="mt16 mb24" data-testid="next-step" text="Start my journey" onClick={onClick} />
    </div>
  );
};

export default connect(mapStateToProps)(LoverOnboardingAcknowledgement);
